.map-attribution {
  position: fixed;
  bottom: 52px;
  min-width: 456px;

  .sr-attribution {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
  }
}
