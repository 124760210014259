.summary-container {
  padding: 17px 13px 20px 13px;
  width: 100%;

  input {
    border-radius: 4px;
  }
  .content-wrapper {
    margin-top: 13px;
    .description {
      margin-top: 10px;
      word-wrap: break-word;
      max-width: 100%;
      max-height: 125px;
      overflow: auto;
      .edit-desc {
        resize: none;
        border-radius: 4px;
        max-height: 115px;
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 12px;
        padding: 0;
        padding-left: 5px;
      }
    }
  }
  .add-attributes-wrapper {
    margin-top: 30px;
    .msg-content {
      margin-top: 6px;
    }
    .create-att-but {
      margin-top: 8px;
      font-size: 12px;
    }
  }

  .but-wrapper {
    text-align: right;
    margin-top: auto;
  }

  .styler-tool-wrapper {
    z-index: 10;
    background: #ffffff;
    width: 280px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  }
}

.feature-title {
  max-width: 200px;
}
