.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  min-height: 0;
}
.sidebarFinal {
  background: rgba(248, 249, 250, 1);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  min-height: 0;
  padding: 4px 4px 8px;
}
.sidebarFinal > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.sidebarFinal > :global(.__wab_flex-container) > *,
.sidebarFinal > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sidebarFinal > :global(.__wab_flex-container) > picture > img,
.sidebarFinal
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.freeBox {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: 100%;
  max-width: 100%;
  min-height: 0;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.pcls_H4lkggZON2Mt {
  outline: none;
  padding: 0px;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.bidMeterWidget2:global(.__wab_instance) {
  max-width: 100%;
}
.coOrderCartBidConsumeWrapper:global(.__wab_instance) {
  max-width: 100%;
}
