@import '../../../../../styles/base/colors.less';

.color-container {
  input[type='color'] {
    width: 100%;
    height: 30px;
    padding: 2px 2px;
    border-radius: 4px;
    border: 1px solid #dedede;
    outline: none;
  }

  input[type='color']:active {
    outline: none;
  }

  input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type='color']::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }
}

.arrow-container .icons-list .active {
  background-color: rgba(255, 208, 43, 0.1) !important;
  border: 0.5px solid @main-color !important;
}

.edit-btn {
  color: @blue;
}
