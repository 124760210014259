.estimationView3 {
  width: 248px;
  height: 32px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
  justify-self: flex-start;
  padding: 4px 8px 4px 12px;
}
.estimationView3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.estimationView3 > :global(.__wab_flex-container) > *,
.estimationView3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.estimationView3 > :global(.__wab_flex-container) > picture > img,
.estimationView3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.estimationView3isActiveEstimation {
  background: #fee9ad;
}
.estimationView3isActiveEstimation > :global(.__wab_flex-container) {
  justify-content: flex-start;
  align-items: center;
}
.estimationView3rename2 {
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #ffd02b;
  border-radius: 4px;
}
.estimationView3rename2 > :global(.__wab_flex-container) {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.estimationView3rename2 > :global(.__wab_flex-container) > *,
.estimationView3rename2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.estimationView3rename2 > :global(.__wab_flex-container) > picture > img,
.estimationView3rename2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.estimationView3isDisabled {
  background: #e8e8e8;
  cursor: default;
}
.estimationView3isActiveEstimation_isDisabled {
  cursor: default;
}
.estimationView3:hover {
  background: #e9e9e9;
  width: 248px;
  flex-grow: 0;
  padding-right: 8px;
}
.estimationView3:hover > :global(.__wab_flex-container) {
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.estimationView3:hover > :global(.__wab_flex-container) > *,
.estimationView3:hover
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.estimationView3:hover > :global(.__wab_flex-container) > picture > img,
.estimationView3:hover
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.estimationView3isActiveEstimation:hover {
  background: #fee9ad;
}
.estimationView3rename2:hover {
  background: #ffffff;
}
.estimationView3isDisabled:hover {
  background: #e8e8e8;
  cursor: auto;
}
.svg__rwDp1 {
  object-fit: cover;
  max-width: 100%;
  color: #999;
  width: 16px;
  height: 32px;
  flex-shrink: 0;
}
.svgrename2__rwDp13ViDp {
  display: none;
}
.svg__iHvdc {
  object-fit: cover;
  max-width: 100%;
  color: #999;
  width: 16px;
  height: 32px;
  flex-shrink: 0;
}
.svgrename2__iHvdc3ViDp {
  display: none;
}
.freeBox__rh3Tu {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  width: 100%;
  min-width: 0;
}
.freeBox__rh3Tu > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__rh3Tu > :global(.__wab_flex-container) > *,
.freeBox__rh3Tu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rh3Tu > :global(.__wab_flex-container) > picture > img,
.freeBox__rh3Tu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.freeBoxisActiveEstimation__rh3Tu5EbYz {
  width: 100%;
  min-width: 0;
}
.freeBoxisActiveEstimation__rh3Tu5EbYz > :global(.__wab_flex-container) {
  min-width: 0;
}
.freeBoxrename2__rh3Tu3ViDp {
  width: 100%;
  min-width: 0;
}
.freeBoxrename2__rh3Tu3ViDp > :global(.__wab_flex-container) {
  min-width: 0;
}
.estimationView3:hover .freeBox__rh3Tu {
  width: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  min-width: 0;
}
.estimationView3:hover .freeBox__rh3Tu > :global(.__wab_flex-container) {
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.estimationView3:hover .freeBox__rh3Tu > :global(.__wab_flex-container) > *,
.estimationView3:hover
  .freeBox__rh3Tu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.estimationView3:hover
  .freeBox__rh3Tu
  > :global(.__wab_flex-container)
  > picture
  > img,
.estimationView3:hover
  .freeBox__rh3Tu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.estimationView3rename2:hover .freeBoxrename2__rh3Tu3ViDp {
  width: 100%;
  min-width: 0;
}
.estimationView3rename2:hover
  .freeBoxrename2__rh3Tu3ViDp
  > :global(.__wab_flex-container) {
  min-width: 0;
}
.estimationView3rename2_isActiveEstimation:hover
  .freeBoxrename2_isActiveEstimation__rh3Tu3ViDp5EbYz {
  width: 100%;
  min-width: 0;
}
.estimationView3rename2_isActiveEstimation:hover
  .freeBoxrename2_isActiveEstimation__rh3Tu3ViDp5EbYz
  > :global(.__wab_flex-container) {
  min-width: 0;
}
.svg__cVfk6 {
  object-fit: cover;
  max-width: 100%;
  color: #666666;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.svgisActiveEstimation__cVfk65EbYz {
  width: 12px;
  color: #333333;
  flex-shrink: 0;
  display: block;
}
.svgrename2__cVfk63ViDp {
  display: none;
}
.svgisDisabled__cVfk6P0Ocb {
  color: #999999;
}
.estimationView3:hover .svg__cVfk6 {
  width: 12px;
  color: #333333;
  flex-shrink: 0;
  display: block;
}
.estimationView3isActiveEstimation:hover .svgisActiveEstimation__cVfk65EbYz {
  display: block;
}
.estimationView3rename2:hover .svgrename2__cVfk63ViDp {
  display: none;
}
.estimationView3isDisabled:hover .svgisDisabled__cVfk6P0Ocb {
  color: #999999;
}
.estimationView3rename2_isActiveEstimation:hover
  .svgrename2_isActiveEstimation__cVfk63ViDp5EbYz {
  display: none;
}
.freeBox___7NBd {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  cursor: pointer;
  min-width: 0;
}
.freeBox___7NBd > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox___7NBd > :global(.__wab_flex-container) > *,
.freeBox___7NBd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___7NBd > :global(.__wab_flex-container) > picture > img,
.freeBox___7NBd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.freeBoxisActiveEstimation___7NBd5EbYz {
  width: 100%;
  align-self: auto;
  min-width: 0;
}
.freeBoxisActiveEstimation___7NBd5EbYz > :global(.__wab_flex-container) {
  min-width: 0;
}
.freeBoxrename2___7NBd3ViDp {
  width: 100%;
  min-width: 0;
}
.freeBoxrename2___7NBd3ViDp > :global(.__wab_flex-container) {
  min-width: 0;
}
.estimationView3:hover .freeBox___7NBd {
  flex-shrink: 1;
}
.estimationView3:hover .freeBox___7NBd > :global(.__wab_flex-container) {
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.estimationView3:hover .freeBox___7NBd > :global(.__wab_flex-container) > *,
.estimationView3:hover
  .freeBox___7NBd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.estimationView3:hover
  .freeBox___7NBd
  > :global(.__wab_flex-container)
  > picture
  > img,
.estimationView3:hover
  .freeBox___7NBd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.estimationView3rename2:hover .freeBoxrename2___7NBd3ViDp {
  width: 100%;
  min-width: 0;
}
.estimationView3rename2:hover
  .freeBoxrename2___7NBd3ViDp
  > :global(.__wab_flex-container) {
  min-width: 0;
}
.estimationView3rename2_isActiveEstimation:hover
  .freeBoxrename2_isActiveEstimation___7NBd3ViDp5EbYz {
  width: 100%;
  min-width: 0;
}
.estimationView3rename2_isActiveEstimation:hover
  .freeBoxrename2_isActiveEstimation___7NBd3ViDp5EbYz
  > :global(.__wab_flex-container) {
  min-width: 0;
}
.input:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.inputrename2:global(.__wab_instance) {
  display: flex;
}
.frame1000004957 {
  height: auto;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  display: none;
}
.frame1000004957 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000004957 > :global(.__wab_flex-container) > *,
.frame1000004957 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004957 > :global(.__wab_flex-container) > picture > img,
.frame1000004957
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000004957rename2 {
  display: flex;
}
.estimationView3:hover .frame1000004957 {
  flex-shrink: 1;
  width: auto;
  display: none;
}
.estimationView3rename2:hover .frame1000004957rename2 {
  display: none;
}
.frame1000001994 {
  background: rgba(244, 244, 244, 1);
  width: auto;
  height: auto;
  position: relative;
  flex-direction: row;
  display: none;
  border-radius: 8px;
  padding: 2px 8px;
}
.frame1000001994 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000001994 > :global(.__wab_flex-container) > *,
.frame1000001994 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001994 > :global(.__wab_flex-container) > picture > img,
.frame1000001994
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.frame1000001994rename2 {
  display: none;
}
.estimationView3:hover .frame1000001994 {
  display: flex;
}
.estimationView3rename2:hover .frame1000001994rename2 {
  display: none;
}
.text___50O2V {
  display: block;
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(102, 102, 102, 1);
  position: relative;
  font-style: normal;
  text-align: left;
  text-transform: none;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.tooltipCore:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  display: none;
}
.tooltipCoreisDisabled:global(.__wab_instance) {
  display: none;
}
.estimationView3:hover .tooltipCore:global(.__wab_instance) {
  display: flex;
}
.text__qmmWk {
  position: relative;
  height: auto;
  color: #666666;
  white-space: pre;
  text-overflow: ellipsis !important;
  overflow: hidden;
  font-size: 12px;
  pointer-events: auto;
  cursor: pointer;
  width: 100%;
  min-width: 0;
}
.textisActiveEstimation__qmmWk5EbYz {
  color: #333333;
}
.textrename2__qmmWk3ViDp {
  display: none;
}
.textisDisabled__qmmWkp0Ocb {
  color: #999999;
}
.textisActiveEstimation_isDisabled__qmmWk5EbYzP0Ocb {
  cursor: default;
}
.estimationView3:hover .text__qmmWk {
  color: #333333;
  white-space: pre;
  text-overflow: ellipsis !important;
  overflow: hidden;
  pointer-events: auto;
  cursor: pointer;
  width: 100%;
  flex-shrink: 1;
  min-width: 0;
}
.estimationView3isDisabled:hover .textisDisabled__qmmWkp0Ocb {
  color: #999999;
  cursor: default;
}
.freeBox__k7H7P {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: 24px;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  box-shadow: none;
  background: #333333;
  pointer-events: none;
  border-radius: 4px;
  padding: 4px 8px;
}
.freeBoxisDisabled__k7H7Pp0Ocb {
  max-width: 200px;
  height: auto;
  display: flex;
}
.freeBoxisActiveEstimation_isDisabled__k7H7P5EbYzP0Ocb {
  max-width: 200px;
  display: flex;
}
.estimationView3:hover .freeBox__k7H7P {
  pointer-events: none;
  display: flex;
}
.estimationView3isDisabled:hover .freeBoxisDisabled__k7H7Pp0Ocb {
  max-width: 200px;
  height: auto;
  display: flex;
}
.text__kTtB {
  color: #ffffff;
  font-size: 12px;
  display: none;
}
.textisDisabled__kTtBp0Ocb {
  white-space: pre-wrap;
}
.textisActiveEstimation_isDisabled__kTtB5EbYzP0Ocb {
  white-space: pre-wrap;
}
.estimationView3:hover .text__kTtB {
  pointer-events: none;
  display: block;
}
.estimationView3isDisabled:hover .textisDisabled__kTtBp0Ocb {
  white-space: pre-wrap;
}
.frame1000001995 {
  background: rgba(244, 244, 244, 1);
  width: auto;
  height: 16px;
  display: flex;
  position: relative;
  flex-direction: row;
  border-radius: 8px;
  padding: 2px 8px;
}
.frame1000001995 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000001995 > :global(.__wab_flex-container) > *,
.frame1000001995 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001995 > :global(.__wab_flex-container) > picture > img,
.frame1000001995
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.frame1000001995isActiveEstimation {
  display: flex;
}
.frame1000001995rename2 {
  display: none;
}
.text__dMzj {
  display: block;
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  font-style: normal;
  text-align: left;
  text-transform: none;
  white-space: pre;
  user-select: none;
  text-decoration-line: none;
}
.textisDisabled__dMzJp0Ocb {
  color: #999999;
}
.estimationView3:hover .text__dMzj {
  line-height: 12px;
}
.freeBox__pkQo2 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBox__pkQo2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__pkQo2 > :global(.__wab_flex-container) > *,
.freeBox__pkQo2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pkQo2 > :global(.__wab_flex-container) > picture > img,
.freeBox__pkQo2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.freeBoxisActiveEstimation__pkQo25EbYz {
  width: auto;
  display: flex;
  padding: 0px;
}
.freeBoxrename2__pkQo23ViDp {
  display: none;
}
.estimationView3:hover .freeBox__pkQo2 {
  width: auto;
  flex-shrink: 0;
  display: flex;
  padding: 0px;
}
.estimationView3:hover .freeBox__pkQo2 > :global(.__wab_flex-container) {
  justify-content: flex-end;
  align-items: center;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.estimationView3:hover .freeBox__pkQo2 > :global(.__wab_flex-container) > *,
.estimationView3:hover
  .freeBox__pkQo2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.estimationView3:hover
  .freeBox__pkQo2
  > :global(.__wab_flex-container)
  > picture
  > img,
.estimationView3:hover
  .freeBox__pkQo2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.estimationView3rename2:hover .freeBoxrename2__pkQo23ViDp {
  display: none;
}
.estimationView3isDisabled:hover .freeBoxisDisabled__pkQo2P0Ocb {
  display: none;
}
.eMviewPanelChips:global(.__wab_instance) {
  max-width: 100%;
  width: 24px;
  flex-shrink: 0;
}
.estimationView3:hover .eMviewPanelChips:global(.__wab_instance) {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_CFt7CKQuT4Qi {
  outline: none;
  padding: 0px;
}
.popoverisDisabled:global(.__wab_instance) {
  display: none;
}
.freeBox__hjJiy {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: auto;
  max-width: 100%;
  border-radius: 2px;
}
.svg__uyUiq {
  object-fit: cover;
  max-width: 100%;
  color: #aaaaaa;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.estimationView3 .svg__uyUiq:hover {
  color: #333333;
}
.tooltipContent:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.freeBox__auOwv {
  position: relative;
  flex-direction: row;
  width: auto;
  height: 24px;
  display: none;
}
.freeBox__auOwv > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox__auOwv > :global(.__wab_flex-container) > *,
.freeBox__auOwv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__auOwv > :global(.__wab_flex-container) > picture > img,
.freeBox__auOwv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.freeBoxisActiveEstimation__auOwv5EbYz {
  flex-direction: row-reverse;
  display: flex;
}
.freeBoxisActiveEstimation__auOwv5EbYz > :global(.__wab_flex-container) {
  flex-direction: row-reverse;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxisActiveEstimation__auOwv5EbYz > :global(.__wab_flex-container) > *,
.freeBoxisActiveEstimation__auOwv5EbYz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxisActiveEstimation__auOwv5EbYz
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxisActiveEstimation__auOwv5EbYz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.freeBoxrename2__auOwv3ViDp {
  display: none;
}
.estimationView3:hover .freeBox__auOwv {
  flex-direction: row-reverse;
  display: flex;
}
.estimationView3:hover .freeBox__auOwv > :global(.__wab_flex-container) {
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.estimationView3:hover .freeBox__auOwv > :global(.__wab_flex-container) > *,
.estimationView3:hover
  .freeBox__auOwv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.estimationView3:hover
  .freeBox__auOwv
  > :global(.__wab_flex-container)
  > picture
  > img,
.estimationView3:hover
  .freeBox__auOwv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.estimationView3rename2:hover .freeBoxrename2__auOwv3ViDp {
  display: none;
}
.estimationView3isActiveEstimation
  .freeBoxisActiveEstimation__auOwv5EbYz:hover {
  background: #0000001a;
}
.estimationView3:hover .freeBox__auOwv:hover {
  background: #3333331a;
}
.dropdown:global(.__wab_instance) {
  object-fit: cover;
  height: 100%;
  max-width: 100%;
  position: relative;
  min-height: 0;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu) {
  width: 164px;
  height: 104px;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu)
  :global(.ant-dropdown-menu-item) {
  color: #666666;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  width: 160px;
  height: 32px;
  flex-shrink: 1;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 0px;
  white-space: pre;
  background: #ffffff;
  outline: none;
  padding: 8px 12px;
}
.dropdownisActiveEstimation:global(.__wab_instance) {
  display: flex;
}
.dropdownrename2:global(.__wab_instance) {
  display: none;
}
.estimationView3:hover .dropdown:global(.__wab_instance) {
  font-size: 12px;
  display: flex;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  outline: none;
  border-radius: 8px;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu)
  :global(.ant-dropdown-menu-item) {
  color: #666666;
  font-weight: 500;
  font-size: 12px;
  outline: none;
  padding: 8px 12px;
}
.estimationView3isDisabled:hover .dropdownisDisabled:global(.__wab_instance) {
  display: none;
}
.freeBox___2ReG5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.estimationView3:hover .freeBox___2ReG5 {
  padding: 0px;
}
.menuItem__k8D2U:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  display: none;
}
.menuItemisActiveEstimation__k8D2U5EbYz:global(.__wab_instance) {
  display: flex;
}
.estimationView3:hover .menuItem__k8D2U:global(.__wab_instance) {
  display: flex;
}
.text__c1Th {
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #333333;
  min-width: 0;
}
.freeBox__dubz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.menuItem__ncGR:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  display: none;
}
.menuItemisActiveEstimation__ncGR5EbYz:global(.__wab_instance) {
  display: flex;
}
.estimationView3:hover .menuItem__ncGR:global(.__wab_instance) {
  display: flex;
}
.text___0EnWz {
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.estimationView3:hover .text___0EnWz {
  width: 100%;
  min-width: 0;
}
.freeBox__ryOwI {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.menuItem__pe90G:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  display: none;
}
.menuItemisActiveEstimation__pe90G5EbYz:global(.__wab_instance) {
  display: flex;
}
.estimationView3:hover .menuItem__pe90G:global(.__wab_instance) {
  display: flex;
}
.text__lp1X1 {
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.svg__l5Wil {
  position: relative;
  object-fit: cover;
  color: #666;
  width: 24px;
  height: 1em;
}
.svgisActiveEstimation__l5Wil5EbYz {
  color: #333333;
}
.estimationView3:hover .svg__l5Wil {
  color: #333333;
}
.sideEffect:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
