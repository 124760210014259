.counter-container {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
  width: 100%;
  height: 30px;
}

.btn-opacity {
  border: none;
  color: #666666;
  background-color: #f2f2f2;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  cursor: pointer;
}

.minus-icon-text-position {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-95%, -55%);
}

.plus-icon-text-position {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(150%, -55%);
}

.counter-btn {
  background-color: #d1d5db;
  width: 24px;
  height: 24px;

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
