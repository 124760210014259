.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
}
.component153 {
  background: rgba(255, 255, 255, 1);
  box-shadow: none;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-width: 0;
  border-radius: 4px;
}
.component153 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.component153 > :global(.__wab_flex-container) > *,
.component153 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.component153 > :global(.__wab_flex-container) > picture > img,
.component153
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000002613 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  min-width: 0;
  padding: 8px 12px;
}
.frame1000002613 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 128px);
  width: calc(100% + 128px);
}
.frame1000002613 > :global(.__wab_flex-container) > *,
.frame1000002613 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002613 > :global(.__wab_flex-container) > picture > img,
.frame1000002613
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 128px;
}
.text___0Mrr6 {
  display: block;
  font-size: undefinedpx;
  letter-spacing: 0px;
  line-height: 100%;
  position: relative;
}
.span___6Um4V {
  font-size: 10px;
  font-weight: 600;
}
.span___6FGj {
  color: #666666;
  font-size: 10px;
}
.svg__qKfw4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  cursor: pointer;
  flex-shrink: 0;
}
.svg__pYxCx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  cursor: pointer;
  flex-shrink: 0;
}
.group1000002087 {
  width: 100%;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  flex-shrink: 0;
}
.group1000002085 {
  width: 72px;
  height: 64px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.svg___5PVib {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #eee;
  transform: translate(0px, 0.20600000000000307px);
  width: 72px;
  height: 45px;
  display: block;
  top: 19px;
  left: 0px;
}
.rectangle26942 {
  background: rgba(255, 208, 42, 1);
  transform: translate(-0.3569999999999993px, -0.3100000000000023px);
  width: 46px;
  height: 56px;
  display: block;
  position: absolute;
  top: 9px;
  left: 11px;
  border-radius: 3.908px;
}
.rectangle26944 {
  background: rgba(214, 167, 0, 1);
  transform: scale(0.9994598541212149, 0.9992977665702922)
    rotate(-3.0971522847343227deg) skew(-0.17172851698009206deg, 0deg)
    translate(0.4579999999999984px, 0.347999999999999px);
  width: 37px;
  height: 48px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 14px;
  left: 13px;
  border-radius: 1.954px;
}
.rectangle26943 {
  background: rgba(255, 255, 255, 1);
  transform: translate(0.26900000000000546px, -0.1490000000000009px);
  width: 37px;
  height: 46px;
  display: block;
  position: absolute;
  top: 12px;
  left: 15px;
  border-radius: 1.954px;
}
.rectangle26945 {
  background: rgba(215, 215, 215, 1);
  transform: translate(0.08699999999998909px, 0.3200000000000003px);
  width: 27px;
  height: 8px;
  display: block;
  position: absolute;
  top: 6px;
  left: 20px;
  border-radius: 9.77px 9.77px 3.908px 3.908px;
}
.group1000002084 {
  transform: translate(0.4939999999999998px, 0px);
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 0px;
  left: 30px;
}
.svg__b3Oy {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #d8d8d8;
  width: 7px;
  height: 7px;
  display: block;
  top: 0px;
  left: 0px;
}
.rectangle26947 {
  background: rgba(172, 172, 172, 1);
  transform: translate(0px, 0.134999999999998px);
  width: 7px;
  height: 2px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0px;
}
.svg__vhu7R {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #c5c5c5;
  width: 11px;
  height: 8px;
  display: block;
  top: 6px;
  left: 37px;
  transform: translate(-0.04099999999999682px, 0.3269999999999982px);
}
.svg___6V9Pp {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #d9d9d9;
  transform: translate(0.002999999999985903px, -0.07399999999999807px);
  width: 33px;
  height: 4px;
  display: block;
  top: 54px;
  left: 17px;
}
.svg__p51Cd {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ccc;
  transform: translate(0.10599999999999454px, 0.23400000000000176px);
  width: 23px;
  height: 17px;
  display: block;
  top: 21px;
  left: 38px;
  border-radius: 9.77px;
}
.svg__nadQ {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ccc;
  transform: translate(-0.42199999999999704px, -0.16100000000000136px);
  width: 4px;
  height: 3px;
  display: block;
  top: 37px;
  left: 34px;
  border-radius: 9.77px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  transform: scale(0.9988413287404562, 0.9996883101134304)
    rotate(13.312780089515309deg) skew(0.5419967014944053deg, 0deg)
    translate(0.2079999999999984px, 0.24900000000000233px);
  width: 10.937px;
  height: 12.285px;
  display: block !important;
  transform-origin: top left;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.frame1000005363 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 16px;
  width: 100%;
  min-width: 0;
}
.frame1000005363 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.frame1000005363 > :global(.__wab_flex-container) > *,
.frame1000005363 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005363 > :global(.__wab_flex-container) > picture > img,
.frame1000005363
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.frame1000005367 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
}
.frame1000005367 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005367 > :global(.__wab_flex-container) > *,
.frame1000005367 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005367 > :global(.__wab_flex-container) > picture > img,
.frame1000005367
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__uVue3 {
  display: block;
  flex-grow: 0;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  letter-spacing: 0px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  white-space: pre-wrap;
  flex-shrink: 1;
}
