@import '../../../style.less';

.toolbar-menu-wrapper {
  .tool {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: fit-content;
    height: 44px;
    background: #ffffff;
    border-radius: 0px;
    cursor: pointer;
    border-bottom-color: transparent !important;

    .inner-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      min-height: 44px;
      justify-content: space-around;
    }
  }

  .tool:hover {
    background: #e9e9e9 !important;
  }

  .tool.selected {
    background: #22b45c !important;

    img {
      filter: brightness(100);
    }

    span {
      color: #ffffff !important;
    }
  }

  .tool.disabled {
    cursor: not-allowed !important;
    background: #ffffff !important;
    pointer-events: none;
  }

  .tool.disabled:hover {
    background: #ffffff !important;
  }

  .ant-menu-item {
    margin-block: unset !important;
    margin-inline: unset !important;
    width: fit-content;
    border-radius: 0% !important;
    padding: 0 !important;
  }

  .ant-menu-title-content,
  .ant-menu-title-content>span {
    display: flex !important;
    align-items: center !important;
    gap: 4px !important;
  }

  .ant-menu-item-selected::after,
  .ant-menu-item-active::after {
    border-bottom-color: transparent !important;
  }

  .ant-popover-inner {
    padding: 0 !important;
  }
}

.toolbar-menu-popover {
  width: 140px !important;
  padding-top: 4px;
  padding-bottom: 4px;
  z-index: 999 !important;

  .dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 4px;
    height: 44px;
    background: #ffffff;
    border-radius: 0px;
    cursor: pointer;
  }

  .dropdown.selected {
    background: #22b45c !important;

    img {
      filter: brightness(100);
    }

    span {
      color: #ffffff !important;
    }
  }

  .dropdown:hover {
    background: #e9e9e9;
  }
}

.menu-wrong-layer {
  padding-right: 0 !important;
  z-index: 999 !important;

  .ant-popover-arrow {
    display: none;
  }

  .wrong-layer-container {
    display: flex;
    flex-direction: column;
    width: 240px;
    max-height: 600px;
    overflow-y: auto;
    padding: 12px 0px 0;
    align-items: flex-start;
    gap: 12px;
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      visibility: hidden !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #DDD !important;
      border-radius: 4px !important;
      width: 4px !important;
    }

    .header {
      display: flex;
      padding: 0px 12px;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;  

      .header-inner {
        border-radius: 4px;
        background: #F3F4F6;
        display: flex;
        padding: 4px;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
      }
    }

    .select-layer-section {

      .select-layer-header {
        padding: 0px 12px 8px;
      }

      .radio-set {
        .layer {
          cursor: pointer;
          align-items: center;
          padding: 12px;
          background: #ffffff;

          &:hover {
            background-color: #e9e9e9;
          }

          &:active {
            border: 1px solid #c9c9c9;
          }
        }

        .layer--disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      .no-layers-cover {
        gap: 5px;
        display: flex;
        flex-direction: column;
        margin-left: 40px;

        .no-layers {
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }

    }
  }
}

.menu-wrong-layer .ant-popover-inner-content {
  padding: 0;
  margin: 0;
}

.menu-wrong-layer .ant-popover-inner {
  padding: 0;
}

.menu-wrong-layer .ant-popover-inner-content>* {
  margin: 0;
}