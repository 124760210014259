.plans {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 0px 0px #ffd02b;
  width: 100%;
  height: 104px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  border-radius: 4px;
}
.plans > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.plans > :global(.__wab_flex-container) > *,
.plans > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.plans > :global(.__wab_flex-container) > picture > img,
.plans > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 4px;
}
.frame1000006362 {
  background: rgba(248, 249, 250, 1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding: 4px 8px;
}
.frame1000006362 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000006362 > :global(.__wab_flex-container) > *,
.frame1000006362 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006362 > :global(.__wab_flex-container) > picture > img,
.frame1000006362
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.text__wkWgP {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.frame1000006365 {
  height: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 0;
}
.frame1000006365 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000006365 > :global(.__wab_flex-container) > *,
.frame1000006365 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006365 > :global(.__wab_flex-container) > picture > img,
.frame1000006365
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000006050 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  padding-top: 3px;
  padding-bottom: 3px;
}
.frame1000006050 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006050 > :global(.__wab_flex-container) > *,
.frame1000006050 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006050 > :global(.__wab_flex-container) > picture > img,
.frame1000006050
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__myohD {
  display: block;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 18px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.coOrderCartBidConsume:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: auto;
  width: auto;
}
