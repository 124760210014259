.layer-modal {
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close {
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    .ant-modal-close-x {
      height: fit-content;
    }
  }
  .divide-feature {
    display: flex;
    flex-direction: row;
  }
}
.close-icon-class {
  position: absolute;
  top: -15px;
  left: 35px;
}
