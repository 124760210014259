.create-new-view-modal.ant-modal {
    width: auto !important;
    padding-bottom: 0 !important;
    
    .ant-modal-content {
        width: 803px;
        border-radius: 16px !important;

        .ant-modal-header {
            border-radius: 16px 16px 0 0 !important;
            padding: 14px 24px 10px;

            .ant-modal-title {
                font-size: 20px;
                line-height: 30px;
                font-weight: 500;
                color: #333333;
            }
        }

        .ant-modal-close-icon {
            margin-top: 34px;

            svg {
                color: #666666;
            }
        }

        .ant-modal-body {
            padding: 16px 24px 20px;

            .ant-btn.view-wrapper-btn {
                width: 100%;
                height: 100%;
                padding: 0 8px;
                position: relative;
                border: 0;
            }

            .ant-btn.view-wrapper-btn-active {
                width: 100%;
                height: 100%;
                padding: 0 8px;
                border-radius: 12px;
                position: relative;
                border: 2px solid #FFD02B;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
            }

            .view-wrapper-btn-active:after {
                z-index: 0;
                position: absolute;
                top: 101.1%;
                left: 70%;
                margin-left: -25%;
                content: '';
                width: 0;
                height: 0;
                border-top: solid 8px #FFD02B;
                border-left: solid 8px transparent;
                border-right: solid 8px transparent;
            }

            .new-view-section2 {
                border-radius: 12px;
                background-color: #F5F5F5;

                .display-img {
                    width: 212px;
                    height: 160px;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                .ant-divider-vertical {
                    height: 15rem;
                    margin: 0.75rem 0;
                }

                .input-view-name.ant-input {
                    width: 325px;
                    height: 36px;
                }

                .select-view-name.ant-select {
                    width: 325px;

                    .ant-select-selector {
                        height: 36px;
                    }
                }

                .action-btns {
                    .ant-btn {
                        height: 38px;
                        padding: 0 24px;
                        border: 1px solid rgba(153, 153, 153, 0.5) !important;
                    }

                    .ant-btn-primary {
                        border: 0 !important;
                        background: #FFD02B;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
                        background-image: none;
                    }
                }
            }
        }
    }
}