.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  justify-self: flex-start;
  min-width: 0;
  min-height: 0;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_DJLVv8Ui5Km7 {
  outline: none;
  padding: 0px;
}
.freeBox__iUom {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  cursor: pointer;
  margin-left: 4px;
  min-width: 0;
  min-height: 0;
}
.freeBox__iUom > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.freeBox__iUom > :global(.__wab_flex-container) > *,
.freeBox__iUom > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__iUom > :global(.__wab_flex-container) > picture > img,
.freeBox__iUom
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.takeoffLayerServiceItemsNumber__pZzZe:global(.__wab_instance) {
  max-width: 100%;
  margin-left: calc(-4px + 0px) !important;
  flex-shrink: 0;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 16px;
  margin-left: calc(-4px + 0px) !important;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.takeoffLayerPanelServiceItemPopup__xxgyg:global(.__wab_instance) {
  max-width: 100%;
}
.popover3:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  box-shadow: none;
  filter: none;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}
.popover3__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_B0kgM1fVPBNZ {
  color: #000000e6;
  outline: none;
  padding: 0px;
}
.freeBox__qEtjx {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  cursor: pointer;
  min-width: 0;
  min-height: 0;
}
.freeBox__qEtjx > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox__qEtjx > :global(.__wab_flex-container) > *,
.freeBox__qEtjx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qEtjx > :global(.__wab_flex-container) > picture > img,
.freeBox__qEtjx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.takeoffLayerServiceItemsNumber__d9IYj:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 0;
}
.takeoffLayerPanelServiceItemPopup__xqIs7:global(.__wab_instance) {
  max-width: 100%;
}
