.custom-button {
    font-size: 12px;
    padding: 6px 16px;
    background-color: #000000;
    border: 1px solid #2d2d2d;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    height: auto;
}

.custom-button:hover {
    background-color: #3f3d3d;
    border: 1px solid #333333;
}

.custom-button:active {
    background-color: #000000;
    border: 1px solid #333;
    box-shadow: 0px 0px 0px 2px #999;
}

.custom-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

/* Add this CSS */
.custom-hover-button.ant-btn:active {
    background-color: #FFF9E4 !important;
    border: '1px solid #FFD02B',
}

button.ant-btn.custom-hover-add-feedback-button:hover {
    border-radius: 4px !important;
    border: 1px solid #FFD02B !important;
    background-color: #FFF !important;
    color: #666666 !important;
}

button.ant-btn.custom-hover-add-feedback-button:active {
    border-radius: 4px !important;
    border: 1px solid #FFD02B !important;
    background-color: #FFF !important;
    box-shadow: 0px 0px 0px 2px #FFD02B !important;
    color: #666666 !important;
}