.checkboxFinal9 {
  width: 16px;
  height: 16px;
  display: block;
  background: #ffd02b;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
}
.checkboxFinal9unChecked {
  box-shadow: inset 0px 0px 0px 1px #dddddd;
  background: #ffffff;
  border-radius: 3px;
}
.checkboxFinal9checkBoxState_fullselected {
  box-shadow: none;
  background: #ffd02b;
  border-radius: 3px;
}
.checkboxFinal9checkBoxState_partialselected {
  background: #ffffff;
  box-shadow: none;
}
.checkboxFinal9checkBoxState_unSelected {
  box-shadow: inset 0px 0px 0px 1px #dddddd;
  background: #ffffff;
  width: 16px;
  height: 16px;
  border-radius: 3px;
}
.checkboxFinal9checkBoxState_fullselecteddisabled {
  background: #999999;
}
.checkboxFinal9checkBoxState_unselectedDisabled {
  background: #f5f5f5;
  box-shadow: inset 0px 0px 0px 1px #dddddd;
}
.img__nv2Dc {
  object-fit: cover;
  left: 0px;
  top: 0px;
  position: absolute;
  width: 16px;
  height: 16px;
  display: none !important;
}
.img__nv2Dc > picture > img {
  object-fit: cover;
}
.imgcheckBoxState_partialselected__nv2Dcayli5 {
  display: block !important;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #333;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  width: 10px;
  height: 8px;
  display: block;
  top: 4px;
  left: 3px;
}
.svgunChecked {
  display: none;
}
.svgcheckBoxState_fullselected {
  transform: translateX(0px) translateY(0px) translateZ(0px);
}
.svgcheckBoxState_partialselected {
  display: none;
}
.svgcheckBoxState_unSelected {
  display: none;
}
.svgcheckBoxState_fullselecteddisabled {
  color: #ffffff;
}
.svgcheckBoxState_unselectedDisabled {
  display: none;
}
.img___6Uk1X {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  left: 0px;
  top: 0px;
}
.img___6Uk1X > picture > img {
  object-fit: cover;
}
.imgcheckBoxState_partialselecteddisbaled___6Uk1XfeTf1 {
  display: block !important;
}
