.checkboxFinal3 {
  width: 16px;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.tooltipCore:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.rectangle313 {
  background: #ffd02a80;
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: auto;
  top: auto;
  position: relative;
  border-radius: 3px;
}
.checkboxFinal3:hover .rectangle313 {
  left: auto;
  top: auto;
  border-width: 0.5px;
  border-style: solid;
}
.checkboxFinal3:active .rectangle313 {
  background: #eabc19;
  left: auto;
  top: auto;
  border-width: 0.5px;
  border-style: solid;
}
.svg__fVtab {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  display: block;
  height: 8px;
  width: 10px;
  transform: translate(0px, -0.1429999999999998px);
  color: #33333366;
  max-width: 100%;
  object-fit: cover;
  flex-shrink: 0;
}
.freeBox___0EkJe {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 300px;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  box-shadow: 0px 4px 16px 0px #00000033;
  background: white;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #e2e8f0;
}
.rectangle316 {
  background: rgba(255, 208, 42, 1);
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: auto;
  top: auto;
  position: relative;
  flex-shrink: 0;
  border-radius: 3px;
}
.checkboxFinal3:hover .rectangle316 {
  left: auto;
  top: auto;
  border-width: 0.5px;
  border-style: solid;
}
.checkboxFinal3:active .rectangle316 {
  background: #eabc19;
  left: auto;
  top: auto;
  border-width: 0.5px;
  border-style: solid;
}
.svg__yfbn {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
  display: block;
  height: 8px;
  width: 10px;
  transform: translate(0px, -0.1429999999999998px);
  color: #333;
  max-width: 100%;
  object-fit: cover;
  flex-shrink: 0;
}
.rectangle315 {
  background: var(--token-jgohepLVeKvh);
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: auto;
  top: auto;
  position: relative;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #ffd02a;
}
.checkboxFinal3:hover .rectangle315 {
  left: auto;
  top: auto;
  border-width: 0.5px;
  border-style: solid;
}
.checkboxFinal3:active .rectangle315 {
  background: #eabc19;
  left: auto;
  top: auto;
  border-width: 0.5px;
  border-style: solid;
}
.freeBox__iaZfR {
  display: block;
  position: relative;
  width: 8px;
  height: 1px;
  max-width: 100%;
  background: #333333d9;
  flex-shrink: 0;
  border-radius: 0.5px;
}
.rectangle314 {
  background: #ffffff;
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: auto;
  top: auto;
  position: relative;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #dddddd;
}
.checkboxFinal3:hover .rectangle314 {
  border-width: 0.5px;
  border-style: solid;
}
.checkboxFinal3:active .rectangle314 {
  background: #eabc19;
  border-width: 0.5px;
  border-style: solid;
}
