.frame1000002613 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  cursor: auto;
  position: relative;
  align-items: flex-start;
  justify-self: flex-start;
  min-width: 0;
}
.freeBox___5W0Gy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  pointer-events: none;
  min-width: 0;
  padding: 8px;
}
.freeBox__sib9U {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  max-width: 96%;
  max-height: 32px;
  height: auto;
}
.freeBox__sib9U > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: stretch;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox__sib9U > :global(.__wab_flex-container) > *,
.freeBox__sib9U > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sib9U > :global(.__wab_flex-container) > picture > img,
.freeBox__sib9U
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.text___3PgxF {
  width: auto;
  height: auto;
  max-width: 100%;
  font-size: 12px;
  font-weight: 500;
  padding-right: 0px;
  line-height: 18px;
}
.text__yG5J {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  color: #666666;
  font-weight: 500;
  line-height: 1.94;
  padding-right: 0px;
  font-size: 10px;
}
.chevron {
  width: 20px;
  height: 20px;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  display: none;
}
.chevron > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.chevron > :global(.__wab_flex-container) > *,
.chevron > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.chevron > :global(.__wab_flex-container) > picture > img,
.chevron
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__nhnA {
  position: relative;
  object-fit: cover;
  color: #666;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.svg__kgAh7 {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.freeBox__vN1Iu {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: 0px;
  top: 267px;
  z-index: 100;
  background: #3333331a;
  min-width: 0;
  min-height: 0;
  display: none;
}
.emActionCenterAssignedItemsPanel:global(.__wab_instance) {
  max-width: 100%;
}
.emActionCenterAssignedItemsPanel2:global(.__wab_instance) {
  max-width: 100%;
}
