.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.pricingInProgress {
  background: rgba(255, 255, 255, 1);
  width: 458px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
}
.pricingInProgress > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.pricingInProgress > :global(.__wab_flex-container) > *,
.pricingInProgress > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.pricingInProgress > :global(.__wab_flex-container) > picture > img,
.pricingInProgress
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.frame1000006945 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000006945 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006945 > :global(.__wab_flex-container) > *,
.frame1000006945 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006945 > :global(.__wab_flex-container) > picture > img,
.frame1000006945
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.yellowLoader:global(.__wab_instance) {
  max-width: 100%;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}
.heading {
  display: block;
  font-size: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.alternative {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 21px;
  color: rgba(102, 102, 102, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
}
.actions {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.actions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.actions > :global(.__wab_flex-container) > *,
.actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actions > :global(.__wab_flex-container) > picture > img,
.actions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.buttonsMarch30 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(208, 212, 215, 1);
  width: auto;
  height: auto;
  position: relative;
  flex-direction: row;
  display: none;
  border-radius: 4px;
  padding: 12px 20px;
}
.buttonsMarch30 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch30 > :global(.__wab_flex-container) > *,
.buttonsMarch30 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch30 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch30
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText {
  display: block;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.buttonsMarch27 {
  background: rgba(255, 208, 42, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 208, 42, 1);
  width: 140px;
  height: auto;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  display: none;
  border-radius: 4px;
  padding: 12px 16px;
}
.buttonsMarch27 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch27 > :global(.__wab_flex-container) > *,
.buttonsMarch27 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch27 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch27
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText2 {
  display: block;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.tRsecondaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 140px;
  height: 46px;
  flex-shrink: 0;
}
.tRprimaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 140px;
  height: 46px;
  flex-shrink: 0;
}
