.anticon.siterecon-icon-button {
  background-image: @background-gradient;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin: 0 10px;
  color: @black;
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
