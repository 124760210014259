.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: auto;
  border-bottom-style: solid;
  border-bottom-color: #e8e8e8;
  min-width: 0;
  padding: 0px;
  border-width: 0.5px;
}
.rootopenServiceItemColumn {
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
}
.frame1000005355 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100%;
  cursor: pointer;
  box-shadow: none;
  margin-bottom: 0px;
  min-width: 0;
}
.frame1000005355isActive {
  background: #fdebbb;
}
.frame1000005355openServiceItemColumn {
  padding-right: 0px;
  width: 100%;
  padding-left: 8px;
  height: 32px;
  min-width: 0;
  flex-shrink: 0;
}
.root:hover .frame1000005355 {
  background: #fffaea;
}
.rootisActive:hover .frame1000005355isActive {
  background: #fdebbb;
}
.freeBox___6F0P0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBoxopenServiceItemColumn___6F0P0VsFgr {
  margin-right: 0px;
}
.svg__qdlW {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #333333;
  width: 12px;
  height: 12px;
  pointer-events: auto;
  cursor: pointer;
  flex-shrink: 0;
}
.svg__vOYm3 {
  object-fit: cover;
  max-width: 100%;
  color: #333333;
  width: 12px;
  height: 12px;
  pointer-events: auto;
  cursor: pointer;
  flex-shrink: 0;
}
.freeBox__xwPo2 {
  display: block;
  position: absolute;
  height: 100%;
  width: auto;
  margin-left: 32px;
  left: 28px;
  z-index: 0;
  flex-shrink: 1;
  right: 0px;
  padding-bottom: 0px;
  min-height: 0;
}
.freeBoxisActive__xwPo2DhIGq {
  width: 100%;
  flex-shrink: 0;
  min-width: 0;
}
.freeBoxopenServiceItemColumn__xwPo2VsFgr {
  padding-right: 7px;
  width: auto;
}
.frame1000002580 {
  height: 28px;
  display: flex;
  position: relative;
  flex-direction: row;
  pointer-events: none;
  width: 260px;
  margin-right: 0px;
  flex-shrink: 0;
  padding: 4px 8px 4px 0px;
  border-right: 1px solid #e8e8e8;
}
.frame1000002580 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002580 > :global(.__wab_flex-container) > *,
.frame1000002580 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002580 > :global(.__wab_flex-container) > picture > img,
.frame1000002580
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000002580isActive {
  border-right-color: #dbdbdb;
}
.frame1000002580openServiceItemColumn {
  width: 148px;
  padding-right: 8px;
  margin-left: 0px;
  padding-left: 0px;
  flex-shrink: 0;
}
.frame1000002580openServiceItemColumn > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.frame1000002580openServiceItemColumn > :global(.__wab_flex-container) > *,
.frame1000002580openServiceItemColumn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.frame1000002580openServiceItemColumn
  > :global(.__wab_flex-container)
  > picture
  > img,
.frame1000002580openServiceItemColumn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.emCheckbox:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.frame1000005356 {
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  pointer-events: none;
  width: 100%;
  padding-left: 2px;
  padding-right: 0px;
  margin-left: calc(-3px + 8px) !important;
  min-width: 0;
}
.frame1000005356 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005356 > :global(.__wab_flex-container) > *,
.frame1000005356 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005356 > :global(.__wab_flex-container) > picture > img,
.frame1000005356
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000005356isActive {
  margin-left: calc(-3px + 8px) !important;
}
.frame1000005356isActive > :global(.__wab_flex-container) {
  justify-content: space-between;
}
.frame1000005356openServiceItemColumn {
  padding-right: 0px;
  width: 100%;
  margin-left: calc(-3px + 8px) !important;
  min-width: 0;
}
.frame1000005356openServiceItemColumn > :global(.__wab_flex-container) {
  min-width: 0;
}
.tooltipCore:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__zUkCe {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
  pointer-events: auto;
  text-overflow: ellipsis !important;
  overflow: hidden;
  width: auto;
  margin-bottom: 0px;
}
.freeBox__xAquc {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  box-shadow: none;
  background: #333333;
  min-height: 0;
  border-radius: 4px;
  padding: 4px 8px;
}
.text__nsdg {
  font-size: 12px;
  color: #ffffff;
  width: auto;
  height: auto;
  max-width: 223px;
  white-space: pre-wrap;
}
.tooltip:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.pcls_nKQjX7DTR6jF {
  position: absolute;
  z-index: 100000;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  outline: none;
}
.freeBox__xwNto {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: 16px;
  max-width: 100%;
}
.svg__w4LMn {
  object-fit: cover;
  color: #fbbf24;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.mrTakeoffLayerPanelTopoWarning__vCvR:global(.__wab_instance) {
  max-width: 100%;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 16px;
  flex-shrink: 0;
  display: none;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_59O215aEoMId {
  outline: none;
  padding: 0px;
}
.svg__dI4Rd {
  object-fit: cover;
  max-width: 100%;
  color: #fbbf24;
  width: 16px;
  height: 16px;
}
.mrTakeoffLayerPanelTopoWarning__tfs0S:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__jMeIb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 38px;
}
.assignedServiceItemsChips {
  background: none;
  width: auto;
  height: 20px;
  flex-direction: column;
  display: none;
  border-radius: 26px;
  padding: 2px 4px;
}
.assignedServiceItemsChips > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.assignedServiceItemsChips > :global(.__wab_flex-container) > *,
.assignedServiceItemsChips
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.assignedServiceItemsChips > :global(.__wab_flex-container) > picture > img,
.assignedServiceItemsChips
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.frame1000005329 {
  width: auto;
  height: auto;
  position: relative;
  flex-direction: row;
  display: none;
}
.frame1000005329 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.frame1000005329 > :global(.__wab_flex-container) > *,
.frame1000005329 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005329 > :global(.__wab_flex-container) > picture > img,
.frame1000005329
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.serviceItemDot {
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.rectangle26938 {
  background: rgba(118, 237, 237, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 12px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 11.999px;
  height: 12px;
  display: flex !important;
  flex-direction: row;
  flex-shrink: 0;
}
.img > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.img > :global(.__wab_flex-container) > *,
.img > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.img > :global(.__wab_flex-container) > picture > img,
.img > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 11.999px;
  height: 12px;
  display: flex !important;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.freeBox__hewJh {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: auto;
  max-width: 100%;
}
.emTakeOffLayerChips:global(.__wab_instance) {
  max-width: 100%;
  width: 40px;
  flex-shrink: 0;
  display: none;
}
.emTakeOffLayerChips2:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__hhXU {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 118px;
  height: 100%;
  max-width: 100%;
  margin-left: 18px;
  flex-shrink: 0;
  min-height: 0;
  border-right: 1px solid #e8e8e8;
}
.freeBoxisActive__hhXUDhIGq {
  border-right-color: #dbdbdb;
}
.freeBoxopenServiceItemColumn__hhXUVsFgr {
  width: 136px;
  flex-shrink: 0;
  margin: 0px;
}
.mrLayerRowMeasurementCopy:global(.__wab_instance) {
  max-width: 100%;
  height: 28px;
  flex-shrink: 0;
}
.mrLayerRowMeasurementCopyopenServiceItemColumn:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.freeBox___4IWoE {
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 32px;
  margin-left: 4px;
  min-width: 0;
  display: none;
}
.freeBox___4IWoE > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.freeBox___4IWoE > :global(.__wab_flex-container) > *,
.freeBox___4IWoE > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___4IWoE > :global(.__wab_flex-container) > picture > img,
.freeBox___4IWoE
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.freeBoxopenServiceItemColumn___4IWoEVsFgr {
  display: flex;
}
.mrTakeoffLayerServiceRowChipCollection:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.text__rm6Kf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #666666;
  font-size: 10px;
  margin-left: calc(1px + 0px) !important;
  min-width: 0;
  display: none;
}
.textopenServiceItemColumn__rm6KfVsFgr {
  margin-left: calc(1px + 0px) !important;
}
.text__ay8B9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #666666;
  font-size: 10px;
  margin-left: calc(1px + 0px) !important;
  min-width: 0;
  display: none;
}
.textopenServiceItemColumn__ay8B9VsFgr {
  margin-left: calc(1px + 0px) !important;
}
.mrTakeoffLayerServiceRowChipCollection__m3HMm:global(.__wab_instance) {
  max-width: 100%;
  height: 100%;
  min-height: 0;
}
