.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
  border-radius: 4px;
  border: 0.5px solid #dddddd;
}
.component153 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 0;
  border-radius: 4px;
}
.component153open {
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 0;
}
.freeBox___0Sayu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  align-self: stretch;
  min-width: 0;
}
.freeBoxopen___0SayuOcLi7 {
  width: 100%;
  min-width: 0;
  padding: 0px;
}
.emServiceAssignmentHeader3:global(.__wab_instance) {
  max-width: 100%;
}
.frame1000002614 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 8px;
}
.frame1000002614 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000002614 > :global(.__wab_flex-container) > *,
.frame1000002614 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002614 > :global(.__wab_flex-container) > picture > img,
.frame1000002614
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.frame1000002614open {
  display: flex;
}
.searchField {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 0.5px #dddddd;
  height: 32px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 4px;
  padding: 10px 8px 10px 0px;
}
.searchField > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.searchField > :global(.__wab_flex-container) > *,
.searchField > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.searchField > :global(.__wab_flex-container) > picture > img,
.searchField
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.searchFieldopen {
  width: 100%;
  min-width: 0;
}
.searchFieldopen > :global(.__wab_flex-container) {
  min-width: 0;
}
.root .searchField:hover {
  box-shadow: 0px 4px 16px 0px #ffd02b;
  background: rgba(255, 255, 255, 1);
}
.frame1000002279 {
  height: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-right: 8px;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.frame1000002279 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002279 > :global(.__wab_flex-container) > *,
.frame1000002279 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002279 > :global(.__wab_flex-container) > picture > img,
.frame1000002279
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000002279open {
  width: 100%;
  min-width: 0;
}
.frame1000002279open > :global(.__wab_flex-container) {
  min-width: 0;
}
.input:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.img__oqE7Y {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 12px;
  height: 12px;
  display: block !important;
  overflow: hidden;
}
.img__oqE7Y > picture > img {
  object-fit: cover;
}
.img__vhAwr {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 12px;
}
.img__vhAwr > picture > img {
  object-fit: cover;
}
.svg__w8O2Z {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  cursor: pointer;
  flex-shrink: 0;
}
.svgopen__w8O2ZOcLi7 {
  display: block;
}
.svg__p4NQa {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  cursor: pointer;
  flex-shrink: 0;
}
.svg__l9Wy0 {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  cursor: pointer;
  flex-shrink: 0;
}
.frame1000002612 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 0px;
  min-width: 0;
}
.frame1000005238 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.searchResult {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  flex-shrink: 0;
}
.searchResult > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.searchResult > :global(.__wab_flex-container) > *,
.searchResult > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.searchResult > :global(.__wab_flex-container) > picture > img,
.searchResult
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.frame1000001961 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  padding-left: 10px;
  flex-shrink: 0;
}
.frame1000001961 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.frame1000001961 > :global(.__wab_flex-container) > *,
.frame1000001961 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001961 > :global(.__wab_flex-container) > picture > img,
.frame1000001961
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.text__tPkn {
  display: block;
  flex-grow: 1;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.6;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.frame1000005241 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  width: 100%;
  padding-left: 0px;
  height: auto;
  min-width: 0;
}
.frame1000005241close {
  display: none;
}
.emZeroMeasurements:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.emCustomCollapse3:global(.__wab_instance) {
  max-width: 100%;
}
.emNoItemsFound:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.emEmptyService:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.switchToMap {
  width: auto;
  height: 52px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 8px 12px;
}
.switchToMap > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.switchToMap > :global(.__wab_flex-container) > *,
.switchToMap > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.switchToMap > :global(.__wab_flex-container) > picture > img,
.switchToMap
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.text__zExss {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  color: #666666;
  position: relative;
  white-space: pre-wrap;
  font-style: italic;
}
.freeBox__rByUv {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 44px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
  display: none;
  padding: 8px;
}
.tRsecondaryButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 34px;
  display: none;
}
.tRprimaryButton:global(.__wab_instance) {
  max-width: 100%;
  height: 36px;
  display: none;
}
.sideEffect:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  display: none;
}
