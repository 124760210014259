.icons-list {
  height: 24px;

  .selected-icon {
    border: 0.5px solid #ffd02b;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 208, 43, 0.1);
    width: 30px;
    height: 30px; 
    overflow: hidden;

    .inner {
      display: flex;
      justify-content: center;
      width: 90%;
    }
  }
}

.icons-list .active {
  background-color: rgba(255, 208, 43, 0.1) !important;
  border: 0.5px solid #ffd02b !important;
}

.show-icon-modal {
  box-sizing: content-box;
  position: absolute;
  width: 233px;
  height: auto;
  background: #ffffff;
  z-index: 1;
  border-radius: 6px;
}

.pop-div {
  display: flex;
  border: 1px solid grey;
  border-radius: 6px;
  background-color: white;
  padding: 4px;
}

.icons-list.ant-btn-icon-only {
  height: 24px;
}

.icons-list .ant-btn-link {
  height: 24px;
}

.arrow-list-popup.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow {
  display: none;
}

.arrow-list-popup {
  .ant-popover-inner {
    width: 253px;
    position: absolute;
    left: -20px;
    max-height: 150px;
    overflow-y: auto;
    top: 0px;
  }

  .icon-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .icon-item-inner {
      display: flex;
      width: 90%;
    }
  }
}

.icon-group {
  display: flex;
}

.icon-upload {
  .ant-upload-list {
    margin: 0;
  }
}

.back-arrow-container {
  padding-left: 8px;
  padding-top: 8px;
  padding-right: 8px;
}

.icon-input:focus {
  box-shadow: none !important;
}

.text-input {
  border-top: 2px solid #d1cccc;
  padding-top: 8px;
  padding-bottom: 8px;
}

.icon-list-container {
  padding-top: 5px;
  padding-bottom: 2px;
  overflow-y: auto;
  max-height: 60px;

  :last-child {
    margin-left: auto;
  }
}

.ant-popover-inner {
  &::-webkit-scrollbar-thumb {
    background-color: #EAEAEA;
  }
}