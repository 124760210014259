.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.confirmationPopups {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px;
}
.confirmationPopups > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.confirmationPopups > :global(.__wab_flex-container) > *,
.confirmationPopups > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.confirmationPopups > :global(.__wab_flex-container) > picture > img,
.confirmationPopups
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.frame1000003005 {
  width: 496px;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000003005 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.frame1000003005 > :global(.__wab_flex-container) > *,
.frame1000003005 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000003005 > :global(.__wab_flex-container) > picture > img,
.frame1000003005
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.featuredIcon {
  background: rgba(254, 227, 225, 1);
  box-shadow: 0px 0px 0px 4px rgba(253, 242, 241, 1),
    inset 0px 0px 0px 4px rgba(253, 242, 241, 1);
  width: 48px;
  height: 48px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 28px;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: #d92d20;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 12px;
  left: 12px;
  overflow: hidden;
}
.textAndSupportingText {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
}
.textAndSupportingText > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.textAndSupportingText > :global(.__wab_flex-container) > *,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.textAndSupportingText > :global(.__wab_flex-container) > picture > img,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text {
  display: block;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 28px;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
}
.supportingText {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.54;
  color: rgba(102, 102, 102, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
}
.actions {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.actions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.actions > :global(.__wab_flex-container) > *,
.actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actions > :global(.__wab_flex-container) > picture > img,
.actions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.secondaryButton {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  min-width: 0;
  border-radius: 4px;
  padding: 2px;
}
.secondaryButton > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.secondaryButton > :global(.__wab_flex-container) > *,
.secondaryButton > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.secondaryButton > :global(.__wab_flex-container) > picture > img,
.secondaryButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.root .secondaryButton:active {
  box-shadow: 0px 0px 0px 1px #ffe06f;
}
.frame1000001879 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(208, 212, 218, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 128px;
  border-radius: 4px;
  padding: 12px 24px;
}
.frame1000001879 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000001879 > :global(.__wab_flex-container) > *,
.frame1000001879 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001879 > :global(.__wab_flex-container) > picture > img,
.frame1000001879
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.root .frame1000001879:hover {
  background: #f7f7f7;
}
.buttonText {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.primaryButton {
  width: auto;
  height: 49px;
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
}
.primaryButton > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.primaryButton > :global(.__wab_flex-container) > *,
.primaryButton > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.primaryButton > :global(.__wab_flex-container) > picture > img,
.primaryButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.root .primaryButton:active {
  box-shadow: 0px 0px 0px 1px #bf3737;
}
.frame1000001880 {
  background: rgba(238, 67, 67, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(238, 67, 67, 1);
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  min-height: 0;
  border-radius: 4px;
  padding: 12px 24px;
}
.frame1000001880 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000001880 > :global(.__wab_flex-container) > *,
.frame1000001880 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001880 > :global(.__wab_flex-container) > picture > img,
.frame1000001880
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.root .frame1000001880:hover {
  background: #cf3b3b;
}
.buttonText2 {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
