.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.rootfeatureStates_polygon {
  width: 40px;
  align-items: flex-start;
  justify-content: flex-start;
}
.rootfeatureStates_line {
  width: 40px;
  align-items: center;
  justify-content: center;
}
.rootfeatureStates_point {
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 32px;
  display: flex;
  flex-direction: row;
}
.rootfeatureStates_path {
  width: 40px;
  align-items: flex-start;
  justify-content: flex-start;
}
.embedHtml:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  flex-shrink: 0;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: none;
}
.embedHtmlfeatureStates_polygon:global(.__wab_instance) {
  display: flex;
}
.svg__tpcMk {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  height: 1em;
  flex-shrink: 0;
}
.svgfeatureStates_line__tpcMKrosOp {
  display: block;
}
.svg__cwXqx {
  position: relative;
  object-fit: cover;
  color: #864444;
  left: auto;
  top: auto;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.svgfeatureStates_point__cwXqxMmhwC {
  display: block;
}
.svg__yd7Jy {
  position: relative;
  object-fit: cover;
  color: #000;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.svgfeatureStates_path__yd7JYdCuNv {
  display: block;
}
.emCustomSymbol:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.emCustomSymbolfeatureStates_point:global(.__wab_instance) {
  display: flex;
}
