@font-family: 'Poppins';

@heading-color: fade(@black, 85%);
@text-color-dark: fade(@white, 85%);
@text-color-secondary-dark: fade(@white, 65%);
@text-selection-bg: @primary-color;
@font-variant-base: tabular-nums;
@font-feature-settings-base: 'tnum';

@font-size-lg: 16px;
@font-size-base: 14px;
@font-size-sm: 12px;

@heading-1-size: 48px;
@heading-2-size: 34px;
@heading-3-size: 28px;
@heading-4-size: 18px;
@heading-5-size: 16px;

@line-height-base: 21px;

::-moz-selection {
  color: #333333 !important;
}

::selection {
  color: #333333 !important;
}

.text-error {
  color: @text-error-color;
}
