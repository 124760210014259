.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
}
.frame1000005390 {
  width: 100%;
  height: 690px;
  display: flex;
  flex-direction: column;
  padding-top: 44px;
  padding-bottom: 44px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 8px;
}
.frame1000005390 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.frame1000005390 > :global(.__wab_flex-container) > *,
.frame1000005390 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005390 > :global(.__wab_flex-container) > picture > img,
.frame1000005390
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.img {
  position: relative;
  object-fit: fill;
  max-width: 100%;
  width: 342px;
  height: auto;
}
.img > picture > img {
  object-fit: fill;
}
.text {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.text > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.text > :global(.__wab_flex-container) > *,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.text > :global(.__wab_flex-container) > picture > img,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.frame1000006361 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.heading {
  display: block;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 400px;
}
.heading2 {
  display: block;
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1.54;
  color: rgba(16, 23, 38, 1);
  align-self: stretch;
  position: relative;
}
.frame1000006330 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.frame1000006330 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 7px);
  height: calc(100% + 7px);
}
.frame1000006330 > :global(.__wab_flex-container) > *,
.frame1000006330 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006330 > :global(.__wab_flex-container) > picture > img,
.frame1000006330
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 7px;
}
.frame1000006326 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006326 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006326 > :global(.__wab_flex-container) > *,
.frame1000006326 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006326 > :global(.__wab_flex-container) > picture > img,
.frame1000006326
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__pjNk4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #76ca66;
  width: 16px;
  height: 16px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.heading3 {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.34;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.frame1000006327 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006327 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006327 > :global(.__wab_flex-container) > *,
.frame1000006327 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006327 > :global(.__wab_flex-container) > picture > img,
.frame1000006327
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__dggsk {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #76ca66;
  width: 16px;
  height: 16px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.heading4 {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.34;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.frame1000006328 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006328 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006328 > :global(.__wab_flex-container) > *,
.frame1000006328 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006328 > :global(.__wab_flex-container) > picture > img,
.frame1000006328
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame {
  width: 16px;
  height: 21px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.svg__too10 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #76ca66;
  transform: translate(0px, -0.5px);
  width: 16px;
  height: 16px;
  display: block;
  top: 3px;
  left: 0%;
  overflow: hidden;
}
.heading5 {
  display: block;
  flex-grow: 1;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.34;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.frame1000005410 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000005410 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000005410 > :global(.__wab_flex-container) > *,
.frame1000005410 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005410 > :global(.__wab_flex-container) > picture > img,
.frame1000005410
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.buttonsMarch28 {
  background: #ffd02a;
  box-shadow: inset 0px 0px 0px 1px rgba(255, 208, 42, 1);
  width: 300px;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  border-radius: 4px;
  padding: 12px 24px;
}
.buttonsMarch28 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch28 > :global(.__wab_flex-container) > *,
.buttonsMarch28 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch28 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch28
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.root .buttonsMarch28:hover {
  background: #ffe06f;
  box-shadow: inset 0px 0px 0px 1px #ffd02b;
  cursor: pointer;
}
.root .buttonsMarch28:active {
  background: #ffe06f;
  box-shadow: 0px 0px 0px 2px #ffd02b;
}
.buttonText {
  display: block;
  flex-grow: 1;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 24px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: stretch;
  min-width: 0;
}
.detail {
  display: block;
  font-size: 14px;
  text-align: center;
  text-decoration-line: underline;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.54;
  color: rgba(102, 102, 102, 1);
  font-style: italic;
  width: auto;
  cursor: pointer;
}
