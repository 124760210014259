.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.frame1000004823 {
  background: rgba(51, 51, 51, 1);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.frame1000004823 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000004823 > :global(.__wab_flex-container) > *,
.frame1000004823 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004823 > :global(.__wab_flex-container) > picture > img,
.frame1000004823
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.text {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  position: relative;
  white-space: pre-wrap;
  text-align: left;
  max-width: 168px;
}
