.pinPanelIcon {
  background: rgba(255, 255, 255, 1);
  width: 28px;
  height: 28px;
  display: block;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border-radius: 28px;
}
.pinPanelIconisPinned {
  background: linear-gradient(#e9e9e9, #e9e9e9), rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 0px 1px #c9c9c9;
}
.pinPanelIcon:hover {
  background: #e9e9e9;
}
.pinPanelIcon:active {
  background: linear-gradient(#dddddd, #dddddd), rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 0px 1px #dddddd;
}
.keep {
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  top: 7px;
  left: 7px;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  transform: translate(-0.5px, -0.25px);
  width: 7px;
  height: 12px;
  display: block;
  top: 14.28%;
  left: 28.57%;
}
