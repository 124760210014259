.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 456px;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  background: var(--token-jgohepLVeKvh);
  overflow: visible;
  justify-self: flex-start;
  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 0;
}
.rootopen {
  width: 456px;
}
.frame1000004569 {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  left: auto;
  top: auto;
  z-index: 1;
  position: relative;
  min-width: 0;
}
.frame1000004569 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000004569 > :global(.__wab_flex-container) > *,
.frame1000004569 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004569 > :global(.__wab_flex-container) > picture > img,
.frame1000004569
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000005061 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
}
.frame1000005335 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
}
.frame1000005333 {
  background: #f9fafb;
  height: 32px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  left: auto;
  top: auto;
  border-bottom-style: solid;
  border-bottom-color: #666666cc;
  padding-top: 6px;
  padding-bottom: 6px;
  min-width: 0;
  flex-shrink: 0;
  border-width: 0.5px;
}
.frame1000005333 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: stretch;
  min-width: 0;
}
.frame1000005333open {
  width: 100%;
  min-width: 0;
}
.frame1000005333open > :global(.__wab_flex-container) {
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.frame1000005333open > :global(.__wab_flex-container) > *,
.frame1000005333open
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.frame1000005333open > :global(.__wab_flex-container) > picture > img,
.frame1000005333open
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.emAllServiceToggle:global(.__wab_instance) {
  position: relative;
  width: auto;
  flex-shrink: 1;
  display: none;
}
.emAllServiceToggle2:global(.__wab_instance) {
  max-width: 100%;
  width: 288px;
  height: 28px;
  flex-shrink: 0;
}
.emAllServiceToggle2open:global(.__wab_instance) {
  width: 176px;
  height: 28px;
  flex-shrink: 0;
}
.frame1000005332 {
  width: 136px;
  height: 28px;
  display: flex;
  position: relative;
  flex-direction: row;
  border-right-color: #dddddd;
  border-right-style: solid;
  flex-shrink: 0;
  padding: 4px 8px;
  border-width: 1px;
}
.frame1000005332 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.frame1000005332 > :global(.__wab_flex-container) > *,
.frame1000005332 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005332 > :global(.__wab_flex-container) > picture > img,
.frame1000005332
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.frame1000005332open {
  margin-left: calc(0px + 0px) !important;
  height: 28px;
}
.frame1000005332open > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.frame1000005332open > :global(.__wab_flex-container) > *,
.frame1000005332open
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.frame1000005332open > :global(.__wab_flex-container) > picture > img,
.frame1000005332open
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.text__u4EEf {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.freeBox__mkiq {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  max-width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 25px;
}
.root .freeBox__mkiq:hover {
  background: #66666642;
  border-radius: 25px;
}
.svg__i0JU0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666666;
  height: 1em;
}
.svg__lfoIp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666666;
  height: 1em;
}
.frame1000005338 {
  width: 100%;
  height: 30px;
  display: flex;
  position: relative;
  flex-direction: row;
  padding-left: 0px;
  min-width: 0;
  border-width: 1px;
}
.frame1000005338 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.frame1000005338 > :global(.__wab_flex-container) > *,
.frame1000005338 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005338 > :global(.__wab_flex-container) > picture > img,
.frame1000005338
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.frame1000005338open {
  width: 144px;
  margin-left: calc(0px + 0px) !important;
  margin-right: 0px;
  align-self: auto;
  height: 28px;
  flex-shrink: 0;
  padding: 6px 4px 6px 8px;
}
.frame1000005338open > :global(.__wab_flex-container) {
  justify-content: space-between;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.frame1000005338open > :global(.__wab_flex-container) > *,
.frame1000005338open
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.frame1000005338open > :global(.__wab_flex-container) > picture > img,
.frame1000005338open
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.text___9Zqt0 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.textopen___9Zqt0Pp4Ab {
  width: 100%;
  flex-grow: 0;
  text-align: left;
  min-width: 0;
  display: block;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.pcls_f3l_0ber8HcR {
  outline: none;
  padding: 0px;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.freeBox__jDkjk {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  max-width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 25px;
}
.img___16Di {
  object-fit: cover;
  max-width: 100%;
}
.img___16Di > picture > img {
  object-fit: cover;
}
.imgopen___16DiPp4Ab {
  width: 24px;
  flex-shrink: 0;
}
.img___23HRg {
  object-fit: cover;
  max-width: 100%;
}
.img___23HRg > picture > img {
  object-fit: cover;
}
.tooltipContent:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__gevKm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
  left: auto;
  top: auto;
  min-width: 0;
}
.layersList {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  align-self: auto;
  min-width: 0;
}
.parcelStack2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 32px;
  max-width: 100%;
  padding-left: 13px;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  border-top: 0.5px none #e8e8e8;
  border-bottom: 0.5px solid #e8e8e8;
}
.frame1000005339 {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.frame1000002575 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  width: auto;
  flex-shrink: 0;
  min-height: 0;
  padding: 4px 8px 4px 14px;
}
.frame1000002575 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002575 > :global(.__wab_flex-container) > *,
.frame1000002575 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002575 > :global(.__wab_flex-container) > picture > img,
.frame1000002575
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.emCheckbox14:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.frame1000005350 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.text__xI7Aa {
  display: block;
  flex-grow: 0;
  font-size: 12px;
  letter-spacing: 0em;
  line-height: 16px;
  position: relative;
  width: 211px;
  font-weight: 500;
  padding-right: 0px;
  flex-shrink: 0;
  cursor: pointer;
}
.textopen__xI7Aapp4Ab {
  width: 100px;
  flex-shrink: 0;
}
.freeBox__bhYyX {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.popover2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  cursor: pointer;
}
.popover2__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_7sQHKIf6Rquw {
  outline: none;
  padding: 0px;
}
.emTakeOffLayerChips2:global(.__wab_instance) {
  max-width: 100%;
}
.mrParcelStyling:global(.__wab_instance) {
  max-width: 100%;
  width: 262px;
}
.text__oxQUw {
  position: relative;
  width: 125px;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  line-height: 2.34;
  margin-left: 9px;
  height: 26px;
  flex-shrink: 0;
  border-left: 1px solid #dddddd;
}
.textopen__oxQUwpp4Ab {
  width: 126px;
  margin-left: 8px;
  flex-shrink: 0;
}
.nearMapStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 32px;
  max-width: 100%;
  padding-left: 13px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-width: 0;
  flex-shrink: 0;
}
.frame1000005334 {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frame1000002572 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  flex-shrink: 0;
  width: 100%;
  min-width: 0;
  padding: 4px 12px 4px 14px;
}
.frame1000002572 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002572 > :global(.__wab_flex-container) > *,
.frame1000002572 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002572 > :global(.__wab_flex-container) > picture > img,
.frame1000002572
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.emCheckbox1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.frame1000005347 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-direction: row;
  width: auto;
}
.frame1000005347 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.frame1000005347 > :global(.__wab_flex-container) > *,
.frame1000005347 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005347 > :global(.__wab_flex-container) > picture > img,
.frame1000005347
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.text__vEyi {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  font-weight: 500;
  font-size: 12px;
}
.text___53VSv {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  font-size: 12px;
  text-align: justify;
  line-height: 16px;
}
.baseMap {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 32px;
  max-width: 100%;
  padding-left: 13px;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  border-top: 0.5px solid #dddddd;
  border-bottom: 0.5px solid #dddddd;
}
.frame1000005336 {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frame1000002573 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  padding: 4px 12px 4px 14px;
}
.frame1000002573 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002573 > :global(.__wab_flex-container) > *,
.frame1000002573 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002573 > :global(.__wab_flex-container) > picture > img,
.frame1000002573
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.emCheckbox12:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.frame1000005348 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.frame1000005348 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005348 > :global(.__wab_flex-container) > *,
.frame1000005348 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005348 > :global(.__wab_flex-container) > picture > img,
.frame1000005348
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__lZoPf {
  display: block;
  flex-grow: 0;
  font-size: 12px;
  letter-spacing: 0em;
  line-height: 16px;
  position: relative;
  font-weight: 500;
}
