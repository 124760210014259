@import url("https://fonts.googleapis.com/css2?family=Poppins%3Aital%2Cwght%400%2C100%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B1%2C100%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700%3B1%2C800&family=Inconsolata%3Aital%2Cwght%400%2C200%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800&family=Inter%3Aital%2Cwght%400%2C100%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800&family=Rubik%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700%3B1%2C800&display=swap");

.plasmic_tokens {
  --token-7P2QL5QsTp66: 0px;
  --plasmic-token-unnamed-style-token: var(--token-7P2QL5QsTp66);
  --token-IKSvyr9Bh_hH: #dc2f2f;
  --plasmic-token-unnamed-style-token-2: var(--token-IKSvyr9Bh_hH);
  --token-0ymh_5tGYPWB: 0px;
  --plasmic-token-unnamed-style-token-3: var(--token-0ymh_5tGYPWB);
  --token-2OEzFCRNqebU: Poppins;
  --plasmic-token-unnamed-style-token-4: var(--token-2OEzFCRNqebU);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-KFNpWfHLKXVV_font-family: "Poppins", sans-serif;
  --mixin-KFNpWfHLKXVV_font-size: 10px;
  --mixin-KFNpWfHLKXVV_font-weight: 400;
  --mixin-KFNpWfHLKXVV_font-style: normal;
  --mixin-KFNpWfHLKXVV_color: #333333;
  --mixin-KFNpWfHLKXVV_text-align: left;
  --mixin-KFNpWfHLKXVV_text-transform: none;
  --mixin-KFNpWfHLKXVV_letter-spacing: normal;
  --mixin-KFNpWfHLKXVV_white-space: nowrap;
  --mixin-KFNpWfHLKXVV_user-select: none;
  --mixin-KFNpWfHLKXVV_text-decoration-line: none;
  --mixin-VUJMDBIGFDk5_color: #000000;
  --mixin-VUJMDBIGFDk5_font-weight: 600;
  --mixin-VUJMDBIGFDk5_font-size: 40px;
  --mixin-VUJMDBIGFDk5_line-height: 1;
  --mixin-VUJMDBIGFDk5_letter-spacing: -1px;
  --mixin-VUJMDBIGFDk5_white-space: pre-wrap;
  --mixin-GswtO6V7LQiB_color: #000000;
  --mixin-GswtO6V7LQiB_font-size: 30px;
  --mixin-GswtO6V7LQiB_font-weight: 600;
  --mixin-GswtO6V7LQiB_line-height: 1.1;
  --mixin-GswtO6V7LQiB_white-space: pre-wrap;
  --mixin-IBrIyXq95sYB_color: #0070f3;
  --mixin-IBrIyXq95sYB_white-space: pre-wrap;
  --mixin-yDyCcT4R5viN_color: #000000;
  --mixin-yDyCcT4R5viN_font-size: 24px;
  --mixin-yDyCcT4R5viN_font-weight: 600;
  --mixin-yDyCcT4R5viN_line-height: 1.2;
  --mixin-yDyCcT4R5viN_white-space: pre-wrap;
  --mixin-EaVfc5Xgl6yz_color: #000000;
  --mixin-EaVfc5Xgl6yz_font-size: 20px;
  --mixin-EaVfc5Xgl6yz_font-weight: 600;
  --mixin-EaVfc5Xgl6yz_line-height: 1.3;
  --mixin-EaVfc5Xgl6yz_white-space: pre-wrap;
  --mixin-ImViesh4pYpt_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-ImViesh4pYpt_border-bottom-color: #dddddd;
  --mixin-ImViesh4pYpt_border-bottom-style: solid;
  --mixin-ImViesh4pYpt_border-bottom-width: 1px;
  --mixin-ImViesh4pYpt_border-left-color: #dddddd;
  --mixin-ImViesh4pYpt_border-left-style: solid;
  --mixin-ImViesh4pYpt_border-left-width: 1px;
  --mixin-ImViesh4pYpt_border-right-color: #dddddd;
  --mixin-ImViesh4pYpt_border-right-style: solid;
  --mixin-ImViesh4pYpt_border-right-width: 1px;
  --mixin-ImViesh4pYpt_border-top-color: #dddddd;
  --mixin-ImViesh4pYpt_border-top-style: solid;
  --mixin-ImViesh4pYpt_border-top-width: 1px;
  --mixin-ImViesh4pYpt_border-bottom-left-radius: 3px;
  --mixin-ImViesh4pYpt_border-bottom-right-radius: 3px;
  --mixin-ImViesh4pYpt_border-top-left-radius: 3px;
  --mixin-ImViesh4pYpt_border-top-right-radius: 3px;
  --mixin-ImViesh4pYpt_font-family: "Inconsolata";
  --mixin-ImViesh4pYpt_padding-bottom: 1px;
  --mixin-ImViesh4pYpt_padding-left: 4px;
  --mixin-ImViesh4pYpt_padding-right: 4px;
  --mixin-ImViesh4pYpt_padding-top: 1px;
  --mixin-ImViesh4pYpt_white-space: pre-wrap;
  --mixin-aAW9xi2FPPKU_border-left-color: #dddddd;
  --mixin-aAW9xi2FPPKU_border-left-style: solid;
  --mixin-aAW9xi2FPPKU_border-left-width: 3px;
  --mixin-aAW9xi2FPPKU_color: #888888;
  --mixin-aAW9xi2FPPKU_padding-left: 10px;
  --mixin-aAW9xi2FPPKU_white-space: pre-wrap;
  --mixin-Mqje7POflpa1_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-Mqje7POflpa1_border-bottom-color: #dddddd;
  --mixin-Mqje7POflpa1_border-bottom-style: solid;
  --mixin-Mqje7POflpa1_border-bottom-width: 1px;
  --mixin-Mqje7POflpa1_border-left-color: #dddddd;
  --mixin-Mqje7POflpa1_border-left-style: solid;
  --mixin-Mqje7POflpa1_border-left-width: 1px;
  --mixin-Mqje7POflpa1_border-right-color: #dddddd;
  --mixin-Mqje7POflpa1_border-right-style: solid;
  --mixin-Mqje7POflpa1_border-right-width: 1px;
  --mixin-Mqje7POflpa1_border-top-color: #dddddd;
  --mixin-Mqje7POflpa1_border-top-style: solid;
  --mixin-Mqje7POflpa1_border-top-width: 1px;
  --mixin-Mqje7POflpa1_border-bottom-left-radius: 3px;
  --mixin-Mqje7POflpa1_border-bottom-right-radius: 3px;
  --mixin-Mqje7POflpa1_border-top-left-radius: 3px;
  --mixin-Mqje7POflpa1_border-top-right-radius: 3px;
  --mixin-Mqje7POflpa1_font-family: "Inconsolata";
  --mixin-Mqje7POflpa1_padding-bottom: 3px;
  --mixin-Mqje7POflpa1_padding-left: 6px;
  --mixin-Mqje7POflpa1_padding-right: 6px;
  --mixin-Mqje7POflpa1_padding-top: 3px;
  --mixin-Mqje7POflpa1_white-space: pre-wrap;
  --mixin-tAKmKOSwxSl1_display: flex;
  --mixin-tAKmKOSwxSl1_flex-direction: column;
  --mixin-tAKmKOSwxSl1_align-items: stretch;
  --mixin-tAKmKOSwxSl1_justify-content: flex-start;
  --mixin-tAKmKOSwxSl1_list-style-position: outside;
  --mixin-tAKmKOSwxSl1_padding-left: 40px;
  --mixin-tAKmKOSwxSl1_position: relative;
  --mixin-tAKmKOSwxSl1_list-style-type: disc;
  --mixin-tAKmKOSwxSl1_white-space: pre-wrap;
  --mixin-gezww0bKqAfT_display: flex;
  --mixin-gezww0bKqAfT_flex-direction: column;
  --mixin-gezww0bKqAfT_align-items: stretch;
  --mixin-gezww0bKqAfT_justify-content: flex-start;
  --mixin-gezww0bKqAfT_list-style-position: outside;
  --mixin-gezww0bKqAfT_padding-left: 40px;
  --mixin-gezww0bKqAfT_position: relative;
  --mixin-gezww0bKqAfT_list-style-type: decimal;
  --mixin-gezww0bKqAfT_white-space: pre-wrap;
  --mixin-hJw_NWwza9Jf_color: #000000;
  --mixin-hJw_NWwza9Jf_font-size: 16px;
  --mixin-hJw_NWwza9Jf_font-weight: 600;
  --mixin-hJw_NWwza9Jf_line-height: 1.5;
  --mixin-hJw_NWwza9Jf_white-space: pre-wrap;
  --mixin-1a4ZbErMI4IW_color: #000000;
  --mixin-1a4ZbErMI4IW_font-size: 14px;
  --mixin-1a4ZbErMI4IW_font-weight: 600;
  --mixin-1a4ZbErMI4IW_line-height: 1.5;
  --mixin-1a4ZbErMI4IW_white-space: pre-wrap;
  --mixin--ZL1E2y7Gmkx_color: #3291ff;
  --mixin--ZL1E2y7Gmkx_white-space: pre-wrap;
  --mixin-3U9VzWX4yaiL_white-space: pre-wrap;
  --mixin-s5oy8QaTVDu6_white-space: pre-wrap;
}

.plasmic_mixins {
  --mixin-2au9sd9HQzqZ_white-space: pre-wrap;
  --plasmic-mixin-unnamed-style-preset_white-space: var(
    --mixin-2au9sd9HQzqZ_white-space
  );
  --mixin-PWWAf-PDc_2H_white-space: pre-wrap;
  --plasmic-mixin-unnamed-style-preset-2_white-space: var(
    --mixin-PWWAf-PDc_2H_white-space
  );
  --mixin-C4OBqYUqMUW6_white-space: pre-wrap;
  --plasmic-mixin-unnamed-style-preset-3_white-space: var(
    --mixin-C4OBqYUqMUW6_white-space
  );
  --mixin-T_wAaFzBvTxL_white-space: pre-wrap;
  --plasmic-mixin-over-flow_white-space: var(--mixin-T_wAaFzBvTxL_white-space);
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-KFNpWfHLKXVV_font-family);
  font-size: var(--mixin-KFNpWfHLKXVV_font-size);
  font-weight: var(--mixin-KFNpWfHLKXVV_font-weight);
  font-style: var(--mixin-KFNpWfHLKXVV_font-style);
  color: var(--mixin-KFNpWfHLKXVV_color);
  text-align: var(--mixin-KFNpWfHLKXVV_text-align);
  text-transform: var(--mixin-KFNpWfHLKXVV_text-transform);
  letter-spacing: var(--mixin-KFNpWfHLKXVV_letter-spacing);
  white-space: var(--mixin-KFNpWfHLKXVV_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-VUJMDBIGFDk5_color);
  font-weight: var(--mixin-VUJMDBIGFDk5_font-weight);
  font-size: var(--mixin-VUJMDBIGFDk5_font-size);
  line-height: var(--mixin-VUJMDBIGFDk5_line-height);
  letter-spacing: var(--mixin-VUJMDBIGFDk5_letter-spacing);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-GswtO6V7LQiB_color);
  font-size: var(--mixin-GswtO6V7LQiB_font-size);
  font-weight: var(--mixin-GswtO6V7LQiB_font-weight);
  line-height: var(--mixin-GswtO6V7LQiB_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-IBrIyXq95sYB_color);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  color: var(--mixin-yDyCcT4R5viN_color);
  font-size: var(--mixin-yDyCcT4R5viN_font-size);
  font-weight: var(--mixin-yDyCcT4R5viN_font-weight);
  line-height: var(--mixin-yDyCcT4R5viN_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  color: var(--mixin-EaVfc5Xgl6yz_color);
  font-size: var(--mixin-EaVfc5Xgl6yz_font-size);
  font-weight: var(--mixin-EaVfc5Xgl6yz_font-weight);
  line-height: var(--mixin-EaVfc5Xgl6yz_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-ImViesh4pYpt_font-family);
  border-radius: var(--mixin-ImViesh4pYpt_border-top-left-radius)
    var(--mixin-ImViesh4pYpt_border-top-right-radius)
    var(--mixin-ImViesh4pYpt_border-bottom-right-radius)
    var(--mixin-ImViesh4pYpt_border-bottom-left-radius);
  padding: var(--mixin-ImViesh4pYpt_padding-top)
    var(--mixin-ImViesh4pYpt_padding-right)
    var(--mixin-ImViesh4pYpt_padding-bottom)
    var(--mixin-ImViesh4pYpt_padding-left);
  border-top: var(--mixin-ImViesh4pYpt_border-top-width)
    var(--mixin-ImViesh4pYpt_border-top-style)
    var(--mixin-ImViesh4pYpt_border-top-color);
  border-right: var(--mixin-ImViesh4pYpt_border-right-width)
    var(--mixin-ImViesh4pYpt_border-right-style)
    var(--mixin-ImViesh4pYpt_border-right-color);
  border-bottom: var(--mixin-ImViesh4pYpt_border-bottom-width)
    var(--mixin-ImViesh4pYpt_border-bottom-style)
    var(--mixin-ImViesh4pYpt_border-bottom-color);
  border-left: var(--mixin-ImViesh4pYpt_border-left-width)
    var(--mixin-ImViesh4pYpt_border-left-style)
    var(--mixin-ImViesh4pYpt_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-aAW9xi2FPPKU_color);
  padding-left: var(--mixin-aAW9xi2FPPKU_padding-left);
  border-left: var(--mixin-aAW9xi2FPPKU_border-left-width)
    var(--mixin-aAW9xi2FPPKU_border-left-style)
    var(--mixin-aAW9xi2FPPKU_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-Mqje7POflpa1_font-family);
  border-radius: var(--mixin-Mqje7POflpa1_border-top-left-radius)
    var(--mixin-Mqje7POflpa1_border-top-right-radius)
    var(--mixin-Mqje7POflpa1_border-bottom-right-radius)
    var(--mixin-Mqje7POflpa1_border-bottom-left-radius);
  padding: var(--mixin-Mqje7POflpa1_padding-top)
    var(--mixin-Mqje7POflpa1_padding-right)
    var(--mixin-Mqje7POflpa1_padding-bottom)
    var(--mixin-Mqje7POflpa1_padding-left);
  border-top: var(--mixin-Mqje7POflpa1_border-top-width)
    var(--mixin-Mqje7POflpa1_border-top-style)
    var(--mixin-Mqje7POflpa1_border-top-color);
  border-right: var(--mixin-Mqje7POflpa1_border-right-width)
    var(--mixin-Mqje7POflpa1_border-right-style)
    var(--mixin-Mqje7POflpa1_border-right-color);
  border-bottom: var(--mixin-Mqje7POflpa1_border-bottom-width)
    var(--mixin-Mqje7POflpa1_border-bottom-style)
    var(--mixin-Mqje7POflpa1_border-bottom-color);
  border-left: var(--mixin-Mqje7POflpa1_border-left-width)
    var(--mixin-Mqje7POflpa1_border-left-style)
    var(--mixin-Mqje7POflpa1_border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-tAKmKOSwxSl1_display);
  flex-direction: var(--mixin-tAKmKOSwxSl1_flex-direction);
  align-items: var(--mixin-tAKmKOSwxSl1_align-items);
  justify-content: var(--mixin-tAKmKOSwxSl1_justify-content);
  list-style-position: var(--mixin-tAKmKOSwxSl1_list-style-position);
  padding-left: var(--mixin-tAKmKOSwxSl1_padding-left);
  position: var(--mixin-tAKmKOSwxSl1_position);
  list-style-type: var(--mixin-tAKmKOSwxSl1_list-style-type);
  flex-column-gap: var(--mixin-tAKmKOSwxSl1_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-gezww0bKqAfT_display);
  flex-direction: var(--mixin-gezww0bKqAfT_flex-direction);
  align-items: var(--mixin-gezww0bKqAfT_align-items);
  justify-content: var(--mixin-gezww0bKqAfT_justify-content);
  list-style-position: var(--mixin-gezww0bKqAfT_list-style-position);
  padding-left: var(--mixin-gezww0bKqAfT_padding-left);
  position: var(--mixin-gezww0bKqAfT_position);
  list-style-type: var(--mixin-gezww0bKqAfT_list-style-type);
  flex-column-gap: var(--mixin-gezww0bKqAfT_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  color: var(--mixin-hJw_NWwza9Jf_color);
  font-size: var(--mixin-hJw_NWwza9Jf_font-size);
  font-weight: var(--mixin-hJw_NWwza9Jf_font-weight);
  line-height: var(--mixin-hJw_NWwza9Jf_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  color: var(--mixin-1a4ZbErMI4IW_color);
  font-size: var(--mixin-1a4ZbErMI4IW_font-size);
  font-weight: var(--mixin-1a4ZbErMI4IW_font-weight);
  line-height: var(--mixin-1a4ZbErMI4IW_line-height);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin--ZL1E2y7Gmkx_color);
}

:where(.root_reset) li:where(.li),
li:where(.root_reset.li),
:where(.root_reset .__wab_expr_html_text) li,
:where(.root_reset_tags) li,
li:where(.root_reset_tags) {
}

:where(.root_reset) p:where(.p),
p:where(.root_reset.p),
:where(.root_reset .__wab_expr_html_text) p,
:where(.root_reset_tags) p,
p:where(.root_reset_tags) {
}
