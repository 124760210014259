@import '../../../style.less';


.modify-manual-sync-tip {
  display: flex;
  align-items: center;
  .modify-sync-btn {
    border: 0;
    background: transparent;
    cursor: pointer;
  
    &:hover {
      background: transparent !important;
    }
  }
}

.quick-tips-section {
  width: calc(100% - 170px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  .quick-tips-header {
    margin-right: 8px;
    width: 56px;
    display: flex;
    white-space: nowrap;
  }

  .quick-tips-data {
    display: flex;
    width: calc(100% - 56px);
    margin-right: 8px;
    .overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
