.tools-item-icon-active {
  svg {
    rect {
      fill: #ffd02b;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.tool--navbar {
  width: 36px;
  height: 36px;
  border-radius: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 4px;
}
.tool--navbar:hover {
  background: #e9e9e9;
}

.tool--navbar:active {
  background: #e9e9e9;
  border: 1px solid #c9c9c9;
}
