@import '@/styles/base/colors.less';

@mobileScreen: 600px;

// Left Side
.organizations-list {
  max-height: 275px;
  overflow-y: auto;

  .ant-btn {
    height: 55px !important;
    width: 212px !important;
    margin-left: 6px;
    border-color: transparent;
  }

  .ant-btn:focus {
    background-color: rgba(255, 208, 43, 0.1);
  }
}

/* Track */
.organizations-list::-webkit-scrollbar-track {
  background: #ffffff !important;
}

/* Handle */
.organizations-list::-webkit-scrollbar-thumb {
  background: #e3e3e3 !important;
}

.draw-line {
  background-color: #e9ebf0;
  height: 1px;
  z-index: 10;
  position: relative;
  width: 90%;
  margin-left: 10px;
}

.addBtn.ant-btn {
  width: 58px;
  height: 24px;
  padding: 0 11px;
}

.orgSelected.ant-btn {
  background-color: rgba(255, 208, 43, 0.1);
  border: 0.6px solid #ffd02b;
}

.workspaceCount {
  color: @text-primary-color;
  font-weight: 500;
  font-size: 14px;
}

.profile-data-info {
  padding-top: 10px;
}

.workspace-info.ant-btn {
  width: 64px;
  height: 24px;
}

.profile-dropdown-wrapper-left {
  display: flex;

  .logo-image-wrapper {
    margin-top: 6px;
    padding-right: 2px;
    overflow: hidden;
    min-width: 60px;

    .avatar-wrapper {
      height: 44px;
      width: 60px;
      display: inline-block;
      overflow: hidden;

      span.avatar-text {
        margin-top: 0;
        padding: 0;
        height: 44px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 400;

        .wrapper-profile-picture {
          height: 44px;
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;

          .avatar-image {
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
            object-fit: contain;
          }
        }
      }
    }

    .name-bg-color {
      background-color: #f6f9f0;
    }
  }
}

.profile-dropdown-wrapper-right {
  display: flex;
  padding: 13px 12px 7px 19px;

  .profile-info-container {
    padding-top: 11px;
    overflow: hidden;
  }

  .logo-image-wrapper {
    margin-top: 8px;
    padding-right: 14px;

    .avatar-wrapper {
      height: 45px;
      width: 45px;
      display: inline-block;
      overflow: hidden;
      background-color: #f6f9f0;
      border-radius: 50%;

      span.avatar-text {
        margin-top: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .avatar-image {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
  }
}

a.navbar-item-a .name .org-avatar-wrapper span.org-avatar-text a.navbar-item-a {
  &.name {
    .map-orgUserLogo {
      .org-avatar-wrapper {
        span.org-avatar-text {
          width: 40px !important;
          height: 26px !important;
        }
      }
    }
  }
}

.map-orgUserLogo .org-avatar-wrapper span.org-avatar-text {
  width: 40px !important;
  height: 26px !important;
}

.map-orgUserLogo .avatar-wrapper {
  height: 26px !important;
  width: 26px !important;
}

.map-orgUserLogo .ant-col-8 .profile-picture-wrapper {
  width: 26px;
  height: 26px;

  .avatar-image {
    width: 26px;
    height: 26px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.map-orgUserLogo .name-bg-color {
  background-color: #f6f9f0;
}

.orgUserLogo .name-bg-color {
  background-color: #f6f9f0;
}

a.navbar-item-a {
  padding: 0;

  .anticon {
    margin: 0px;
  }

  &.name {
    font-size: 16px;
    font-weight: 500;

    .avatar-wrapper {
      background-color: #f6f9f0;
      height: 36px;
      width: 36px;
      display: inline-block;
      border-radius: 50%;

      span.avatar-text {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
      }
    }

    .org-avatar-wrapper {
      max-height: 36px;
      width: 60px;
      display: inline-block;

      span.org-avatar-text {
        margin: 0;
        padding: 0;
        height: 36px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
      }

      .profile-picture-wrapper {
        border-radius: 0;
        height: 36px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.orgUserLogo {
  margin: 2px 20px 24px 28px;
  padding: 7px 8px;
  width: 120px;
  height: 50px;
  border: 0.5px solid #dedede;
  border-radius: 4px;

  .ant-col-14 {
    display: flex;
  }

  .ant-col-8 {
    .avatar-image {
      width: 36px;
      height: 36px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .avatar-wrapper {
    border-radius: 0;

    .profile-picture-wrapper {
      border-radius: 0;
    }
  }
}

.map-orgUserLogo {
  background-color: #ffffff;
  border: 0.5px solid #dedede;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 36px !important;
  width: 89px !important;

  .ant-col-14 {
    width: 40px;
    height: 26px;
  }

  .ant-col-8 {
    max-height: 26px;
  }

  .org-avatar-wrapper {
    overflow: hidden;
    height: 26px !important;
    width: 40px !important;
    margin-bottom: 0;
    display: block;
    max-height: 26px !important;

    .org-avatar-text {
      height: 26px !important;
      font-size: 16px !important;

      .profile-picture-wrapper {
        height: 26px !important;
        width: 40px !important;
        max-width: 40px !important;
        overflow: hidden;
      }
    }
  }

  .avatar-wrapper {
    span.avatar-text {
      font-size: 16px !important;
    }
  }
}

.dropdown-link-items {
  width: 100%;
  border-bottom: none;
}

.wrapper-profile-picture {
  overflow: hidden;
  width: 100%;
  height: 100%;

  .avatar-image {
    width: 100%;
    height: 100%;
  }

  .notificationAlert {
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: #ff000f;
    border-radius: 50%;
    z-index: 10;
    top: 8px;
    left: 67px;
  }
}

.setOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  display: inline-block;
}

.workspace-data-name {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: @text-primary-color;
  word-wrap: break-word;
  text-align: left;
}

.workspace-data-organisation {
  font-weight: 400;
  font-size: 10px;
  line-height: 7px;
  color: @tag-text-color;
  text-align: left;
  word-wrap: break-word;
}

.org-input-wrapper {
  margin-bottom: 20px;
  position: relative;

  .organisation-input {
    width: 354px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .correct-admin {
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 10px;

    .correct-admin-text {
      margin-left: 3px;
    }
  }
}

.or-line {
  width: 30%;
  height: 1px;
  color: @text-secondary-color;
}

.create-workspace-msg {
  color: @text-primary-color;
  text-align: center !important;
  width: 100%;
  margin: 0.8rem 0;
  font-size: 14px;
}

.remove-border {
  margin: 1rem 0;
  height: 1rem;

  input {
    border-radius: 4px 0 0 4px;
    background: rgba(238, 225, 225, 0.5);
    line-height: 1rem;
  }

  button {
    border-radius: 0 4px 4px 0;
    line-height: 1rem;
  }
}

.signup-modal {
  .ant-modal-content {
    background: rgba(255, 255, 255, 0.36) !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(54px);
    border-radius: 16px;
  }
}

.ant-modal-content {
  border-radius: 16px;
}

.workspace-modal,
.signup-modal {
  padding: 2rem 3rem;

  .ant-modal-content {
    border-radius: 16px;
  }
}

.or-line-wrapper {
  margin: 1rem 0;
}

.workspace-footer {
  div:first-child {
    text-align: left;
  }

  div:last-child {
    text-align: right;
  }

  button {
    padding: 0;
    font-size: 1rem;
    color: @text-primary-color;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.signup-modal {
  color: @background-color !important;

  button,
  button > * {
    color: @background-color !important;
  }

  button,
  .btn-primary-color,
  .btn-primary-color span {
    color: @text-primary-color !important;
  }

  .remove-border {
    input {
      background: @background-color;
    }
  }

  .ant-modal-body {
    padding-top: 0;
  }
}

.join-workspace {
  height: 10vw;
  max-height: 10vw;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #999 rgba(255, 255, 255, 0.3);
}

.workspace-wrapper {
  background: @background-color;
  border: 0.5px solid @border-color;
  border-radius: 4px;
  width: 98%;
  padding: 0.2rem 0;
  padding-left: 0.5rem;
  pointer-events: fill;
  margin: 0.3rem 0;
}

.selected-ws {
  border: 1px solid @border-color-active-order;
  padding: 8px;
  background: @avatar-background;
  border-radius: 4px;
}

button:disabled {
  background: @background-gradient;
}

.avatar {
  width: 34px;
  height: 34px;
}

.signup-modal {
  max-width: 427px;
}

.avatar-wrapper-create {
  height: 44px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #f6f9f0;

  .avatar-text {
    margin-top: 0;
    padding: 0;
    height: 44px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
  }

  .ant-image-img {
    width: 100%;
    height: 100%;
  }
}


