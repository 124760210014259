.box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  height: 20px;
  width: fit-content;
  padding: 0px 12px;
  font-weight: normal;
  font-size: 10px;
}

.orange {
  color: rgb(255, 164, 103);
  border: 1px solid rgb(255, 164, 103);
}
