.view-selection-menu {
  position: fixed;
  left: 56px;
  top: 50px;
  height: 100%;
  // width: 248px;
  background: #fff;
  z-index: 998;

  &-content {
    //   padding: 20px;
  }

  &-enter {
    transform: translateX(-100%);
  }

  &-enter-active {
    transform: translateX(0);
    transition: transform 300ms;
  }

  &-exit {
    transform: translateX(0);
  }

  &-exit-active {
    transform: translateX(-120%);
    transition: transform 300ms;
  }

  .deafult-badge {
    line-height: normal;
  }

  .view-panel-scrollable {
    width: calc(100% - 2px);
    padding-right: 4px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      visibility: hidden !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd !important;
      border-radius: 4px !important;
      width: 4px !important;
    }
  }
}
