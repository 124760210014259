.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  flex-grow: 1;
  min-width: 0;
}
.polygonLayerStyling {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 500px;
  min-width: 0;
  border-radius: 4px;
  border: 1px solid #dddddd;
}
.frame1000005039 {
  background: #ffffff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: auto;
  display: flex;
  position: relative;
  align-self: auto;
  overflow: hidden;
  flex-direction: row;
  cursor: default;
  width: 100%;
  pointer-events: none;
  min-width: 0;
  padding: 12px 8px;
}
.frame1000005039 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005039 > :global(.__wab_flex-container) > *,
.frame1000005039 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005039 > :global(.__wab_flex-container) > picture > img,
.frame1000005039
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000005039disable {
  background: #dddddd;
  cursor: not-allowed;
}
.emTakeOffLayerChips2__axGx:global(.__wab_instance) {
  max-width: 100%;
}
.text__tl0JO {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.textdisable__tl0JOApUHo {
  color: #999999;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  display: none;
  padding: 0px;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_DMq7pcq6IVwx {
  outline: none;
  padding: 0px;
}
.freeBox___33Pa {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
}
.root .freeBox___33Pa:hover {
  background: #f5f5f5;
  border-radius: 2px;
}
.svg__l4GZx {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  display: block;
  flex-shrink: 0;
  border-radius: 2px;
}
.tooltipContent:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.polygonColors {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame1000005043 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  box-shadow: none;
  display: none;
  padding: 8px;
  border-top: 0.5px solid #dddddd;
  border-bottom: 0px none #dddddd;
}
.frame1000005043 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000005043 > :global(.__wab_flex-container) > *,
.frame1000005043 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005043 > :global(.__wab_flex-container) > picture > img,
.frame1000005043
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000005043disable {
  display: none;
}
.text___3BMmq {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
  height: 18px;
  flex-shrink: 0;
}
.frame1000005040 {
  height: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  min-height: 0;
}
.frame1000005040 > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  min-height: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.frame1000005040 > :global(.__wab_flex-container) > *,
.frame1000005040 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005040 > :global(.__wab_flex-container) > picture > img,
.frame1000005040
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 2px;
}
.freeBox___0SMf9 {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  max-width: 100%;
  cursor: pointer;
  background: none;
  flex-shrink: 0;
  border-radius: 3px;
}
.root .freeBox___0SMf9:hover {
  box-shadow: inset 0px 0px 0px 0.75px #e0e0e0;
}
.root .freeBox___0SMf9:active {
  background: #ebebeb;
}
.emTakeOffLayerChips2__vTKxK:global(.__wab_instance) {
  max-width: 100%;
}
.emTakeOffLayerChips:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.freeBox__wc8Yv {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  border-radius: 3px;
}
.root .freeBox__wc8Yv:active {
  background: #ebebeb;
}
.svg__bgDe0 {
  object-fit: cover;
  max-width: 100%;
  color: #666666;
  width: 24px;
  height: 24px;
  display: block;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 3px;
}
.root .svg__bgDe0:hover {
  color: #333333;
}
.colorPicker {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding: 8px;
  border-top: 0.5px solid #dddddd;
}
.colorPicker > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.colorPicker > :global(.__wab_flex-container) > *,
.colorPicker > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.colorPicker > :global(.__wab_flex-container) > picture > img,
.colorPicker
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000005097 {
  width: 280px;
  height: auto;
  display: flex;
  flex-direction: row;
}
.frame1000005097 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.frame1000005097 > :global(.__wab_flex-container) > *,
.frame1000005097 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005097 > :global(.__wab_flex-container) > picture > img,
.frame1000005097
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.frame1000004333 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000004333 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.frame1000004333 > :global(.__wab_flex-container) > *,
.frame1000004333 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004333 > :global(.__wab_flex-container) > picture > img,
.frame1000004333
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.frame1000004331 {
  background: none;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px 12px;
}
.frame1000004331 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000004331 > :global(.__wab_flex-container) > *,
.frame1000004331 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004331 > :global(.__wab_flex-container) > picture > img,
.frame1000004331
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.text__kNg46 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.embedHtml:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.opacity2 {
  background: rgba(255, 255, 255, 1);
  height: 36px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
  padding: 10px 8px;
  border-top: 0.5px solid #dddddd;
  border-bottom: 0.5px solid #dddddd;
}
.opacity2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.opacity2 > :global(.__wab_flex-container) > *,
.opacity2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.opacity2 > :global(.__wab_flex-container) > picture > img,
.opacity2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.opacity2disable {
  display: none;
}
.text__afYq0 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000002500 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002500 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002500 > :global(.__wab_flex-container) > *,
.frame1000002500 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002500 > :global(.__wab_flex-container) > picture > img,
.frame1000002500
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000002501 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: auto;
  flex-shrink: 0;
  border-radius: 33.333px;
  padding: 3.333px;
}
.frame1000002501 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.frame1000002501 > :global(.__wab_flex-container) > *,
.frame1000002501 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002501 > :global(.__wab_flex-container) > picture > img,
.frame1000002501
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .frame1000002501:hover {
  background: #dddddd;
}
.root .frame1000002501:active {
  background: #b8b8b8;
}
.svg___29Vso {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.text__gagpY {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 36px;
  flex-shrink: 0;
}
.plusIcon {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 33.333px;
  padding: 3.333px;
}
.plusIcon > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.plusIcon > :global(.__wab_flex-container) > *,
.plusIcon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.plusIcon > :global(.__wab_flex-container) > picture > img,
.plusIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .plusIcon:hover {
  background: #dddddd;
}
.root .plusIcon:active {
  background: #bbbbbb;
}
.svg__fOizK {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.strokeSize2 {
  background: rgba(255, 255, 255, 1);
  height: 36px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
  padding: 10px 8px;
}
.strokeSize2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.strokeSize2 > :global(.__wab_flex-container) > *,
.strokeSize2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.strokeSize2 > :global(.__wab_flex-container) > picture > img,
.strokeSize2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.strokeSize2disable {
  display: none;
}
.opacity {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000002502 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002502 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002502 > :global(.__wab_flex-container) > *,
.frame1000002502 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002502 > :global(.__wab_flex-container) > picture > img,
.frame1000002502
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000002503 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 33.333px;
  padding: 3.333px;
}
.frame1000002503 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.frame1000002503 > :global(.__wab_flex-container) > *,
.frame1000002503 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002503 > :global(.__wab_flex-container) > picture > img,
.frame1000002503
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .frame1000002503:hover {
  background: #dddddd;
}
.root .frame1000002503:active {
  background: #bbbbbb;
}
.svg__nPqHt {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
._80 {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 36px;
  flex-shrink: 0;
}
.plusIcon2 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 33.333px;
  padding: 3.333px;
}
.plusIcon2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.plusIcon2 > :global(.__wab_flex-container) > *,
.plusIcon2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.plusIcon2 > :global(.__wab_flex-container) > picture > img,
.plusIcon2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .plusIcon2:hover {
  background: #dddddd;
}
.root .plusIcon2:active {
  background: #bbbbbb;
}
.svg__yQWaP {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.vertices {
  background: rgba(255, 255, 255, 1);
  height: 36px;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
  display: none;
  padding: 6px 8px;
  border-top: 0.5px solid #dddddd;
}
.vertices > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.vertices > :global(.__wab_flex-container) > *,
.vertices > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.vertices > :global(.__wab_flex-container) > picture > img,
.vertices
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.verticesdisable {
  display: none;
}
.frame1000005072 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.frame1000005072 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000005072 > :global(.__wab_flex-container) > *,
.frame1000005072 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005072 > :global(.__wab_flex-container) > picture > img,
.frame1000005072
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.labels {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.infoTooltip2 {
  background: rgba(255, 255, 255, 1);
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 1.333px;
}
.svg__ujC7L {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 12px;
  height: 11.999px;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
}
.toggle4 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.toggle4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 7.5px);
  height: calc(100% + 7.5px);
}
.toggle4 > :global(.__wab_flex-container) > *,
.toggle4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.toggle4 > :global(.__wab_flex-container) > picture > img,
.toggle4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 7.5px;
}
.switchComponent:global(.__wab_instance) {
  max-width: 100%;
}
.text__iYBvo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
}
.sideEffect:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
