.frame1000002572 {
  height: auto;
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-self: flex-start;
  min-width: 0;
  padding: 4px 12px 4px 32px;
  border-right: 1px solid #e8e8e8;
}
.frame1000002572 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002572 > :global(.__wab_flex-container) > *,
.frame1000002572 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002572 > :global(.__wab_flex-container) > picture > img,
.frame1000002572
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBox___7IYqS {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___7IYqS > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox___7IYqS > :global(.__wab_flex-container) > *,
.freeBox___7IYqS > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___7IYqS > :global(.__wab_flex-container) > picture > img,
.freeBox___7IYqS
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.emCheckbox:global(.__wab_instance) {
  max-width: 100%;
  margin-right: 5px;
  flex-shrink: 0;
}
.text__dYz4X {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  position: relative;
}
.span___5Fh3O {
  color: #666666;
  width: 100%;
  height: 100%;
  line-height: 16px;
  font-weight: 400;
  font-size: 10px;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.text__uWTso {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  position: relative;
  color: #999999;
}
.span__v4Dmo {
  color: #666666;
  width: 100%;
  height: 100%;
  line-height: 16px;
  font-weight: 400;
  font-size: 10px;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  background: transparent;
  padding: 0px;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  box-shadow: 0px 4px 16px 0px transparent;
  outline: none;
  padding: 0px;
}
.pcls_PD2rYRrr0zk9 {
  box-shadow: 0px 4px 16px 0px transparent;
  outline: none;
  padding: 0px;
}
.freeBox__gNcq3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 24px;
  height: 24px;
  max-width: 100%;
  cursor: pointer;
}
.frame1000002572 .freeBox__gNcq3:hover {
  background: #e9e9e9;
  border-radius: 21px;
}
.frame1000002572 .freeBox__gNcq3:active {
  background: #e9e9e9;
  box-shadow: inset 0px 0px 0px 1px #c9c9c9;
  border-radius: 25px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.emServiceLabelSwitchPopover:global(.__wab_instance) {
  max-width: 100%;
}
