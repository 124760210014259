.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  min-height: 0;
}
.lottie:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: fixed;
  height: 38px;
  left: 1052px;
  top: 199px;
  z-index: 1;
  flex-shrink: 0;
  padding: 0px;
}
.freeBox {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 15px;
  height: auto;
  max-width: 100%;
  margin-right: 0px;
  display: none;
}
