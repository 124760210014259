.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-jgohepLVeKvh);
  overflow: visible;
  justify-self: flex-start;
  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 0;
  min-height: 0;
  border-style: solid;
  border-color: #333333;
}
.frame1000004569 {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  left: auto;
  top: auto;
  z-index: 1;
  position: relative;
  border-top: 0.5px solid #dddddd;
  border-right: 0.5px solid #dddddd;
  border-left: 0.5px solid #dddddd;
}
.frame1000004569 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000004569 > :global(.__wab_flex-container) > *,
.frame1000004569 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004569 > :global(.__wab_flex-container) > picture > img,
.frame1000004569
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000005061 {
  width: 456px;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame1000005335 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.frame1000005333 {
  background: #f9fafb;
  height: 48px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  left: auto;
  top: auto;
  border-bottom-style: solid;
  border-bottom-color: #666666cc;
  min-width: 0;
  flex-shrink: 0;
  border-width: 0.5px;
}
.emAllServiceToggle:global(.__wab_instance) {
  position: relative;
  width: 100%;
  flex-shrink: 1;
  min-width: 0;
}
.frame1000005332 {
  width: 145px;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  padding: 4px;
  border-width: 1px;
}
.frame1000005332 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.frame1000005332 > :global(.__wab_flex-container) > *,
.frame1000005332 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005332 > :global(.__wab_flex-container) > picture > img,
.frame1000005332
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.text__f6UtG {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.freeBox___7ZWrz {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  max-width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 25px;
}
.root .freeBox___7ZWrz:hover {
  background: #66666642;
  border-radius: 25px;
}
.svg__mDvt {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666666;
  height: 1em;
}
.svg__txLeS {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666666;
  height: 1em;
}
.freeBox__kaQlj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
  left: auto;
  top: auto;
  min-width: 0;
}
.layersList {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  align-self: auto;
  min-width: 0;
}
.parcelStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 36px;
  max-width: 100%;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 4px 14px 4px 17px;
  border-top: 1px none #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.frame1000002574 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  padding: 0px 0px 0px 14px;
}
.frame1000002574 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002574 > :global(.__wab_flex-container) > *,
.frame1000002574 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002574 > :global(.__wab_flex-container) > picture > img,
.frame1000002574
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.emCheckbox13:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.text__mNjx {
  display: block;
  flex-grow: 0;
  font-size: 12px;
  letter-spacing: 0em;
  line-height: 16px;
  position: relative;
  width: 223px;
  font-weight: 500;
  padding-right: 0px;
  flex-shrink: 0;
}
.emTakeOffLayerChips:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.freeBox__wkvAj {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 24px;
  max-width: 100%;
  min-width: 0;
  border-left: 1px solid #e8e8e8;
}
.text___9R1K0 {
  width: 100%;
  height: 28px;
  max-width: 100%;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  line-height: 2.54;
  min-width: 0;
}
.nearMapStack {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 36px;
  max-width: 100%;
  padding-left: 17px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-width: 0;
  flex-shrink: 0;
}
.frame1000005334 {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.frame1000002572 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  flex-shrink: 0;
  padding: 4px 12px 4px 14px;
}
.frame1000002572 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002572 > :global(.__wab_flex-container) > *,
.frame1000002572 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002572 > :global(.__wab_flex-container) > picture > img,
.frame1000002572
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.emCheckbox1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.frame1000005347 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.frame1000005347 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.frame1000005347 > :global(.__wab_flex-container) > *,
.frame1000005347 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005347 > :global(.__wab_flex-container) > picture > img,
.frame1000005347
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.text__oKtsj {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  font-weight: 500;
  font-size: 12px;
}
.text__ondYi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 12px;
  text-align: justify;
  line-height: 16px;
  min-width: 0;
}
.baseMap {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 36px;
  max-width: 100%;
  padding-left: 17px;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.frame1000005336 {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.frame1000002573 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  padding: 4px 12px 4px 14px;
}
.frame1000002573 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002573 > :global(.__wab_flex-container) > *,
.frame1000002573 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002573 > :global(.__wab_flex-container) > picture > img,
.frame1000002573
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.emCheckbox12:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.frame1000005348 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.frame1000005348 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005348 > :global(.__wab_flex-container) > *,
.frame1000005348 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005348 > :global(.__wab_flex-container) > picture > img,
.frame1000005348
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__cUbQp {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  letter-spacing: 0em;
  line-height: 16px;
  position: relative;
  font-weight: 500;
}
