.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  background: #33333300;
}
.frame1000005277 {
  background: #333333cc;
  width: 152px;
  height: auto;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  border-radius: 4px;
  padding: 4px 4px 8px;
}
.frame1000005277 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000005277 > :global(.__wab_flex-container) > *,
.frame1000005277 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005277 > :global(.__wab_flex-container) > picture > img,
.frame1000005277
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__k8FF {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
}
.text__yejHm {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  position: relative;
  cursor: pointer;
}
