.frame1000002572 {
  height: auto;
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-self: flex-start;
  min-width: 0;
  padding: 4px 8px 4px 28px;
  border-right: 1px solid #e8e8e8;
}
.frame1000002572 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002572 > :global(.__wab_flex-container) > *,
.frame1000002572 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002572 > :global(.__wab_flex-container) > picture > img,
.frame1000002572
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBox__wdTmu {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__wdTmu > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox__wdTmu > :global(.__wab_flex-container) > *,
.freeBox__wdTmu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wdTmu > :global(.__wab_flex-container) > picture > img,
.freeBox__wdTmu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.emCheckbox:global(.__wab_instance) {
  max-width: 100%;
  margin-right: 5px;
  flex-shrink: 0;
}
.text__xLufc {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  position: relative;
}
.span__mDhmk {
  color: #666666;
  width: 100%;
  height: 100%;
  line-height: 16px;
  font-weight: 400;
  font-size: 10px;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.text__byuvF {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  position: relative;
  color: #999999;
}
.span__faEbi {
  color: #666666;
  width: 100%;
  height: 100%;
  line-height: 16px;
  font-weight: 400;
  font-size: 10px;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.pcls_feGBMWx2PEZR {
  outline: none;
  padding: 0px;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.freeBox___3Dwdi {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 24px;
  height: 24px;
  max-width: 100%;
  cursor: pointer;
}
.img__obdh {
  object-fit: cover;
  max-width: 100%;
}
.img__obdh > picture > img {
  object-fit: cover;
}
.img__d8Q6K {
  object-fit: cover;
  max-width: 100%;
}
.img__d8Q6K > picture > img {
  object-fit: cover;
}
.tooltipContent:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
