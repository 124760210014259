@checkbox-size: 20px;
@checkbox-color: @main-color;
@checkbox-check-color: @black;
@checkbox-check-bg: #ffffff;
@checkbox-border-width: @border-width-base;
@checkbox-group-item-margin-right: 8px;

/*============================OVERRIDES============================*/

.ant-checkbox-inner {
  border: 1px solid #d0d0d0;
  border-radius: 2px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: @background-gradient;
  border: none;
}

.ant-checkbox-checked {
  &:after {
    border: none !important;
  }
}

.ant-checkbox-inner::after {
  top: 9px;
  left: 5px;
}
