.em-quick-tip {
  position: fixed;
  width: 100%;
  border-top: 0.5px solid #DDD;
  border-left: 0.5px solid #DDD;
}

.takeoff-layer-panel-box {
  .layer-panel-scrollable-container,
  .takeoff-layer-panel__component-list {
    width: calc(100% - 2px);
    padding-right: 0px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      visibility: hidden !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd !important;
      border-radius: 4px !important;
      width: 4px !important;
    }
  }
}
