.root {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  justify-self: flex-start;
  cursor: pointer;
}
.rootdisabled {
  cursor: default;
}
.tooltipCore:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.parcel {
  width: 48px;
  height: 40px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.parcelselected {
  background: #fdedbb;
}
.root:hover .parcel {
  background: #e9e9e9;
}
.rootselected:hover .parcelselected {
  background: #fdedbb;
}
.rootdisabled:hover .parceldisabled {
  background: none;
}
.svg__jbxUc {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  color: #666666;
  flex-shrink: 0;
}
.svgselected__jbxUc1F62J {
  color: #333333;
}
.root:hover .svg__jbxUc {
  color: #333333;
}
.svg__wYu4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  color: #666666;
  flex-shrink: 0;
}
.root:hover .svg__wYu4 {
  color: #333333;
}
.svg__c2Ni {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  color: #666666;
  flex-shrink: 0;
}
.svgselected__c2Ni1F62J {
  color: #333333;
}
.svgdisabled__c2Ni44Zc4 {
  color: #cccccc;
}
.root:hover .svg__c2Ni {
  color: #333333;
}
.rootdisabled:hover .svgdisabled__c2Ni44Zc4 {
  color: #cccccc;
}
.svg___3JziV {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  color: #666666;
  flex-shrink: 0;
}
.svgselected___3JziV1F62J {
  color: #333333;
}
.svgdisabled___3JziV44Zc4 {
  color: #cccccc;
}
.root:hover .svg___3JziV {
  color: #333333;
}
.rootdisabled:hover .svgdisabled___3JziV44Zc4 {
  color: #cccccc;
}
.svg__w8BpZ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  color: #666666;
  flex-shrink: 0;
}
.svgselected__w8BpZ1F62J {
  color: #333333;
}
.svgdisabled__w8BpZ44Zc4 {
  color: #cccccc;
}
.root:hover .svg__w8BpZ {
  color: #333333;
}
.rootdisabled:hover .svgdisabled__w8BpZ44Zc4 {
  color: #cccccc;
}
.svg___7Xo4U {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  color: #666666;
  flex-shrink: 0;
}
.svgselected___7Xo4U1F62J {
  color: #333333;
}
.svgdisabled___7Xo4U44Zc4 {
  color: #cccccc;
}
.root:hover .svg___7Xo4U {
  color: #333333;
}
.rootdisabled:hover .svgdisabled___7Xo4U44Zc4 {
  color: #cccccc;
}
.svg__t9Ox {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.svg__hUq3R {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666666;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.root:hover .svg__hUq3R {
  color: #333333;
}
.rootdisabled:hover .svgdisabled__hUq3R44Zc4 {
  color: #cccccc;
}
.freeBox {
  display: flex;
  flex-direction: column;
  position: sticky;
  height: auto;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  background: #333333;
  z-index: 100;
  left: 0px;
  top: 0px;
  width: auto;
  border-radius: 4px;
  padding: 4px 8px;
}
.text {
  color: #ffffff;
  font-size: 12px;
  text-transform: capitalize;
}
