.no-border {
  border: none !important;
  outline: none !important;
  padding: 12px 10px !important;
}

// Invite member modal
.invite-member-modal {
  .ant-modal-content {
    border-radius: 8px;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.2));
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff5151;
  }
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #ff5151 !important;
  }
  .ant-modal-body {
    padding: 16px 24px 27px;
  }

  .ant-divider-horizontal {
    margin: 16px 0;
  }

  .ant-btn,
  .ant-select-selector {
    min-height: 44px;
    height: auto !important;
    border-color: #eaeaea !important;
    border-radius: 4px !important;
    padding-left: 15px !important;
    font-size: rem(12);
  }
  .ant-select-selection-item {
    font-size: rem(12);
    color: #333333;
    font-weight: 400;
  }

  .email-form-item {
    position: relative;
    margin-bottom: 0px;
    .ant-form-item-explain {
      position: absolute;
      top: 185px;
      font-size: rem(12);
    }
  }

  ::placeholder,
  .ant-select-selection-placeholder {
    font-style: normal !important;
    font-size: rem(12);
    font-weight: 400;
  }

  .ant-select-multiple .ant-select-selection-item {
    background: #ffffff;
    border: 1px solid #999999;
  }

  .ant-select-multiple {
    .ant-select-selection-placeholder {
      margin-left: 4px;
    }
  }

  .ant-input:placeholder-shown {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
  }

  .invite-as-dropdown.ant-btn {
    width: 193px;
  }

  .access-property-dropdown.ant-btn {
    width: 387px;
  }

  .anticon {
    color: #666666;
  }

  .send-invite.ant-btn {
    width: 175px;
    font-size: 16px;
  }

  .send-invite.ant-btn[disabled] {
    background: #acacac !important;
    color: #dcdcdc;
  }

  .ant-divider {
    line-height: 5px;
  }

  .copy-code {
    height: 44px;
    border: 1px dashed #c4c4c4;
    border-radius: 4px;
  }

  .ant-typography {
    display: inline-block;
  }

  .invite-dropdown {
    .ant-select-selection-item {
      position: relative;
      top: 6px;
    }
  }

  .data-access-dropdown {
    .ant-select-selection-item {
      position: relative;
      top: 6px;
    }
  }

  .invite-dropdown-height {
    .ant-select-selector {
      height: 44px;
      .ant-select-selection-search {
        margin-top: 6px;
      }
      .ant-select-selection-placeholder {
        margin-top: 6px;
      }
    }
  }
  .ant-select-selection-overflow-item-rest .ant-select-selection-item {
    display: none;
  }
  .email-count {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 10px;
    background: #d1fae5;
    border-radius: rem(10);
    color: #065f46;
    font-weight: 500;
    font-size: rem(10);
    padding: 2px 10px;
    cursor: pointer;
  }
  .email-error {
    color: #dc2626;
    background-color: #fee2e2;
  }
  .em-enabled {
    display: inline-block;
  }
  .em-disabled {
    display: none !important;
  }
  .invite-email {
    .ant-select-selector {
      padding-left: 5px !important;
    }
  }
}
.invite-email-select {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent;
    border: none;
    border-left: 3px solid transparent;
    padding: 9px 16px;
    font-weight: 400;
    font-size: 12px;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled):hover {
    background-color: #f5f5f5;
    border-left: 3px solid #ffc145;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    .anticon-check {
      position: relative;

      &::after {
        content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik05LjA0Nzk2IDkuMDQ3OTVMMSAxIiBzdHJva2U9IiM5OTk5OTkiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPHBhdGggZD0iTTkuMTI5OTkgMUwxLjA4MjAzIDkuMDQ3OTUiIHN0cm9rZT0iIzk5OTk5OSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4NCg==);
        position: absolute;
        background: white;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: background 0.3s ease;
      }
    }

    &:hover {
      .anticon-check {
        &::after {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

.incorrect-email {
  .ant-select-selector {
    border: 1px solid rgb(255, 81, 81) !important;
  }

  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: none !important;
    }
  }
}
// Leave workspace moda
.leave-workspace-modal {
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-body {
      padding: 24px 0px 24px 24px;
    }
  }
}

// Delete Modal
.delete-member-modal {
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-body {
      padding: 24px 0px 24px 0px;
    }
  }
}

// New admin modal
.new-admin-modal {
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-body {
      padding: 24px 6px 24px 24px;
    }
  }
}

.invite-as-dd {
  .ant-select-item-option-content {
    span {
      white-space: normal !important;
    }
  }
}
.invite-moodal-property-dd {
  .ant-select-item-option-content {
    white-space: normal !important;
    font-size: 12px !important;
  }
}
