.frame1000005891 {
  width: 148px;
  height: auto;
  display: flex;
  flex-direction: row;
  position: relative;
  background: none;
}
.frame1000005891 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000005891 > :global(.__wab_flex-container) > *,
.frame1000005891 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005891 > :global(.__wab_flex-container) > picture > img,
.frame1000005891
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000005880 {
  background: rgba(0, 0, 0, 1);
  box-shadow: inset 0px 0px 0px 0.5px rgba(221, 221, 221, 1);
  width: 26px;
  height: 16px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 46.874px;
  padding: 3.749px 8.749px;
}
.frame1000005880 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 9.999px);
  height: calc(100% + 9.999px);
}
.frame1000005880 > :global(.__wab_flex-container) > *,
.frame1000005880 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005880 > :global(.__wab_flex-container) > picture > img,
.frame1000005880
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 9.999px;
}
._23 {
  position: relative;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.text__vd1NQ {
  display: block;
  font-size: 8px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 20px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 11.64px;
}
.img > picture > img {
  object-fit: cover;
}
.text {
  display: block;
  flex-grow: 0;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.54;
  color: rgba(255, 255, 255, 1);
  position: relative;
  white-space: pre-wrap;
  width: 130px;
  flex-shrink: 0;
}
