.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.newTakeoffLayer {
  background: rgba(255, 255, 255, 1);
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2));
  width: 544px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 16px 24px 24px;
}
.newTakeoffLayer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.newTakeoffLayer > :global(.__wab_flex-container) > *,
.newTakeoffLayer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.newTakeoffLayer > :global(.__wab_flex-container) > picture > img,
.newTakeoffLayer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.frame1000006818 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000006818 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000006818 > :global(.__wab_flex-container) > *,
.frame1000006818 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006818 > :global(.__wab_flex-container) > picture > img,
.frame1000006818
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000006794 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006794 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006794 > :global(.__wab_flex-container) > *,
.frame1000006794 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006794 > :global(.__wab_flex-container) > picture > img,
.frame1000006794
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.img__ixNhq {
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  flex-shrink: 0;
}
.img__ixNhq > picture > img {
  object-fit: cover;
}
.text {
  display: block;
  flex-grow: 1;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 28px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.crossIcon {
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  flex-direction: column;
  left: 466px;
  top: -6px;
  flex-shrink: 0;
  padding: 8px;
}
.crossIcon > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.crossIcon > :global(.__wab_flex-container) > *,
.crossIcon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.crossIcon > :global(.__wab_flex-container) > picture > img,
.crossIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.root .crossIcon:hover {
  background: #e9e9e9;
  border-radius: 50px;
}
.root .crossIcon:active {
  box-shadow: inset 0px 0px 0px 1px #c9c9c9;
  border-radius: 50px;
}
.svg__uYqdf {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  flex-shrink: 0;
}
.supportingText {
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 1.54;
  color: rgba(102, 102, 102, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
  display: none;
}
.embedHtml:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  font-size: 14px;
}
.frame1000006845 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000006845 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000006845 > :global(.__wab_flex-container) > *,
.frame1000006845 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006845 > :global(.__wab_flex-container) > picture > img,
.frame1000006845
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.title {
  background: rgba(244, 244, 244, 1);
  backdrop-filter: blur(32px);
  height: 208px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  -webkit-backdrop-filter: blur(32px);
  border-radius: 10px;
}
.frame1000002688 {
  background: rgba(244, 244, 244, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding: 10px 12px 10px 14px;
  border-bottom: 1px solid #e5e5e5;
}
.frame1000002688 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002688 > :global(.__wab_flex-container) > *,
.frame1000002688 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002688 > :global(.__wab_flex-container) > picture > img,
.frame1000002688
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000002694 {
  height: 20px;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.frame1000002694 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002694 > :global(.__wab_flex-container) > *,
.frame1000002694 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002694 > :global(.__wab_flex-container) > picture > img,
.frame1000002694
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000003621 {
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
}
.frame1000003621 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 0.566px);
  height: calc(100% + 0.566px);
}
.frame1000003621 > :global(.__wab_flex-container) > *,
.frame1000003621 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000003621 > :global(.__wab_flex-container) > picture > img,
.frame1000003621
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0.566px;
}
.img__d0M7Q {
  object-fit: cover;
  max-width: 100%;
  width: 18px;
  height: auto;
  display: block !important;
  position: relative;
  overflow: hidden;
}
.img__d0M7Q > picture > img {
  object-fit: cover;
}
.title2 {
  display: block;
  flex-grow: 1;
  font-size: undefinedpx;
  letter-spacing: 0px;
  line-height: 1.24;
  position: relative;
}
.span___2ZOoh {
  font-weight: 500;
  font-size: 14px;
}
.span__vkJrE {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}
.frame1000006834 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-grow: 0;
  flex-direction: column;
  height: 168px;
  flex-shrink: 0;
  overflow: auto;
  padding: 8px 12px 4px;
}
.frame1000006834 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000006834 > :global(.__wab_flex-container) > *,
.frame1000006834 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006834 > :global(.__wab_flex-container) > picture > img,
.frame1000006834
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_XLAu-2sTYSHw {
  outline: none;
  padding: 0px;
}
.frame1000006838 {
  height: 40px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 4px 4px 8px;
}
.frame1000006838 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006838 > :global(.__wab_flex-container) > *,
.frame1000006838 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006838 > :global(.__wab_flex-container) > picture > img,
.frame1000006838
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.root .frame1000006838:hover {
  background: #ffffff;
}
.coParcelRadioBtn__xuAhv:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.svg__wZugr {
  object-fit: cover;
  max-width: 100%;
  color: #8e8e8e;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.tooltipContent__zGZ:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.frame1000006835 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-grow: 0;
  flex-direction: column;
  height: 168px;
  flex-shrink: 0;
  overflow: auto;
  padding: 8px 12px 4px;
}
.frame1000006835 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000006835 > :global(.__wab_flex-container) > *,
.frame1000006835 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006835 > :global(.__wab_flex-container) > picture > img,
.frame1000006835
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.freeBox__mew2V {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.draftTakeoffView2 {
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  flex-shrink: 0;
}
.draftTakeoffView2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.draftTakeoffView2 > :global(.__wab_flex-container) > *,
.draftTakeoffView2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.draftTakeoffView2 > :global(.__wab_flex-container) > picture > img,
.draftTakeoffView2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000004956 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  padding-left: 4px;
  width: auto;
  padding-right: 4px;
}
.frame1000004956 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000004956 > :global(.__wab_flex-container) > *,
.frame1000004956 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004956 > :global(.__wab_flex-container) > picture > img,
.frame1000004956
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__amLx {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
  width: auto;
}
.svg__rPblp {
  object-fit: cover;
  max-width: 100%;
  color: #8e8e8e;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.draftView {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 4px;
  min-width: 0;
}
.draftView > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.draftView > :global(.__wab_flex-container) > *,
.draftView > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.draftView > :global(.__wab_flex-container) > picture > img,
.draftView
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000004955 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.frame1000004955 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000004955 > :global(.__wab_flex-container) > *,
.frame1000004955 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004955 > :global(.__wab_flex-container) > picture > img,
.frame1000004955
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__nNqlo {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(153, 153, 153, 1);
  position: relative;
}
.moreIcon {
  opacity: 0;
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  padding: 8px;
}
.moreIcon > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.moreIcon > :global(.__wab_flex-container) > *,
.moreIcon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.moreIcon > :global(.__wab_flex-container) > picture > img,
.moreIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBox___3Kvhh {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.more {
  transform: rotate(-90deg);
  width: 24px;
  height: 24px;
  display: flex;
  transform-origin: top left;
  position: absolute;
  overflow: hidden;
  flex-direction: column;
  left: 0px;
  top: 24px;
  padding: 6px 2px;
}
.more > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.more > :global(.__wab_flex-container) > *,
.more > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.more > :global(.__wab_flex-container) > picture > img,
.more > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.freeBox__kpGu2 {
  display: block;
  position: relative;
  width: 9px;
  height: 2px;
  flex-shrink: 0;
}
.svg__kyfRe {
  object-fit: cover;
  max-width: 100%;
  color: #999;
  transform: rotate(-90deg);
  width: 2px;
  height: 9.037px;
  display: block;
  transform-origin: top left;
  position: absolute;
  left: 0px;
  top: 2px;
}
.popover2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover2__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_EcoUlzcdO61z {
  outline: none;
  padding: 0px;
}
.frame1000006839 {
  height: 40px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 4px 4px 12px;
}
.frame1000006839 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006839 > :global(.__wab_flex-container) > *,
.frame1000006839 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006839 > :global(.__wab_flex-container) > picture > img,
.frame1000006839
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.root .frame1000006839:hover {
  background: #ffffff;
}
.coParcelRadioBtn__bJvFb:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.svg__aarex {
  object-fit: cover;
  max-width: 100%;
  color: #8e8e8e;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.tooltipContent__bU6J4:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.popover3:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover3__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.createNewView {
  background: rgba(244, 244, 244, 1);
  height: 40px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  border-radius: 8px;
}
.createNewView > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.createNewView > :global(.__wab_flex-container) > *,
.createNewView > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.createNewView > :global(.__wab_flex-container) > picture > img,
.createNewView
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.root .createNewView:hover {
  background: #ebebeb;
}
.root .createNewView:active {
  background: #dbdbdb;
  box-shadow: inset 0px 0px 0px 1px #999999;
}
.svg__wIhmn {
  object-fit: cover;
  max-width: 100%;
  color: #333;
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.title3 {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.tooltipContent__ufLAq:global(.__wab_instance) {
  max-width: 100%;
}
.frame1000006675 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000006675 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000006675 > :global(.__wab_flex-container) > *,
.frame1000006675 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006675 > :global(.__wab_flex-container) > picture > img,
.frame1000006675
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.actions {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.actions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.actions > :global(.__wab_flex-container) > *,
.actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actions > :global(.__wab_flex-container) > picture > img,
.actions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.tRsecondaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 128px;
  height: 48px;
  flex-shrink: 0;
}
.tRprimaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 175px;
  height: 48px;
  flex-shrink: 0;
}
