.action-modal {
  width: 420px !important;
  .ant-modal-content {
    border-radius: 12px;
  }

  .action-modal-container {
    .modal-icon {
      height: 44px;
      width: 44px;
    }
  }

  .action-modal-footer {
    .modal-btn {
      width: 156px;
      height: 36px;
      color: #333;
    }

    .btn-cancel {
    }

    .btn-ok {
    }
  }
}
