.form-input-group {
    display: flex;
    flex-direction: column;
    padding: 10px 5px 5px 5px;

    .form-input-label {
        padding-bottom: 5px;
        font-family: 'Poppins';
        font-weight: 400;
    }

    .form-input {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        outline: none;
        padding: 8px 10px;
        position: relative;
        width: 100%;

        &.blue {
            border: 1px solid #7bb9fa;
        }

        &.red {
            border: 1px solid #f34e4e;
            background-image: url('../../../assets/errortriangle.svg');
            background-position: 97.5% 50%;
            background-repeat: no-repeat;
        }

        &:disabled {
            cursor: not-allowed;
            background: #d9d9d9;
        }

    }

    #inputID::placeholder {
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 17px !important;
        color: #999999;
    }
}

input[type='password'] {
    font: small-caption;
    font-size: 16px;
}