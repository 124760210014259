.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  border-radius: 8px;
}
.stateDefault {
  background: rgba(255, 255, 255, 1);
  width: 208px;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 8px;
}
.frame1000005187 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding: 8px;
}
.frame1000005187 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000005187 > :global(.__wab_flex-container) > *,
.frame1000005187 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005187 > :global(.__wab_flex-container) > picture > img,
.frame1000005187
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.searchField {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(208, 212, 218, 1);
  height: 30px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 4px;
}
.searchField > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.searchField > :global(.__wab_flex-container) > *,
.searchField > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.searchField > :global(.__wab_flex-container) > picture > img,
.searchField
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000002279 {
  height: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 193px;
  padding-left: 0px;
  min-width: 0;
  min-height: 0;
}
.input:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  height: 30px;
  width: 190px;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 12px;
  height: 12px;
  display: block !important;
  position: relative;
  overflow: hidden;
}
.img > picture > img {
  object-fit: cover;
}
.frame1000005185 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
}
.frame1000005185 > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.frame1000005185 > :global(.__wab_flex-container) > *,
.frame1000005185 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005185 > :global(.__wab_flex-container) > picture > img,
.frame1000005185
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.frame1000005144 {
  background: rgba(255, 255, 255, 1);
  box-shadow: none;
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 4px;
}
.root .frame1000005144:hover {
  box-shadow: inset 0px 0px 0px 0.75px #e0e0e0;
}
.root .frame1000005144:active {
  background: #ebebeb;
}
.emCustomSymbol:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
