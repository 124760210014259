.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
  border-radius: 9px;
}
.hoverYesPdfYesImageNo {
  background: #f9fafb;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  min-width: 0;
  border-radius: 4px;
  padding: 4px 4px 4px 8px;
}
.hoverYesPdfYesImageNo > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.hoverYesPdfYesImageNo > :global(.__wab_flex-container) > *,
.hoverYesPdfYesImageNo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.hoverYesPdfYesImageNo > :global(.__wab_flex-container) > picture > img,
.hoverYesPdfYesImageNo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.hoverYesPdfYesImageNolsLoading {
  display: none;
}
.root:hover .hoverYesPdfYesImageNo {
  background: #e8e8e8;
  height: 100%;
  cursor: pointer;
  min-height: 0;
}
.root:hover .hoverYesPdfYesImageNo > :global(.__wab_flex-container) {
  min-height: 0;
}
.rootpreview:hover .hoverYesPdfYesImageNopreview {
  background: #e8e8e8;
  height: auto;
}
.frame1000005134 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  min-width: 0;
}
.frame1000005134 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005134 > :global(.__wab_flex-container) > *,
.frame1000005134 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005134 > :global(.__wab_flex-container) > picture > img,
.frame1000005134
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000005134lsLoading {
  display: none;
}
.img__ugjIy {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.img__ugjIy > picture > img {
  object-fit: cover;
}
.frame1000005133 {
  width: 20px;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
}
.frame1000005133 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000005133 > :global(.__wab_flex-container) > *,
.frame1000005133 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005133 > :global(.__wab_flex-container) > picture > img,
.frame1000005133
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.img__rM1Dq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 16px;
  height: 20px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__rM1Dq > picture > img {
  object-fit: cover;
}
.text__clZtx {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  color: #666666;
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  line-height: 1.54;
  min-width: 0;
}
.textpreview__clZtXbd6EM {
  white-space: pre-wrap;
  width: 100%;
  min-width: 0;
}
.root:hover .text__clZtx {
  color: #333333;
}
.freeBox__apNyk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 24px;
  height: 24px;
  max-width: 100%;
  flex-shrink: 0;
}
.root:hover .freeBox__apNyk {
  background: none;
}
.root .freeBox__apNyk:active {
  box-shadow: inset 0px 0px 0px 1px #c9c9c9;
  border-radius: 24px;
}
.svg__oR5D {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 24px;
  height: 24px;
  cursor: pointer;
  flex-shrink: 0;
  display: none;
}
.root:hover .svg__oR5D {
  display: block;
}
.rootpreview:hover .svgpreview__oR5Dbd6EM {
  display: none;
}
.root .svg__oR5D:hover {
  color: #ff0000;
}
.root .svg__oR5D:active {
  color: #ff0000;
}
.hoverNoPdfYesImageNoLoadingYes {
  background: rgba(248, 249, 250, 1);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  min-width: 0;
  border-radius: 4px;
  padding: 4px 4px 4px 8px;
}
.hoverNoPdfYesImageNoLoadingYes > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.hoverNoPdfYesImageNoLoadingYes > :global(.__wab_flex-container) > *,
.hoverNoPdfYesImageNoLoadingYes
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.hoverNoPdfYesImageNoLoadingYes
  > :global(.__wab_flex-container)
  > picture
  > img,
.hoverNoPdfYesImageNoLoadingYes
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000005135 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.frame1000005135 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005135 > :global(.__wab_flex-container) > *,
.frame1000005135 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005135 > :global(.__wab_flex-container) > picture > img,
.frame1000005135
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBox__r74N {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: none;
}
.freeBoxlsLoading__r74NYjZfq {
  display: block;
}
.loaderYellow {
  transform: scale(1.000000499999875, 0.9999985000008749)
    rotate(-0.057295760416576934deg) skew(-0.1145912916467999deg, 0deg);
  width: 20px;
  height: 20px;
  display: block;
  transform-origin: top left;
  position: absolute;
  overflow: hidden;
  left: 0px;
  top: 0px;
}
.svg__aIaZt {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ffd02b;
  transform: translate(-0.4990000000000001px, 0.08400000000000007px);
  width: 15.333px;
  height: 15.666px;
  top: 2px;
  left: 3px;
  display: none;
}
.svglsLoading__aIaZtYjZfq {
  display: none;
}
.emSpinner:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 20px;
  height: 20px;
}
.emSpinnerlsLoading:global(.__wab_instance) {
  display: none;
}
.yellowLoader:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 20px;
  height: 20px;
}
.text___9A0Jk {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.54;
  color: rgba(153, 153, 153, 1);
  position: relative;
  white-space: pre-wrap;
}
.trash {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  padding: 8px;
}
.trash > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.trash > :global(.__wab_flex-container) > *,
.trash > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.trash > :global(.__wab_flex-container) > picture > img,
.trash > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.text__ndaUy {
  display: block;
  font-size: 12px;
  text-align: center;
  font-family: "SF Pro";
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
