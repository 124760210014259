.request-received-popup-modal {
  .ant-modal-content {
    width: max-content;

    .ant-modal-body {
      padding: 0;
      overflow: hidden;
    }
  }
}
