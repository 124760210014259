@px: 16rem;

.order-queue-modal-container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 9999;
  width: 704 / @px;
  height: 460 / @px;
  background-color: white;
  border-radius: 4px;

  ::placeholder {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #666666 !important;
  }

  // Scroll bar styling
  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 8px;
    -webkit-box-shadow: 20px 20px 60px #dfdfdf, -20px -20px 60px #ffffff;
  }

  .order-queue-modal-header {
    padding: rem(16) rem(24);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 65 / @px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 0px 0px;

    .title-bar {
      width: 40%;
      max-width: 60%;

      h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        margin-bottom: 0 / @px;
      }

      h4 {
        font-family: 'Poppins';
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
    }

    .order-queue-modal-header-section {
      display: flex;
      width: 60%;
      max-width: 60%;
    }

    .search-bar {
      flex: 1;
      padding: 0 / @px 18 / @px 0 / @px 0 / @px;

      .form-input-group {
        padding: 0 / @px;

        .form-input {
          background: #ffffff;
          border: 1px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 20px;
          height: 32 / @px;
          transition: 0.5s all ease;

          .ant-input {
            line-height: 38px;
          }

          ::placeholder {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666666;
          }
        }

        .ant-select-selection-placeholder {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #666666;
        }
      }

      // Suggestion container
      .suggestion-container {
        position: absolute;
        width: 90%;
        left: 36px;
        top: 54px;
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        z-index: 2;
        padding: 18 / @px 36 / @px;
        padding-bottom: 12 / @px;

        p {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: flex-end;
          cursor: pointer;
          color: #000000;
        }
      }
    }

    .close-button {
      cursor: pointer;
      position: relative;
      top: 4px;
    }
  }

  .order-queue-modal-main-content {
    flex: 1;
    display: flex;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    overflow-x: scroll;
    padding: 12px 26px 0px 26px;
  }

  .order-queue-modal-footer {
    height: 36 / @px;
    background: #fff;
    filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.1));
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 4px 4px;

    p {
      margin-bottom: 0 / @px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-right: 12 / @px;
    }
  }

  .ant-card,
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0 / @px;
    width: 640 / @px;
    height: 460 / @px;
    padding: 20 / @px;

    .question-mark-icon {
      margin-bottom: 10 / @px;
    }

    .text-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 35 / @px;

      h2 {
        font-size: 24 / @px;
        font-weight: 500;
        margin-bottom: 4 / @px;
      }

      p {
        font-size: 14 / @px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .estimated-cost-time {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 40.5 / @px;

      .estimated-cost {
        margin-bottom: 6 / @px;
      }

      .estimated-cost,
      .estimated-time {
        font-size: 1rem;
        color: #333333;
        display: flex;
        flex-direction: row;
        font-weight: 500;

        p {
          margin-right: 1rem;
          margin-bottom: 0;
        }
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .cancel-button {
        width: 164 / @px;
        height: 36 / @px;
        margin-right: 28 / @px;
      }

      .place-order {
        width: 164 / @px;
        height: 36 / @px;
      }
    }
  }
}