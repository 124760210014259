.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.confirmationPopups {
  background: rgba(255, 255, 255, 1);
  width: 496px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px;
}
.confirmationPopups > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: calc(0px - 27px);
  height: calc(100% + 27px);
}
.confirmationPopups > :global(.__wab_flex-container) > *,
.confirmationPopups > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.confirmationPopups > :global(.__wab_flex-container) > picture > img,
.confirmationPopups
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 27px;
}
.textAndSupportingText {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: 0;
}
.textAndSupportingText > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.textAndSupportingText > :global(.__wab_flex-container) > *,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.textAndSupportingText > :global(.__wab_flex-container) > picture > img,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.frame1000006794 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006794 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006794 > :global(.__wab_flex-container) > *,
.frame1000006794 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006794 > :global(.__wab_flex-container) > picture > img,
.frame1000006794
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  position: relative;
  flex-shrink: 0;
  border-radius: 18.666px;
}
.img > picture > img {
  object-fit: cover;
}
.text {
  display: block;
  flex-grow: 1;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 28px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.crossIcon {
  width: 32px;
  height: auto;
  display: flex;
  position: absolute;
  flex-direction: column;
  cursor: pointer;
  left: 416px;
  top: -12px;
  bottom: 12px;
  flex-shrink: 0;
  padding: 8px;
}
.crossIcon > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.crossIcon > :global(.__wab_flex-container) > *,
.crossIcon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.crossIcon > :global(.__wab_flex-container) > picture > img,
.crossIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.supportingText {
  font-size: 14px;
  letter-spacing: 0em;
  color: rgba(102, 102, 102, 1);
  align-self: stretch;
  position: relative;
  line-height: 1.54;
  white-space: pre-wrap;
  display: none;
}
.embedHtml:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  font-size: 12px;
  white-space: pre-wrap;
  line-height: 1.54;
}
.actions {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.actions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.actions > :global(.__wab_flex-container) > *,
.actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actions > :global(.__wab_flex-container) > picture > img,
.actions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.buttonsMarch29 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(208, 212, 215, 1);
  width: 128px;
  height: auto;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  display: none;
  border-radius: 4px;
  padding: 12px 24px;
}
.buttonsMarch29 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch29 > :global(.__wab_flex-container) > *,
.buttonsMarch29 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch29 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch29
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText {
  display: block;
  flex-grow: 1;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.buttonsMarch27 {
  background: rgba(255, 208, 42, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 208, 42, 1);
  width: auto;
  height: auto;
  position: relative;
  flex-direction: row;
  display: none;
  border-radius: 4px;
  padding: 12px 24px;
}
.buttonsMarch27 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch27 > :global(.__wab_flex-container) > *,
.buttonsMarch27 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch27 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch27
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText2 {
  display: block;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.tRsecondaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 128px;
  flex-shrink: 0;
}
.tRprimaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 169px;
  flex-shrink: 0;
}
