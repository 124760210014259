@import '../../../../../../../../styles/base/colors.less';

@mobileScreen: 600px;

.addTagModal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
      padding: 1rem;

      .addTagModal__select {
        .ant-select-selector {
          border-radius: 4px;

          .ant-select-selection-item {
            padding: 0px 8px;
            border-radius: 24px;
          }
        }
      }

      .tags {
        border-radius: 24px;
        background-color: @tag-background;
      }
    }
  }

  .ant-btn {
    &.disabled {
      opacity: 1;
    }
  }
}

.tag-dropdowns {
  .ant-select-item {
    &:not(:last-child) {
      .ant-select-item-option-content {
        border-bottom: 1px solid @tag-background;
      }
    }
  }
}

@media screen and (max-width: @mobileScreen) {
  .addTagModal {
    .ant-modal-wrap {
      margin: 0px -5px;
      padding: 0;

      .addTagModal {
        margin: 0px !important;
        max-width: 100%;
        width: 100%;
        height: 100%;
        padding-bottom: 0px;
        top: 0px;

        .ant-modal-content {
          height: 100%;
          border-radius: 0px;

          .ant-modal-body {
            height: 100%;
            position: relative;
            overflow: hidden;

            .addButton {
              position: absolute;
              bottom: 10px;
              width: 100%;
              left: 0px;
              justify-content: flex-end !important;
              padding: 0px 20px;
            }
          }
        }
      }
    }
  }
}
