.frame1000001880 {
  box-shadow: none;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.frame1000001880loading {
  opacity: 0.8;
  cursor: default;
  pointer-events: none;
}
.frame1000001880disable {
  cursor: default;
  pointer-events: none;
}
.frame1000001880disable_loading {
  pointer-events: none;
  cursor: default;
}
.frame1000001880disable:hover {
  pointer-events: none;
  cursor: default;
}
.frame1000001880disable:active {
  cursor: default;
  pointer-events: none;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
  border: 1px solid transparent;
}
.frame1000001880:hover .freeBox {
  padding: 0px;
  border: 1px solid transparent;
}
.frame1000001880:active .freeBox {
  max-width: auto;
  height: 100%;
  min-height: 0;
  border-radius: 4px;
  padding: 0px;
  border: 1px solid #ffd02b;
}
.frame1000001880loading:active .freeBoxloading {
  border-color: #ffd02b00;
}
.frame1000001880disable:active .freeBoxdisable {
  border-color: #ffd02b00;
}
.sizeLargeTypeDefaultLoaderNo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  border-radius: 4px;
  padding: 2px;
}
.sizeLargeTypeDefaultLoaderNo > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.sizeLargeTypeDefaultLoaderNo > :global(.__wab_flex-container) > *,
.sizeLargeTypeDefaultLoaderNo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sizeLargeTypeDefaultLoaderNo > :global(.__wab_flex-container) > picture > img,
.sizeLargeTypeDefaultLoaderNo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.frame1000001879 {
  background: rgba(255, 207, 42, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 207, 42, 1);
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: calc(0px + 10px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  min-width: 0;
  min-height: 0;
  border-radius: 4px;
  padding: 12px 24px;
}
.frame1000001879 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000001879 > :global(.__wab_flex-container) > *,
.frame1000001879 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001879 > :global(.__wab_flex-container) > picture > img,
.frame1000001879
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.frame1000001879loading {
  background: #ffcf2a;
  box-shadow: none;
  margin-top: calc(0px + 10px) !important;
}
.frame1000001879loading > :global(.__wab_flex-container) {
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.frame1000001879loading > :global(.__wab_flex-container) > *,
.frame1000001879loading
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.frame1000001879loading > :global(.__wab_flex-container) > picture > img,
.frame1000001879loading
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.frame1000001879disable {
  box-shadow: none;
  background: #f5f5f5;
  margin-top: calc(0px + 10px) !important;
}
.frame1000001879disable_loading {
  margin-top: calc(0px + 10px) !important;
}
.frame1000001880:hover .frame1000001879 {
  background: #ffe06f;
  box-shadow: inset 0px 0px 0px 1px #ffd02b;
  margin-top: calc(0px + 10px) !important;
}
.frame1000001880loading:hover .frame1000001879loading {
  background: #ffcf2a;
  margin-top: calc(0px + 10px) !important;
  border-width: 0px;
}
.frame1000001880disable:hover .frame1000001879disable {
  background: #f5f5f5;
  box-shadow: inset 0px 0px 0px 1px #f5f5f5;
  margin-top: calc(0px + 10px) !important;
  border-width: 0px;
  border-color: #ffd02b00;
}
.frame1000001880:active .frame1000001879 {
  box-shadow: inset 0px 0px 0px 1px #ffcf2a;
  margin-top: calc(0px + 10px) !important;
}
.frame1000001880loading:active .frame1000001879loading {
  margin-top: calc(0px + 10px) !important;
}
.frame1000001880disable:active .frame1000001879disable {
  box-shadow: inset 0px 0px 0px 1px #f5f5f5;
  margin-top: calc(0px + 10px) !important;
}
.text {
  display: block;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  color: rgba(51, 51, 51, 1);
  position: relative;
  width: 100%;
  user-select: none;
  white-space: pre;
  line-height: 20px;
  min-width: 0;
}
.textloading {
  width: auto;
}
.textdisable {
  color: #999999;
}
.lottie:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  height: 11px;
  margin-bottom: 4px;
  width: 29px;
  flex-shrink: 0;
  display: none;
}
.lottieloading:global(.__wab_instance) {
  display: flex;
}
.lottiedisable_loading:global(.__wab_instance) {
  display: none;
}
