.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.modal {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(221, 221, 221, 1);
  width: auto;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 12px;
}
.modal > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.modal > :global(.__wab_flex-container) > *,
.modal > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.modal > :global(.__wab_flex-container) > picture > img,
.modal > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.modalswitchToNewMapExpanded {
  display: none;
}
.modaluploadFromNew {
  display: flex;
  flex-direction: column;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
}
.modaluploadFromNew > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.modaluploadFromNew > :global(.__wab_flex-container) > *,
.modaluploadFromNew > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.modaluploadFromNew > :global(.__wab_flex-container) > picture > img,
.modaluploadFromNew
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 4px;
}
.freeBox__vymW {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBoxuploadFromNew__vymWKWi27 {
  justify-content: center;
  align-items: center;
}
.svg__ewg8U {
  object-fit: cover;
  max-width: 100%;
  color: #000;
  width: 24px;
  height: 24px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.heading9 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.5;
  color: rgba(51, 51, 51, 1);
}
.heading9uploadFromNew {
  line-height: 2;
}
.svg__y1M6J {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.multipleTakeoffs {
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.maskGroup {
  width: 15px;
  height: 21px;
  display: block;
  position: absolute;
  top: 2px;
  left: 5px;
  -webkit-mask-image: var(--image-pCsRp0Mm-WRL);
  -webkit-mask-size: 15px 21px;
  -webkit-mask-repeat: no-repeat;
}
.image5 {
  background: url("./images/image68.png") center center / cover no-repeat;
  transform: translate(0px, -0.15625px);
  width: 29px;
  height: 29px;
  display: block;
  position: absolute;
  top: -2px;
  left: -7px;
}
.heading {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.5;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000006816 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(221, 221, 221, 1);
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.03999999910593033))
    drop-shadow(0px 20px 12.5px rgba(0, 0, 0, 0.10000000149011612));
  width: 318px;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 12px;
}
.frame1000006816 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000006816 > :global(.__wab_flex-container) > *,
.frame1000006816 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006816 > :global(.__wab_flex-container) > picture > img,
.frame1000006816
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__zJe7E {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
  height: 36px;
  white-space: pre-wrap;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.text__fPusJ {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(153, 153, 153, 1);
  position: relative;
}
.frame1000006907 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000006907 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000006907 > :global(.__wab_flex-container) > *,
.frame1000006907 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006907 > :global(.__wab_flex-container) > picture > img,
.frame1000006907
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.input2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  height: 61px;
  flex-shrink: 0;
}
.coParcelUpload__h1A8Z:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__uZykh {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.coParcelUploadListItem__s7ClH:global(.__wab_instance) {
  max-width: 100%;
}
.input:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.buttonsMarch30 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  border-radius: 4px;
  padding: 8px 24px;
}
.buttonsMarch30 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.buttonsMarch30 > :global(.__wab_flex-container) > *,
.buttonsMarch30 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch30 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch30
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.buttonSfProIcon {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
}
.buttonSfProIcon > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.buttonSfProIcon > :global(.__wab_flex-container) > *,
.buttonSfProIcon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonSfProIcon > :global(.__wab_flex-container) > picture > img,
.buttonSfProIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
._ {
  display: block;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  font-family: "SF Pro";
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.buttonText4 {
  display: block;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000007239 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.frame1000007239 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000007239 > :global(.__wab_flex-container) > *,
.frame1000007239 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000007239 > :global(.__wab_flex-container) > picture > img,
.frame1000007239
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBox__abjoA {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-grow: 0;
  min-width: 0;
  padding: 8px;
}
.freeBox__abjoA > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__abjoA > :global(.__wab_flex-container) > *,
.freeBox__abjoA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__abjoA > :global(.__wab_flex-container) > picture > img,
.freeBox__abjoA
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.buttonsMarch27 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(209, 213, 216, 1);
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  border-radius: 4px;
  padding: 8px 12px;
}
.buttonsMarch27 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch27 > :global(.__wab_flex-container) > *,
.buttonsMarch27 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch27 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch27
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText5 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.buttonsMarch26 {
  background: rgba(255, 208, 43, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 208, 43, 1);
  height: 32px;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-direction: row;
  flex-shrink: 1;
  width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: 8px 14px;
}
.buttonsMarch26 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch26 > :global(.__wab_flex-container) > *,
.buttonsMarch26 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch26 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch26
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText3 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000006817 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(221, 221, 221, 1);
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.03999999910593033))
    drop-shadow(0px 20px 12.5px rgba(0, 0, 0, 0.10000000149011612));
  width: 318px;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 12px;
}
.frame1000006817 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000006817 > :global(.__wab_flex-container) > *,
.frame1000006817 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006817 > :global(.__wab_flex-container) > picture > img,
.frame1000006817
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.frame1000006817uploadFromNew {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  box-shadow: inset 0px 0px 0px 1px #ffffff;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}
.text__shKy3 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
  height: 36px;
  white-space: pre-wrap;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.text__lIfRt {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(153, 153, 153, 1);
  position: relative;
}
.frame1000006908 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000006908 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000006908 > :global(.__wab_flex-container) > *,
.frame1000006908 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006908 > :global(.__wab_flex-container) > picture > img,
.frame1000006908
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.input3:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  height: 61px;
  flex-shrink: 0;
}
.input3uploadFromNew:global(.__wab_instance) {
  display: none;
}
.textArea:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.coParcelUpload__xTMjG:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__x6Pe {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.coParcelUploadListItem__qwNtw:global(.__wab_instance) {
  max-width: 100%;
}
.input4:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.input4uploadFromNew:global(.__wab_instance) {
  display: none;
}
.buttonsMarch31 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  border-radius: 4px;
  padding: 8px 24px;
}
.buttonsMarch31 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.buttonsMarch31 > :global(.__wab_flex-container) > *,
.buttonsMarch31 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch31 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch31
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.buttonsMarch31uploadFromNew {
  display: none;
}
.buttonSfProIcon2 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
}
.buttonSfProIcon2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.buttonSfProIcon2 > :global(.__wab_flex-container) > *,
.buttonSfProIcon2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonSfProIcon2 > :global(.__wab_flex-container) > picture > img,
.buttonSfProIcon2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
._2 {
  display: block;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  font-family: "SF Pro";
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.buttonText6 {
  display: block;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000007240 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.frame1000007240 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000007240 > :global(.__wab_flex-container) > *,
.frame1000007240 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000007240 > :global(.__wab_flex-container) > picture > img,
.frame1000007240
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBox__fpQwk {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-grow: 0;
  min-width: 0;
  padding: 8px;
}
.freeBox__fpQwk > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__fpQwk > :global(.__wab_flex-container) > *,
.freeBox__fpQwk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fpQwk > :global(.__wab_flex-container) > picture > img,
.freeBox__fpQwk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.buttonsMarch28 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(209, 213, 216, 1);
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  border-radius: 4px;
  padding: 8px 12px;
}
.buttonsMarch28 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch28 > :global(.__wab_flex-container) > *,
.buttonsMarch28 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch28 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch28
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText7 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.buttonsMarch29 {
  background: rgba(255, 208, 43, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 208, 43, 1);
  height: 32px;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-direction: row;
  flex-shrink: 1;
  width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: 8px 14px;
}
.buttonsMarch29 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch29 > :global(.__wab_flex-container) > *,
.buttonsMarch29 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch29 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch29
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText8 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.property1ExpandedExpandedYes {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(221, 221, 221, 1);
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.03999999910593033))
    drop-shadow(0px 20px 12.5px rgba(0, 0, 0, 0.10000000149011612));
  width: 318px;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin-top: 8px;
  margin-right: 30px;
  border-radius: 8px;
  padding: 8px 12px 12px;
}
.property1ExpandedExpandedYes > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.property1ExpandedExpandedYes > :global(.__wab_flex-container) > *,
.property1ExpandedExpandedYes
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.property1ExpandedExpandedYes > :global(.__wab_flex-container) > picture > img,
.property1ExpandedExpandedYes
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.frame1000007235 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000007235 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000007235 > :global(.__wab_flex-container) > *,
.frame1000007235 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000007235 > :global(.__wab_flex-container) > picture > img,
.frame1000007235
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000007230 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  cursor: pointer;
}
.frame1000007230 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000007230 > :global(.__wab_flex-container) > *,
.frame1000007230 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000007230 > :global(.__wab_flex-container) > picture > img,
.frame1000007230
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.svg___51MdI {
  object-fit: cover;
  max-width: 100%;
  color: #000;
  width: 24px;
  height: 24px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.svgswitchToNewMapExpanded___51MdIg5Wp {
  display: block;
}
.multipleTakeoffs2 {
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.maskGroup2 {
  width: 15px;
  height: 21px;
  display: block;
  position: absolute;
  top: 2px;
  left: 5px;
  -webkit-mask-image: var(--image-pCsRp0Mm-WRL);
  -webkit-mask-size: 15px 21px;
  -webkit-mask-repeat: no-repeat;
}
.image6 {
  background: url("./images/image68.png") center center / cover no-repeat;
  transform: translate(0px, -0.15625px);
  width: 29px;
  height: 29px;
  display: block;
  position: absolute;
  top: -2px;
  left: -7px;
}
.heading8 {
  display: block;
  flex-grow: 1;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.5;
  color: rgba(51, 51, 51, 1);
  position: relative;
  width: 100%;
  min-width: 0;
}
.heading10 {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.5;
  color: rgba(51, 51, 51, 1);
  position: relative;
  width: 100%;
  min-width: 0;
}
.heading10switchToNewMapExpanded {
  display: block;
}
.chevron2 {
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.svg__prBn7 {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.text__r3XQm {
  display: block;
  font-size: 10px;
  text-align: center;
  font-family: "SF Pro";
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 13.333333015441895px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.textswitchToNewMapExpanded__r3XQmG5Wp {
  display: none;
}
.frame1000007237 {
  width: 294px;
  height: auto;
  display: flex;
  flex-direction: column;
}
.frame1000007237 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000007237 > :global(.__wab_flex-container) > *,
.frame1000007237 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000007237 > :global(.__wab_flex-container) > picture > img,
.frame1000007237
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000007238 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000007238 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000007238 > :global(.__wab_flex-container) > *,
.frame1000007238 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000007238 > :global(.__wab_flex-container) > picture > img,
.frame1000007238
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.heading11 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  white-space: pre-wrap;
  height: 112px;
}
.frame1000007234 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000007234 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000007234 > :global(.__wab_flex-container) > *,
.frame1000007234 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000007234 > :global(.__wab_flex-container) > picture > img,
.frame1000007234
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000007231 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000007231 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000007231 > :global(.__wab_flex-container) > *,
.frame1000007231 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000007231 > :global(.__wab_flex-container) > picture > img,
.frame1000007231
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__vh81 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
  white-space: pre-wrap;
}
.frame1000007236 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000007236 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000007236 > :global(.__wab_flex-container) > *,
.frame1000007236 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000007236 > :global(.__wab_flex-container) > picture > img,
.frame1000007236
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.heading2 {
  display: block;
  font-size: 12px;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 24px;
  flex-shrink: 0;
}
.heading3 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.frame1000007232 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000007232 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000007232 > :global(.__wab_flex-container) > *,
.frame1000007232 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000007232 > :global(.__wab_flex-container) > picture > img,
.frame1000007232
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.heading4 {
  display: block;
  font-size: 12px;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 24px;
  flex-shrink: 0;
}
.heading5 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.frame1000007233 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000007233 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000007233 > :global(.__wab_flex-container) > *,
.frame1000007233 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000007233 > :global(.__wab_flex-container) > picture > img,
.frame1000007233
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.heading6 {
  display: block;
  font-size: 12px;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 24px;
  flex-shrink: 0;
}
.heading7 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.text__sbxmP {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 14px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 268px;
  height: 28px;
  white-space: pre-wrap;
  flex-shrink: 0;
}
.freeBox__xdaLd {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: stretch;
  min-width: 0;
}
.freeBox__xdaLd > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 9px);
  width: calc(100% + 9px);
}
.freeBox__xdaLd > :global(.__wab_flex-container) > *,
.freeBox__xdaLd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xdaLd > :global(.__wab_flex-container) > picture > img,
.freeBox__xdaLd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 9px;
}
.buttonsMarch24 {
  background: #ffffff;
  box-shadow: inset 0px 0px 0px 1px #d1d5d8;
  height: 32px;
  display: flex;
  flex-direction: row;
  width: 116px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 4px;
  padding: 8px 12px;
}
.buttonsMarch24 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch24 > :global(.__wab_flex-container) > *,
.buttonsMarch24 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch24 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch24
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.rootswitchToNewMapExpanded .buttonsMarch24switchToNewMapExpanded:hover {
  background: #ffffff;
  box-shadow: inset 0px 0px 0px 1px #ffd02b;
}
.buttonText {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: #666666;
  position: relative;
}
.buttonsMarch25 {
  background: rgba(0, 0, 0, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 1);
  height: 32px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  min-width: 0;
  border-radius: 4px;
  padding: 8px 12px;
}
.buttonsMarch25 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch25 > :global(.__wab_flex-container) > *,
.buttonsMarch25 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch25 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch25
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.rootswitchToNewMapExpanded .buttonsMarch25switchToNewMapExpanded:hover {
  background: #3f3d3d;
}
.buttonText2 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
