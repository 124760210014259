.parcel-modify-confirm-panel {
  width: 100%;
  background: #ffffff;
  height: auto;
  overflow-y: auto;

  .parcel-modify-confirm-panel-header {
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
    }
  }

  .parcel-modify-confirm-panel-body-instructions {
    padding: 10px 12px;
    color: #999999;
    font-size: 14px;
    font-weight: 400;
  }

  .parcel-modify-confirm-panel-body-list {
    padding: 10px 20px;
    background-color: #f6f9f0;
    font-size: 14px;
    font-weight: 400;
    overflow-y: auto;
    max-height: 175px;

    p {
      display: block;
      margin: 0 auto 0 0;
      font-size: 14px;
      padding: 5px 2px;
      cursor: pointer;

      &:not(:first-child) {
        border-top: 0.3px solid rgba(247, 192, 65, 0.8);
      }

      span {
        font-size: 10px;
        margin-left: 10px;
      }
    }

    button.delete-button {
      border: none;
      background: none;
      float: right;
      cursor: pointer;
      outline: none;
    }
  }

  .parcel-modify-confirm-panel-button-group {
    display: flex;
    justify-content: left;
    padding-bottom: 17px;
  }

  .parcel-modify-confirm-panel-button-group-verticle {
    padding: 15px 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .tool-button {
      color: rgba(#333333, 0.5);
      font-weight: 500;
      font-size: 14px;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-right: 6px;

      svg {
        margin-left: 10px;
      }

      span {
        margin-left: 10px;
      }

      &.active {
        color: #000000;
        background: #fdeebb;
      }
    }

    .button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .floating-toolbar {
    padding: 10px;
    position: absolute;
    left: 42%;
    top: 40px;
    background-color: #f6f9f0;
    border-radius: 0 0 4px 4px;
    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-content: space-between;
    visibility: visible;
    width: 415px;
    height: 39px;

    button {
      color: #333333;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin-top: -8px;
      margin-right: -7px;
    }

    .floating-icon {
      position: absolute;
      left: 95px;
      top: 12px;
      cursor: pointer;
    }
  }

  .floating-tools {
    position: fixed;
    visibility: visible;
    border-left: 1px solid var(--fadedstroke, #e8e8e8);
    border-right: 1px solid var(--fadedstroke, #e8e8e8);
    transition: left 0.2s, width 0.2s;
    border-top: 0.5px solid #DDD;

    // button {
    //   color: #333333;
    //   font-weight: 500;
    //   font-size: 14px;
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    //   justify-content: center;
    //   gap: 5px;
    //   width: 35px;
    //   padding: 0px;
    //   padding-left: 2px;
    //   padding-top: 4px;
    // }

    button.active,
    button.active:hover {
      background: #ffd02b !important;
    }
  }
}

.ant-upload {
  height: 25px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ant-upload-list {
  min-height: 30px;
  margin-left: 11px;
  margin-bottom: 10px;
  margin-top: 8px;
  height: auto;
}

.ant-upload-list-item {
  height: 15px;
}

.modal-class {
  h1 {
    padding: 30px;
    color: #333333;
  }

  h3 {
    color: #999999;
  }

  .delete-modal {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 25vh;
  }

  .ant-modal-footer {
    border-top: 0;
    text-align: center;
  }
}

.button-container button.active {
  background-color: #ffd02b !important;
}

.button-container button:disabled,
button[disabled] {
  opacity: 0.4;
}

.map-tools {
  width: 40px !important;
  overflow: hidden;
  border-radius: 6px;
  border-right: 0px !important;

  .ant-menu-item-selected:hover {
    background-color: #fad049 !important;
  }

  .tool-menu-item {
    padding: 0px 0px 0px 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px !important;
  }

  .tool-menu-item:active {
    background-color: white !important;
  }

  .ant-menu-submenu.ant-menu-submenu-disabled {
    svg {
      opacity: 0.4;
    }
  }

  .ant-menu-submenu-title:active {
    background-color: white !important;
    margin: 0px !important;
  }

  .ant-menu-submenu-title {
    padding: 0px !important;
    display: flex;
    align-items: center;
    margin: 0px !important;
    width: 100%;
    border-radius: 6px;
    justify-content: center;
  }

  .ant-menu-item-selected {
    color: #000000;
    background-color: #fad049 !important;
    border-radius: 6px;
  }

  .ant-menu-item-disabled {
    opacity: 0.4;
  }

  .ant-menu-submenu i.ant-menu-submenu-arrow {
    right: 2px;
    top: 18px;
    display: block !important;
  }
}

.ant-menu-submenu .ant-menu {
  border-radius: 8px !important;
  overflow: hidden;
}

.tool-menu-item {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  height: 32px !important;
  overflow: initial;
}

.tool-sub-menu {
  ant-menu-sub {
    border-radius: 4px;
  }

  .tool-menu-item {
    display: flex;
    align-items: center;
  }

  .tool-menu-item:active {
    color: #000000;
    background-color: white !important;
    border-radius: 6px;
  }

  .ant-menu > .ant-menu-item-active,
  .ant-menu > .ant-menu-submenu-active,
  .ant-menu > .ant-menu-item-open,
  .ant-menu > .ant-menu-submenu-open,
  .ant-menu > .ant-menu-item-selected,
  .ant-menu > .ant-menu-submenu-selected {
    color: #000000;
    background-color: #fad049 !important;
    border-radius: 6px;
  }

  .ant-menu-item:active {
    color: #000000;
    background-color: #fad049 !important;
    border-radius: 6px;
  }

  .maptool-submenu.ant-menu-item.tool-menu-item.ant-menu-item-active {
    background: #fff7dc !important;
  }

  .maptool-submenu {
    padding: 0 12px !important;

    & > span {
      flex: 1;

      .ant-typography {
        line-height: 1;
      }
    }

    & > span > span {
      justify-content: space-between;
      align-items: center;
      display: flex;
    }
  }

  .maptool-submenu.ant-menu-item.tool-menu-item.ant-menu-item-selected {
    background: #fff7dc !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}

.ant-menu-submenu-open {
  color: #000000;
  background-color: #fad049 !important;
  border-radius: 6px;
}

.pro-feature {
  position: absolute;
  right: 16px;
  font-weight: 400;
}

.menu-tool-tip {
  left: 507px !important;
}

.selected-tool-linesubmenu-path,
.selected-tool-linesubmenu-line,
.selected-tool-cutsubmenu-slice,
.selected-tool-cutsubmenu-doughnut,
.selected-tool-cutsubmenu-cut {
  color: #000000;
  background-color: #fad049 !important;
  border-radius: 6px;
}
