.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.eodBaseViewModal:global(.__wab_instance) {
  max-width: 100%;
  height: 219px;
  flex-shrink: 0;
}
.eodNewTakeoffViewCreation:global(.__wab_instance) {
  max-width: 100%;
}
.eodTakeoffViewLocked:global(.__wab_instance) {
  max-width: 100%;
  height: 227px;
  flex-shrink: 0;
}
.eodEstimationViewLocked:global(.__wab_instance) {
  max-width: 100%;
}
.eodEstimationViewLocked2:global(.__wab_instance) {
  max-width: 100%;
}
