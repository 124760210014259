.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
}
.component153 {
  background: rgba(255, 255, 255, 1);
  box-shadow: none;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-width: 0;
  border-radius: 4px;
}
.component153 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.component153 > :global(.__wab_flex-container) > *,
.component153 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.component153 > :global(.__wab_flex-container) > picture > img,
.component153
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000005249 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  cursor: pointer;
  padding: 8px 12px;
}
.frame1000005249 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000005249 > :global(.__wab_flex-container) > *,
.frame1000005249 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005249 > :global(.__wab_flex-container) > picture > img,
.frame1000005249
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.frame1000005402 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  border-radius: 4px;
}
.frame1000005402 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000005402 > :global(.__wab_flex-container) > *,
.frame1000005402 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005402 > :global(.__wab_flex-container) > picture > img,
.frame1000005402
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.frame1000005403 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000005403 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000005403 > :global(.__wab_flex-container) > *,
.frame1000005403 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005403 > :global(.__wab_flex-container) > picture > img,
.frame1000005403
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.text__jEzny {
  display: block;
  flex-grow: 1;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 16px;
  position: relative;
}
.svg__mIh4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  cursor: pointer;
  flex-shrink: 0;
}
.svg__dx7Tq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  cursor: pointer;
  pointer-events: none;
  flex-shrink: 0;
}
.search {
  width: 97px;
  height: 70px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.search2 {
  transform: translate(-0.5px, 0px);
  width: 107px;
  height: 77px;
  display: block;
  position: absolute;
  top: -3px;
  left: -4px;
}
.group35 {
  transform: translate(0px, 0.11100000000000021px);
  width: 96px;
  height: 65px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}
.svg__t028N {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff9e4;
  transform: translate(0.07900000000000063px, -0.19100000000000072px);
  width: 52px;
  height: 12px;
  display: block;
  top: 18.51%;
  left: 37.65%;
  border-radius: 382.617px;
}
.rectangle33 {
  background: rgba(255, 248, 227, 1);
  transform: translate(-0.05699999999999861px, -0.3689999999999998px);
  width: 59.49%;
  height: 21.6%;
  display: block;
  position: absolute;
  top: 7.71%;
  left: 24.05%;
  border-radius: 382.617px;
}
.rectangle37 {
  background: rgba(255, 248, 227, 1);
  transform: translate(-0.10799999999999699px, -0.31400000000000006px);
  width: 25%;
  height: 12.34%;
  display: block;
  position: absolute;
  top: 67.89%;
  left: 65.9%;
  border-radius: 382.617px;
}
.rectangle35 {
  background: rgba(255, 248, 227, 1);
  transform: translate(0.2780000000000058px, 0.375px);
  width: 14.03%;
  height: 20.05%;
  display: block;
  position: absolute;
  top: 35.48%;
  left: 74.27%;
  border-radius: 382.617px;
}
.rectangle30 {
  background: rgba(255, 248, 227, 1);
  transform: translate(-0.38900000000000023px, -0.3440000000000012px);
  width: 77.63%;
  height: 33.94%;
  display: block;
  position: absolute;
  top: 24.68%;
  left: 5.23%;
  border-radius: 382.617px;
}
.rectangle31 {
  background: rgba(255, 248, 227, 1);
  transform: translate(0.062000000000001165px, 0.43599999999999994px);
  width: 72.8%;
  height: 27.77%;
  display: block;
  position: absolute;
  top: 61.72%;
  left: 10.46%;
  border-radius: 382.617px;
}
.rectangle32 {
  background: rgba(255, 248, 227, 1);
  transform: translate(-0.23100000000000165px, 0.06099999999999994px);
  width: 68.85%;
  height: 27.77%;
  display: block;
  position: absolute;
  top: 44.74%;
  left: 31.38%;
  border-radius: 382.617px;
}
.rectangle34 {
  background: rgba(255, 248, 227, 1);
  transform: translate(0.24799999999999756px, 0.4660000000000011px);
  width: 28.94%;
  height: 15.43%;
  display: block;
  position: absolute;
  top: 84.86%;
  left: 55.44%;
  border-radius: 382.617px;
}
.rectangle39 {
  background: rgba(255, 248, 227, 1);
  transform: translate(0.3390000000000004px, 0px);
  width: 28.94%;
  height: 15.43%;
  display: block;
  position: absolute;
  top: 0%;
  left: 14.64%;
  border-radius: 382.617px;
}
.rectangle38 {
  background: rgba(255, 248, 227, 1);
  transform: translate(-0.036000000000001364px, -0.3789999999999978px);
  width: 28.94%;
  height: 15.43%;
  display: block;
  position: absolute;
  top: 81.78%;
  left: 21.96%;
  border-radius: 382.617px;
}
.rectangle36 {
  background: rgba(255, 248, 227, 1);
  transform: translate(0px, 0.12300000000000466px);
  width: 41.66%;
  height: 20.05%;
  display: block;
  position: absolute;
  top: 50.91%;
  left: 0%;
  border-radius: 382.617px;
}
.group34 {
  transform: translate(-0.034000000000000696px, -0.38900000000000023px);
  width: 39px;
  height: 49px;
  display: block;
  position: absolute;
  top: 8px;
  left: 13px;
}
.svg__wK8Bw {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  transform: translate(0.22600000000000087px, 0.28500000000000103px);
  width: 31px;
  height: 42px;
  display: block;
  top: 14.45%;
  left: 20.61%;
}
.svg__jj7Tz {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #999;
  width: 11.198px;
  height: 10.5px;
  display: block;
  top: 0px;
  left: 0px;
}
.group32 {
  transform: scale(0.9998489885977782, 1.0005560953789647) rotate(45deg)
    skew(-0.04052034650700807deg, 0deg) translate(0.24600000000000222px, 0px);
  width: 64px;
  height: 45px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 0px;
  left: 61px;
}
.rectangle28 {
  background: rgba(214, 167, 0, 1);
  transform: scale(0.9993015328531554, 0.9992565316321864)
    rotate(-0.28364129064675453deg) skew(-0.5674851382813528deg, 0deg)
    translate(-0.2675986225578555px, -0.38445229681978077px);
  width: 40.95%;
  height: 22.25%;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 46.74%;
  left: 59.49%;
  border-radius: 7.563px;
}
.svg__sqSdg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ffd02b;
  transform: scale(0.9993015328531554, 0.9992565316321864)
    rotate(-0.28364129064675453deg) skew(-0.5674851382813528deg, 0deg)
    translate(-0.028947276353072482px, 0.06855123674912278px);
  width: 39px;
  height: 39px;
  display: block;
  transform-origin: top left;
  top: 13.35%;
  left: 1.56%;
}
.group26 {
  transform: scale(1.0011526404439979, -1.0004422037357694)
    rotate(-135.09768971201373deg) skew(0.09762038969437294deg, 0deg)
    translate(-0.15392266132217003px, 0.3533568904593771px);
  width: 11px;
  height: 11px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 25px;
  left: 28px;
}
.rectangle22 {
  background: rgba(153, 153, 153, 1);
  transform: scale(0.9990696313658033, 0.9993089688549821)
    rotate(45.25508943009514deg) skew(0.6076756406450206deg, 0deg)
    translate(-0.4445554445554407px, -0.0015538906647805106px);
  width: 121.24%;
  height: 28.05%;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 0%;
  left: 18.89%;
  border-radius: 1.26px;
}
.rectangle23 {
  background: rgba(153, 153, 153, 1);
  transform: scale(0.999365148693501, 0.997601395447402)
    rotate(134.64755931867498deg) skew(-0.6068588520354996deg, 0deg)
    translate(-0.42457542457541564px, -0.4391362882871377px);
  width: 121.24%;
  height: 28.05%;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 18.7%;
  left: 103.92%;
  border-radius: 1.26px;
}
.svg__twUY {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #999;
  width: 34px;
  height: 12px;
  display: block;
  transform-origin: top left;
  transform: scale(0.9999999999999999, 0.9999999999999998)
    skew(6.361109362927035e-15deg, 0deg) translate(0px, 7.105427357601002e-15px);
  top: 0px;
  left: 0px;
}
.svg__nU5E1 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ffd02b;
  transform: scale(0.9984447694775419, 0.9991508831758995) rotate(-45deg)
    skew(0.04052034650700613deg, 0deg)
    translate(-0.004770068122425641px, 0.25512367491167254px);
  width: 15px;
  height: 15px;
  display: block;
  transform-origin: top left;
  top: 51.19%;
  left: 65.75%;
}
.frame1000005363 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 16px;
  width: 100%;
  min-width: 0;
}
.frame1000005363 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.frame1000005363 > :global(.__wab_flex-container) > *,
.frame1000005363 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005363 > :global(.__wab_flex-container) > picture > img,
.frame1000005363
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.frame1000005367 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  min-width: 0;
}
.frame1000005367 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005367 > :global(.__wab_flex-container) > *,
.frame1000005367 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005367 > :global(.__wab_flex-container) > picture > img,
.frame1000005367
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__oFRdl {
  display: block;
  flex-grow: 1;
  font-size: 10px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  white-space: pre-wrap;
  line-height: 1.54;
}
