.root {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 48px;
  height: 44px;
  max-width: 100%;
  justify-self: flex-start;
}
.snapshots {
  background: #ffffff;
  width: 100%;
  height: 45px;
  display: block;
  overflow: hidden;
  cursor: pointer;
  min-width: 0;
  border-radius: 4px;
}
.snapshotsbidConsumption_almostExhausted {
  display: none;
}
.snapshotsbidConsumption_fullyExhausted {
  display: none;
}
.root:hover .snapshots {
  background: #e9e9e9;
}
.group1000002143 {
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  top: 4px;
  left: 3px;
  min-width: 0;
}
.frame1000006616 {
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 25px;
  left: 0px;
  flex-direction: row;
}
.frame1000006616 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000006616 > :global(.__wab_flex-container) > *,
.frame1000006616 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006616 > :global(.__wab_flex-container) > picture > img,
.frame1000006616
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
._14M {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
  width: 100%;
  min-width: 0;
}
.img__icyBl {
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.5px, 0px);
  width: auto;
  height: 40px;
  display: block !important;
  position: absolute;
  top: 0px;
  left: 1px;
}
.img__icyBl > picture > img {
  object-fit: cover;
}
.imgbidConsumption__10__icyBlW291N {
  display: none !important;
}
.textbidConsumption_almostExhausted {
  display: none;
}
.property1Variant3 {
  background: #ffffff;
  width: 48px;
  height: 44px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 4px;
}
.group1000002144 {
  width: 42px;
  height: 40px;
  display: block;
  position: absolute;
  top: 4px;
  left: 3px;
}
.frame1000006617 {
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 25px;
  left: 0px;
  flex-direction: row;
}
.frame1000006617 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000006617 > :global(.__wab_flex-container) > *,
.frame1000006617 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006617 > :global(.__wab_flex-container) > picture > img,
.frame1000006617
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
._14M2 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.img__sUfDe {
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.5px, 0px);
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0px;
  left: 1px;
  display: none !important;
}
.img__sUfDe > picture > img {
  object-fit: cover;
}
.imgbidConsumption_almostExhausted__sUfDeXYpp {
  display: block !important;
}
.property1Variant4 {
  background: #ffffff;
  width: 48px;
  height: 44px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 4px;
}
.group1000002145 {
  width: 42px;
  height: 40px;
  display: block;
  position: absolute;
  top: 4px;
  left: 3px;
}
.frame1000006618 {
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 25px;
  left: 0px;
  flex-direction: row;
}
.frame1000006618 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000006618 > :global(.__wab_flex-container) > *,
.frame1000006618 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006618 > :global(.__wab_flex-container) > picture > img,
.frame1000006618
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
._14M3 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.img___8W1Oh {
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.5px, 0px);
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0px;
  left: 1px;
  display: none !important;
}
.img___8W1Oh > picture > img {
  object-fit: cover;
}
.imgbidConsumption_fullyExhausted___8W1OhtNogb {
  display: block !important;
}
