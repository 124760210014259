@import '/src/styles/base/variables/breakpoints';

@spacing-base: 4px;
@max-spacing: 32;

.properties(@properties, @spacing) {
  @transformed: if(@spacing = auto, auto, rem(@spacing));

  each(@properties, {
      @{value}: @transformed !important;
  });
}

.spacing-css(@abbr, @properties) {
  @class: ~'@{abbr}';

  .loop (@i) when (@i <= @max-spacing) {
    @spacing: @i * @spacing-base;

    .@{class}-@{i} {
      .properties(@properties, @spacing);
    }

    .loop(@i + 1);
  }
  .loop(0);

  .@{class}-auto {
    .properties(@properties, auto);
  }
}

.spacers(@abbr, @properties) {
  // TODO: Use each(range(0, @max-spacing)) instead of loop if possible
  .spacing-css('@{abbr}', @properties);

  each(@breakpoints, {
    @media only screen and (min-width: @value) {
      @class: '@{abbr}-@{key}';
      .spacing-css(@class, @properties);
    }
  });
}

@properties-px: padding-left, padding-right;
@properties-py: padding-top, padding-bottom;
@properties-mx: margin-left, margin-right;
@properties-my: margin-top, margin-bottom;
@properties-m: margin-top, margin-bottom, margin-left, margin-right;
@properties-p: padding-top, padding-bottom, padding-left, padding-right;

.spacers(m, @properties-m);
.spacers(mx, @properties-mx);
.spacers(my, @properties-my);
.spacers(mt, margin-top);
.spacers(mb, margin-bottom);
.spacers(mr, margin-right);
.spacers(ml, margin-left);

.spacers(p, @properties-p);
.spacers(px, @properties-px);
.spacers(py, @properties-py);
.spacers(pt, padding-top);
.spacers(pb, padding-bottom);
.spacers(pr, padding-right);
.spacers(pl, padding-left);

.spacers(gap, gap);
