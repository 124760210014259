@import '/src/styles/base/variables/index';

.alert-modal {
  .ant-modal-content {
    border-radius: @border-radius-lg;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-modal-body {
      padding: 10px;
    }
  }
}
