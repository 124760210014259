.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  min-height: 0;
}
.modalForAdmin {
  background: rgba(255, 255, 255, 1);
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.039))
    drop-shadow(0px 20px 12.5px rgba(0, 0, 0, 0.1));
  width: 560px;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: 8px;
  padding: 24px;
}
.modalForAdmin > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.modalForAdmin > :global(.__wab_flex-container) > *,
.modalForAdmin > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.modalForAdmin > :global(.__wab_flex-container) > picture > img,
.modalForAdmin
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.leadingContent {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.leadingContent > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.leadingContent > :global(.__wab_flex-container) > *,
.leadingContent > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.leadingContent > :global(.__wab_flex-container) > picture > img,
.leadingContent
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.frame1000005416 {
  background: rgba(255, 241, 200, 1);
  box-shadow: 0px 0px 0px 8px rgba(255, 250, 234, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 100px;
  padding: 8px;
}
.frame1000005416 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000005416 > :global(.__wab_flex-container) > *,
.frame1000005416 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005416 > :global(.__wab_flex-container) > picture > img,
.frame1000005416
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000005415 {
  width: 37px;
  height: 36px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.sidebarIconsBoxiconsPack {
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.notesSvgrepoCom3 {
  transform: translate(0.33299999999999996px, 0.33299999999999996px);
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  overflow: hidden;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #333;
  transform: translate(0.05500000000000016px, -0.16799999999999993px);
  width: 25px;
  height: 27px;
  display: block;
  top: 6.81%;
  left: 10.22%;
}
.frame1000005140 {
  transform: translate(0.3330000000000002px, -0.28000000000000025px);
  width: 15px;
  height: auto;
  display: flex;
  position: absolute;
  top: 7px;
  left: 7px;
  flex-direction: column;
}
.frame1000005140 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 2.444px);
  height: calc(100% + 2.444px);
}
.frame1000005140 > :global(.__wab_flex-container) > *,
.frame1000005140 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005140 > :global(.__wab_flex-container) > picture > img,
.frame1000005140
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 2.444px;
}
.rectangle26868 {
  background: rgba(51, 51, 51, 1);
  height: 5px;
  display: block;
  position: relative;
  align-self: stretch;
  flex-shrink: 0;
  border-radius: 0.488px;
}
.frame1000005142 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000005142 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: calc(0px - 2.444px);
  width: calc(100% + 2.444px);
}
.frame1000005142 > :global(.__wab_flex-container) > *,
.frame1000005142 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005142 > :global(.__wab_flex-container) > picture > img,
.frame1000005142
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2.444px;
}
.frame1000005141 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.frame1000005141 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 2.444px);
  height: calc(100% + 2.444px);
}
.frame1000005141 > :global(.__wab_flex-container) > *,
.frame1000005141 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005141 > :global(.__wab_flex-container) > picture > img,
.frame1000005141
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 2.444px;
}
.rectangle26870 {
  background: rgba(51, 51, 51, 1);
  width: 4px;
  height: 4px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 0.611px;
}
.rectangle26875 {
  background: rgba(51, 51, 51, 1);
  width: 4px;
  height: 4px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 0.611px;
}
.frame1000005146 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.frame1000005146 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 2.444px);
  height: calc(100% + 2.444px);
}
.frame1000005146 > :global(.__wab_flex-container) > *,
.frame1000005146 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005146 > :global(.__wab_flex-container) > picture > img,
.frame1000005146
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 2.444px;
}
.rectangle26871 {
  background: rgba(51, 51, 51, 1);
  width: 4px;
  height: 4px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 0.611px;
}
.rectangle26876 {
  background: rgba(51, 51, 51, 1);
  width: 4px;
  height: 4px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 0.611px;
}
.frame1000005145 {
  width: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000005145 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 2.444px);
  height: calc(100% + 2.444px);
}
.frame1000005145 > :global(.__wab_flex-container) > *,
.frame1000005145 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005145 > :global(.__wab_flex-container) > picture > img,
.frame1000005145
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 2.444px;
}
.rectangle26873 {
  background: rgba(51, 51, 51, 1);
  width: 4px;
  display: block;
  position: relative;
  flex-grow: 1;
  border-radius: 0.611px;
}
.img {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.2010000000000005px, 0px);
  width: 18.444px;
  height: 18.437px;
  display: flex !important;
  top: 17px;
  left: 18px;
  flex-direction: column;
  border-radius: 100px;
  padding: 2px;
}
.img > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.img > :global(.__wab_flex-container) > *,
.img > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.img > :global(.__wab_flex-container) > picture > img,
.img > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.img {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.2010000000000005px, 0px);
  width: 18.444px;
  height: 18.437px;
  display: flex !important;
  top: 17px;
  left: 18px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 2px;
}
.img > picture > img {
  object-fit: cover;
}
.text {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.text > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.text > :global(.__wab_flex-container) > *,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.text > :global(.__wab_flex-container) > picture > img,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
.heading {
  display: block;
  font-size: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 24px;
  color: rgba(16, 23, 38, 1);
  align-self: stretch;
  position: relative;
}
.detail {
  opacity: 0.899;
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(106, 113, 127, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
}
.actions {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.actions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.actions > :global(.__wab_flex-container) > *,
.actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actions > :global(.__wab_flex-container) > picture > img,
.actions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.buttonsMarch28 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(208, 212, 215, 1);
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 24px;
}
.buttonsMarch28 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch28 > :global(.__wab_flex-container) > *,
.buttonsMarch28 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch28 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch28
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText {
  display: block;
  flex-grow: 1;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.buttonsMarch27 {
  background: rgba(255, 208, 42, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 208, 42, 1);
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 24px;
}
.buttonsMarch27 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch27 > :global(.__wab_flex-container) > *,
.buttonsMarch27 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch27 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch27
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText2 {
  display: block;
  flex-grow: 1;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
