@px: 16rem;

.add-new-queue-container {
  width: 300 / @px;
  min-width: 300 / @px;
  padding: 30 / @px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -20px;
  p {
    margin-bottom: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #666666;
    line-height: 17 / @px;
  }
  .add-queue-button {
    span {
      text-decoration: underline;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      text-decoration-line: underline;
      color: #666666;
      position: relative;
      top: -6px;
    }
  }
  .request-sent-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
  }
  .request-sent-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #666666;
  }
}
