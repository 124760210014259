.confirmation-modal {
  .ant-modal-content {
    border-radius: 8px;
  }
  .confirmation-modal-main {
    text-align: center;
    .confirmation-modal-title {
      margin-bottom: 16px;
    }
    .confirmation-modal-content {
      margin-bottom: 24px;
    }
    .confirmation-modal-footer {
      display: flex;
      justify-content: space-between;
      .confirmation-modal-footer-button {
        width: 49%;
      }
    }
  }
}
