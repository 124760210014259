.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.pricingDeliveredCallOut {
  background: rgba(255, 255, 255, 1);
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.039))
    drop-shadow(0px 20px 12.5px rgba(0, 0, 0, 0.1));
  width: 646px;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: 8px;
  padding: 24px;
}
.pricingDeliveredCallOut > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.pricingDeliveredCallOut > :global(.__wab_flex-container) > *,
.pricingDeliveredCallOut
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.pricingDeliveredCallOut > :global(.__wab_flex-container) > picture > img,
.pricingDeliveredCallOut
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.crossIcon {
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  flex-direction: column;
  left: 607px;
  top: -8px;
  cursor: pointer;
  bottom: 465px;
  flex-shrink: 0;
  padding: 8px;
}
.crossIcon > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.crossIcon > :global(.__wab_flex-container) > *,
.crossIcon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.crossIcon > :global(.__wab_flex-container) > picture > img,
.crossIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.svg__ovNGc {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.heading {
  display: block;
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 42px;
  color: rgba(16, 23, 38, 1);
  align-self: stretch;
  position: relative;
}
.frame1000006641 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006641 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.frame1000006641 > :global(.__wab_flex-container) > *,
.frame1000006641 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006641 > :global(.__wab_flex-container) > picture > img,
.frame1000006641
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.asset13 {
  background: rgba(244, 244, 244, 1);
  width: 168px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.object {
  transform: translate(0px, 0.4230000000000018px);
  width: 141px;
  height: 107px;
  position: relative;
  top: auto;
  left: auto;
  flex-shrink: 0;
  display: none;
}
.object2 {
  width: 141px;
  height: 107px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}
.svg__akxdr {
  object-fit: cover;
  max-width: 100%;
  color: #e7e7e7;
  transform: translate(-0.0009999999999994458px, 0.16899999999999693px);
  width: 141px;
  height: 94px;
  display: block;
  position: absolute;
  top: 12.16%;
  left: 0%;
}
.svg__ikyq4 {
  object-fit: cover;
  max-width: 100%;
  color: #ffd02b;
  transform: translate(0.1880000000000024px, 0.20399999999999352px);
  width: 75px;
  height: 100px;
  display: block;
  position: absolute;
  top: 6.54%;
  left: 15.64%;
}
.svg___9CGwr {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: rotate(90deg)
    translate(0.18600000000000705px, -0.492999999999995px);
  width: 87px;
  height: 62px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 12.16%;
  left: 64%;
}
.svg__swEwE {
  object-fit: cover;
  max-width: 100%;
  color: #6d6d6d;
  transform: translate(-0.036000000000001364px, 0px);
  width: 26px;
  height: 14px;
  display: block;
  position: absolute;
  top: 0%;
  left: 33.42%;
}
.svg___9NdpC {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: translate(-0.30100000000000193px, -0.09700000000000131px);
  width: 2px;
  height: 3px;
  display: block;
  position: absolute;
  top: 1.87%;
  left: 41.95%;
}
.svg__ez3LC {
  object-fit: cover;
  max-width: 100%;
  color: #6d6d6d;
  transform: translate(-0.23899999999999721px, 0.3100000000000023px);
  width: 50px;
  height: 4px;
  display: block;
  position: absolute;
  top: 18.71%;
  left: 24.88%;
}
.svg__ef6Eo {
  object-fit: cover;
  max-width: 100%;
  color: #6d6d6d;
  transform: translate(-0.23899999999999721px, -0.16500000000000625px);
  width: 50px;
  height: 4px;
  display: block;
  position: absolute;
  top: 24.32%;
  left: 24.88%;
}
.svg__ws13F {
  object-fit: cover;
  max-width: 100%;
  color: #bcbcbc;
  transform: translate(0.07500000000000284px, 0.3289999999999935px);
  width: 15px;
  height: 22px;
  display: block;
  position: absolute;
  top: 56.13%;
  left: 44.8%;
}
.svg__wPrdQ {
  object-fit: cover;
  max-width: 100%;
  color: #ffd274;
  transform: translate(-0.09300000000000352px, 0.2150000000000034px);
  width: 11px;
  height: 12px;
  display: block;
  position: absolute;
  top: 55.2%;
  left: 49.06%;
}
.svg__xqxEx {
  object-fit: cover;
  max-width: 100%;
  color: #6d6d6d;
  transform: translate(0.3229999999999933px, 0.34600000000000364px);
  width: 11px;
  height: 20px;
  display: block;
  position: absolute;
  top: 57.07%;
  left: 51.91%;
}
.svg__isU5 {
  object-fit: cover;
  max-width: 100%;
  color: #bcbcbc;
  transform: translate(-0.03399999999999892px, -0.031999999999996476px);
  width: 18px;
  height: 13px;
  display: block;
  position: absolute;
  top: 41.16%;
  left: 26.31%;
}
.svg__d5Qqw {
  object-fit: cover;
  max-width: 100%;
  color: #6d6d6d;
  transform: translate(-0.4029999999999987px, -0.025000000000005684px);
  width: 5px;
  height: 10px;
  display: block;
  position: absolute;
  top: 37.42%;
  left: 26.31%;
}
.svg__dn2X {
  object-fit: cover;
  max-width: 100%;
  color: #ffd274;
  transform: translate(0.07099999999999795px, 0.03699999999999193px);
  width: 15px;
  height: 8px;
  display: block;
  position: absolute;
  top: 34.61%;
  left: 27.73%;
}
.svg__nhcCt {
  object-fit: cover;
  max-width: 100%;
  color: #6d6d6d;
  transform: translate(-0.12899999999999778px, -0.18600000000000705px);
  width: 4px;
  height: 8px;
  display: block;
  position: absolute;
  top: 40.23%;
  left: 31.29%;
}
.svg__fozPu {
  object-fit: cover;
  max-width: 100%;
  color: #e4e4e4;
  transform: translate(0.2740000000000009px, 0.42099999999999227px);
  width: 19px;
  height: 2px;
  display: block;
  position: absolute;
  top: 37.42%;
  left: 46.93%;
}
.svg__fExXa {
  object-fit: cover;
  max-width: 100%;
  color: #e4e4e4;
  transform: translate(0.2740000000000009px, 0.4579999999999984px);
  width: 19px;
  height: 2px;
  display: block;
  position: absolute;
  top: 41.16%;
  left: 46.93%;
}
.svg__tVqJ7 {
  object-fit: cover;
  max-width: 100%;
  color: #e4e4e4;
  transform: translate(0.2740000000000009px, 0.4959999999999951px);
  width: 19px;
  height: 2px;
  display: block;
  position: absolute;
  top: 44.9%;
  left: 46.93%;
}
.svg___2Tls8 {
  object-fit: cover;
  max-width: 100%;
  color: #e4e4e4;
  transform: translate(0.36299999999999955px, -0.12099999999999511px);
  width: 19px;
  height: 2px;
  display: block;
  position: absolute;
  top: 58.94%;
  left: 25.6%;
}
.svg__nyVjk {
  object-fit: cover;
  max-width: 100%;
  color: #e4e4e4;
  transform: translate(0.36299999999999955px, -0.08500000000000796px);
  width: 19px;
  height: 2px;
  display: block;
  position: absolute;
  top: 62.68%;
  left: 25.6%;
}
.svg___8A2Kw {
  object-fit: cover;
  max-width: 100%;
  color: #e4e4e4;
  transform: translate(0.36299999999999955px, -0.04800000000000182px);
  width: 19px;
  height: 2px;
  display: block;
  position: absolute;
  top: 66.42%;
  left: 25.6%;
}
.svg__mKovV {
  object-fit: cover;
  max-width: 100%;
  color: #bcbcbc;
  transform: translate(-0.3860000000000028px, 0.05699999999998795px);
  width: 3px;
  height: 4px;
  display: block;
  position: absolute;
  top: 72.97%;
  left: 27.02%;
}
.svg__cyn2K {
  object-fit: cover;
  max-width: 100%;
  color: #6d6d6d;
  transform: translate(0.012999999999998124px, 0.05699999999998795px);
  width: 3px;
  height: 4px;
  display: block;
  position: absolute;
  top: 72.97%;
  left: 31.29%;
}
.svg__tt8D {
  object-fit: cover;
  max-width: 100%;
  color: #ffd274;
  transform: translate(0.41200000000000614px, 0.05699999999998795px);
  width: 3px;
  height: 4px;
  display: block;
  position: absolute;
  top: 72.97%;
  left: 35.55%;
}
.svg__myyY {
  object-fit: cover;
  max-width: 100%;
  color: #bcbcbc;
  transform: translate(0.025000000000005684px, 0.38800000000000523px);
  width: 9px;
  height: 11px;
  display: block;
  position: absolute;
  top: 36.48%;
  left: 39.82%;
}
.svg__ot8S {
  object-fit: cover;
  max-width: 100%;
  color: #e4e4e4;
  transform: translate(0.36299999999999955px, 0.08299999999999841px);
  width: 49px;
  height: 3px;
  display: block;
  position: absolute;
  top: 83.26%;
  left: 25.6%;
}
.svg__dmrwk {
  object-fit: cover;
  max-width: 100%;
  color: #e4e4e4;
  transform: translate(0.36299999999999955px, 0.1490000000000009px);
  width: 49px;
  height: 3px;
  display: block;
  position: absolute;
  top: 79.52%;
  left: 25.6%;
}
.img__um3Um {
  object-fit: cover;
  max-width: 100%;
  transform: translate(0.2530000000000001px, 0.4509999999999934px);
  width: 40.556px;
  height: 56.431px;
  display: block !important;
  position: absolute;
  top: 50px;
  left: 84px;
}
.img__um3Um > picture > img {
  object-fit: cover;
}
.img__lPiDx {
  object-fit: cover;
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  width: 140px;
  height: 106px;
  flex-shrink: 0;
}
.img__lPiDx > picture > img {
  object-fit: cover;
}
.frame1000006647 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
}
.frame1000006647 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.frame1000006647 > :global(.__wab_flex-container) > *,
.frame1000006647 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006647 > :global(.__wab_flex-container) > picture > img,
.frame1000006647
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.frame1000006620 {
  background: rgba(244, 244, 244, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
}
.frame1000006620 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.frame1000006620 > :global(.__wab_flex-container) > *,
.frame1000006620 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006620 > :global(.__wab_flex-container) > picture > img,
.frame1000006620
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.frame1000006618 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006618 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.frame1000006618 > :global(.__wab_flex-container) > *,
.frame1000006618 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006618 > :global(.__wab_flex-container) > picture > img,
.frame1000006618
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.text__qpSi {
  display: block;
  flex-grow: 1;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.text__eyuPz {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000006619 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006619 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.frame1000006619 > :global(.__wab_flex-container) > *,
.frame1000006619 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006619 > :global(.__wab_flex-container) > picture > img,
.frame1000006619
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.text__bdpe9 {
  display: block;
  flex-grow: 1;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.text__oaiap {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000006621 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006621 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.frame1000006621 > :global(.__wab_flex-container) > *,
.frame1000006621 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006621 > :global(.__wab_flex-container) > picture > img,
.frame1000006621
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.text___6MlJr {
  display: block;
  flex-grow: 1;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.text__mxqHl {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000006622 {
  background: rgba(244, 244, 244, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  border-radius: 8px;
  padding: 16px;
}
.frame1000006622 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.frame1000006622 > :global(.__wab_flex-container) > *,
.frame1000006622 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006622 > :global(.__wab_flex-container) > picture > img,
.frame1000006622
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.bidMeterWidget2:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 0;
}
.snapshots {
  width: 48px;
  height: 44px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  border-radius: 4px;
}
.group1000002143 {
  width: 42px;
  height: 40px;
  display: block;
  position: absolute;
  top: 4px;
  left: 3px;
}
.frame1000006616 {
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 25px;
  left: 0px;
  flex-direction: row;
}
.frame1000006616 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000006616 > :global(.__wab_flex-container) > *,
.frame1000006616 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006616 > :global(.__wab_flex-container) > picture > img,
.frame1000006616
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
._14M {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.img__lTj0 {
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.5px, 0px);
  width: 40px;
  height: 40px;
  display: block !important;
  position: absolute;
  top: 0px;
  left: 1px;
}
.img__lTj0 > picture > img {
  object-fit: cover;
}
.frame1000006645 {
  height: 54px;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
}
.frame1000006645 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.frame1000006645 > :global(.__wab_flex-container) > *,
.frame1000006645 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006645 > :global(.__wab_flex-container) > picture > img,
.frame1000006645
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.frame1000006623 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006623 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.frame1000006623 > :global(.__wab_flex-container) > *,
.frame1000006623 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006623 > :global(.__wab_flex-container) > picture > img,
.frame1000006623
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.text__mtK2E {
  display: block;
  flex-grow: 1;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.text__uqfkN {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000006624 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006624 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.frame1000006624 > :global(.__wab_flex-container) > *,
.frame1000006624 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006624 > :global(.__wab_flex-container) > picture > img,
.frame1000006624
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.text__hMtoA {
  display: block;
  flex-grow: 1;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.text__fbaM {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.text__xorbw {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  align-self: stretch;
  position: relative;
  margin-bottom: -8px;
}
._default {
  background: rgba(249, 249, 249, 1);
  box-shadow: inset 0px 0px 0px 0.5px rgba(208, 212, 218, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  border-radius: 8px;
  padding: 8px;
}
._default > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
._default > :global(.__wab_flex-container) > *,
._default > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
._default > :global(.__wab_flex-container) > picture > img,
._default
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.textField {
  background: rgba(255, 255, 255, 0);
  height: 64px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 4px;
}
.textField > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.textField > :global(.__wab_flex-container) > *,
.textField > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textField > :global(.__wab_flex-container) > picture > img,
.textField
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.frame1000002128 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: row;
  overflow: auto;
}
.frame1000002128 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002128 > :global(.__wab_flex-container) > *,
.frame1000002128 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002128 > :global(.__wab_flex-container) > picture > img,
.frame1000002128
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__d219S {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
  height: auto;
}
.svg___9XrV7 {
  object-fit: cover;
  max-width: 100%;
  color: #ccc;
  width: 2px;
  height: 34px;
  position: relative;
  flex-shrink: 0;
  display: none;
}
.svg__mIkYe {
  object-fit: cover;
  max-width: 100%;
  color: #d1d5db;
  width: 13px;
  height: 13px;
  position: relative;
  flex-shrink: 0;
  display: none;
}
.freeBox {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.tRsecondaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 291px;
  height: 44px;
  flex-shrink: 0;
}
.tRprimaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 300px;
  height: 44px;
  align-self: flex-start;
  flex-shrink: 0;
}
