@import './colors.less';

@sm: 576px;
@md: 768px;
@lg: 992px;
@xl: 1200px;

@sm-in: 575.98px;
@md-in: 767.98px;
@lg-in: 991.98px;
@xl-in: 1119.98px;

.bg-primary {
  background-color: @main-color !important;
}

.bg-white {
  background-color: white !important;
}

.overflow {
  overflow: auto;
}

.w-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

.h-40 {
  height: 40px !important;
}

.h-20 {
  height: 20px !important;
}

.br-8 {
  border-radius: 8px !important;
}

.br-10 {
  border-radius: 10px !important;
}

.br-50 {
  border-radius: 50px !important;
}

.d-xs-none {
}

.d-sm-none {
}

.d-md-none {
}

.d-lg-none {
}

.d-xl-none {
}

@media (max-width: @sm-in) {
  .d-xs-none {
    display: none;
  }
}

@media (min-width: @sm) and (max-width: @md-in) {
  .d-sm-none {
    display: none;
  }
}

@media (min-width: @md) and (max-width: @lg-in) {
  .d-md-none {
    display: none;
  }
}

@media (min-width: @lg) and (max-width: @xl-in) {
  .d-lg-none {
    display: none;
  }
}

@media (min-width: @xl) {
  .d-xl-none {
    display: none;
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.ws-btn:disabled {
  background: linear-gradient(180deg, #fbe27f 0%, #f3a40e 100%) !important;
  opacity: 0.5;
  border-radius: 0px 4px 4px 0px;
}

.invite-as-dd {
  .ant-select-item-option-content {
    white-space: normal;
    word-break: break-word;
    font-size: rem(12);
    display: block;
    line-height: 14px !important;
  }
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.text-ellipsis {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}