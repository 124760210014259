.left-feature {
  background-color: #f5f5f5;
  padding: 19px 0 12px 15px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  .library-heading {
    border-radius: 4px;
    text-align: center;
    width: 120px;
  }

  .search-box {
    position: relative;

    .ant-input-affix-wrapper {
      background: #ebebeb;
      padding: 3px 8px;

      input {
        background: #ebebeb;
        font-style: normal;
        border-radius: 4px;

        &::placeholder {
          font-style: normal;
        }
      }

      .search-icon {
        position: absolute;
        right: 13px;
        top: 50%;
        transform: translateY(-50%) !important;
      }
    }
  }

  .create-new-but {
    background-color: #f5f5f5;
    width: 197px;
    font-size: 12px !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    margin-top: 15px;
    margin-right: 13px;

    img {
      margin-right: 14px;
    }

    span {
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
    }
  }

  .top-content {
    overflow-y: auto;
    max-height: 401px;
    min-height: 401px;

    &::-webkit-scrollbar-track {
      visibility: hidden;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 41px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #dfd4d4;
    }

    .features-heading {
      display: block;
      font-weight: 600;
      margin-bottom: 8px;
      letter-spacing: 0.3px;
    }

    .custom {
      margin-top: 10px;
    }

    p {
      font-family: 'Poppins';

      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }

    h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #333333;
    }

    .name-list {
      padding: 5px;
      cursor: pointer;
      margin-bottom: 5px;
      margin-right: 9px;
      position: relative;
      display: flex;
      justify-content: space-between;

      &.active-name {
        background-color: #fff7dc;
        border-radius: 4px;
      }

      .layer-name-active {
        color: #333333 !important;
      }

      .check-icon {
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%) !important;
      }

      .hidden {
        display: none;
      }

      .dot {
        height: 12px;
        width: 12px;
        background-color: #bbb;
        border-radius: 50%;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%) !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        .inner-dot {
          height: 3px;
          width: 3px;
          position: absolute;
          border-radius: 50%;
          left: 2.4px;
          top: 2.5px;
        }
      }

      .path {
        right: 10px;
        position: absolute;
      }

      .path > svg {
        width: 16px;
      }
    }
  }
}
