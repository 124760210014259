@import '../../../style.less';

.toolbar {
  // top: -172px;
  // height: 44px;
  // left: 100%;
  width: 100%;
  height: 44px;

  //   position: absolute;
  .ant-menu-horizontal {
    border-bottom: 0px !important;
  }

  .setting-item {
    display: flex !important;
    margin: 0 !important;
    border-bottom: transparent !important;
    margin-left: auto !important;
    width: 44px !important;
    height: 100% !important;
  }

  .tool-box {
    // width: 810px;
    display: flex;
  }

  .more-dropdown {
    width: 100px;
    display: flex;
  }

  .settings {
    display: flex;
    justify-content: center;
  }
}

.lower-toolbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  width: 100%;
  height: 44px;
  background: #ffffff;

  .lower-toolbar-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 16px 4px 8px;
    gap: 8px;
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    border-radius: 2px;

    .undo-editing-section {
      max-width: 240px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .currently-editing {
        cursor: pointer;
        height: 100%;
        padding: 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 2px;

        &:hover {
          background: #e9e9e9;
        }

        &:active {
          border: 1px solid #c9c9c9;
        }
      }

      .currently-editing--active {
        background: #e9e9e9;
        border: 1px solid #c9c9c9;
      }
    }

    .quick-tips-section {
      width: calc(100% - 240px);
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .quick-tips-header {
        margin-right: 8px;
        width: 56px;
        display: flex;
        white-space: nowrap;
      }

      .quick-tips-data {
        display: flex;
        width: calc(100% - 56px);
        margin-right: 8px;

        .overflow {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.last-edited-layers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px !important;
  gap: 10px;

  width: 244px;

  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  .other-layers {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: column;
    overflow-y: auto;
    max-height: 230px;

    &::-webkit-scrollbar-track {
      background: none;
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background: #D1D5D8;
    }
  }

  .edited-layers {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: column;
    overflow-y: auto;
    max-height: 145px;
  }

  .layer {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px 6px 5px;
    gap: 12px;

    width: 100%;
    height: 33px;

    background: #ffffff;

    &:hover {
      background-color: #e9e9e9;
    }

    &:active {
      border: 1px solid #c9c9c9;
    }
  }

  .no-layers {
    width: 100%;
    padding: 0px 12px;
    height: 100px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #666666;
  }
}