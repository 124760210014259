.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.actionPanel {
  background: rgba(248, 249, 250, 1);
  box-shadow: inset 0px 0px 0px 0.5px rgba(220, 220, 220, 1);
  width: 282px;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  left: auto;
  top: auto;
  border-radius: 8px;
  padding: 12px;
}
.actionPanel > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.actionPanel > :global(.__wab_flex-container) > *,
.actionPanel > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actionPanel > :global(.__wab_flex-container) > picture > img,
.actionPanel
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.svg__fva2O {
  object-fit: cover;
  max-width: 100%;
  color: #999;
  position: absolute;
  left: 0px;
  top: -10px;
  height: 16px;
  bottom: 161px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.frame1000005234 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  margin-top: calc(4px + 12px) !important;
}
.frame1000005234 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005234 > :global(.__wab_flex-container) > *,
.frame1000005234 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005234 > :global(.__wab_flex-container) > picture > img,
.frame1000005234
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000002031 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.frame1000002031 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 1px);
  height: calc(100% + 1px);
}
.frame1000002031 > :global(.__wab_flex-container) > *,
.frame1000002031 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002031 > :global(.__wab_flex-container) > picture > img,
.frame1000002031
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 1px;
}
.popover5:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  left: auto;
  top: auto;
}
.popover5__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_AsQPHWjjsqqF {
  outline: none;
  padding: 0px;
}
.freeBox__fdAcr {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__fdAcr > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 3px);
  width: calc(100% + 3px);
}
.freeBox__fdAcr > :global(.__wab_flex-container) > *,
.freeBox__fdAcr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fdAcr > :global(.__wab_flex-container) > picture > img,
.freeBox__fdAcr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 3px;
}
.text__s37Pg {
  width: auto;
  height: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
}
.text__fInDo {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
  width: auto;
}
.tooltipContent__f7Cek:global(.__wab_instance) {
  max-width: 100%;
}
._522196SqFt34143Ft {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
}
.span___4Atot {
  color: #dddddd;
}
.frame1000002573 {
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  width: 57px;
  flex-shrink: 0;
}
.frame1000002573 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002573 > :global(.__wab_flex-container) > *,
.frame1000002573 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002573 > :global(.__wab_flex-container) > picture > img,
.frame1000002573
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  padding: 0px;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_5jepE8L071uS {
  outline: none;
  padding: 0px;
}
.freeBox__agIkA {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  cursor: pointer;
}
.svg___9Oid {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 24px;
  height: 24px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 28px;
}
.svg__um6Sv {
  object-fit: cover;
  max-width: 100%;
  color: #999;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.tooltipContent__tz5L8:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.dropdown:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 24px;
  font-size: 12px;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-shrink: 0;
  border-radius: 8px;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu) {
  width: 140px;
  text-align: left;
  outline: none;
  padding: 5px 0px;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu)
  :global(.ant-dropdown-menu-item) {
  text-align: left;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #333333;
  outline: none;
  padding: 0px;
}
.menuItem___51YHx:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover2__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_GJkE60NDfsJX {
  outline: none;
  padding: 0px;
}
.moveLayer {
  width: 140px;
  height: 32px;
  display: flex;
  flex-direction: row;
  padding: 8px;
}
.moveLayer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.moveLayer > :global(.__wab_flex-container) > *,
.moveLayer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.moveLayer > :global(.__wab_flex-container) > picture > img,
.moveLayer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__lUurs {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 31px;
}
.svg__cplsR {
  object-fit: cover;
  max-width: 100%;
  color: #999;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.item {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.tooltipContent__lJSki:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.menuItem__pEzW:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover3:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover3__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls__lpgCBG_3c_o {
  outline: none;
  padding: 0px;
}
.moveLayer2 {
  width: 140px;
  height: 32px;
  display: flex;
  flex-direction: row;
  padding: 8px;
}
.moveLayer2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.moveLayer2 > :global(.__wab_flex-container) > *,
.moveLayer2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.moveLayer2 > :global(.__wab_flex-container) > picture > img,
.moveLayer2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg__zOoZ7 {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 32px;
}
.svg__rcB4L {
  object-fit: cover;
  max-width: 100%;
  color: #999;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.item2 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.tooltipContent__pp1D0:global(.__wab_instance) {
  max-width: 100%;
}
.menuItem__aVLn2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover4:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover4__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_J-PpE_HBDzw6 {
  outline: none;
  padding: 0px;
}
.moveLayer3 {
  width: 140px;
  height: 32px;
  display: flex;
  flex-direction: row;
  padding: 8px;
}
.moveLayer3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.moveLayer3 > :global(.__wab_flex-container) > *,
.moveLayer3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.moveLayer3 > :global(.__wab_flex-container) > picture > img,
.moveLayer3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.svg___2MpHs {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 23.333px;
}
.svg__xFvZx {
  object-fit: cover;
  max-width: 100%;
  color: #999;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.item3 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.tooltipContent__kG9Ma:global(.__wab_instance) {
  max-width: 100%;
}
.frame1000006564 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000006564 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000006564 > :global(.__wab_flex-container) > *,
.frame1000006564 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006564 > :global(.__wab_flex-container) > picture > img,
.frame1000006564
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.root .frame1000006564:hover {
  background: #e9e9e9;
  box-shadow: none;
  border-radius: 50px;
}
.root .frame1000006564:active {
  box-shadow: inset 0px 0px 0px 1px #c9c9c9f7;
  border-radius: 50px;
}
.moreIcon {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 24px;
}
.svg__xb3Ny {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  transform: translate(-0.29499999999999993px, 0.2809999999999997px);
  width: 2.572px;
  height: 10.799px;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  flex-direction: column;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.svg__xb3Ny > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 1.543px);
  height: calc(100% + 1.543px);
}
.svg__xb3Ny > :global(.__wab_flex-container) > *,
.svg__xb3Ny > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.svg__xb3Ny > :global(.__wab_flex-container) > picture > img,
.svg__xb3Ny
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 1.543px;
}
.svg__hyrUk {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  flex-shrink: 0;
}
.freeBox__mniXa {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.actionCenterMoveLayers:global(.__wab_instance) {
  max-width: 100%;
}
.actionCenterLayerStyling2:global(.__wab_instance) {
  max-width: 100%;
}
.emServiceAssigments4__voSS:global(.__wab_instance) {
  max-width: 100%;
}
