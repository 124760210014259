@import '../../../style.less';

.map-tool-container {
  width: 100%;
  height: 100%;

  .tool {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 4px;
    width: fit-content;
    // height: 44px;
    background: #ffffff;
    border-radius: 0px;
    cursor: pointer;
    border-bottom-color: transparent !important;
  }

  .tool:hover {
    background: #e9e9e9 !important;
  }

  .tool.disabled {
    cursor: not-allowed !important;
    background: #ffffff !important;
    pointer-events: none;
  }

  .tool.popover {
    background: #e9e9e9 !important;
  }

  .tool.disabled:hover {
    background: #ffffff !important;
  }

  .tool.selected {
    background: #22b45c !important;

    img {
      filter: brightness(100);
    }

    span {
      color: #ffffff !important;
    }
  }

  .ant-menu-item {
    margin-block: unset !important;
    margin-inline: unset !important;
    width: fit-content;
    border-radius: 0% !important;
  }

  .ant-menu-title-content {
    display: flex !important;
    align-items: center !important;
    gap: 4px !important;
  }

  .ant-menu-item-selected::after,
  .ant-menu-item-active::after {
    border-bottom-color: transparent !important;
  }
}

.sub-tool-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px;

  width: 130px;

  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);

  .sub-tool {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 4px;

    width: 130px;
    height: 44px;

    background: #ffffff;
    border-radius: 0px;

    &:hover {
      background-color: #e9e9e9;
    }

    &.selected {
      background: #22b45c !important;

      img {
        filter: brightness(100);
      }

      span {
        color: #ffffff !important;
        filter: brightness(100);
      }
    }
  }
}

.wrong-layer-popover,
.sub-layer-popover,
.sub-tools-popover {
  display: inline-flex;
  padding: 12px 0px 24px 0px;
  align-items: flex-start;
  gap: 10px;
  padding-top: 0px !important;
  z-index: 999 !important;

  .ant-popover-arrow {
    display: none;
  }

  .map-tool-wrong-layer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 5px;
    width: 217px;

    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .no-layers-cover {
    gap: 5px;
    display: flex;
    flex-direction: column;

    .no-layers {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .ant-btn-primary {
    background-image: none !important;
    background-color: #ffd02b !important;
    font-style: 'Poppins';
    font-weight: 500;
    color: #333333;
    line-height: 21px;
    padding: 0px 24px;
    border: none !important;
    border-radius: 4px;
    transition: all 0.25s ease-in-out;
    height: 32px;

    &:focus {
      background: #ffd02b !important;
      color: #333333 !important;
      border: none !important;
    }

    &:hover {
      background: #ffe06f !important;
      // border: 1px solid #ffd02b !important;
    }

    &:active {
      background-color: #ffe06f !important;
      transform: scale(0.9) !important;
      outline: 2px solid #ffe06f !important;
      outline-offset: 2px !important;
    }
  }

  .ant-popover-arrow {
    display: none;
  }

  .wrong-layer-container {
    display: flex;
    flex-direction: column;
    width: 240px;
    max-height: 600px;
    overflow-y: auto;
    padding: 12px 0 0;
    align-items: flex-start;
    gap: 12px;
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      visibility: hidden !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #DDD !important;
      border-radius: 4px !important;
      width: 4px !important;
    }

    .header {
      display: flex;
      padding: 0px 12px;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;

      .header-inner {
        border-radius: 4px;
        background: #F3F4F6;
        display: flex;
        padding: 4px;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
      }
    }

    .select-layer-section {

      .select-layer-header {
        padding: 0 12px 8px;
      }

      .radio-set {

        .layer {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 12px;
          width: 100%;
          background: #ffffff;

          &:hover {
            background-color: #e9e9e9;
          }

          &:active,
          &:focus {
            background-color: #FFF9E5;
          }

          svg {
            max-width: 20px;
            max-height: 20px;
          }
        }

        .layer--disabled {
          pointer-events: none;

          .layer-title {
            opacity: 0.3;
          }
        }
      }

      .no-layers-cover {
        gap: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 16px;

        .no-layers {
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }

    }
  }
}

.sub-layer-popover {
  padding-top: 0px !important;
  top: 99px !important;

  .ant-popover-arrow {
    display: none;
  }
}