.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  cursor: pointer;
  min-width: 0;
  padding: 0px;
}
.rootdisabled {
  cursor: default;
}
.sizeLargeTypeDefaultLoadingStateNo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  border-radius: 4px;
  padding: 2px;
  border: 1px solid transparent;
}
.sizeLargeTypeDefaultLoadingStateNo > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.sizeLargeTypeDefaultLoadingStateNo > :global(.__wab_flex-container) > *,
.sizeLargeTypeDefaultLoadingStateNo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.sizeLargeTypeDefaultLoadingStateNo
  > :global(.__wab_flex-container)
  > picture
  > img,
.sizeLargeTypeDefaultLoadingStateNo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.root:active .sizeLargeTypeDefaultLoadingStateNo {
  border: 1px solid #ffd02b;
}
.rootdisabled:active .sizeLargeTypeDefaultLoadingStateNodisabled {
  border-style: none;
}
.rootloading:active .sizeLargeTypeDefaultLoadingStateNoloading {
  border-style: none;
}
.frame1000001880 {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 0px 1px #d1d5d8;
  height: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 4px;
  padding: 12px 24px;
  border-style: solid;
}
.frame1000001880 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000001880 > :global(.__wab_flex-container) > *,
.frame1000001880 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001880 > :global(.__wab_flex-container) > picture > img,
.frame1000001880
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.frame1000001880disabled {
  cursor: default;
  background: #ffffff;
}
.frame1000001880loading {
  background: #ffffffa3;
  cursor: default;
}
.root:hover .frame1000001880 {
  box-shadow: inset 0px 0px 0px 1px #ffd02b;
}
.rootdisabled:hover .frame1000001880disabled {
  box-shadow: 0px 0px 0px 1px #d1d5d8;
  border-style: none;
}
.rootloading:hover .frame1000001880loading {
  box-shadow: inset 0px 0px 0px 1px #dddddd;
}
.root:active .frame1000001880 {
  border-color: #d0d4da;
}
.rootdisabled:active .frame1000001880disabled {
  box-shadow: inset 0px 0px 0px 1px #d1d5d8;
  border-width: 1px;
  border-style: none;
}
.text {
  display: block;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: #666666;
  position: relative;
  user-select: none;
  white-space: pre;
  margin-right: 0px;
}
.textdisabled {
  color: #999999;
}
.textloading {
  color: #999999;
}
.root:active .text {
  font-size: 12px;
  line-height: 24px;
}
.lottie:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 29px;
  height: auto;
  flex-shrink: 0;
  display: none;
}
.lottieloading:global(.__wab_instance) {
  display: flex;
}
