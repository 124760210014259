.view-select-dropdown {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;

  .ant-select-selector {
    width: 90% !important;
    border: 0 !important;
  }
}

.view-select-dropdown.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none !important;
}

.sr-nearmap-loading {
  height: 42px;
  width: 238px;
  background: #ffffff;
  border-radius: 4px;
  padding: 9px 18px 9px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.take-off-breakup-popover {
  .ant-popover-inner {
    border-radius: 12px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.36);
  }

  .closable-close {
    left: 334px !important;
  }
}

#parcel-style-picker {
  width: 220px;
}

.map-editor-base-view-edit-restriction-modal {
  width: 400px !important;
  .ant-modal-content {
    border-radius: 12px !important;

    .ant-modal-body {
      padding: 0 !important;
    }
  }
}