.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 141px;
  height: auto;
  max-width: 100%;
  position: relative;
}
.root:hover {
  padding-right: 0px;
}
.text__uofzb {
  width: auto;
  height: auto;
  max-width: 120px;
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  padding-right: 2px;
}
.root:hover .text__uofzb {
  display: none;
}
.text___9Uygc {
  position: relative;
  width: 20px;
  height: auto;
  max-width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  flex-shrink: 0;
}
.root:hover .text___9Uygc {
  display: none;
}
.freeBox {
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 64px;
  height: auto;
  max-width: 100%;
  cursor: pointer;
  flex-shrink: 0;
  display: none;
}
.root:hover .freeBox {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.img {
  object-fit: cover;
  max-width: 100%;
}
.img > picture > img {
  object-fit: cover;
}
