.editable-text {
  width: max-content;
  max-width: 650px;

  .ant-input {
    border: 0;
  }

  .blue {
    color: #1055eb;
  }

  .red {
    color: #ff0000;
  }

  .error-action {
    animation: errorAnimation 0.5s;
  }

  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none;
  }

  .final-text {
    display: inline-block;
    width: max-content;
    min-width: 20px;
  }
}

@keyframes errorAnimation {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}
