.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 534px;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
}
.lottie:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 899px;
  top: 310px;
  height: 14px;
  width: 14px;
  flex-shrink: 0;
}
