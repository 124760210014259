.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  background: #ffffff;
  min-width: 0;
}
.awaitingApprovalAndApprovedButton {
  background: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  border-radius: 8px;
  padding: 8px;
}
.awaitingApprovalAndApprovedButton > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.awaitingApprovalAndApprovedButton > :global(.__wab_flex-container) > *,
.awaitingApprovalAndApprovedButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.awaitingApprovalAndApprovedButton
  > :global(.__wab_flex-container)
  > picture
  > img,
.awaitingApprovalAndApprovedButton
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.root:active .awaitingApprovalAndApprovedButton {
  background: #f9fafb;
  box-shadow: inset 0px 0px 0px 2px #ffd02b;
}
.root .awaitingApprovalAndApprovedButton:hover {
  background: #f5f5f5;
}
.frame1000001903 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
}
.frame1000001903 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000001903 > :global(.__wab_flex-container) > *,
.frame1000001903 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001903 > :global(.__wab_flex-container) > picture > img,
.frame1000001903
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.frame1000005068 {
  background: rgba(204, 250, 240, 1);
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 36px;
  padding: 8px;
}
.frame1000005068 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000005068 > :global(.__wab_flex-container) > *,
.frame1000005068 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005068 > :global(.__wab_flex-container) > picture > img,
.frame1000005068
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.svg__b0Nn {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #0d9488;
  width: 16.167px;
  height: 16px;
  display: block;
  flex-shrink: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.heading {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  color: rgba(51, 51, 51, 1);
  width: 100%;
  white-space: pre-wrap;
  min-width: 0;
}
.frame1000005306 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000005306 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000005306 > :global(.__wab_flex-container) > *,
.frame1000005306 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005306 > :global(.__wab_flex-container) > picture > img,
.frame1000005306
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000005071 {
  background: none;
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 24px;
}
.frame1000005071 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005071 > :global(.__wab_flex-container) > *,
.frame1000005071 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005071 > :global(.__wab_flex-container) > picture > img,
.frame1000005071
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__bEcBj {
  display: block;
  font-size: 10px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(219, 37, 37, 1);
  position: relative;
}
.frame1000005307 {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
}
.frame1000005307 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000005307 > :global(.__wab_flex-container) > *,
.frame1000005307 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005307 > :global(.__wab_flex-container) > picture > img,
.frame1000005307
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000005072 {
  background: #dbebff;
  width: auto;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: row;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 24px;
}
.frame1000005072 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005072 > :global(.__wab_flex-container) > *,
.frame1000005072 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005072 > :global(.__wab_flex-container) > picture > img,
.frame1000005072
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
._01 {
  display: block;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  color: #1a73e9;
  position: relative;
}
.chevron2 {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  padding: 8px;
}
.chevron2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.chevron2 > :global(.__wab_flex-container) > *,
.chevron2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.chevron2 > :global(.__wab_flex-container) > picture > img,
.chevron2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.text__zwZsX {
  display: block;
  font-size: 12px;
  text-align: center;
  font-family: "SF Pro";
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.chevron {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
}
.chevron > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.chevron > :global(.__wab_flex-container) > *,
.chevron > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.chevron > :global(.__wab_flex-container) > picture > img,
.chevron
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.svg__ieAyC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.heading2 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  color: #666666;
  white-space: pre-wrap;
  width: 100%;
  line-height: 1.3;
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
  min-width: 0;
}
.sideEffect:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
