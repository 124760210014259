.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 456px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  background: #ffffff;
}
.frame1000005740 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  min-width: 0;
}
.frame1000005740 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000005740 > :global(.__wab_flex-container) > *,
.frame1000005740 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005740 > :global(.__wab_flex-container) > picture > img,
.frame1000005740
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.noDataPana1 {
  width: 164px;
  height: 109px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.img__uaByX {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.4540000000000006px, -0.009999999999999787px);
  width: 126.87px;
  height: 78.785px;
  display: block !important;
  top: 16px;
  left: 24px;
}
.img__uaByX > picture > img {
  object-fit: cover;
}
.svg__mbXm4 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #263238;
  transform: translate(-0.12300000000000111px, -0.1740000000000066px);
  width: 126.245px;
  height: 0.113px;
  display: block;
  top: 96px;
  left: 19px;
}
.img___9BbR {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.4549999999999983px, 0.22700000000000387px);
  width: 10.395px;
  height: 13.808px;
  display: block !important;
  top: 82px;
  left: 24px;
}
.img___9BbR > picture > img {
  object-fit: cover;
}
.freepikFolderInject118 {
  transform: translate(-0.2539999999999978px, 0.030000000000001137px);
  width: 85px;
  height: 56px;
  display: block;
  position: absolute;
  top: 41px;
  left: 38px;
}
.svg___78Lh2 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ffd02b;
  transform: translate(-0.11200000000000188px, 0.42900000000000205px);
  width: 67px;
  height: 48px;
  display: block;
  top: 12.7%;
  left: 22.37%;
}
.svg__qPehy {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  opacity: 0.5;
  transform: translate(-0.11200000000000188px, 0.4299999999999997px);
  width: 66.011px;
  height: 47.491px;
  display: block;
  top: 7px;
  left: 19px;
}
.svg__qR2C3 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ffd02b;
  width: 74px;
  height: 56px;
  display: block;
  top: 0%;
  left: 0%;
}
.svg__dxZCl {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #333;
  opacity: 0.5;
  transform: translate(0.4540000000000006px, 0.26300000000000523px);
  width: 17.047px;
  height: 16.559px;
  display: block;
  top: 24px;
  left: 23px;
}
.frame1000005335 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
}
.text {
  display: block;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 100%;
  white-space: pre-wrap;
  line-height: 1.54;
  min-width: 0;
}
.buttonsMarch24 {
  background: rgba(255, 208, 42, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 208, 42, 1);
  width: auto;
  height: auto;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  display: none;
  border-radius: 4px;
  padding: 12px 24px;
}
.buttonsMarch24 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch24 > :global(.__wab_flex-container) > *,
.buttonsMarch24 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch24 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch24
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.root .buttonsMarch24:active {
  border: 1px solid #ffd02b;
}
.buttonText {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.tRprimaryButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 43px;
  width: 214px;
  flex-shrink: 0;
}
