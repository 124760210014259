.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-self: flex-start;
  min-width: 0;
}
.rootisDisabled {
  cursor: default;
}
.root:hover {
  justify-content: flex-start;
  align-items: center;
  background: #e8e8e8;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  color: #333333cc;
  font-weight: 400;
  font-size: 10px;
  text-align: left;
  min-width: 0;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  color: #3333331a;
  margin-bottom: -5px;
  box-shadow: none;
  outline: none;
  padding: 0px;
}
.pcls_1yFQWTBRYg11 {
  color: #333333cc;
  margin-bottom: -4px;
  box-shadow: none;
  outline: none;
  padding: 0px;
}
.checkboxWithText2 {
  background: rgba(255, 255, 255, 1);
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 5px 8px;
}
.checkboxWithText2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.checkboxWithText2 > :global(.__wab_flex-container) > *,
.checkboxWithText2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.checkboxWithText2 > :global(.__wab_flex-container) > picture > img,
.checkboxWithText2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.checkboxWithText2isDisabled {
  pointer-events: none;
}
.root:hover .checkboxWithText2 {
  background: #e8e8e8;
  height: 100%;
  min-height: 0;
  padding: 5px 8px;
}
.root:hover .checkboxWithText2 > :global(.__wab_flex-container) {
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 0;
}
.freeBox__fTiPa {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
}
.freeBox__fTiPa > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 5px);
  width: calc(100% + 5px);
}
.freeBox__fTiPa > :global(.__wab_flex-container) > *,
.freeBox__fTiPa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fTiPa > :global(.__wab_flex-container) > picture > img,
.freeBox__fTiPa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5px;
}
.freeBox__fqJjW {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 3px;
  min-width: 0;
}
.freeBox__fqJjW > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__fqJjW > :global(.__wab_flex-container) > *,
.freeBox__fqJjW > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fqJjW > :global(.__wab_flex-container) > picture > img,
.freeBox__fqJjW
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.root:hover .freeBox__fqJjW > :global(.__wab_flex-container) {
  justify-content: center;
  align-items: center;
}
.freeBox__hKlvu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 16px;
  max-width: 100%;
  padding-bottom: 0px;
  min-width: 0;
}
.root:hover .freeBox__hKlvu {
  height: 16px;
}
.tooltip__foTxs:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  white-space: pre-wrap;
  margin-bottom: 0px;
}
.emCheckBox:global(.__wab_instance) {
  max-width: 100%;
  width: 16px;
  height: 16px;
}
.tooltip__nfoay:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.img {
  object-fit: cover;
  max-width: 100%;
  object-position: 50% 50%;
  width: 26px;
}
.img > picture > img {
  object-fit: cover;
  object-position: 50% 50%;
}
.freeBox__nl7G {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  max-width: 100%;
  min-height: 0;
}
.frame1000006024 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 0.5px rgba(221, 221, 221, 1);
  width: 26px;
  height: 16px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 42.187px;
  padding: 3.375px 7.875px;
}
.frame1000006024 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 9px);
  height: calc(100% + 9px);
}
.frame1000006024 > :global(.__wab_flex-container) > *,
.frame1000006024 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006024 > :global(.__wab_flex-container) > picture > img,
.frame1000006024
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 9px;
}
.text__uiZbZ {
  display: block;
  font-size: 9px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 13.5px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: auto;
  max-width: 20px;
}
.freeBox__cdThv {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  padding: 2px 0px 0px;
}
.freeBox__cdThv > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
  margin-left: calc(0px - 5px);
  width: calc(100% + 5px);
  margin-top: calc(0px - 3px);
  height: calc(100% + 3px);
}
.freeBox__cdThv > :global(.__wab_flex-container) > *,
.freeBox__cdThv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__cdThv > :global(.__wab_flex-container) > picture > img,
.freeBox__cdThv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5px;
  margin-top: 3px;
}
.freeBoxisDisabled__cdThvCpsbA {
  cursor: default;
  pointer-events: none;
}
.root:hover .freeBox__cdThv {
  height: auto;
}
.root:hover .freeBox__cdThv > :global(.__wab_flex-container) {
  justify-content: flex-start;
  align-content: center;
}
.freeBox__du6Tx {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-shrink: 1;
  width: auto;
  padding: 0px;
}
.mowing322 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  color: #333333;
  white-space: pre-wrap;
  line-height: 1.5;
  flex-shrink: 1;
  flex-grow: 0;
  width: auto;
}
.mowing322isDisabled {
  color: #999999;
}
.frame1000001994 {
  background: rgba(244, 244, 244, 1);
  width: 45px;
  height: 16px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 2px 8px;
}
.frame1000001994 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000001994 > :global(.__wab_flex-container) > *,
.frame1000001994 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001994 > :global(.__wab_flex-container) > picture > img,
.frame1000001994
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.frame1000001994isDisabled {
  background: #f5f5f5;
}
.text___1Bdht {
  display: block;
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 45px;
  height: 15px;
  text-align: center;
  line-height: 2;
  flex-shrink: 0;
}
.textisDisabled___1BdhtCpsbA {
  color: #999999;
}
.freeBox__bb3Q {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  align-content: center;
  min-height: 0;
  display: none;
}
.root:hover .freeBox__bb3Q {
  display: none;
}
._22Assigned4 {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(102, 102, 102, 1);
  white-space: pre;
  display: none;
}
._22Assigned4isDisabled {
  color: #999999;
}
.root:hover ._22Assigned4 {
  display: block;
}
.freeBox___4P3I0 {
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  display: none;
}
.freeBox___4P3I0 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox___4P3I0 > :global(.__wab_flex-container) > *,
.freeBox___4P3I0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___4P3I0 > :global(.__wab_flex-container) > picture > img,
.freeBox___4P3I0
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.freeBox__tRjyM {
  position: relative;
  width: auto;
  flex-direction: row;
  display: none;
  padding: 0px;
}
.tooltipContent:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
