.partner-container {
  position: absolute;
  left: 50%;
  bottom: 22px;
  z-index: 3;
  width: 336px;

  .partner-card {
    border-radius: 24px;

    .ant-card-body {
      padding: 2px 4px;
    }
    .text-center {
      text-align: center;
    }
  }
}
