/** @format */

@input-height-base: @height-base;
@input-height-lg: @height-lg;
@input-height-sm: @height-sm;
@input-padding-horizontal: @control-padding-horizontal - 1px;
@input-padding-horizontal-base: @input-padding-horizontal;
@input-padding-horizontal-sm: @control-padding-horizontal-sm - 1px;
@input-padding-horizontal-lg: @input-padding-horizontal;
@input-padding-vertical-base: max(round((@input-height-base - @font-size-base * @line-height-base) / 2 * 10) / 10 - @border-width-base,
    3px);
@input-padding-vertical-sm: max(round((@input-height-sm - @font-size-base * @line-height-base) / 2 * 10) / 10 - @border-width-base,
    0);
@input-padding-vertical-lg: ceil((@input-height-lg - @font-size-lg * @line-height-base) / 2 * 10) / 10 - @border-width-base;
@input-placeholder-color: hsv(0, 0, 75%);
@input-color: @text-color;
@input-icon-color: @input-color;
@input-border-color: @border-color-base;
@input-bg: @component-background;
@input-number-hover-border-color: @input-hover-border-color;
@input-number-handler-active-bg: #f4f4f4;
@input-number-handler-hover-bg: @primary-5;
@input-number-handler-bg: @component-background;
@input-number-handler-border-color: @border-color-base;
@input-addon-bg: @background-color-light;
@input-hover-border-color: @primary-5;
@input-disabled-bg: @disabled-bg;
@input-outline-offset: 0 0;
@input-icon-hover-color: fade(@black, 85%);
@input-disabled-color: @disabled-color;

// /*============================OVERRIDES============================*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

::placeholder,
.ant-select-selection-placeholder {
  font-family: 'Poppins';
  font-style: italic;
  color: @text-secondary-color  !important;
  opacity: 1;
}