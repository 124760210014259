@import '../../../styles/base/colors.less';

@tabletScreen: 1200px;
@mobileScreen: 600px;

#navbar {
  width          : 100%;
  height         : 60px;
  max-width      : 1920px;
  margin         : 0px auto;
  border-bottom  : 1px solid rgba(75, 44, 44, 0.08);
  background     : #ffffff;
  padding        : 0 40px;
  filter         : drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08));
  display        : flex;
  justify-content: space-between;
  align-items    : center;
  position       : relative;

  .ant-popover {
    width : 243px;
    height: 304px;
  }

  .wrapper {
    display        : flex;
    justify-content: space-between;
    align-items    : center;

    .ant-menu-horizontal {
      line-height: 33px;
    }

    .myproperty,
    .invite-user {
      margin-left: 34px !important;
    }
  }


  .wrapper-first {
    display    : flex;
    align-items: center;

    .ant-menu-horizontal {
      line-height: 33px;
    }

    .myproperty,
    .invite-user {
      margin-left: 34px !important;
    }
  }

  .ant-btn {
    height: 37px;
  }

  .navbar-logo-wrapper {
    padding: 2px 0px;

    .navbar-logo {
      height: 100%;
    }
  }

  .new-property-button {
    padding: 10px;
  }

  .profile-logo {
    margin-left: 40px;
  }
}

.myproperty {
  border-bottom: 2px solid #ffd02b !important;
}

.download-app-popup {
  top: 58px !important;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 8px;

    .ant-card {
      border-radius: 8px;
    }
  }

  .app-announcement-bottom-row {
    background-color: #ffd02b;
    border-radius   : 0px 0px 8px 8px;
    margin-top      : 1.65rem;
    padding-top     : 1.5rem;
    text-align      : center;
  }
}

// Download App Popup Arrow
.download-app-popup.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow {
  left: 1.4375rem !important;
}

.download-app-popup {
  .ant-card-body {
    padding: 0;
  }

  .ant-dropdown-menu-item {
    cursor : auto;
    padding: 0;
  }

  .ant-card {
    border     : none;
    white-space: normal;
  }

  .ant-col {
    ul {
      list-style-type: disc;
    }
  }
}

// Download App CTA Brand Name
#download-app-cta-brand-name {
  background-color       : #ff7700;
  background-size        : 100%;
  background-clip        : text;
  -webkit-background-clip: text;
  -moz-background-clip   : text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color   : transparent;
}

.ant-modal {
  .ant-upload {
    height: 25px;
  }

  .ant-upload-list {
    height       : 30px;
    margin-left  : 30px;
    margin-bottom: 10px;
    margin-top   : 8px;
  }

  .ant-upload-list-item {
    height: 15px;
  }
}


.ant-menu-item {
  font-size  : 14px;
  font-weight: 400;
  color      : #617288;
  height     : 32px;
}

.ant-menu-item-selected {
  font-size  : 16px !important;
  font-weight: 500;
  color      : #333333;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item {

  &:last-child,
  &:nth-last-child(2n) {
    margin: 0 0 0 20px;
  }
}

.notification-container-class {
  max-height: 50vh;
  overflow-y: scroll;
  width     : 300px;
  padding   : 12px 22px;

  .notification-header {
    width          : 100%;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
  }
}

a.notification-item {
  border-bottom  : 1px solid #ccc;
  padding        : 17px 0px 12px;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : flex-start;
  background     : #ffffff;

  &:hover {
    background: #f1f1f1;
  }

  &.read {
    background: #ffffff;
  }

  p.notification-title {
    padding    : 0;
    margin     : 0;
    font-size  : 13px;
    line-height: 16px;
    font-weight: 400;
    color      : #333333;
    position   : relative;

    &.unread {
      font-weight: 600;

      &::before {
        position : absolute;
        content  : '•';
        font-size: 16px;
        color    : #ffd02b;
        left     : -12px;
      }
    }
  }

  span.notification-subtext {
    padding    : 2.5px 0;
    margin     : 0;
    font-size  : 13px;
    color      : #617288;
    font-weight: 300;
  }
}

.new-notification {
  padding        : 4px;
  font-weight    : 400;
  font-family    : 'Poppins';
  position       : absolute;
  top            : -6px;
  left           : 8px;
  display        : flex;
  align-items    : center;
  justify-content: center;
  font-size      : 10px;
  height         : 15px;
  border-radius  : 50%;
  color          : #fff;
  background     : #ff000f !important;
  border-radius  : 50%;
  margin-left    : -2px;
  margin-top     : 6px;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

.my-property-style {
  font-size  : 16px;
  line-height: 30px;
}

.dashboards-style {
  font-weight: 500;
  font-size  : 16px;
  line-height: 24px;
}

.home-property-style {
  margin-left: 6px;
}

.profile-data-name {
  font-size  : 14px;
  line-height: 20px;
  color      : #000000;
  word-wrap  : break-word;
}

.profile-data-organisation {
  font-weight  : 300;
  font-size    : 12px;
  line-height  : 18px;
  color        : #999999;
  width        : 135px;
  overflow     : hidden !important;
  text-overflow: ellipsis;
}

.dropdown-link-items {
  width        : 100%;
  border-bottom: 1px solid rgba(153, 153, 153, 0.5);
}

.dropdown-signOut {
  width: 100%;
}


.profile-picture-wrapper {
  overflow      : hidden;
  border-radius : 50%;
  width         : 60px;
  height        : 36px;
  text-align    : center;
  vertical-align: middle;

  .avatar-image {
    max-width     : 100%;
    max-height    : 100%;
    vertical-align: middle;
    object-fit    : contain;
  }
}

#navbar-create {
  cursor          : default;
  width           : 100%;
  height          : 50px;
  z-index         : 10;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  background      : #f9fafb;
  position        : fixed;
  justify-content : space-between;
  display         : flex;
  flex-direction  : row;
  align-items     : center;
  padding         : 2px 12px 2px 0px;

  .ant-btn:hover,
  .ant-btn:focus {
    background   : #ffffff !important;
    // border: 0.6px solid #ffd02b !important;
    border-radius: 4px !important;
  }

  .ant-btn:active {
    transform     : scale(0.9) !important;
    border        : 1px solid #d1d5db !important;
    outline       : 1px solid #ffd02b !important;
    outline-offset: 2px !important;
  }

  .ant-btn-primary {
    background-image: none !important;
    background-color: #ffd02b !important;
    font-style      : 'Poppins';
    font-weight     : 500;
    color           : #333333;
    line-height     : 21px;
    padding         : 0px 24px;
    border          : none !important;
    border-radius   : 4px;
    transition      : all 0.25s ease-in-out;
    height          : 32px;

    &:focus {
      background: @main-color !important;
      color     : #333333 !important;
      border    : none !important;
    }

    &:hover {
      background: #ffe06f !important;
      // border: 1px solid #ffd02b !important;
    }

    &:active {
      background-color: #ffe06f !important;
      transform       : scale(0.9) !important;
      outline         : 2px solid #ffe06f !important;
      outline-offset  : 2px !important;
    }
  }

  .icon-address {
    display       : flex;
    flex-direction: row;
    align-items   : center;
    padding       : 0px 0px 0px 18px;
    height        : 100%;

    .address {
      display       : flex;
      flex-direction: row;
      height        : 36px;
      margin-right  : 16px;
    }

    .add-info {
      display: flex;

      .overflow {
        width        : fit-content;
        overflow     : hidden !important;
        text-overflow: ellipsis;
        white-space  : nowrap;
      }
    }
  }

  .tool-container {
    width          : 36px;
    height         : 36px;
    border-radius  : 43px;
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-right   : 4px;
  }

  .disabled-tool-container {
    width          : 36px;
    height         : 36px;
    border-radius  : 43px;
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-right   : 4px;
  }

  .tool-container:hover {
    background: #e9e9e9;
  }

  .tool-container:active {
    background: #e9e9e9;
    border    : 1px solid #c9c9c9;
  }

  .disable-back-button {
    pointer-events: none;
    opacity       : 0.3;
  }

  .secondary-icons {
    margin-left: 4px;
    cursor     : pointer;
  }

  .small-logo {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    padding        : 7px;

    p {
      margin: 0;
    }
  }

  .order-text {
    padding    : 0 10px;
    font-size  : 16px;
    font-weight: 500;
  }

  .share-btn {
    display       : flex;
    flex-direction: row;
    align-items   : center;
    padding       : 4px 12px;
    gap           : 4px;
    width         : 59px;
    height        : 28px;
  }

  .help-btn {
    width          : 36px;
    height         : 36px;
    display        : flex;
    justify-content: center;
    align-items    : center;
    border-radius  : 43px;
  }

  .help-btn:hover {
    background: #e9e9e9;
  }

  .help-btn:active {
    background: #e9e9e9;
    border    : 1px solid #c9c9c9;
  }

  .new-notification-nav {
    padding        : 4px;
    font-weight    : 400;
    font-family    : 'Poppins';
    position       : absolute;
    display        : flex;
    align-items    : center;
    justify-content: center;
    font-size      : 10px;
    height         : 15px;
    border-radius  : 50%;
    color          : #fff;
    background     : #ff000f !important;
    border-radius  : 50%;
    margin-left    : 10px;
    margin-top     : -29px;
  }

  .spin-container-style {
    position       : absolute;
    z-index        : 100;
    background     : rgba(0, 0, 0, 0.11);
    width          : 100%;
    height         : 100vh;
    display        : flex;
    justify-content: center;
    padding-top    : 21%;
    top            : 0;
  }
}

.announcement-modal {
  .ant-modal-body {
    padding      : 0px;
    border-radius: 8px;
  }

  .ant-modal-content {
    border-radius: 8px;
    height       : 650px;
  }

  .ant-modal-close {
    top  : 10px;
    right: 7px;
  }
}

.ant-badge-status-override {
  line-height: normal !important;

  .ant-badge-dot {
    top  : 2px;
    right: 3px;
  }
}

.ant-badge-status-override-display {
  display: none;
}


.notification-popover.ant-popover-placement-bottomRight {
  left: 1001px !important;
}

@media screen and (max-width: @mobileScreen) {
  .navbar-logo {
    position: relative;
    right   : 55px;
  }
}

@media screen and (max-width: 1375px) {
  #navbar {
    padding: 0 40px;
  }
}

@media screen and (max-width: 1024px) {
  #navbar {
    padding: 0 24px;
  }
}

@media screen and (max-width: 768px) {
  #navbar {
    padding: 0 16px;
  }
}

.partner-nav-container {
  display        : flex;
  align-content  : center;
  justify-content: center;
  align-items    : center;
  cursor         : pointer;
}

.ant-image-preview-mask {
  background-color: rgba(51, 51, 51, 0.75) !important;
  z-index         : 1080 !important;
}

.ant-image-preview-img {
  z-index: 1100;
}

#plato-preview-modal-div {

  .ant-image-preview-operations-operation:nth-child(4),
  .ant-image-preview-operations-operation:nth-child(5) {
    display       : none;
    pointer-events: none;
  }
}

.email-verify-wrapper {
  .ant-modal-content {
    border-radius: 16px;
  }

  .email-title {
    font-size  : 24px;
    font-weight: 600;
    line-height: 36px;
    text-align : center;
    color      : #333333;
  }

  .email-text {
    font-size      : 16px;
    font-weight    : 600;
    font-size      : 16px;
    line-height    : 150%;
    text-align     : center !important;
    display        : flex;
    justify-content: center;
  }

  .ant-modal-body {
    width          : auto;
    height         : auto;
    background     : #ffffff;
    box-shadow     : 0px 0px 0px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(54px);
    border-radius  : 16px;
    padding        : 1rem 3rem;

    .email-verify-text {
      margin-top   : 1rem;
      font-size    : 16px;
      line-height  : 24px;
      display      : flex;
      text-align   : center !important;
      margin-bottom: 2rem;
      text-align   : center;
      width        : 96%;
    }

    .email-otp {
      max-height: 3rem;
    }
  }
}

#event-success-modal-div {
  .ant-modal-mask {
    z-index: 1100;
  }

  .ant-modal-wrap {
    z-index: 1100;
  }
}

.ant-menu a.ant-dropdown-link.ant-dropdown-link-gray {
  color: #617288;
}

.ant-menu .ant-menu-item:hover {
  a.ant-dropdown-link.ant-dropdown-link-gray {
    color: #000000;
  }
}

.property-info-popup {
  display       : flex;
  flex-direction: column;
  align-items   : flex-start;
  padding       : 24px;
  gap           : 15px;
  width         : 466px;
  background    : #ffffff;
  box-shadow    : 0px 0px 12px rgba(0, 0, 0, 0.2);
  border-radius : 16px;

  .ant-btn:hover,
  .ant-btn:focus {
    background   : #ffffff !important;
    // border: 0.6px solid #ffd02b !important;
    border-radius: 4px !important;
  }

  .ant-btn:active {
    transform     : scale(0.9) !important;
    border        : 1px solid #d1d5db !important;
    outline       : 1px solid #ffd02b !important;
    outline-offset: 2px !important;
  }

  .ant-btn-primary {
    background-image: none !important;
    background-color: #ffd02b !important;
    font-style      : 'Poppins';
    font-weight     : 500;
    color           : #333333;
    line-height     : 21px;
    padding         : 0px 24px;
    border          : none !important;
    border-radius   : 4px;
    transition      : all 0.25s ease-in-out;
    height          : 32px;

    &:focus {
      background: @main-color !important;
      color     : #333333 !important;
      border    : none !important;
    }

    &:hover {
      background: #ffe06f !important;
      // border: 1px solid #ffd02b !important;
    }

    &:active {
      background-color: #ffe06f !important;
      transform       : scale(0.9) !important;
      outline         : 2px solid #ffe06f !important;
      outline-offset  : 2px !important;
    }
  }

  Row {
    width: 100%;
  }

  .header {
    display       : flex;
    flex-direction: column;
  }

  .info-item {
    display       : flex;
    width         : 100%;
    flex-direction: column;

    .info-input {
      border       : 1px solid #d1d5db;
      height       : 40px;
      border-radius: 4px;
      width        : 100%;
    }
  }

  .property-info-form {
    width         : 100%;
    display       : flex;
    flex-direction: column;
  }

  .action-button {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    padding        : 9px 24px;
    gap            : 10px;

    width : 106px;
    height: 42px;
  }
}

.overlay-property {
  border-radius: 16px;
}

.add-info {
  display  : flex;
  max-width: 200px;

  .overflow {
    width        : fit-content;
    overflow     : hidden !important;
    text-overflow: ellipsis;
    white-space  : nowrap;
  }
}

.beta-navbar {
  padding      : 2px 4px;
  border-radius: 2px;
  background   : #FFD02B;
}