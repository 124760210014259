.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.statusDraftStateDefault {
  width: auto;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  border-radius: 70px;
  padding: 6px;
}
.statusDraftStateDefault > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.statusDraftStateDefault > :global(.__wab_flex-container) > *,
.statusDraftStateDefault
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.statusDraftStateDefault > :global(.__wab_flex-container) > picture > img,
.statusDraftStateDefault
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_1AvuKvs109zX {
  outline: none;
  padding: 0px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 24px;
}
.img > picture > img {
  object-fit: cover;
}
.tooltipContent:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
