@px: 16rem;

.queue-card {
  width: 290 / @px;
  min-height: 95 / @px;
  margin: 0 auto 14 / @px auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  .queue-card-inner {
    padding: 8 / @px 12 / @px;
    .left-container {
      width: 75%;
      p {
        margin-bottom: 4px;
      }
      .order-id {
        background: #fffae8;
        display: inline-block;
        padding: 0 8px;
        font-weight: 500;
        font-size: 10px;
        line-height: 18px;
        color: #666666;
        span {
          color: #333333;
        }
      }
      .address {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .place-name {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #666666;
      }
      .flex-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 200px;
        .created-by {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #666666;
          padding-right: 6px;
          span {
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            color: #333333;
          }
        }
        .dot {
          height: 3px;
          width: 3px;
          background-color: #ad9393;
          border-radius: 50%;
          display: inline-block;
        }
        .order-time {
          padding-left: 6px;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #666666;
          span {
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            color: #333333;
          }
        }
      }
    }
    .right-container {
      width: 25%;
      position: relative;
      left: 8 / @px;
      .icon-container {
        text-align: center;
        height: 32px;
      }
      .time-container {
        display: flex;
        justify-content: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #333333;
      }
    }
  }
  .delayed-section {
    padding: rem(8) rem(12);
    p {
      margin-bottom: rem(0);
    }
  }
}
