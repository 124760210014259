.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 141px;
  height: auto;
  max-width: 100%;
  position: relative;
}
.rootopenServiceItemColumn {
  width: 136px;
}
.rootisActive {
  border-right-color: #dbdbdb;
}
.text__w8Xko {
  width: auto;
  height: auto;
  max-width: 100%;
  text-align: right;
  padding-right: 2px;
  font-weight: 500;
  font-size: 12px;
}
.root:hover .text__w8Xko {
  display: none;
}
.text__avGz0 {
  position: relative;
  width: 20px;
  height: auto;
  max-width: 100%;
  text-align: left;
  padding-right: 0px;
  font-weight: 500;
  font-size: 12px;
  flex-shrink: 0;
}
.root:hover .text__avGz0 {
  display: none;
}
.freeBox {
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 64px;
  height: auto;
  max-width: 100%;
  cursor: pointer;
  flex-shrink: 0;
  display: none;
}
.root:hover .freeBox {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 64px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
