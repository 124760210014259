@import '/src/styles/base/variables/index';

each(@ui-colors, {
  .color--@{key} {
    color: @value !important;
  }

  .bg-color--@{key} {
    background-color: @value !important;
  }
});

.body-bg-color {
  background: @neutral-25 !important;
}
