@import '/src/styles/base/colors';
@import '/src/styles/base/variables/index';

.tool--snap-nav {
  border          : 0.1px solid #E5E5E5;
  border-radius   : 8px;
  background-color: #f9fafb;
  cursor          : pointer;
  display         : flex;
  align-items     : center;
  padding         : 8px;

  &:hover {
    background-color: #e9e9e9
  }
}

.tool--navbar {
  width          : 36px;
  height         : 36px;
  border-radius  : 43px;
  display        : flex;
  align-items    : center;
  justify-content: center;
  cursor         : pointer;
  margin-right   : 4px;
}

.tool--navbar:hover {
  background: #e9e9e9;
}

.tool--navbar:active {
  background: #e9e9e9;
  border    : 1px solid #c9c9c9;
}

.tool--export-popover {
  .ant-btn>.ant-btn-loading-icon .anticon {
    animation: loadingCircle 1s infinite linear;
  }

  .ant-popover-content {
    .ant-popover-inner {
      border-radius: @border-radius-base;
    }

    button.ant-btn-link {
      font-size  : 12px;
      color      : @text-primary-color;
      min-width  : 176px;
      height     : 40px;
      display    : flex;
      align-items: center;

      svg {
        margin-left: 16px;
      }
    }
  }

  .export-menu-item :hover {
    background-color: #f3f4f6;
  }
}

.snap-container {
  width          : 36px;
  height         : 36px;
  border-radius  : 43px;
  display        : flex;
  align-items    : center;
  justify-content: center;
  margin-right   : 4px;
}

.snap-container:hover {
  background: #e9e9e9;
}

.snap-container:active {
  background: #e9e9e9;
  border    : 1px solid #c9c9c9;
}

.export-aspire-modal {
  .ant-modal-content {
    border-radius: 8px !important;
  }
}

.add-tags-section {
  .add-property-tags {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    padding        : 3px 4px 3px 4px;
    width          : 98px;
    height         : 24px;
    border         : 1px dashed #999999;
    border-radius  : 30px;
  }

  .added-tag {
    margin-right  : 4px;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    padding       : 3px 8px;
    max-width     : 82px;
    height        : 24px;
    background    : #ffffff;
    border        : 1px solid #d9d9d9;
    border-radius : 30px;

    .overflow {
      white-space  : nowrap;
      overflow     : hidden;
      text-overflow: ellipsis;
    }
  }

  .remaining-tags {
    margin-right  : 8px;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    padding       : 3px 4px;
    min-width     : 24px;
    height        : 24px;
    background    : #e8e8e8;
    border-radius : 30px;
    cursor        : pointer;
  }

  .add-plus {
    width          : 24px;
    height         : 24px;
    background     : #f9fafb;
    border         : 1.2px dashed #999999;
    border-radius  : 30px;
    display        : flex;
    justify-content: center;
    align-items    : center;
  }
}

.sr-header-view-tags {
  display       : flex;
  flex-direction: column;
  align-items   : flex-end;
  padding       : 16px;
  gap           : 16px;
  width         : 349px;

  .ant-popover-content {
    width : 100%;
    height: 100%;

    .ant-popover-inner {
      height       : 100%;
      box-shadow   : 0px 0px 12px rgb(0 0 0 / 20%);
      border-radius: 12px;

      .ant-popover-inner-content {
        padding: 16px !important;
      }
    }
  }

  .tag-container {
    display       : flex;
    flex-direction: row;
    align-items   : center;
    padding       : 3.5px 12px;
    max-width     : 99px;
    height        : 24px;
    border        : 1px solid #ffd02b;
    border-radius : 41px;

    .overflow {
      white-space  : nowrap;
      overflow     : hidden;
      text-overflow: ellipsis;
    }
  }
}

.sr-header-add-tags {
  display       : flex;
  flex-direction: column;
  align-items   : flex-end;
  padding       : 16px;
  gap           : 16px;
  width         : 481px;
  background    : #ffffff;
  box-shadow    : 0px 0px 12px rgba(0, 0, 0, 0.2);
  border-radius : 12px;

  .ant-btn:hover,
  .ant-btn:focus {
    background   : #ffffff !important;
    border       : 0.6px solid #ffd02b !important;
    border-radius: 4px !important;
  }

  .ant-btn:active {
    transform     : scale(0.9) !important;
    border        : 1px solid #d1d5db !important;
    outline       : 1px solid #ffd02b !important;
    outline-offset: 2px !important;
  }

  .ant-btn-primary {
    background-image: none !important;
    background-color: #ffd02b !important;
    font-style      : 'Poppins';
    font-weight     : 500;
    color           : #333333;
    line-height     : 21px;
    padding         : 0px 24px;
    border          : none !important;
    border-radius   : 4px;
    transition      : all 0.25s ease-in-out;
    height          : 32px;

    &:focus {
      background: @main-color  !important;
      color     : #333333 !important;
      border    : none !important;
    }

    &:hover {
      background: #ffe06f !important;
      border    : 1px solid #ffd02b !important;
    }

    &:active {
      background-color: #ffe06f !important;
      transform       : scale(0.9) !important;
      outline         : 2px solid #ffe06f !important;
      outline-offset  : 2px !important;
    }
  }

  .row {
    width: 100%;

    .addTagModal__select {
      display       : flex;
      flex-direction: row;
      align-items   : flex-start;
      padding       : 10px;
      background    : #ffffff;
      border        : 1px solid #d1d5db;
      border-radius : 8px;

      .ant-select-selector {
        width : 100%;
        border: none;
      }

      .ant-select-selector {
        border-radius: 4px;

        .ant-select-selection-item {
          display       : flex;
          flex-direction: row;
          align-items   : center;
          padding       : 4px 12px;
          gap           : 4px;

          height: 28px;

          background   : #ffffff;
          border       : 1px solid #ffd02b;
          border-radius: 41px;
        }
      }
    }
  }

  .suggested-row {
    width         : 100%;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    padding       : 0px;
    gap           : 8px;
    height        : 24px;

    .suggested-tag {
      cursor        : pointer;
      display       : flex;
      flex-direction: row;
      align-items   : center;
      padding       : 3.5px 10px;
      gap           : 10px;
      max-width     : 80px;
      height        : 24px;
      background    : #eaeaea;
      border-radius : 29px;

      .overflow {
        white-space  : nowrap;
        overflow     : hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ant-btn {
    &.disabled {
      opacity: 1;
    }
  }
}