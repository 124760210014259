#feature-hover-panel {
  width: 100%;
  background: #ffffff;
  height: 100%;

  .feature-hover-panel-header {
    padding: 20px 12px 10px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
    }
  }

  .feature-hover-panel-body {
    height: calc(100vh - 74px);
    padding: 10px 0px;

    .feature-table {
      border-top: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      margin-top: 5px;
    }

    .ft-head {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex: 1 1 auto;
      font-size: 14px;
    }

    .ft-head-left {
      border-left: 1px solid #dddddd;
      padding: 5px;
      height: 65px;
      width: 40px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ft-head-mid {
      border-left: 1px solid #dddddd;
      padding: 5px;
      height: 65px;
      width: 45px;
      font-weight: bold;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ft-head-right {
      border-left: 1px solid #dddddd;
      padding: 5px;
      height: 65px;
      font-weight: bold;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }

    .ant-checkbox-group {
      width: 100% !important;
    }

    .ft-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 13px;
    }

    .ft-body-row,
    .ft-foot {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      border-top: 1px solid #dddddd;
    }

    .ft-body-row:hover {
      background-color: #dddddd;
    }

    .ft-body-left,
    .ft-foot-left {
      height: 50px;
      font-size: 13px;
      display: flex;
      padding: 5px;
      width: 40px;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #dddddd;
    }

    .ft-body-mid,
    .ft-foot-mid {
      height: 50px;
      font-size: 13px;
      width: 45px;
      display: flex;
      padding: 5px;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #dddddd;
    }

    .ft-body-right,
    .ft-foot-right {
      height: 50px;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #dddddd;
      padding: 5px;
      flex-grow: 1;
    }

    .factor-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: baseline;
      border: 3px solid #f0f0f0;
      padding: 20px 0;
      margin-top: 10px;
      position: absolute;
      left: 8px;
      bottom: 1px;
      width: 260px;
    }

    .factor-box input {
      width: 80px;
      margin: 0 5px 0 5px;
    }
  }

  .order-id-drop-tour {
    width: 100%;
    margin-left: 0px;
  }
}

.modal-class {
  .add-layer {
    display: flex;
    flex-direction: column;

    p {
      font-weight: 500;
      padding: 0px;
      color: #333333;
    }
  }

  .delete-modal {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 25vh;
  }
}

.viewClass {
  text-decoration: underline;
  color: blue;
  font-size: 12px;
}

.drop-select-class {
  .ant-select-item-option {
    border-bottom: 1px solid rgba(153, 153, 153, 0.3);
  }

  .ant-select-dropdown-placement-bottomLeft {
    min-width: unset !important;
    width: 260px !important;
  }
}

// .layers-icon-view-wrapper {
//   overflow: hidden;
//   position: absolute;
//   z-index: 0;
//   top: 93px;
//   left: 0;

//   .layers-icon-text {
//     transform: rotate(-90deg);
//     margin-top: 33px;
//     margin-bottom: 27px;
//   }

//   .layer-open-icon-panel {
//     margin-bottom: 18px;
//   }
// }

.notes-icon-view-wrapper {
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  background-image: url('../../../assets/notes/note-chip-bg.svg');
  background-repeat: no-repeat;

  .close-icon-panel {
    margin-top: 18px;
  }

  .notes-icon-text {
    transform: rotate(-90deg);
    margin-top: 27px;
    margin-bottom: 33px;
  }
}