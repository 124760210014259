@import '/src/styles/base/variables/index';

.sr-table.sr-table-default {
  .ant-table-container {
    border: 1px solid @neutral-200;
    border-radius: @border-radius-lg;
  }

  .ant-table-cell {
    font-weight: 500;
    color: @neutral-700;
  }

  .ant-table-thead {
    tr {
      .ant-table-cell {
        background-color: #fffbed;
        color: @neutral-600;
        padding-top: rem(12);
        padding-bottom: rem(12);

        &:first-child {
          border-top-left-radius: @border-radius-lg;
        }

        &:last-child {
          border-top-right-radius: @border-radius-lg;
        }
      }
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      &:last-child {
        .ant-table-cell {
          &:first-child {
            border-bottom-left-radius: @border-radius-lg;
          }

          &:last-child {
            border-bottom-right-radius: @border-radius-lg;
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border: solid 3px transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: solid 3px transparent;
  }
}
