.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
}
.embedHtml:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
