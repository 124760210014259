// note- import colors from sr-styles

.Toastify {
    .Toastify__toast-container {
        padding: 0;
        width: auto;
        height: auto;

        .Toastify__toast {
            padding: 0;
            min-height: auto;
            border-radius: 4px 0px 0px 4px;

            .Toastify__toast-body {
                padding: 0;
                margin: 0;
            }
        }
    }

    .Toastify__toast-container--top-right {
        right: 0;
    }
}

.toast-container {
    width: 306px;
    min-height: 52px;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    align-items: center;

    span {
        display: block;
    }

    &.success-toast {
        background: #ECFDF5;
        border-left: 6px solid #34D399;

        span {
            color: #065F46;
        }
    }

    &.error-toast {
        background: #FEF2F2;
        border-left: 6px solid #F87171;

        span {
            color: #991B1B;
        }
    }

    &.warning-toast {
        background: #FFFBEB;
        border-left: 6px solid #FBBF24;

        span {
            color: #B45309;
        }
    }
}