.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
  border-top: 0.5px solid #ececec;
  border-bottom: 0.5px solid #ececec;
}
.frame1000006004 {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  padding: 8px 4px 8px 8px;
}
.frame1000005955 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000005955 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000005955 > :global(.__wab_flex-container) > *,
.frame1000005955 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005955 > :global(.__wab_flex-container) > picture > img,
.frame1000005955
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.freeBox {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: 0px;
  top: -3px;
  background: #3333331a;
  z-index: 100;
  min-width: 0;
  min-height: 0;
  display: none;
}
.frame1000005953 {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  position: relative;
  height: auto;
  max-height: 108px;
  width: 100%;
  min-width: 0;
}
.frame1000005953 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000005953 > :global(.__wab_flex-container) > *,
.frame1000005953 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005953 > :global(.__wab_flex-container) > picture > img,
.frame1000005953
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
  margin-top: 4px;
}
.emActionCenterAssignedServiceItemsChip:global(.__wab_instance) {
  max-width: 48%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.frame1000006007 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  min-width: 0;
}
.frame1000006007 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000006007 > :global(.__wab_flex-container) > *,
.frame1000006007 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006007 > :global(.__wab_flex-container) > picture > img,
.frame1000006007
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.text__h8Hj1 {
  display: block;
  flex-grow: 0;
  font-size: 10px;
  text-decoration-line: underline;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(15, 84, 234, 1);
  position: relative;
  width: auto;
  flex-shrink: 0;
  cursor: pointer;
}
.text__kci1G {
  display: block;
  flex-grow: 0;
  font-size: 10px;
  text-decoration-line: underline;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(15, 84, 234, 1);
  position: relative;
  cursor: pointer;
}
