@import '../../../style.less';

.undo-redo-section {
  gap: 4px;
  button[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .flip-section {
    transform: scaleX(-1) !important;
  }

  .icon-button {
    width: 24px !important;
    height: 24px !important;
    border-radius: 24px !important;

    &:hover {
      background: #e9e9e9 !important;
    }
    &:active {
      border: 1px solid #c9c9c9;
    }
  }
}
