.root {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 44px;
  max-width: 100%;
  justify-self: flex-start;
  background: #ffffff;
  border-radius: 4px;
}
.rootwithoutBgColor {
  background: #ffffff00;
}
.bidMeterWidget {
  background: #f9fafb;
  width: 48px;
  height: 44px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 4px;
}
.bidMeterWidgetwithoutBgColor {
  background: #ffffff00;
}
.root:hover .bidMeterWidget {
  background: #e9e9e9;
}
.group1000002143 {
  transform: translate(-0.0009999999999998899px, 0px);
  width: 41px;
  height: 40px;
  display: block;
  position: absolute;
  top: 4px;
  left: 3px;
}
.frame1000006616 {
  width: 100%;
  height: auto;
  display: flex;
  position: absolute;
  top: 25px;
  left: 0px;
  flex-direction: row;
  min-width: 0;
}
.frame1000006616 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000006616 > :global(.__wab_flex-container) > *,
.frame1000006616 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006616 > :global(.__wab_flex-container) > picture > img,
.frame1000006616
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.text {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 15px;
  color: rgba(51, 51, 51, 1);
  position: relative;
  text-align: center;
  width: 100%;
  min-width: 0;
}
.img {
  object-fit: contain;
  max-width: 100%;
  width: 40.5px;
  height: 40px;
  display: block !important;
  position: absolute;
  top: 0px;
  left: 0px;
}
.img > picture > img {
  object-fit: contain;
}
