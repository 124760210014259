.sr-est-view-panel {
  height: 100%;
  background-color: white;
  z-index: 10;
  display: flex;
  flex-direction: column;

  .ant-tabs {
    &.left-aligned {
      .ant-tabs-nav {
        padding-top: 8px;
        padding-left: 16px;
        width: 100%;
        margin-bottom: 0;
      }
    }

    .ant-tabs-nav {
      .ant-tabs-nav-operations {
        display: none !important;
      }

      .ant-tabs-nav-list {
        transition: none !important;
        transform: none !important;
      
        .ant-tabs-tab {
          width: 100px;
          height: 32px;
          justify-content: center;
          border-radius: 4px;
          border: 1px solid #D1D5DB;
          background: #fff;
          cursor: pointer;
          margin: 0 8px 16px 0;
          color: #666;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.5;

          &:hover {
            border: 1px solid #FFD02B;

            .ant-tabs-tab-btn {
              color: #333333 !important;
            }
          }

          &.ant-tabs-tab-active {
            border: 1px solid #FFD02B;
            background: linear-gradient(0deg, rgba(255, 208, 43, 0.25) 0%, rgba(255, 208, 43, 0.25) 100%), #FFF;

            .ant-tabs-tab-btn {
              color: #333333 !important;
              font-weight: 600 !important;
            }
          }

          &.ant-tabs-tab-disabled {
            pointer-events: none;
            background: #E9E9E9;
            color: #333333;
          }
        }
      }
    }

    .ant-tabs-content-holder {
      flex: 2;
      display: flex;

      // .ant-tabs-content {
      //   flex: 1;
      //   display: flex;
      //   flex-direction: column;

      //   .ant-tabs-tabpane {
      //     flex: 1;
      //     display: flex;
      //     flex-direction: column;
      //   }
      // }
    }
  }
}

.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-tab-btn {
  transition: none;
}