@import '../../../style.less';

.topo-errors {
  max-width: calc(100% - 240px);
  display: flex;
  padding: 4px 8px 4px 4px;
  align-items: flex-start;

  border-radius: 4px;
  background: #ffe7e7;

  .topo-errors-inner {
    display: flex;
    gap: 4px;
    width: 100%;
  }
}
