.feature-form-container {
  padding-top: 17px;
  padding-left: 13px;
  padding-right: 13px;
  width: 100%;
  .form-wrapper {
    padding: 0 18px;
    .input-with-heading {
      display: flex;
      flex-direction: column;
      .input-holder {
        border-radius: 4px;
        resize: none;
      }
    }
  }

  .buttons-wrapper {
    text-align: right;
    padding: 0 18px;
    margin-top: 35px;
  }
}
