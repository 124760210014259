.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-self: flex-start;
  cursor: pointer;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.frame1000004652 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}
.frame1000004652 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000004652 > :global(.__wab_flex-container) > *,
.frame1000004652 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004652 > :global(.__wab_flex-container) > picture > img,
.frame1000004652
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.upload:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  width: 100%;
  min-width: 0;
}
.buttonsMarch30 {
  height: 40px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  margin-right: 111px;
  max-width: 292px;
  min-width: 0;
  border-radius: 4px;
  padding: 8px 24px;
}
.buttonsMarch30 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.buttonsMarch30 > :global(.__wab_flex-container) > *,
.buttonsMarch30 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch30 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch30
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.root:active .buttonsMarch30 {
  background: #fff9e5;
  box-shadow: 0px 0px 0px 1px #ffd02b;
}
.root:hover .buttonsMarch30 {
  background: #fff9e5;
}
.svg__cq384 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 19px;
  height: 24px;
  flex-shrink: 0;
}
.root:active .svg__cq384 {
  color: #333333;
}
.root:hover .svg__cq384 {
  color: #333333;
}
.buttonText {
  display: block;
  font-size: 12px;
  text-align: left;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
  width: auto;
  padding-bottom: 0px;
}
.sideEffect:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__nW0Ug {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  border-radius: 17px;
}
.freeBox__nW0Ug > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox__nW0Ug > :global(.__wab_flex-container) > *,
.freeBox__nW0Ug > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nW0Ug > :global(.__wab_flex-container) > picture > img,
.freeBox__nW0Ug
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.hoverNoPdfNoImageYesLoadingYes {
  background: #f8f9fa;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  min-width: 0;
  border-radius: 4px;
  padding: 4px 4px 4px 8px;
}
.hoverNoPdfNoImageYesLoadingYes > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.hoverNoPdfNoImageYesLoadingYes > :global(.__wab_flex-container) > *,
.hoverNoPdfNoImageYesLoadingYes
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.hoverNoPdfNoImageYesLoadingYes
  > :global(.__wab_flex-container)
  > picture
  > img,
.hoverNoPdfNoImageYesLoadingYes
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000005134 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.frame1000005134 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005134 > :global(.__wab_flex-container) > *,
.frame1000005134 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005134 > :global(.__wab_flex-container) > picture > img,
.frame1000005134
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBox__y6OzP {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.loaderYellow {
  transform: scale(1.000000499999875, 0.9999985000008749)
    rotate(-0.057295760416576934deg) skew(-0.1145912916467999deg, 0deg);
  width: 20px;
  height: 20px;
  display: block;
  transform-origin: top left;
  position: absolute;
  overflow: hidden;
  left: 0px;
  top: 0px;
}
.svg___1RKhE {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ffd02b;
  transform: translate(-0.4990000000000001px, 0.08400000000000007px);
  width: 15.333px;
  height: 15.666px;
  top: 2px;
  left: 3px;
  display: none;
}
.emSpinner:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 20px;
}
.emLoader:global(.__wab_instance) {
  max-width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  display: none;
}
.text___7Gn1U {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(153, 153, 153, 1);
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  min-width: 0;
}
.trash {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  padding: 8px;
}
.trash > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.trash > :global(.__wab_flex-container) > *,
.trash > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.trash > :global(.__wab_flex-container) > picture > img,
.trash > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.text__sBcik {
  display: block;
  font-size: 12px;
  text-align: center;
  font-family: "SF Pro";
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.coParcelUploadListItem:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
  display: none;
}
