.em-quick-tip {
  position: fixed;
  width: 100%;
  // transition: left 0.2s, width 0.2s;
  border-top: 0.5px solid #ddd;
  border-left: 0.5px solid #ddd;
  z-index: 999;
}

.service-item-assignment-chip--overlay {
  pointer-events: none !important;

  & * {
    pointer-events: none !important;
  }
}

.takeoff-layer-panel {
   width: 100%;
  .assignedServicePanelHeaderScrollBar {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      visibility: hidden !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd !important;
      border-radius: 4px !important;
      width: 4px !important;
    }
  }

  .service-assignment-scrollable-container {
    // width: calc(100% - 4px);
    // padding-right: 8px;
    flex: 1;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      visibility: hidden !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd !important;
      border-radius: 4px !important;
      width: 4px !important;
    }
  }

  .service-assignment-input-box {
    .ant-input-affix-wrapper {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    ::placeholder {
      font-style: normal;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 16px !important;
      color: #999999 !important;
      padding-left: 4px;
    }
  }

  .loader {
    border: 2px solid #ffffff;
    border-top: 2x solid #ffd02b;
    border-right: 2px solid #ffd02b;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
  }
}