.em-quick-tip {
    position: fixed;
    width: 100%;
    // transition: left 0.2s, width 0.2s;
    border-top: 0.5px solid #DDD;
    border-left: 0.5px solid #DDD;
  }

.estimation-layer-panel {
  .layer-panel-scrollable-container {
      width: calc(100% - 2px);
      padding-right: 2px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        visibility: hidden !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #DDD !important;
        border-radius: 4px !important;
        width: 4px !important;
      }
  }
}