.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  justify-self: flex-start;
  min-width: 0;
}
.tooltipCore:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.serviceItemChip {
  background: #f5f5f5;
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  border-radius: 4px;
  padding: 2px 4px;
}
.serviceItemChip > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.serviceItemChip > :global(.__wab_flex-container) > *,
.serviceItemChip > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.serviceItemChip > :global(.__wab_flex-container) > picture > img,
.serviceItemChip
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.root:hover .serviceItemChip {
  background: #e1e1e1;
}
.takeoffLayerServiceItemsNumber:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 0;
}
.number {
  width: 26px;
  height: 16px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 31.065px;
  padding: 2.485px 5.798px;
}
.number > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.627px);
  height: calc(100% + 6.627px);
}
.number > :global(.__wab_flex-container) > *,
.number > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.number > :global(.__wab_flex-container) > picture > img,
.number
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.627px;
}
.frame1000005960 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 0.5px rgba(221, 221, 221, 1);
  width: 26px;
  height: 16px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 42.187px;
}
.frame1000005960 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 9px);
  height: calc(100% + 9px);
}
.frame1000005960 > :global(.__wab_flex-container) > *,
.frame1000005960 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005960 > :global(.__wab_flex-container) > picture > img,
.frame1000005960
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 9px;
}
.text__m8WXv {
  position: relative;
  width: auto;
  height: 12px;
  max-width: 100%;
  line-height: 13.5px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 9px;
  font-weight: 500;
  color: #666666;
  flex-shrink: 0;
}
.text__nvoW {
  display: block;
  flex-grow: 0;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 1;
  width: 100%;
  min-width: 0;
}
.cLose {
  background: rgba(255, 255, 255, 1);
  width: 18px;
  height: 18px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: none;
  flex-shrink: 0;
  display: none;
  border-radius: 18px;
}
.root:hover .cLose {
  display: block;
}
.close {
  transform: translate(0.375px, 0.371px);
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  top: 4px;
  left: 3.5px;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  transform: translate(0.34299999999999997px, 0.33899999999999997px);
  width: 7px;
  height: 7px;
  display: block;
  top: 17.77%;
  left: 17.77%;
}
.tooltipContent:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
