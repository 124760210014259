.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
  background: #e8e8e8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.226138);
  border: 2px solid #e8e8e8 !important;
  height: 24px !important;
  width: 24px !important;
  margin: 0;

  .ant-steps-icon {
    color: #e8e8e8;
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  border: 2px solid #6cbb2f !important;
  background-color: white !important;
  background-image: url('../../../assets/Vector.svg') !important;
  background-repeat: no-repeat !important;
  background-position: 50% 45% !important;
  height: 24px !important;
  width: 24px !important;
  margin: 0;

  .ant-steps-icon {
    color: transparent;
  }
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #6cbb2f;
  background-image: url('../../../assets/WhiteTick.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 24px !important;
  width: 24px !important;
  margin: 0;
  border: 2px solid #6cbb2f;
  border-radius: 50%;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #6cbb2f !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #6cbb2f;
}

.ant-steps-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ant-steps-item-tail {
    position: relative;
  }

  .ant-steps-item-tail::after {
    background: #e8e8e8;
    position: absolute;
    width: 1.5px !important;
    top: 23px;
    right: 0.5px;
  }

  .ant-steps-item-icon {
    .ant-steps-icon {
      display: none;
    }
  }

  .ant-steps-item-content {
    line-height: 21px;
    display: flex;
    flex-direction: column;
    width: 87.5%;

    .ant-steps-item-title {
      white-space: nowrap;
      width: 100%;
      padding-left: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 15px !important;

      .siterecon-icon-button {
        margin: 0;

        &[disabled] {
          background: #cccccc;
        }
      }
    }

    .ant-steps-item-description {
      margin: 0;
      width: 100%;
      padding-left: 5px;
      font-size: 13px !important;

      button {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content {
  .ant-steps-item-title {
    color: #999999;
    font-weight: 400;
    font-style: 'Poppins';
    font-size: 12px;
  }

  .approval-description {
    padding: 0px 0px;
  }

  .delivery-description {
    padding: 0px 0px;
  }
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content {
  .ant-steps-item-title {
    color: #6cbb2f !important;
    font-weight: 400;
    font-style: 'Poppins';
    font-size: 14px;
  }

  .ant-steps-item-description {
    color: #333333 !important;
    font-weight: 400;
    font-style: 'Poppins';
    font-size: 12px;
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content {
  .ant-steps-item-title {
    color: #333333;
    font-weight: 500;
    font-style: 'Poppins';
    font-size: 16px;
  }
}

.step-description {
  padding: 3px 8px;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  margin-top: 5px;

  &.bold {
    font-weight: 500;
  }

  &.subtle {
    font-size: 12px;
    color: #666666;
    font-weight: 400;
  }

  &.estimate {
    font-size: 20px;
  }

  &.done {
    color: #6cbb2f;
    margin-top: 4px;
  }

  &.link {
    font-size: 12px;
    font-weight: 400;
    padding: 4px 8px;
    margin-top: 0px;
    background: #e8e8e8;
    border-radius: 4px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    svg {
      margin: 0;
      height: 10px;
      width: 6px;
    }
  }

  &.link:hover {
    background-color: #fdeebb !important;
    color: black !important;

    .icon {
      opacity: 1 !important;
    }
  }
}

.measurements-description {
  .create-div {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 24px;

    .or {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #666666;
    }

    .create {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      text-align: center;
      text-decoration-line: underline;

      color: #333333;
      cursor: pointer;

      &[disabled] {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    .create-row {
      display: flex;
      flex-direction: row;
      margin-left: -3px;
      width: 194px;
    }
  }

  .holiday-note {
    padding-right: 11px;

    p {
      font-size: 10px;
      line-height: 15px;
      color: #333333;
    }
  }
}

.approval-description {
  padding: 15px 0px;
}

.delivery-description {
  padding: 15px 0px;

  & > .step-description.link {
    margin-bottom: 5px;
  }
}

.gif-wrapper {
  height: 50px;
  padding: 0 60px;
  margin: 8px 0;

  img {
    height: 100%;
    border-radius: 8px;
  }
}

#map-popover {
  background-color: #ffffff;
  background: 'white';
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  display: none;
  font-size: 15px;
  position: absolute;
  z-index: 5;

  p {
    margin-bottom: 0;
  }

  .map-hover-parent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    height: 70px;
    padding: 0.75rem;

    .map-hover-child__left {
      align-items: center;
      display: flex;
      margin-bottom: 0.25rem;

      #name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      #separator {
        padding: 0 0.3125rem;
        color: #666666;
      }

      #featureId {
        color: #666666;
      }
    }

    .map-hover-child__right {
      align-items: center;
      display: flex;
      font-weight: 400;

      #featureIcon {
        margin-right: 0.25rem;
      }

      #hoverArea {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      #unit {
        padding-left: 0.3125rem;
      }
    }
  }
}

#track_map {
  .ol-overlaycontainer-stopevent {
    z-index: 5 !important;
  }
}

#track_map.draw #ol-map--project {
  cursor: url('../../../assets/cursors/crosshair.svg') 16 16, auto !important;
}

#track_map.cut #ol-map--project {
  cursor: url('../../../assets/cursors/cut.svg') 6 25, auto !important;
}

#track_map.modify #ol-map--project {
  cursor: url('../../../assets/cursors/modify.svg') 15 15, auto !important;
}

#track_map.measuretool #ol-map--project {
  cursor: url('../../../assets/cursors/measuretool.svg') 0 35, auto !important;
}

#track_map.commenttool #ol-map--project {
  cursor: url('../../../assets/cursors/comments.svg') -12 20, auto !important;
}

#track_map.feedbacktool #ol-map--project {
  cursor: url('../../../assets/cursors/feedback.svg') -12 20, auto !important;
}

#track_map.slice #ol-map--project {
  cursor: url('../../../assets/cursors/slice.svg') 20 0, auto !important;
}

#track_map.duplicate #ol-map--project {
  cursor: url('../../../assets/cursors/duplicate.svg') 32 2, auto !important;
}

#track_map.doughnut #ol-map--project {
  cursor: url('../../../assets/cursors/lassoSplit.svg') 20 0, auto !important;
}

#track_map.undo-processing #ol-map--project {
  cursor: url('https://fragmentor.siterecon.ai/common-assets/gif/circular-loader-yellow.gif')
      15 15,
    wait !important;
}

#measure-div {
  z-index: 100;
  background: white;
  position: absolute;
  right: 16px;
  top: 154px;
  width: 250px;
  min-height: 180px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

sup {
  font-size: smaller;
}

.map-img {
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 10;
}

.layer-style {
  .layer-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    background: #f4f4f4;
    border-radius: 4px 4px 0px 0px;
    height: 38px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #333333;
  }

  .layer-inner-1 {
    padding: 10px;
    border-top: 1px solid #d1d1d1;

    input[type='color'] {
      width: 100%;
      height: 20px;
      padding: 2px 2px;
      border-radius: 4px;
      border: 1px solid #dedede;
      outline: none;
    }

    input[type='color']:active {
      outline: none;
    }

    input[type='color']::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    input[type='color']::-webkit-color-swatch {
      border: none;
      border-radius: 4px;
    }
  }
}

.share-map-modal {
  font-family: 'Poppins';

  h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
  }

  p {
    margin-top: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
  }

  a {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #333333;
  }

  .copy-link {
    margin-top: 10px;
    cursor: pointer;
  }
}

.modal-video {
  .ant-modal-content {
    left: 15%;
    height: 500px;
    background: rgba(51, 51, 51, 0.45);
  }
}

.player-react {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-right: 35px;
  padding-top: 30px;
}

.scene-footer-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 3;

  .scene-footer {
    border-radius: 4px 0px 0px 0px;
    float: right;
    background-color: white;
    padding-left: 3px;
    display: flex;
    font-size: 10px;
    color: #000000;
    padding-right: 2px;
  }
}

.map-img {
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 10;
}

.note-card-class {
  z-index: 25;
}

.feedback-card-detail-class {
  z-index: 25;
}

.note-add {
  z-index: 25;
}

.pointer {
  cursor: pointer;
}

.backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

svg.activeIcon {
  transform: scale(1.15) !important;
}

svg.activeIconClick {
  transform: scale(1.15) !important;
}

svg.notActiveIcon {
  transform: scale(1) !important;
}

.force-hide {
  display: none !important;
}

// order queue modal
.order-queue-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  z-index: 999;
}

//Map Measurement Failed

@px: 16rem;

.measurement-failed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 16 / @px 22 / @px;
  height: calc(100% - 92px);
  text-align: center;

  .measurement-failed-icon {
    margin-bottom: 1 / @px;
  }

  svg {
    width: 126px;
  }

  .text-content {
    margin-bottom: 40 / @px;

    h2 {
      font-size: 24 / @px;
      font-weight: 500;
      color: #333333;
    }

    p {
      font-size: 14 / @px;
      font-weight: 400;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
      width: 230 / @px;
      height: 42 / @px;
    }
  }
}

.ol-popup {
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 1px solid #cccccc;
}

.ol-popup:hover {
  background-color: #f5f5f5;
}

#popup-content {
  padding: 8px;
  cursor: pointer;
}

// Ask Confirmation modal
.ask-confirmation-modal {
  .ant-modal-content {
    border-radius: rem(10);
  }

  transition: 0.5s all ease;

  .ant-modal-content {
    border-radius: 10px;

    .buttons {
      .no-button {
        width: rem(109);
        height: rem(36);
      }

      .yes-button {
        width: rem(109);
        height: rem(36);
      }
    }
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.access-expired-container {
  height: 100vh;

  .access-expired-outer {
    border: none;
  }
}

.legacy-estimation-alert {
  .ant-modal-body {
    padding: 0;
  }
}

.legacy-estimation-alert {
  .ant-modal-body {
    padding: 0;
  }
}

.co-widget-bar-integration {
  top: 50px;
}

.feature-panel-cross {
  cursor: pointer;
  top: 2px;
  height: 12px;
  width: 12px;
  padding: 8px;

  .cross-icon {
    &:hover {
      background: #f5f5f5;
      border-radius: 50%;
    }
  }
}
