.bulk-order-name-chip-container {
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: auto;
  max-width: '100%';
  border-radius: 16px;
  padding: 2px 6px 2px 2px;
  background-color: #efefef;
  height: 24px;
  cursor: pointer;
  &:hover {
    background-color: #e9e9e9;
  }
  &:active {
    background-color: #e9e9e9;
    border: 1px solid #c9c9c9;
  }
  .order-name-background {
    display: flex;
    align-items: center;
    gap: 4px;
    overflow: hidden;

    .bulk-icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .overflow {
    width: fit-content;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
