.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 216px;
  height: 44px;
  justify-self: flex-start;
  background: none;
  padding-bottom: 12px;
  padding-top: 5px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 4px;
}
.frame1000005806 {
  width: 216px;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-right: 2px;
}
.frame1000005806 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005806 > :global(.__wab_flex-container) > *,
.frame1000005806 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005806 > :global(.__wab_flex-container) > picture > img,
.frame1000005806
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
._3M {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.img {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  left: 2px;
  top: -3px;
  z-index: 100;
  height: 32px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.sideEffect:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__hsyMg {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 183px;
  height: 10px;
  max-width: 100%;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: auto;
  flex-shrink: 0;
}
.freeBox__fmeQg {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #7ad21c;
  box-shadow: none;
  min-width: 0;
}
.svg__xPsKr {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #4a8a03;
  width: 2px;
  height: 14px;
  display: block;
  flex-shrink: 0;
}
.freeBox__lujDb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #eeeeee;
  min-width: 0;
}
.freeBox__vo4M {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #dddddd;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  min-width: 0;
}
.svg__hmLt0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #aaa;
  width: 7.894px;
  height: 8px;
  flex-shrink: 0;
}
