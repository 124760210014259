.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 262px;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  justify-self: flex-start;
  box-shadow: 0px 0px 0px 0.5px #dddddd;
  border-radius: 4px;
}
.freeBox {
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(24px);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
  -webkit-backdrop-filter: blur(24px);
  border-radius: 8px;
  padding: 12px;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.eoDbidConsumeModal:global(.__wab_instance) {
  flex-shrink: 0;
}
.frame1000006055 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000006055 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.frame1000006055 > :global(.__wab_flex-container) > *,
.frame1000006055 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006055 > :global(.__wab_flex-container) > picture > img,
.frame1000006055
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.frame1000006315 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000006315 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000006315 > :global(.__wab_flex-container) > *,
.frame1000006315 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006315 > :global(.__wab_flex-container) > picture > img,
.frame1000006315
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.frame1000006052 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006052 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006052 > :global(.__wab_flex-container) > *,
.frame1000006052 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006052 > :global(.__wab_flex-container) > picture > img,
.frame1000006052
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__p13Qs {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.text__xKaed {
  display: block;
  font-size: 12px;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.frame1000006056 {
  height: auto;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  display: none;
}
.frame1000006056 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006056 > :global(.__wab_flex-container) > *,
.frame1000006056 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006056 > :global(.__wab_flex-container) > picture > img,
.frame1000006056
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__c80Lg {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.text__fulzD {
  display: block;
  font-size: 12px;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.frame1000006316 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006316 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006316 > :global(.__wab_flex-container) > *,
.frame1000006316 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006316 > :global(.__wab_flex-container) > picture > img,
.frame1000006316
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text___5R5Pr {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.text__pmWX {
  display: block;
  font-size: 12px;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.text___6G0Mw {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
  display: none;
}
