/* body {
    overflow: hidden;
} */

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  overflow: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width   : none;
  /* Firefox */
  overflow-y        : hidden;
}

#root {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  width : 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  border-radius     : 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color  : #999;
  background        : #f5f5f5;
  box-shadow        : 0px 0px 4px rgba(255, 255, 255, 0.12);
  border-radius     : 4px;
}

::-webkit-scrollbar-thumb {
  border-radius     : 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color  : #999;
  background        : #f5f5f5;
  box-shadow        : 0px 0px 4px rgba(255, 255, 255, 0.12);
  border-radius     : 4px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius     : 0px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius     : 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.drag-handle {
  cursor: move;
}

.timeline-container {
  height          : calc(100vh - 40px);
  max-height      : calc(100vh - 40px);
  background-color: #ffffff;
  position        : absolute;
  z-index         : 0;
  overflow-y      : auto;
  border-top: 0.5px solid #DDD;
}

.timeline-container::-webkit-scrollbar {
  display: none;
}

#feature-panel-sider {
  flex: 1 !important;
}

.timeline-detail {
  padding   : 0;
  z-index   : 5;
  transition: 0.2s;
  cursor    : default;
}

.search-bar-toggle-btn {
  position        : fixed;
  height          : 42px;
  padding         : 0;
  background-color: #fcfcfc;
  border          : 1px solid #ddd;
  outline         : none;
  width           : 25px;
  text-align      : center;
  top             : 56px;
  transition      : 0.2s;
}

.ant-upload-list {
  vertical-align: bottom;
  display       : inline-block;
}

.post-date {
  color: #aaa;
}

.ol-attribution {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ol-attribution {
  display: none;
}

.ol-zoom {
  top          : auto !important;
  left         : auto !important;
  bottom       : 125px !important;
  padding      : 0 !important;
  right        : 27px !important;
  width        : 28px !important;
  height       : 56px !important;
  border-radius: 4px !important;

  .ol-zoom-in,
  .ol-zoom-out {
    background-color: #ffffff !important;
    color           : #666666 !important;
    font-size       : calc(100vh * (18 / 820)) !important;
    font-weight     : 500 !important;
    margin          : 0 !important;
    height          : calc(100vh * (28 / 820)) !important;
    width           : calc(100vh * (28 / 820)) !important;
  }

  .ol-zoom-in:hover {
    background-color: #e9e9e9 !important;
  }

  .ol-zoom-out:hover {
    background-color: #e9e9e9 !important;
  }
}

#idtable td,
#idtable th {
  border : 1px solid #ddd;
  padding: 8px;
}

#idtable tr:hover {
  background-color: #ddd;
}

#idtable th {
  padding-top   : 12px;
  padding-bottom: 12px;
  text-align    : left;
  color         : black;
}

#colorspan {
  width: 200px;
}

.ant-popover-content,
.ant-popover-inner-content {
  padding: 0 !important;
  margin : 0 !important;
}

.change-base-layer {
  position: fixed;
  bottom  : 36px;
  right   : 65px;
  z-index : 4;
}

.spin {
  text-align   : center;
  background   : rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding      : 30px 50px;
  margin       : 20px 0;
  position     : relative;
}

.ant-upload-list {
  height     : 30px;
  margin-left: 30px;
}

/*
.form-input-label, .form-input, .form-input.blue, .cc_cursor, body, .ogdlpmhglpejoiomcodnpjnfgcpmgale_default, body, html, input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"], input::-webkit-contacts-auto-fill-button, input:read-only {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAFDklEQVRIS63VeWwUdRTA8e/Mzk673e1Ba6mlLaxKlEaucLTQQtKKxRQFRGoADVeFKMYLE5CGYxE0HomJhAoqYEQ8UMEQ5QpBQKAFCaW0FmgBodvWtee22273msuwSpMarHi8/2by5n1+eb/3+42AAzHltLg+zhZRpGih+REmnq9za+WeozzN/xAC2UQvzBDff3PJsieOXLYwIaWEh1//nspduvA/1EcwjWPa/Ay2b3jqvjibLRq3p5nsdQ1cadIXaafY9l8R4d6pgnLgRVlK629CMgl0BwyWV4Y42JZA9562g+2y4FB05RzHUP8NJsTm8Eq2nRlTck2Z+YNltqr9cJni6IxPQN/fxNU4g2tfX39b8ajfqqcp+afIjT4LUhbrkpdGrcoN2Tl5fSpDCi/z7L5Uts6uQZBFumq7jGu765rrv6s/qJxiwT9BwhspZbFWXio4crLzuV4sk11dQfPqgdh/8LJ6u8ai4/0RZRF3VTvn3/qpwdPgy6eUqtuBegDzSzimTZzEfauvEUqNpKZoEJFl3UwqN9hbaEMQweP14Kx04lzpek0tZQ1g/B3SA1hnCo7PtyRxakYs1UWDkE948T0QEy58I9wdbqrOXGCw38q9Dm/rzjv81xWPkUsl3X0hPcCylhhH5cfpyGP6IR/ysNI+iHe0JjrTIzAUDXO9D8uHLhK8BrXFQ9BVndKlZ7RgW3BDZ5t/JccI3ArqAVIeFB0jlz+IaP59yZHlPoJqiKEnu/GMtdO0o5LqBhF7/lA8Wa3EjYrF0A0aS5qp3nblhY4vvBv7BOY3WR0F5hikP1pSlmhQNvcOLKP7oSXHUFZcw5CqofhCBu5MPwW5qfyY4aLh8C9UvVez238gVNAncGOThUQwdUmIokhGeiayxYwcL4MAzu0N+HYqNOqxzHo4mchHI9h96TDjjitcSDTReF6p97cG5wYk9TQHCN7Ewi0yjWeOaQIfiOlEDxyZhrDHjFJq4KWTMR8ND+f6XUEsqZHhcTXHSuF3QmU7/c/6aFwwALVbpXxbpdZ8omWJspctvQDGY5FExsh50vHhi++ndrGLj57bzqLFz3D/fjtRd0WiBfSewjc/npN/kV2fDEZJlNENnZqrNfy81nlW2cXY3sAfT7YXZWP0qhFE+Wycm+olkJRA5mawpvXurtyhU7RjNFvuKcc1BQKhABVnKmg93O7RT/GIeoyTtwRi5lmM8RvHEmuy8cjuu9kx/Tw2i61XdaFTZeyKBpInjOKbWc00upuorarF/V6Xy2jlOc3DPi4SuiVgKZCMhFWx4bm3K0nEj0xDMv3e75sRV+xkWFmAknfvwen5lQsfVKPWaFVKO9M5ybU/T1Kvn4p5Opo0FzGyfwQ5mbk9uYZhhGc++kg7M5bW8up6A6/Uxi9njaB+nJeVUjb91WnuBUiTeFucTOHI2cMSUu5MwR/009rRiqIqjKuwkrWils1PasQFVAqP+NmIUXdCJ48zXL4tgEySIh5i14iFwyakDEhB1VRcLS4Gt1h5bOZVXk/v5AGfjTxfJ9GawdwIrjrNZHGCltsDJpIYkcOX9scH5qalpnKp9hJRl3y8tMhqfJXW3ViaEDw6MWCdk6Eb6pcWn7MOXtZO893fXnY9CZOxmlPYJD3GPEt8JL6KAHmbLIGfRfWzK9HK+0oJZ6Vs1gg6nUqITymjta/i4cP4pwTBnMMII5a9gkiCEeIN0c1XoWjqOdT3tfxX0G9juiM3r2pFSgAAAABJRU5ErkJggg==) 2 1 , auto !important;
}

.cc_pointer, [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration, .paper-button, .ytp-progress-bar-container, input[type=submit], :link, :visited, a > *, img, button, ::-webkit-scrollbar-button, .ogdlpmhglpejoiomcodnpjnfgcpmgale_pointer, ::-webkit-file-upload-button, button, .ytp-volume-panel, #myogdlpmhglpejoiomcodnpjnfgcpmgale .icon {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEi0lEQVRIS92Ve0xbdRTHv7f33j4ptBToNmihFKHt7UbnBIaODR3G6IZsOrJNM8mc02RmZkaNz7gsMZlZ4jKcJDOLCA7JBB1YpoZN2Hu6LRYMHeuArh2M8lDeBdre3nsNGA0k4MTsL39//R7fcz455/c750dgxhAAwp69w8bzsEMhlZMct8fbfbvqlY4fgjN1C5kTM8WfWwoXLbWk1abbLFnUQwxuX2zmOs46svMaiq8txOlM7SxAaUqhxZa51Mk88xjBZZgQEpH46c1DHz5e+t7b9wRQZtnKMPk5Tuu6HITMBghRCpzZ8s7VjSf2Z90TQGXGTsb8RJaTidRCNDaOyU2rUP/WwbFNJw9G3hOAPf9Vxrjc6jTfGAcRAYSy0lBXZcemxpJZqVwIbJah3VLEpMqjnWlyDWCMBEQE7K0tUJh1H/HenlOuTs+5hb6oWYBKy1ZmmSzWySji/gQAcOeaoF+fjTv7K4RfGn4+bHv/+SH3t+eGe846yrZ7a4fvFs0sQFnK00x65GKnZdcGiC+1Ttv69z4LQUyCaPGgu69XiF2XQwzaL7DXDlW4rVYT57refvR6s+HwPuzj54IRVSgkJ5MRz1O8PExAbNvwSJO1IFck/awRvEqBiZfzoTjwNfwFK9E/MowlKhUCUhr+3gHEaDW4dayuy3HJsea5m9WeOQEnc3fnJei0JQiH9WOj45VSq2GbtWANTV9uByeXgkuIgWhsAmGzHkSIhSChwQsCWCkNmqLQVlp7p7WmYfW8gJqVL/Q+WfCoVkQQCAQC4FUREK1aCtA0iP5RhFLjQXn7IJAkeI0S8i8agCALSGj4Y5W4dPq8t8/je3i767h3zghqLNsCG5ZnSpCkAmRiQCEDZ9Ai9KAF4tNNCK40QdTuA2vSgeB58NFKEIEQqCY3uMpGnHI0e0c5dn7A99aiD3KiEt+NIMUYyEtGRJwakkkWoczU6fSQvkFw+jhwShmIoTGQ3YMQ9Q2Bj9dg0tOD8x9XeIcGRuYHHEsrTFuhjL+iMyZFda7WQW1NgYYUQ+xwI6yPRXhZMsiufrBMIgSlHJLvriJsTYLkghPD6zPQvPdTh+fMxfwdN+2+OVM0tVlt3ronafPaA7JYNT21Vj/AQB0fB+pmN0BTYK2JoNq7EU5ZAmnN5ekLp1s8uPJ7D24UH//EHyV6fb4CnK6DctNGTbQppc6Ql5UNgoDrUBUMKyywZdoAlQKCXAr2fiNEIxMg3T3gEuPgOvIN6k/UB9Va9Ws7O2pK5iu4vwut1LhFp7pP26lK0+PXL+vBhkIwrmCQW/wGZDEqUK4uUNc7Mb45B87yOrTVNiDoG2wTCDrzpVvVI3cFTAmO6AsswWDw3KR/IkYQBEhVEVhkM4JjOfiuusCxYWhSE6BcrAH722invz9s29VZOfRP7WJWq5j6Mo8mP7U2ODFZEgwGU9kQC102A7FCioGObgx7eiGRSXmFOuJHghLvftH1VduCetFf4vLkQv14KGAgKbJYopSlE5QI4QALdiLQyAbCeyLVEl+Rq2bgbs6nzv9zn/83zv8fgD8AxT/JKPzrQAgAAAAASUVORK5CYII=) 5 0, auto !important;
}
*/
svg {
  -webkit-transform: none !important;
}

.olTileImage {
  -webkit-backface-visibility: visible !important;
  -webkit-transform          : none !important;
}

svg {
  -webkit-transform: none !important;
}

.olTileImage {
  -webkit-backface-visibility: visible !important;
  -webkit-transform          : none !important;
}

.ol-tooltip {
  position     : relative;
  background   : rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color        : white;
  padding      : 4px 8px;
  opacity      : 0.7;
  white-space  : nowrap;
  font-size    : 12px;
}

.ol-tooltip-measure {
  opacity    : 1;
  font-weight: bold;
}

.ol-tooltip-static {
  background-color: #ffcc33;
  color           : black;
  border          : 1px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top  : 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left : 6px solid transparent;
  content     : '';
  position    : absolute;
  bottom      : -6px;
  margin-left : -7px;
  left        : 50%;
}

.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.ant-tag-orange,
.ant-tag-green,
.ant-tag-blue,
.ant-tag-cyan,
.ant-tag-red,
.ant-tag-geekblue {
  background      : #ffffff !important;
  background-color: #ffffff !important;
}

tr {
  cursor: pointer;
}

.ant-slider-track {
  background-color: #ffd02b;
}

.ant-slider-handle {
  border          : 2px solid #ffd02b;
  background-color: #ffd02b;
}

span.anticon.anticon-loading.anticon-spin svg {
  /* border: 1px solid black; */
  /* -webkit-animation: spin 1s infinite linear !important; */
  /* animation: spin 1s infinite linear !important; */
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg) !important;
}

.ol-control {
  position   : fixed;
  // bottom  : 125px !important;
}

.track_map {
  position: fixed;
  left    : 0;
  right   : 0;
  bottom  : 0;
}

#ol-map--project {
  width   : 100%;
  z-index : 2;
  position: absolute;
}

.track_map #ol-map--project {
  height: calc(100vh - 40px);
}

.track_map.track_map--with-topbar .timeline-container {
  height: calc(100vh - 90px);
}

.ol-viewport {
  position: absolute !important;
}

.track_map.track_map--with-topbar #ol-map--project {
  height: calc(100vh - 40px) !important;
}

.ol-rotate-reset {
  display: none !important;
}

.rotate-compass,
.street-view,
.rotate-90,
.reset-to-parcel,
.ol-scale-bar {
  z-index   : 20;
  box-sizing: border-box;
  text-align: center;
  cursor    : pointer;
}

.rotate-compass.dark-mode {
  border: 1px solid #E8E8E8;
  border-radius: 50%;
}

.scale-bar-secondary {
  transform       : scale(0.4);
  transform-origin: bottom left;
  background-color: rgba(0, 0, 0, 0.50);
  border-radius: 8px;
  padding         : calc(100vh * (24 / 820)) calc(100vh * (12 / 820)) calc(100vh * (12 / 820));

  .ol-scale-step-text {
    color      : #FFF !important;
    top        : 0;
    text-shadow: none !important;
    text-align : center;
    font-family: Poppins;
    font-size  : calc(100vh * (16 / 820));
    font-style : normal;
    font-weight: 500;
    line-height: normal;
  }

  .ol-scale-step-marker {
    background-color: transparent !important;
  }

  .scale-bar-secondary-inner>div {
    height: calc(100vh * (10 / 820));
  }

  .scale-bar-secondary-inner>div>div:nth-child(2) .ol-scale-singlebar {
    background-color: #ffd02b !important;
    border-radius   : calc(100vh * (5 / 820)) 0 0 calc(100vh * (5 / 820));
    border          : none;
  }

  .scale-bar-secondary-inner>div>div:nth-child(3) .ol-scale-singlebar {
    background-color: #ddd !important;
    border-radius   : 0 calc(100vh * (5 / 820)) calc(100vh * (5 / 820)) 0;
    border          : none;
  }

  .ol-scale-step-text {
    color      : #FFF;
    top        : -0;
    text-shadow: none !important;
    text-align : center;
    font-family: Poppins;
    font-size  : calc(100vh * (16 / 820));
    font-style : normal;
    font-weight: 500;
    line-height: normal;
  }
}

.ol-scale-bar {
  top             : auto !important;
  left            : auto !important;
  bottom          : 62px !important;
  right           : 96px !important;
  transform       : scale(0.5);
  transform-origin: bottom right;

  .ol-scale-step-marker {
    background-color: transparent !important;
  }

  .ol-scale-bar-inner>div>div:nth-child(2) .ol-scale-singlebar {
    background-color: #ffd02b !important;
    border-radius   : 5px 0 0 5px;
    border          : none;
  }

  .ol-scale-bar-inner>div>div:nth-child(3) .ol-scale-singlebar {
    background-color: #ddd !important;
    border-radius   : 0 5px 5px 0;
    border          : none;
  }

  .ol-scale-step-text {
    color      : #FFF;
    top        : -25px;
    text-shadow: none !important;
    text-align : center;
    font-family: Poppins;
    font-size  : 16px;
    font-style : normal;
    font-weight: 500;
    line-height: normal;
  }
}

.rotate-90 {
  bottom: 81px;
  right : 25px;
}

.street-view {
  bottom: 240px;
  right : 12px;
}

.reset-to-parcel {
  bottom: 193px;
  right : 40px;
}

.multiSelectBox {
  background-color: rgba(227, 243, 255, 0.5);
  border          : 1px solid #73c6fd;
  box-sizing      : border-box;
  /*opacity       :1;*/
}

/**********
* Helpers *
**********/
.flex-center {
  display        : flex !important;
  align-items    : center;
  justify-content: center;
}

.toggle-arrow {
  transition: all 0.2s ease;
  transform : rotate(180deg) !important;
}

.dn-none {
  display: none !important;
}

.rotate-compass.disabled {
  cursor: default;
}

// TODO: Find a better way to hide default zoom controls from ol map. Setting `{ zoom: false }` in defaultOptions doesn't seem to work
.ol-control.ol-zoom {
  display: none !important;
}

body .loading-cursor {
  height: 60px;
  width: 60px;
  background-image: url('https://fragmentor.siterecon.ai/common-assets/gif/circular-loader-yellow.gif');
  background-size: cover;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

body.no-cursor * {
  cursor: none !important;
}

.sr-icon-container {
  border-radius: 50%;
  width: max-content;
  height: max-content;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
      background-color: #E9E9E9;
  }

  &:focus,
  &:active {
      background-color: #E9E9E9;
      outline: #C9C9C9;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}