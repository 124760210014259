.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
}
.property1DefaultTakeOffInProgressYesTakeOffDoneAwaitingFeedbackNoPricingInProgressNo {
  background: #f9fafb;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  cursor: pointer;
  min-width: 0;
  border-radius: 8px;
}
.property1DefaultTakeOffInProgressYesTakeOffDoneAwaitingFeedbackNoPricingInProgressNo
  > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.property1DefaultTakeOffInProgressYesTakeOffDoneAwaitingFeedbackNoPricingInProgressNo
  > :global(.__wab_flex-container)
  > *,
.property1DefaultTakeOffInProgressYesTakeOffDoneAwaitingFeedbackNoPricingInProgressNo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.property1DefaultTakeOffInProgressYesTakeOffDoneAwaitingFeedbackNoPricingInProgressNo
  > :global(.__wab_flex-container)
  > picture
  > img,
.property1DefaultTakeOffInProgressYesTakeOffDoneAwaitingFeedbackNoPricingInProgressNo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.root:active
  .property1DefaultTakeOffInProgressYesTakeOffDoneAwaitingFeedbackNoPricingInProgressNo {
  box-shadow: inset 0px 0px 0px 2px #ffd02b;
}
.root:hover
  .property1DefaultTakeOffInProgressYesTakeOffDoneAwaitingFeedbackNoPricingInProgressNo {
  background: #f5f5f5;
}
.statusChips {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  min-width: 0;
  border-radius: 8px;
  padding: 8px;
}
.statusChips > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.statusChips > :global(.__wab_flex-container) > *,
.statusChips > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.statusChips > :global(.__wab_flex-container) > picture > img,
.statusChips
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.img {
  object-fit: contain;
  max-width: 100%;
  width: 16px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: contain;
}
.imgtakeoffDone {
  object-fit: contain;
}
.imgtakeoffDone > picture > img {
  object-fit: contain;
}
.freeBox {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  align-self: stretch;
  min-width: 0;
  min-height: 0;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.freeBoxpricingDone {
  height: 100%;
  min-height: 0;
}
.freeBoxpricingDone > :global(.__wab_flex-container) {
  justify-content: space-around;
  align-items: flex-start;
  min-height: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 13px);
  height: calc(100% + 13px);
}
.freeBoxpricingDone > :global(.__wab_flex-container) > *,
.freeBoxpricingDone > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBoxpricingDone > :global(.__wab_flex-container) > picture > img,
.freeBoxpricingDone
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 13px;
}
.frame1000006735 {
  height: auto;
  display: flex;
  flex-direction: row;
  width: auto;
}
.frame1000006735 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006735 > :global(.__wab_flex-container) > *,
.frame1000006735 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006735 > :global(.__wab_flex-container) > picture > img,
.frame1000006735
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.takeoffInProgress {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.takeoffInProgresstakeoffDone {
  color: #333333;
  line-height: 1.54;
}
.takeoffInProgress2 {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.54;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.takeoffInProgress2takeoffDone {
  display: block;
}
.frame1000006778 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000006778 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000006778 > :global(.__wab_flex-container) > *,
.frame1000006778 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006778 > :global(.__wab_flex-container) > picture > img,
.frame1000006778
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000006778pricingNotAvailable {
  display: none;
}
.frame1000005071 {
  background: rgba(219, 234, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 24px;
}
.frame1000005071 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005071 > :global(.__wab_flex-container) > *,
.frame1000005071 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005071 > :global(.__wab_flex-container) > picture > img,
.frame1000005071
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000005071takeoffDone {
  display: none;
}
.frame1000005071withoutChevron {
  display: none;
}
.frame1000005071pricingDone {
  display: none;
}
.frame1000005071pricingNotAvailableTakeoffInProgress {
  display: none;
}
.text__oekvW {
  display: block;
  font-size: 10px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 18px;
  color: rgba(25, 114, 232, 1);
  position: relative;
}
.frame1000006748 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000006748 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006748 > :global(.__wab_flex-container) > *,
.frame1000006748 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006748 > :global(.__wab_flex-container) > picture > img,
.frame1000006748
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__jHcN {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(153, 153, 153, 1);
  position: relative;
  width: 100%;
  min-width: 0;
}
.texttakeoffDone__jHcN24K5U {
  line-height: 1.54;
  color: #666666;
}
.textpricingDone__jHcNFnYih {
  color: #666666;
}
.textpricingNotAvailableTakeoffInProgress__zblreZwHld {
  display: none;
}
.frame1000005072 {
  background: rgba(219, 234, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 24px;
}
.frame1000005072 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005072 > :global(.__wab_flex-container) > *,
.frame1000005072 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005072 > :global(.__wab_flex-container) > picture > img,
.frame1000005072
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000005072takeoffDone {
  display: flex;
}
.text__s2Hab {
  display: block;
  font-size: 10px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 18px;
  color: rgba(25, 114, 232, 1);
  position: relative;
}
.frame1000005306 {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: row;
  left: auto;
  top: auto;
  flex-shrink: 0;
}
.frame1000005306 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000005306 > :global(.__wab_flex-container) > *,
.frame1000005306 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005306 > :global(.__wab_flex-container) > picture > img,
.frame1000005306
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000005306withoutChevron {
  display: none;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.sideEffect:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
