@px: 16rem;

.single-queue-container {
  width: 320 / @px;
  min-width: 320 / @px;
  padding: 8 / @px;
  background: #fcfcfa;
  border-radius: 5px;
  margin-bottom: 0 / @px;
  overflow: hidden;
  overflow-y: scroll;
  height: 340px;

  .queue-top-head {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #333333;
    }

    p {
      padding: rem(2) rem(8);
    }
  }

  .queue-bottom-head {
    display: flex;
    justify-content: space-between;

    .text-container {
      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #000000;

        span {
          font-size: 10px;
          line-height: 15px;
          color: #666666;
        }
      }
    }

    .view-button {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #1a73e8;
      cursor: pointer;
    }
  }

  .queue-main-container {
    .no-ongoing-orders {
      width: 290 / @px;
      min-height: 95 / @px;
      margin: 0 auto 14 / @px auto;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    }
  }
}