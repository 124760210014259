@import '/src/styles/base/variables/index';

.typography {
  font-family: @font-family;
  font-size: @font-size-base;
  color: @body-color;
  margin-bottom: 0;

  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }

  &.typography--h1 {
    font-size: @heading-1-size;
    font-weight: 700;
  }

  &.typography--h2 {
    font-size: @heading-2-size;
    font-weight: 600;
  }

  &.typography--h3 {
    font-size: @heading-3-size;
    font-weight: 500;
  }

  &.typography--h4 {
    font-size: @heading-4-size;
    font-weight: 500;
  }

  &.typography--h5 {
    font-size: @heading-5-size;
    font-weight: 500;
  }

  &.typography--h6 {
    font-size: @heading-6-size;
    font-weight: 500;
  }

  &.typography--body {
    font-size: @font-size-base;
    line-height: 1.5;
  }

  &.typography--body-sm {
    font-size: @font-size-sm;
    line-height: 1.5;
  }

  &.typography--body-lg {
    font-size: @font-size-lg;
    line-height: 1.5;
  }

  &.typography--subtitle {
    font-size: @heading-6-size;
  }
}
