@px: 16rem;
.result-not-found {
  width: 100%;
  min-width: 300 / @px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-weight: 500;
    margin-top: 20 / @px;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #666666;
    width: 265 / @px;
  }
}
