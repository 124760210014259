.text {
  font-family: 'Poppins';
}

.text--p17 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.text--p19 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.text--p19_2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.text--p18 {
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
}

.text--p16 {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

.text--p15 {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.text--p14 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.text--p13 {
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}

.text--p12 {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.text--p11 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.text--p10 {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.text--p9 {
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
}

.text--p8 {
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
}

.text--p7 {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.text--p6 {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
}

.text--p5 {
  font-weight: 200;
  font-size: 12px;
  line-height: 20px;
}

.text--p4 {
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
}

.text--p3 {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.text--p2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}

.text--p1 {
  font-weight: normal;
  font-size: 12px;
  line-height: 30px;
}

.text--p0 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.text--p20 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.text--p21 {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}

.text--p22 {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
}

.text--p23 {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.text--p24 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.text--p25 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.text--p26 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.text--p26 {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}

.text--p27 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.text--h9 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.text--h91 {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.text--h8 {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.text--h7 {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.text--h6 {
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
}

.text--h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}

.text--h41 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.text--h4 {
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
}

.text--h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.text--h23 {
  font-weight: 500;
  font-size: 24px;
  line-height: 33.6px;
}

.text--h31 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.text--h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.text--h21 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}

.text--h11 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.text--h22 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.text--h23 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.text--h1 {
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
}

.text--h0 {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

.text--h24 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}

.text--p28 {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
}

.text--p29 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.text--p30 {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.text--p31 {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

.text--p32 {
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
}

.text--p33 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.text--p34 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.text--p35 {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}

.text--p36 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.text--p37 {
  font-weight: 500;
  font-size: 8.05px;
  line-height: 12.08px;
}

.text--p38 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.text--p39 {
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
}

.text--p40 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.text--p41 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.text--p42 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.text--p42 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.text--h10 {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.text--b01 {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.text--p29 {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.text--p43 {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.text--p44 {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
}

.text--p45 {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}

.text--p46 {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.text--p47 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.text--p48 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
}

.text--p49 {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.text--p50 {
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
}

.text--p51 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14.2px;
}

.text--p52 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.text--p53 {
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
}

.text--p54 {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.text--p55 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.text--p56 {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}

.text--p57 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
}

.text--p58 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-style: italic;
}

.text--p59 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
}

.text--p60 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.text--p61 {
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
}

.text--p62 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.text--p63 {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
}

.text--p64 {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.text--p67 {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.text--p68 {
  font-weight: 400;
  font-size: 11px;
  line-height: 10px;
}

.text--p69 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
}

.text--p70 {
  font-weight: 600;
  font-size: 14px;
  line-height: 22.4px;
}

.text--p71 {
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
}

.text--p72 {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}

.text--p73 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14.6px;
}

.text--p74 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14.6px;
}

.text--p75 {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.text--p76 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.text--p77 {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.text--p78 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.text--p79 {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
}

.text--p80 {
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
}

.text--p81 {
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
}

.text--p82 {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.text--p83 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16.8px;
}

.text--p88 {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
}

.text--p84 {
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}

.text--p85 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.text--p86 {
  font-weight: 400;
  font-size: 6px;
  line-height: 8.4px;
}

.text--p87 {
  font-weight: 500;
  font-size: 8px;
  line-height: 30px;
}

.text--p89 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.text--p90 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  font-style: italic;
}

.text--p91 {
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
}

.text--p92 {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}
