@btn-font-weight: 400;
@btn-border-radius-base: 4px;
@btn-border-radius-sm: 4px;
@btn-border-width: @border-width-base;
@btn-border-style: @border-style-base;
@btn-shadow: none;
@btn-primary-shadow: none;
@btn-text-shadow: none;

@btn-primary-color: #000000;
@btn-primary-bg: @main-color;

@btn-default-color: @text-color;
@btn-default-bg: @component-background;
@btn-default-border: @main-color;

@btn-danger-color: #fff;
@btn-danger-bg: @error-color;
@btn-danger-border: @error-color;

@btn-disable-color: @disabled-color;
@btn-disable-bg: @disabled-bg;
@btn-disable-border: @border-color-base;

@btn-default-ghost-color: @component-background;
@btn-default-ghost-bg: transparent;
@btn-default-ghost-border: @component-background;

@btn-font-size-lg: @font-size-lg;
@btn-font-size-sm: @font-size-base;
@btn-padding-horizontal-base: @padding-md - 1px;
@btn-padding-horizontal-lg: @btn-padding-horizontal-base;
@btn-padding-horizontal-sm: @padding-xs - 1px;

@btn-height-base: @height-base;
@btn-height-lg: @height-lg;
@btn-height-sm: @height-sm;

@btn-line-height: @line-height-base;

@btn-circle-size: @btn-height-base;
@btn-circle-size-lg: @btn-height-lg;
@btn-circle-size-sm: @btn-height-sm;

@btn-square-size: @btn-height-base;
@btn-square-size-lg: @btn-height-lg;
@btn-square-size-sm: @btn-height-sm;
@btn-square-only-icon-size: @font-size-base + 2px;
@btn-square-only-icon-size-sm: @font-size-base;
@btn-square-only-icon-size-lg: @btn-font-size-lg + 2px;

@btn-group-border: @primary-5;

@btn-link-hover-bg: transparent;
@btn-text-hover-bg: rgba(0, 0, 0, 0.018);

/*============================OVERRIDES============================*/

.ant-btn-primary {
  background-image: @background-gradient;
  font-style: 'Poppins';
  font-weight: 500;
  color: @text-primary-color;
  line-height: 21px;
  padding: 0px 24px;
  border: none !important;
  transition: all 0.25s ease-in-out;
  height: 32px;
  &:hover,
  &:focus {
    background: @main-color;
    color: @text-primary-color;
    border: none !important;
  }

  &[color='solid'] {
    background-image: unset;
    background-color: @main-color;
  }
}

.ant-btn-primary-auth {
  background-image: @background-gradient;
  font-style: 'Poppins';
  font-weight: 500;
  color: @text-primary-color;
  line-height: 21px;
  padding: 0px 24px;
  border: none !important;
  transition: all 0.25s ease-in-out;
  height: 40px;
  &:hover,
  &:focus {
    background: @main-color;
    color: @text-primary-color;
    border: none !important;
  }
}

.custom-link-button {
  text-decoration: underline;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #ffffff;
}

.custom-secondary-button {
  border: 1px solid @main-color;
  cursor: pointer;
  outline: none;
  font-style: 'Poppins';
  font-weight: 500;
  padding: 0px 24px;
  color: @text-primary-color;
  background-color: #ffffff;
  border-radius: 4px;
  transition: all 0.25s ease-in-out;

  &:hover,
  &:focus {
    color: @text-secondary-color;
    padding: 0px 28px;
  }
}

.custom-text-button {
  border: none;
  cursor: pointer;
  outline: none;
  font-style: 'Poppins';
  font-weight: 400;
  padding: 0px 20px;
  color: @text-primary-color;
  background-color: #ffffff;
  border-radius: 4px;
  transition: all 0.25s ease-in-out;

  &:hover,
  &:focus {
    color: @text-secondary-color;
  }
}

.custom-close-button {
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #ffffff;
  transition: all 0.25s ease-in-out;
  font-size: 22px;

  &:hover,
  &:focus {
    color: @text-secondary-color;
  }
}
