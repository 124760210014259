@import '../../../../styles/base/colors.less';

.whats-new-wrapper {
  padding-top: 28px;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;

  .whats-new-form {
    padding-right: 167px;
    border-right: 0.5px solid #000000;
    width: 50%;

    .whats-new-title {
      margin-bottom: 28px;
    }

    .whats-new-title-wrapper {
      margin-bottom: 23px;

      .whats-new-title-input {
        width: 100%;
        height: 40px;
      }
    }

    .whats-new-desc-wrapper {
      margin-bottom: 23px;
      width: 100%;
    }

    .whats-new-upload-wrapper {
      display: flex;
      flex-wrap: wrap;

      .delete-icon-wrapper {
        position: relative;

        .delete-icon {
          position: absolute;
          top: 65px;
          left: 56px;
        }

        .icon-visible {
          display: none;
        }
      }

      .whats-new-uploaded-images {
        margin-top: 20px;
        width: 120px;
        height: 120px;
        margin-right: 15px;
        object-fit: cover;

        .whats-new-image {
          width: 100%;
          height: 100%;
        }

        .whats-new-image:hover {
          opacity: 0.1;
        }
      }

      .whats-new-upload {
        margin-top: 20px;
        width: 120px;
        height: 120px;
        border: 2px dashed #999999;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .add-more-images {
        text-decoration: underline;
        margin-left: 28px;
        margin-top: 65px;
        cursor: pointer;
      }
    }

    .announce-button {
      margin-top: 35px;
    }
  }

  .table-wrapper {
    width: 50%;
  }

  .whats-new-date {
    margin-top: 26px;

    .date-picker {
      margin-top: 11px;

      .ant-picker-input {
        height: 40px;
      }
    }
  }
}

.upload-modal {
  .ant-modal-body {
    padding: 21px 54px;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 140px;
    height: 32px;
    vertical-align: top;
    border: none;
  }

  .upload-modal-container {
    display: flex;
    justify-content: center;

    .upload-image {
      display: flex;
      justify-content: center;
      padding-bottom: 16px;
    }

    .uploading-image-text {
      display: flex;
      justify-content: center;
    }

    .upload-dragger-wrapper {
      width: 243px;
      height: 243px;
      border: 2px dashed #999999;
    }
  }

  .cancel-button-upload {
    text-decoration: underline;
    text-align: center;
    padding-top: 15px;
    cursor: pointer;
  }
}

.failed-modal {
  .ant-modal-body {
    padding: 23px 18px 0px 18px;
  }

  .ant-modal-close {
    top: -3px;
  }

  .ant-modal-close-x {
    color: #333333;
  }
}

.main-title {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.uploadCsvModal {
  .ant-modal-content {
    padding: 10px 0px;
    border-radius: 10px;

    .ant-modal-body {
      padding: 0;

      .uploadCsvModal__dropHere {
        background-color: white;
        min-height: 173px;
        transition: background-color 0.3s ease-in-out;

        &>span {
          border: none;
          width: 100%;
          display: block;
        }

        &.drag--over {
          background-color: rgba(black, 0.2) !important;
        }

        .droppable {
          border: none;
          background: transparent;
        }

        .uploadCsvModal__dropHere__body {
          border: 2px dashed @dashed-border-color;
          background-color: white;
          min-height: 125px;
          border-radius: 20px;

          .text {
            text-align: center;
          }
        }
      }
    }
  }
}

.upload__processing {
  .retry__button {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
  }

  .progress__wrapper {
    border: 2px dashed @dashed-border-color;
    border-radius: 6px;

    .background__csv__image {
      position: relative;
      z-index: 1;

      img {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }

    .background__csv__err_image {
      position: relative;
      z-index: 1;

      img {
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
  }
}