.propertyName-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  gap: 10px;
  cursor: pointer;
  max-width: 436px;
  height: 36px;
  border-radius: 6px;
}

.propertyName-row:hover {
  border: 1px solid #ffd02b;
}

.editMode {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  gap: 10px;
  cursor: pointer;
  max-width: 436px;
  height: 36px;
  border: 1px solid #ffd02b;
  border-radius: 6px;
  background-color: white;
}
