.frame1000005337 {
  background: #ffffff;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  justify-self: flex-start;
  pointer-events: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  padding: 4px 0px 4px 52px;
  border-bottom: 0.5px solid #dddddd;
}
.frame1000005337isActiveSelect {
  background: #fff9e9;
}
.frame1000005337:hover {
  background: #fffaea;
}
.freeBox__vkXiM {
  display: block;
  position: absolute;
  width: auto;
  height: 100%;
  left: 0px;
  top: auto;
  right: 0px;
  min-height: 0;
  padding: 0px;
}
.freeBoxisActiveSelect__vkXiM9HnRm {
  background: #fff9e9;
}
.frame1000005337:hover .freeBox__vkXiM {
  background: #fffaea;
}
.frame1000002574 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-direction: row;
  flex-shrink: 1;
  width: 259px;
  pointer-events: none;
  padding: 4px 12px 4px 8px;
  border-right: 1px solid #ebebeb;
}
.frame1000002574 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002574 > :global(.__wab_flex-container) > *,
.frame1000002574 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002574 > :global(.__wab_flex-container) > picture > img,
.frame1000002574
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.emCheckbox2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.text__w25Qt {
  display: block;
  font-size: 12px;
  letter-spacing: 0em;
  line-height: 16px;
  position: relative;
  pointer-events: none;
  max-width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  width: 100%;
  min-width: 0;
}
.freeBox__fehu {
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 370px;
  height: auto;
  top: 4px;
  left: 24px;
  background: #ffffff;
  z-index: 3;
  flex-shrink: 0;
  display: none;
}
.frame1000005337:hover .freeBox__fehu {
  display: none;
}
.text__zFf7L {
  display: block;
  font-size: 12px;
  letter-spacing: 0em;
  line-height: 16px;
  pointer-events: none;
  max-width: 370px;
  font-weight: 500;
  width: 100%;
  white-space: pre-wrap;
  min-width: 0;
}
.text___2KO {
  position: relative;
  width: 114px;
  height: auto;
  max-width: 120px;
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  padding-right: 2px;
  flex-shrink: 0;
}
.text__m1Zb9 {
  position: relative;
  width: 20px;
  height: auto;
  max-width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  flex-shrink: 0;
}
