.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.mapSettings {
  background: rgba(255, 255, 255, 1);
  filter: blur(0px);
  width: 264px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0px 1px 6px 0px #00000026;
  border-radius: 8px;
}
.frame1000005308 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding: 8px 12px;
  border-bottom: 0.5px solid #d4d4d4;
}
.frame1000005308 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005308 > :global(.__wab_flex-container) > *,
.frame1000005308 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005308 > :global(.__wab_flex-container) > picture > img,
.frame1000005308
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__o41G {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.54;
  color: rgba(51, 51, 51, 1);
  position: relative;
  width: 209px;
  flex-shrink: 0;
}
.frame1000005310 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding: 8px 12px;
}
.frame1000005310 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000005310 > :global(.__wab_flex-container) > *,
.frame1000005310 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005310 > :global(.__wab_flex-container) > picture > img,
.frame1000005310
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000006015 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame1000006014 {
  width: auto;
  height: 25px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
}
.frame1000006014 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006014 > :global(.__wab_flex-container) > *,
.frame1000006014 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006014 > :global(.__wab_flex-container) > picture > img,
.frame1000006014
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.showServiceLabelsOnMap {
  align-self: stretch;
  position: relative;
  width: 208px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.text___30Lig {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.54;
  color: rgba(51, 51, 51, 1);
}
.toggle {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.toggle > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 7.5px);
  height: calc(100% + 7.5px);
}
.toggle > :global(.__wab_flex-container) > *,
.toggle > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.toggle > :global(.__wab_flex-container) > picture > img,
.toggle
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 7.5px;
}
._switch {
  width: 24px;
  height: 15px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.group1000001991 {
  width: 24px;
  height: 15px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}
.base {
  width: 24px;
  height: 15px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}
.rectangle26811 {
  background: rgba(209, 209, 210, 1);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  cursor: pointer;
  border-radius: 15px;
}
.rectangle26811toggleOn {
  background: #ffd02b;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: translate(-0.5px, -0.5px);
  width: 12px;
  height: 12px;
  display: block;
  top: 2px;
  left: 1px;
}
.svgtoggleOn {
  left: 12px;
  top: 2px;
}
.text__tXqfM {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.54;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 208px;
  white-space: pre-wrap;
}
