.share-property-modal {
  .share-email-select {
    .ant-select-selector {
      border: 1px solid #e3e3e3;
      border-radius: rem(4) !important;
      width: 555px;
      padding-right: 100px;
    }
  }
  .ant-select-multiple .ant-select-selection-item {
    background-color: transparent;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }

  .ant-modal-body,
  .ant-modal-header {
    padding: 1rem;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .ant-modal-header .ant-modal-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-overflow: ellipsis;
    padding-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    color: #333333;
  }

  .ant-select-selection-search {
    align-items: center;
    display: flex;
    height: 30px;
  }

  .ant-select-selection-placeholder {
    margin-top: 1px;
  }
  .ant-modal-content {
    border-radius: 8px;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.2));
    .ant-modal-header {
      border-radius: 8px 8px 0px 0px;
    }
  }
  .share-email-select {
    .ant-select-selector {
      border: 1px solid #e3e3e3;
      width: 555px;
      padding-right: 100px;
    }
  }
  .ant-select-multiple .ant-select-selection-item {
    background-color: transparent;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    background: #999999 !important;
    color: #eaeaea;
  }
  .specific-user-access-dd {
    width: rem(50);
    justify-content: space-between !important;
  }
}

.share-warning-popover {
  left: 100px;
  .ant-popover-inner {
    border-radius: rem(10);
    padding: rem(10) rem(8) rem(0) rem(10);
  }
}
.share-search-dropdown {
  min-width: 122px !important;
  .ant-dropdown-menu {
    top: rem(6);
  }
}
.global-permission-dd {
  .ant-dropdown-menu {
    padding: 8px 0;
    .ant-dropdown-menu-item {
      padding: 6px 12px;
    }
  }
}
.specific-user-dd {
  ul {
    li:last-child {
      border-top: 1px solid #cccccc;
    }
  }
}
.share-email-select-dd {
  .ant-select-item {
    border: none;
  }
}
.view-edit-global-dd {
  svg {
    position: relative;
    left: 4px;
  }
}
