.number {
  width: 26px;
  height: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 31.065px;
  padding: 2.485px 5.798px;
}
.number > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.627px);
  height: calc(100% + 6.627px);
}
.number > :global(.__wab_flex-container) > *,
.number > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.number > :global(.__wab_flex-container) > picture > img,
.number
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.627px;
}
.numberopenServiceItemColumn {
  padding: 0px;
}
.numberarchived {
  box-shadow: 0px 0px 0px 0.5px #dddddd;
  padding: 0px;
}
.frame1000005960 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 0.5px rgba(221, 221, 221, 1);
  width: 26px;
  height: 16px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 42.187px;
}
.frame1000005960 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 9px);
  height: calc(100% + 9px);
}
.frame1000005960 > :global(.__wab_flex-container) > *,
.frame1000005960 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005960 > :global(.__wab_flex-container) > picture > img,
.frame1000005960
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 9px;
}
.frame1000005960openServiceItemColumn {
  box-shadow: 0px 0px 0px 0.5px rgba(221, 221, 221, 1);
  display: flex;
}
.frame1000005960archived {
  padding-left: 7px;
  padding-right: 7px;
  display: none;
}
.frame1000005960archived > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: center;
}
.text {
  display: block;
  font-size: 9px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 13.5px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  max-width: 22px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.textopenServiceItemColumn {
  line-height: 100%;
  display: block;
}
.textarchived {
  display: none;
}
.img__iiZ7 {
  object-fit: cover;
  max-width: 100%;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.img__iiZ7 > picture > img {
  object-fit: cover;
}
.imgarchived__iiZ7PHfJ8 {
  display: none !important;
}
.img__c9EeW {
  object-fit: contain;
  max-width: 100%;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  display: none !important;
}
.img__c9EeW > picture > img {
  object-fit: contain;
}
.imgarchived__c9EeWpHfJ8 {
  display: block !important;
}
.img__zMuIv {
  object-fit: cover;
  max-width: 100%;
  width: 26px;
  display: none !important;
}
.img__zMuIv > picture > img {
  object-fit: cover;
}
.imgarchived__zMuIvpHfJ8 {
  display: block !important;
}
