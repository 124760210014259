@px: 16rem;

.antd-autocomplete {
  margin: 0 auto;
  animation: mount 0.6s;
  animation-iteration-count: 1;
}

@keyframes mount {
  from {
    width: 400px;
  }
  to {
    width: 97%;
  }
}

.autocomplete-label {
  position: absolute;
  z-index: 2;
  left: 62 / @px;
  font-size: 14 / @px;
  cursor: auto;
  pointer-events: none;
  display: inline-block;
  animation: labelmount 0.6s;
  animation-iteration-count: 1;
}
.small-autocomplete-label {
  position: absolute;
  z-index: 2;
  left: 28 / @px;
  top: rem(6);
  font-size: 14 / @px;
  cursor: auto;
  pointer-events: none;
  display: inline-block;
}

@keyframes labelmount {
  from {
    left: 210 / @px;
  }
  to {
    left: 62 / @px;
  }
}

.antd-autocomplete {
  .form-input {
    background: #ffffff;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    border-radius: 20px;
    height: 32 / @px;
    transition: 0.5s all ease;

    .ant-input {
      line-height: 28px;
      position: relative;
      top: -1px;
      left: 4px;
      color: #333;
    }

    input.ant-input ::placeholder {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px !important;
      line-height: 16px;
    }
  }
  .ant-input-prefix {
    padding-right: 4px;
  }
  .ant-input-suffix {
    position: relative;
    top: 2px;
  }
}
.certain-category-search-dropdown {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px !important;
  z-index: 2;
  padding-top: 12 / @px;
  padding-bottom: 12 / @px;
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: flex-end;
    margin-left: rem(4);
    cursor: pointer;
    color: #000000;
  }
  .ant-select-item-option {
    padding-left: 34 / @px;
  }
}
.label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    position: relative;
    top: 6 / @px;
  }
  .order-id {
    margin-right: 28 / @px;
    background-color: #fffae8;
    padding: 4 / @px;
    font-size: rem(12);
  }
}
