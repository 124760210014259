.open-mobile-app {
  background: white;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  min-width: 100%;
  height: auto;
  z-index: 999;
  align-items: center;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

.open-mobile-link {
  display: flex;

  div:first-child {
    width: 40%;

    svg {
      max-width: 90%;
      margin: 0.5rem;
    }
  }

  div:last-child {
    width: 60%;
    background: #ffd02b;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
}

.close-mobile-app {
  background: transparent;
  position: absolute;
  top: -1.3rem;
  right: 1rem;
  border: none;
  border-radius: 50%;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
