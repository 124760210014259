@import '../../../styles/base/colors.less';

.responsive__createProperty {
  position  : fixed;
  bottom    : 0;
  left      : 0;
  width     : 100%;
  min-height: 52px;
  background: white;
  z-index   : 10;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.12);
}

#navbar {
  .new-property-wrapper {
    position: relative;

    .new-property-style {
      margin-left: 10px;

      .text {
        padding-right: 10.5px;
        border-right : 1px solid @nav-text-color;
      }

      img {
        margin-left: 7.5px;
      }
    }
  }
}

.new-property-wrapper {
  display        : flex;
  justify-content: center;
  align-items    : center;

  .new-property-style {
    margin-left: 10px;
  }
}

.new-property-btn.ant-btn-primary:hover {
  background: #ffe06f;
}


.nav__property__dropdown {

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
    border-bottom: 2px solid #ffd02b !important;
  }
}


.nav__property__dropdown {
  .nav__property__dropdown__menu {
    margin-top   : 0;
    border-radius: 8px;
    padding      : 0;
    filter       : drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.2));

    li {
      padding: 8px 12px 8px 16px;

      &:not(:last-child) {
        border-bottom: 1px solid @border-bottom-color3;
      }

      img {
        margin-right: 6.36px;
      }
    }

    li:hover {
      background-color: #f3f4f6;
    }
  }
}
