.frame1000005240 {
  background: rgba(255, 255, 255, 1);
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-self: flex-start;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 0;
  min-height: 0;
  padding: 10px 8px 8px;
}
.frame1000005240 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 3.5px);
  width: calc(100% + 3.5px);
}
.frame1000005240 > :global(.__wab_flex-container) > *,
.frame1000005240 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005240 > :global(.__wab_flex-container) > picture > img,
.frame1000005240
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 3.5px;
}
.frame1000005240:hover {
  background: linear-gradient(#f5f5f5, #f5f5f5), rgba(255, 255, 255, 1);
}
.chevron2 {
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-left: calc(-3px + 3.5px) !important;
  flex-shrink: 0;
}
.chevron2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.chevron2 > :global(.__wab_flex-container) > *,
.chevron2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.chevron2 > :global(.__wab_flex-container) > picture > img,
.chevron2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.chevron2isDropDownOpen {
  margin-left: calc(-3px + 3.5px) !important;
}
.chevron2isDropDownOpen > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-start;
}
.svg__nhIy8 {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.svgisDropDownOpen__nhIy8GLfdE {
  display: none;
}
.svg__d7GXl {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.svgisDropDownOpen__d7GXlgLfdE {
  display: block;
}
.component151 {
  width: 25px;
  height: 16px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.component151 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.component151 > :global(.__wab_flex-container) > *,
.component151 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.component151 > :global(.__wab_flex-container) > picture > img,
.component151
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 25px;
}
.img > picture > img {
  object-fit: cover;
}
.freeBox__skMef {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin-left: calc(2px + 3.5px) !important;
  padding-top: 0px;
  min-width: 0;
  min-height: 0;
}
.freeBox__skMef > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.freeBox__skMef > :global(.__wab_flex-container) > *,
.freeBox__skMef > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__skMef > :global(.__wab_flex-container) > picture > img,
.freeBox__skMef
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.freeBoxisDropDownOpen__skMefGLfdE {
  margin-left: calc(2px + 3.5px) !important;
}
.frame1000001961 {
  height: auto;
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  flex-shrink: 0;
  width: 100%;
  align-self: flex-start;
  min-width: 0;
}
.frame1000001961 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 5px);
  width: calc(100% + 5px);
}
.frame1000001961 > :global(.__wab_flex-container) > *,
.frame1000001961 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001961 > :global(.__wab_flex-container) > picture > img,
.frame1000001961
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5px;
}
.freeBox__rTqgf {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__rTqgf > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox__rTqgf > :global(.__wab_flex-container) > *,
.freeBox__rTqgf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rTqgf > :global(.__wab_flex-container) > picture > img,
.freeBox__rTqgf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_ZopIv3fl7mtC {
  outline: none;
  padding: 0px;
}
.text__o0ZAj {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 18px;
  color: rgba(51, 51, 51, 1);
  position: relative;
  white-space: pre-wrap;
  max-width: auto;
  width: 100%;
  min-width: 0;
}
.tooltipContent:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.tooltip:global(.__wab_instance) {
  max-width: auto;
  object-fit: cover;
  font-size: 12px;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  display: none;
}
.text__uCh1W {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.5;
  color: rgba(51, 51, 51, 1);
  position: relative;
  white-space: pre-wrap;
  max-width: auto;
  width: auto;
}
._22Assigned {
  align-self: stretch;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
._22Assigned2 {
  display: block;
  flex-grow: 1;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(102, 102, 102, 1);
  white-space: pre;
  line-height: 2;
  padding-top: 0px;
  width: 100%;
  min-width: 0;
}
