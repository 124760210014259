.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  background: #000000;
  border-radius: 4px;
}
.frame1000005957 {
  background: #000000;
  opacity: 0.899;
  width: 200px;
  height: auto;
  display: flex;
  overflow: auto;
  flex-direction: column;
  max-height: 232px;
  border-radius: 4px;
  padding: 8px;
}
.frame1000005957 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000005957 > :global(.__wab_flex-container) > *,
.frame1000005957 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005957 > :global(.__wab_flex-container) > picture > img,
.frame1000005957
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.takeoffLayerPanelServiceItemPopupListItem:global(.__wab_instance) {
  position: relative;
}
