.frame1000005337 {
  background: #ffffff;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  justify-self: flex-start;
  pointer-events: auto;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  min-width: 0;
  padding: 2px 0px 2px 42px;
  border-bottom: 0.5px solid #dddddd;
}
.frame1000005337isActiveSelect {
  background: #fffaea;
  height: 32px;
}
.frame1000005337openServiceItemColumn {
  justify-content: flex-start;
  width: 100%;
  height: 32px;
  justify-self: flex-start;
  align-items: center;
  padding-left: 42px;
  min-width: 0;
}
.frame1000005337:hover {
  background: #fffaea;
  box-shadow: none;
}
.frame1000005337isActiveSelect:hover {
  background: #fffaea;
}
.freeBox__wnMhb {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: auto;
  right: 0px;
  z-index: 0;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBoxisActiveSelect__wnMhBuJDxc {
  background: none;
}
.freeBoxopenServiceItemColumn__wnMhBiMq9T {
  width: 100%;
  height: 32px;
  min-width: 0;
}
.frame1000005337:hover .freeBox__wnMhb {
  background: none;
}
.frame1000002574 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-direction: row;
  flex-shrink: 0;
  width: 246px;
  pointer-events: none;
  padding: 4px 8px;
  border-right: 1px solid #e8e8e8;
}
.frame1000002574 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000002574 > :global(.__wab_flex-container) > *,
.frame1000002574 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002574 > :global(.__wab_flex-container) > picture > img,
.frame1000002574
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000002574isActiveSelect {
  border-right-color: #dbdbdb;
}
.frame1000002574openServiceItemColumn {
  width: 134px;
  flex-shrink: 1;
  padding-right: 8px;
}
.emCheckbox2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.text__eCcCq {
  display: block;
  font-size: 12px;
  letter-spacing: 0em;
  line-height: 16px;
  position: relative;
  pointer-events: none;
  max-width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  width: 100%;
  min-width: 0;
}
.tooltip:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.pcls_h6jR_T9f9-ah {
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  position: absolute;
  z-index: 100000;
  color: #333333;
  box-shadow: 0px 4px 16px 0px transparent;
  outline: none;
  padding: 0px;
}
.freeBox___4ZBHn {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
}
.svg___6UStR {
  object-fit: cover;
  color: #fbbf24;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.mrTakeoffLayerPanelTopoWarning__dpKHr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  z-index: 1000000;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 16px;
  flex-shrink: 0;
  display: none;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_FzFIIp_MKgjO {
  outline: none;
  padding: 0px;
}
.svg__xZ4XO {
  object-fit: cover;
  max-width: 100%;
  color: #fbbf24;
  width: 16px;
  height: 16px;
}
.mrTakeoffLayerPanelTopoWarning__tRyK:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__eDbJv {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 16px;
  height: 16px;
  max-width: 100%;
  flex-shrink: 0;
}
.frame1000005337:hover .freeBox__eDbJv {
  justify-content: center;
  align-items: center;
}
.frame1000005337:hover .freeBox__eDbJv:hover {
  background: #e9e9e9;
  border-radius: 20px;
}
.frame1000005337:hover .freeBox__eDbJv:active {
  box-shadow: inset 0px 0px 0px 1px #c9c9c9;
  border-radius: 20px;
}
.svg__vkrxq {
  object-fit: cover;
  max-width: 100%;
  color: red;
  width: 16px;
  height: 16px;
  overflow: hidden;
  cursor: pointer;
  flex-shrink: 0;
  display: none;
  border-radius: 18.666px;
}
.frame1000005337:hover .svg__vkrxq {
  display: block;
}
.freeBox__tg5Cl {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 136px;
  height: 24px;
  max-width: 100%;
  flex-shrink: 0;
  border-right: 1px solid #e8e8e8;
}
.freeBoxisActiveSelect__tg5CLuJDxc {
  border-right-color: #dbdbdb;
}
.freeBoxopenServiceItemColumn__tg5CLiMq9T {
  width: 136px;
  flex-shrink: 0;
}
.mrComponentRowMeasurement2:global(.__wab_instance) {
  max-width: 100%;
  height: 28px;
  flex-shrink: 0;
}
.mrComponentRowMeasurement2isActiveSelect:global(.__wab_instance) {
  flex-shrink: 0;
}
.mrComponentRowMeasurement2openServiceItemColumn:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.frame1000005337:hover .mrComponentRowMeasurement2:global(.__wab_instance) {
  flex-shrink: 0;
}
.freeBox___0Ceuk {
  flex-direction: row;
  position: relative;
  width: 32px;
  height: 24px;
  flex-shrink: 0;
  display: none;
}
.freeBox___0Ceuk > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox___0Ceuk > :global(.__wab_flex-container) > *,
.freeBox___0Ceuk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0Ceuk > :global(.__wab_flex-container) > picture > img,
.freeBox___0Ceuk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.freeBoxopenServiceItemColumn___0CeukiMq9T {
  width: 132px;
  height: 32px;
  margin-left: 4px;
  flex-shrink: 0;
  display: flex;
  padding: 0px;
}
.freeBoxopenServiceItemColumn___0CeukiMq9T > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.freeBoxopenServiceItemColumn___0CeukiMq9T > :global(.__wab_flex-container) > *,
.freeBoxopenServiceItemColumn___0CeukiMq9T
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxopenServiceItemColumn___0CeukiMq9T
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxopenServiceItemColumn___0CeukiMq9T
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.takeoffLayerServiceItemsNumber:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 0;
  display: none;
}
.takeoffLayerServiceItemsNumberopenServiceItemColumn:global(.__wab_instance) {
  flex-shrink: 0;
  display: none;
}
.text___6KXhZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #666666;
  font-size: 10px;
  min-width: 0;
  display: none;
}
.textopenServiceItemColumn___6KXhZiMq9T {
  display: none;
}
.mrTakeoffLayerServiceRowChipCollection___4Vg8M:global(.__wab_instance) {
  max-width: 100%;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
