@import '@/styles/base/colors.less';

.edit-btn.ant-btn {
    height: 20px;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFD02B;

    &.variant--primary-alt {
        background: rgba(217, 229, 255, 0.5);
        color: @lite-link;

        &:hover {
            background: rgba(16, 85, 235, 0.05);
        }

        .text {
            color: @lite-link !important;
        }
    }
}

.edit-btn.ant-btn:hover {
    background: #FFD02B;
}

.edit-btn.ant-btn[disabled] {
    background: #D1D5DB !important;
}