.workspace-switcher {
    .ant-input {
        padding: 8px 11px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #333333;

        ::placeholder {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: #999999;
        }
    }
}