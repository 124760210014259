.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 0px;
}
.freeBox___3SqdZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  top: auto;
  left: auto;
  align-self: flex-start;
  margin-top: 0px;
  margin-bottom: 0px;
  min-width: 0;
}
.text__nvA1P {
  width: 100%;
  height: auto;
  font-size: 12px;
  font-weight: 500;
  background: #ffffff;
  padding-left: 36px;
  padding-bottom: 8px;
  padding-top: 16px;
  flex-shrink: 0;
  position: relative;
  left: auto;
  top: auto;
  align-self: auto;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
  min-width: 0;
  border-width: 1px;
  border-color: #dddddd;
}
.frame1000005355 {
  background: rgba(255, 255, 255, 1);
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  cursor: pointer;
  box-shadow: none;
  margin-bottom: 0px;
  min-width: 0;
  flex-shrink: 0;
  border-bottom: 1px solid #dddddd;
}
.frame1000005355isActive {
  background: linear-gradient(#fdedbb, #fdedbb), rgba(255, 255, 255, 1);
}
.root:hover .frame1000005355 {
  background: #fffaea;
}
.rootisActive:hover .frame1000005355isActive {
  background: #fdedbb;
}
.freeBox__uyDcH {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  max-width: 100%;
  flex-shrink: 0;
}
.svg__osUxn {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 24px;
  height: 24px;
  pointer-events: auto;
  cursor: pointer;
  flex-shrink: 0;
}
.svg___5Le6V {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 24px;
  height: 24px;
  pointer-events: auto;
  cursor: pointer;
  flex-shrink: 0;
}
.freeBox__xi4Mb {
  display: block;
  position: absolute;
  height: 100%;
  width: auto;
  margin-left: 32px;
  left: 28px;
  right: 0px;
  z-index: 1;
  flex-shrink: 1;
  min-height: 0;
}
.freeBoxisActive__xi4MbPlGjR {
  width: 100%;
  min-width: 0;
}
.frame1000002580 {
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  pointer-events: none;
  width: 287px;
  margin-right: 0px;
  flex-shrink: 0;
  padding: 4px 8px;
  border-right: 1px solid #e8e8e8;
}
.frame1000002580 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002580 > :global(.__wab_flex-container) > *,
.frame1000002580 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002580 > :global(.__wab_flex-container) > picture > img,
.frame1000002580
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.emCheckbox:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.frame1000005356 {
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  pointer-events: none;
  width: 100%;
  padding-left: 4px;
  min-width: 0;
}
.frame1000005356 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.frame1000005356 > :global(.__wab_flex-container) > *,
.frame1000005356 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005356 > :global(.__wab_flex-container) > picture > img,
.frame1000005356
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.frame1000005356isActive > :global(.__wab_flex-container) {
  justify-content: space-between;
}
.tooltipCore:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__hyr3K {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
  pointer-events: auto;
  text-overflow: ellipsis !important;
  overflow: hidden;
  width: 100%;
  margin-bottom: 0px;
  min-width: 0;
}
.freeBox___4ESa {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  box-shadow: none;
  background: #333333;
  min-height: 0;
  border-radius: 4px;
  padding: 4px 8px;
}
.text__m8T8B {
  font-size: 12px;
  color: #ffffff;
  width: auto;
  height: auto;
  max-width: 223px;
  white-space: pre-wrap;
}
.freeBox__xUaex {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 38px;
}
.assignedServiceItemsChips {
  background: none;
  width: auto;
  height: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 26px;
  padding: 2px 4px;
}
.assignedServiceItemsChips > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.assignedServiceItemsChips > :global(.__wab_flex-container) > *,
.assignedServiceItemsChips
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.assignedServiceItemsChips > :global(.__wab_flex-container) > picture > img,
.assignedServiceItemsChips
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.frame1000005329 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000005329 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.frame1000005329 > :global(.__wab_flex-container) > *,
.frame1000005329 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005329 > :global(.__wab_flex-container) > picture > img,
.frame1000005329
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.serviceItemDot {
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.rectangle26938 {
  background: rgba(118, 237, 237, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 12px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 11.999px;
  height: 12px;
  display: flex !important;
  flex-direction: row;
  flex-shrink: 0;
}
.img > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.img > :global(.__wab_flex-container) > *,
.img > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.img > :global(.__wab_flex-container) > picture > img,
.img > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 11.999px;
  height: 12px;
  display: flex !important;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.freeBox__vvWsw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: auto;
  max-width: 100%;
}
.emTakeOffLayerChips:global(.__wab_instance) {
  max-width: 100%;
  width: 40px;
  flex-shrink: 0;
}
.text__vqUgA {
  position: relative;
  width: 104px;
  height: auto;
  max-width: 100%;
  text-align: right;
  padding-right: 2px;
  font-weight: 500;
  font-size: 12px;
  margin-left: 10px;
  flex-shrink: 0;
}
.text__acAWn {
  position: relative;
  width: 20px;
  height: auto;
  max-width: 100%;
  text-align: left;
  padding-right: 0px;
  font-weight: 500;
  font-size: 12px;
  flex-shrink: 0;
}
