@import '../../../style.less';

.settings-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .settings-icon {
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 37.2px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-menu-item-selected,
  .ant-menu-item-active::after {
    border-bottom-color: transparent !important;
    background: transparent;
  }

  .settings-icon:hover {
    background: #e9e9e9;
  }

  .settings-icon:active, .settings-icon--active {
    background: #e9e9e9;
    border: 1px solid #c9c9c9 !important;
  }

  .settings-icon.disabled {
    cursor: not-allowed !important;
    background: #ffffff !important;
    pointer-events: none;
  }

  .settings-icon.disabled:hover {
    background: #ffffff !important;
  }

  .ant-menu-title-content {
    display: flex;
    align-items: center;
  }
}

.settings-popover {
  .ant-popover-inner {
    padding: 0px;
  }
  .ant-popover-inner-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 8px 8px;
    gap: 8px;
    isolation: isolate;

    width: 256px;

    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
  }
  .tool {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px;
    gap: 8px;

    width: 240px;
    height: 36px;

    background: #ffffff;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #e9e9e9;
    }

    .image {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .pin {
      display: flex;
      align-items: center;
      justify-content: center;
      right: 20px;
      position: absolute;
      cursor: pointer;
    }
  }
}
