.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.rootisDisabled {
  cursor: default;
}
.checkboxWithText2 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 4px;
  padding-right: 8px;
  padding-bottom: 4px;
  min-width: 0;
}
.checkboxWithText2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.checkboxWithText2 > :global(.__wab_flex-container) > *,
.checkboxWithText2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.checkboxWithText2 > :global(.__wab_flex-container) > picture > img,
.checkboxWithText2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBox__zb96Y {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
}
.freeBox__zb96Y > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 5px);
  width: calc(100% + 5px);
}
.freeBox__zb96Y > :global(.__wab_flex-container) > *,
.freeBox__zb96Y > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zb96Y > :global(.__wab_flex-container) > picture > img,
.freeBox__zb96Y
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5px;
}
.freeBox__xrfCw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 3px;
  min-width: 0;
}
.freeBox__ofSsa {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 15px;
  max-width: 100%;
  min-width: 0;
}
.emSpinner:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.emCheckBox:global(.__wab_instance) {
  max-width: 100%;
  width: 16px;
  margin-bottom: 0px;
  flex-shrink: 0;
}
.emCheckBoxisDisabled:global(.__wab_instance) {
  flex-shrink: 0;
}
.rootisDisabled:hover .emCheckBoxisDisabled:global(.__wab_instance) {
  flex-shrink: 0;
}
.tooltip:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 16px;
  height: 16px;
  margin: 0px 0px 0px 4px;
}
.img > picture > img {
  object-fit: cover;
}
.freeBox___8V7AN {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  padding: 2px 0px 0px;
}
.freeBox___8V7AN > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-end;
  margin-left: calc(0px - 5px);
  width: calc(100% + 5px);
  margin-top: calc(0px - 3px);
  height: calc(100% + 3px);
}
.freeBox___8V7AN > :global(.__wab_flex-container) > *,
.freeBox___8V7AN > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___8V7AN > :global(.__wab_flex-container) > picture > img,
.freeBox___8V7AN
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5px;
  margin-top: 3px;
}
.freeBox___26SGi {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-shrink: 1;
  width: auto;
  padding: 0px;
}
.mowing322 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  color: #333333;
  white-space: pre-wrap;
  line-height: 1.5;
  flex-shrink: 1;
  flex-grow: 0;
  width: auto;
}
.mowing322isDisabled {
  color: #999999;
}
.rootisDisabled:hover .mowing322isDisabled {
  color: #333333;
}
.frame1000001994 {
  background: rgba(244, 244, 244, 1);
  width: 45px;
  height: 16px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 2px 8px;
}
.frame1000001994 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000001994 > :global(.__wab_flex-container) > *,
.frame1000001994 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001994 > :global(.__wab_flex-container) > picture > img,
.frame1000001994
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.frame1000001994isDisabled {
  background: #f5f5f5;
}
.rootisDisabled:hover .frame1000001994isDisabled {
  background: #f4f4f4;
}
.text {
  display: block;
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 45px;
  height: 15px;
  text-align: center;
  line-height: 2;
  flex-shrink: 0;
}
.textisDisabled {
  color: #999999;
}
.rootisDisabled:hover .textisDisabled {
  color: #666666;
}
.freeBox___0UhCj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  align-content: center;
  min-height: 0;
}
._22Assigned4 {
  display: block;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(102, 102, 102, 1);
  white-space: pre;
}
._22Assigned4isDisabled {
  color: #999999;
}
.rootisDisabled:hover ._22Assigned4isDisabled {
  color: #666666;
}
.freeBox___3Myjc {
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  display: none;
}
.freeBox___3Myjc > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox___3Myjc > :global(.__wab_flex-container) > *,
.freeBox___3Myjc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3Myjc > :global(.__wab_flex-container) > picture > img,
.freeBox___3Myjc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.freeBox__mU70P {
  position: relative;
  width: auto;
  flex-direction: row;
  display: none;
  padding: 0px;
}
