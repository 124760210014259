.access-wall-container {
  .property-org-card {
    background-color: #F5F5F5;
    border-radius: 4px;
  }

  .unauthorised-image {
    height: 100vh;
  }
}
