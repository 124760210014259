.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.frame1000005740 {
  width: 456px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.frame1000005740 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000005740 > :global(.__wab_flex-container) > *,
.frame1000005740 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005740 > :global(.__wab_flex-container) > picture > img,
.frame1000005740
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.group1000002087 {
  width: 100px;
  height: 89px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.group1000002085 {
  width: 100px;
  height: 89px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}
.svg__aFdBb {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #eee;
  transform: translate(0px, -0.32500000000000284px);
  width: 100px;
  height: 62px;
  display: block;
  top: 27px;
  left: 0px;
}
.rectangle26942 {
  background: rgba(255, 208, 42, 1);
  transform: translate(-0.21700000000001296px, 0.07000000000000028px);
  width: 64px;
  height: 77px;
  display: block;
  position: absolute;
  top: 12px;
  left: 15px;
  border-radius: 5.427px;
}
.rectangle26944 {
  background: rgba(214, 167, 0, 1);
  transform: scale(0.9994598541212149, 0.9992977665702922)
    rotate(-3.0971522847343227deg) skew(-0.17172851698009206deg, 0deg)
    translate(-0.3089999999999975px, -0.0730000000000004px);
  width: 51px;
  height: 66px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 20px;
  left: 19px;
  border-radius: 2.713px;
}
.rectangle26943 {
  background: rgba(255, 255, 255, 1);
  transform: translate(0.20699999999999363px, 0.4579999999999984px);
  width: 51px;
  height: 64px;
  display: block;
  position: absolute;
  top: 16px;
  left: 21px;
  border-radius: 2.713px;
}
.rectangle26945 {
  background: rgba(215, 215, 215, 1);
  transform: translate(-0.10099999999999909px, -0.22399999999999665px);
  width: 38px;
  height: 11px;
  display: block;
  position: absolute;
  top: 9px;
  left: 28px;
  border-radius: 13.569px 13.569px 5.427px 5.427px;
}
.group1000002084 {
  transform: translate(0.35300000000000864px, 0px);
  width: 9px;
  height: 9px;
  display: block;
  position: absolute;
  top: 0px;
  left: 42px;
}
.svg__mLHd {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #d8d8d8;
  width: 9px;
  height: 9px;
  display: block;
  top: 0px;
  left: 0px;
  transform: translate(0.0010000000000047748px, 0px);
}
.rectangle26947 {
  background: rgba(172, 172, 172, 1);
  transform: translate(0px, 0.13300000000000267px);
  width: 9px;
  height: 2px;
  display: block;
  position: absolute;
  top: 7px;
  left: 0px;
}
.svg__ghx5U {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #c5c5c5;
  width: 15px;
  height: 11px;
  display: block;
  top: 9px;
  left: 51px;
  transform: translate(0.3329999999999984px, -0.21300000000000097px);
}
.svg__rLwb {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #d9d9d9;
  transform: translate(-0.3830000000000098px, -0.1039999999999992px);
  width: 46px;
  height: 5px;
  display: block;
  top: 75px;
  left: 24px;
}
.svg__qpxJu {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ccc;
  transform: translate(-0.07400000000001228px, 0.490000000000002px);
  width: 32px;
  height: 22px;
  display: block;
  top: 29px;
  left: 53px;
  border-radius: 13.569px;
}
.svg__ohMnX {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ccc;
  transform: translate(-0.3640000000000043px, 0.16500000000000625px);
  width: 6px;
  height: 4px;
  display: block;
  top: 51px;
  left: 47px;
  border-radius: 13.569px;
}
.img {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: scale(0.9988413287404562, 0.9996883101134304)
    rotate(13.312780089515309deg) skew(0.5419967014944053deg, 0deg)
    translate(-0.375px, 0.232999999999997px);
  width: 15.189px;
  height: 17.064px;
  display: block !important;
  transform-origin: top left;
  top: 14px;
  left: 84px;
}
.img > picture > img {
  object-fit: cover;
}
.frame1000005335 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
}
.text {
  display: block;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0px;
  line-height: 1.54;
  position: relative;
  width: 332px;
  white-space: pre-wrap;
}
.span {
  font-size: 12px;
  font-weight: 500;
}
.frame1000006326 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000006326 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006326 > :global(.__wab_flex-container) > *,
.frame1000006326 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006326 > :global(.__wab_flex-container) > picture > img,
.frame1000006326
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.buttonsMarch24 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(208, 212, 215, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 16px;
}
.buttonsMarch24 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch24 > :global(.__wab_flex-container) > *,
.buttonsMarch24 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch24 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch24
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.root .buttonsMarch24:hover {
  box-shadow: inset 0px 0px 0px 1px #ffb02b;
}
.root .buttonsMarch24:active {
  box-shadow: 0px 0px 0px 2px #ffb02b;
}
.buttonText {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.buttonsMarch25 {
  background: rgba(255, 208, 42, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 208, 42, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 36px;
}
.buttonsMarch25 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch25 > :global(.__wab_flex-container) > *,
.buttonsMarch25 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch25 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch25
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.root .buttonsMarch25:hover {
  background: #ffe06e;
  box-shadow: inset 0px 0px 0px 1px #ffb02b;
}
.root .buttonsMarch25:active {
  box-shadow: 0px 0px 0px 2px #ffb02b;
}
.buttonText2 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
