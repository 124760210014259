@import '../../../../../../../../styles/base/colors.less';

@mobileScreen: 600px;

.addPeopleModal {
  .ant-modal-content {
    border-radius: 20px;

    .ant-modal-body {
      padding: 1rem 1rem 0rem 1rem;

      .addPeopleModal__select {
        .ant-select-selector {
          border-radius: 6px;
        }

        &.view {
          .ant-select-arrow {
            width: 50px;
            user-select: none;
            pointer-events: none;
            color: black;
          }
        }
      }

      .addPeopleModal__profile {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: lightgrey;
      }

      .guestTag {
        width: 50px;
        min-height: 18px;
        border-radius: 10px;
        background-color: @add-people-guest;
      }
    }
  }
}

.shareOutSideModal {
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-body {
      padding: 1rem;

      .text__button {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: @mobileScreen) {
  .addPeopleModal {
    margin: 0px -50px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding-bottom: 0px;

    .ant-modal-content {
      height: 100%;
      border-radius: 0px;

      .ant-modal-body {
        height: calc(100% - 50px);
      }
    }
  }
}