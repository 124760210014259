@import '@/styles/base/colors.less';

.otp-input-style {
  width: 2rem !important;
  margin: 6px;
  background: transparent;
  border: transparent;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: @tag-text-color;
  border-bottom: 2px solid @tag-text-color;
}

.otp-focus-style {
  outline: none;
}

.white-bg {
  background: white;
  border-radius: 8px;
}

.poppins {
  font-family: Poppins;
  font-style: normal;
}

.acct-verification {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #333333;
}

.check-code {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center !important;
}

.email {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.edit-icon {
  margin-left: 1rem;
  cursor: pointer;
}

.otp-not-sent {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding-bottom: 1rem;
}

.back-button {
  position: absolute;
  top: 0;
  left: 0.5rem;
}

.signup-header {
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;
}

@media only screen and (min-width: 768px) {
  .signup-header {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 36px;
  }
}

.error-text {
  color: #dc2a2a !important;
  padding-left: 0.5rem;
  line-height: initial;
}

.otp-not-recieved {
  line-height: 18px !important;
  text-align: center !important;
  font-size: 0.75rem;
  margin-top: 1rem;
}

.w-100 .ant-space-item {
  width: 100%;
}

.d-none {
  visibility: hidden;
}

.tags-wrapper {
  border-radius: 16px;
}

.user-job-title {
  border-radius: 16px;
}

.tags-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #333333;
}

.user-job-title-wrapper {
  .ant-modal-content {
    border-radius: 16px !important;
  }
}

.otp-not-recieved {
  color: #000;
}

.otp-form {
  .ant-btn-primary {
    font-weight: 600 !important;
  }
}

.error-otp {
  position: absolute;
  bottom: 1.2rem;
}
