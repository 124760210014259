.bulk-project-name-input {
  height: 40px;
  margin-top: 8px;
}
.bulk-project-action-section {
  .save-button {
    margin-left: 16px;
  }
}

.bulk-upload-button {
  top: 65px;
  position: fixed;
  left: 75vw;
  display: flex;
  align-items: center;
  border-color: #ffffff;
}

.bulk-project-upload-icon {
  order: 1;
  margin-left: 10px;
}

.step1__modal {
  .ant-modal-content {
    padding: 12px 38px;
    border-radius: 10px;

    .ant-modal-body {
      padding: 0;
    }
  }
}

.bulk-project-action-section {
  .save-button button:disabled, button[disabled] {
    opacity: 1;
  }
}