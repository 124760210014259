.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.withGif {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px;
}
.withGif > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.withGif > :global(.__wab_flex-container) > *,
.withGif > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.withGif > :global(.__wab_flex-container) > picture > img,
.withGif
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 72px;
}
.img > picture > img {
  object-fit: cover;
}
.frame1000006060 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000006060 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.frame1000006060 > :global(.__wab_flex-container) > *,
.frame1000006060 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006060 > :global(.__wab_flex-container) > picture > img,
.frame1000006060
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.textAndSupportingText {
  width: 432px;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.textAndSupportingText > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.textAndSupportingText > :global(.__wab_flex-container) > *,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.textAndSupportingText > :global(.__wab_flex-container) > picture > img,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text {
  display: block;
  font-size: 18px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 28px;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
}
.supportingText {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.54;
  color: rgba(102, 102, 102, 1);
  align-self: stretch;
  position: relative;
}
.actions {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.actions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.actions > :global(.__wab_flex-container) > *,
.actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actions > :global(.__wab_flex-container) > picture > img,
.actions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.buttonsMarch24 {
  background: rgba(255, 208, 42, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 208, 42, 1);
  width: 164px;
  height: auto;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  display: none;
  border-radius: 4px;
  padding: 12px 24px;
}
.buttonsMarch24 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch24 > :global(.__wab_flex-container) > *,
.buttonsMarch24 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch24 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch24
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.tRprimaryButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 148px;
  flex-shrink: 0;
}
