.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.copyLayer {
  background: rgba(255, 255, 255, 1);
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2));
  width: 554px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 23px 24px;
}
.copyLayer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.copyLayer > :global(.__wab_flex-container) > *,
.copyLayer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.copyLayer > :global(.__wab_flex-container) > picture > img,
.copyLayer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.text__rrsaR {
  display: block;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.dropdown:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.menuItem:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__bSkjS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 12px;
  font-weight: 400;
  min-width: 0;
}
.iPField {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(208, 212, 218, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  border-radius: 8px;
  padding: 8px 12px;
}
.iPField > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.iPField > :global(.__wab_flex-container) > *,
.iPField > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.iPField > :global(.__wab_flex-container) > picture > img,
.iPField
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.text__gLLl5 {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 1.44;
  color: rgba(153, 153, 153, 1);
  position: relative;
}
.freeBox {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.chevron {
  transform: rotate(-90deg);
  width: 24px;
  height: 24px;
  display: flex;
  transform-origin: top left;
  position: absolute;
  flex-direction: column;
  left: 0px;
  top: 24px;
}
.chevron > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.chevron > :global(.__wab_flex-container) > *,
.chevron > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.chevron > :global(.__wab_flex-container) > picture > img,
.chevron
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6px;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.text___9Z6WP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 400;
  min-width: 0;
}
.frame1000006674 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000006674 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000006674 > :global(.__wab_flex-container) > *,
.frame1000006674 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006674 > :global(.__wab_flex-container) > picture > img,
.frame1000006674
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.actions {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.actions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.actions > :global(.__wab_flex-container) > *,
.actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actions > :global(.__wab_flex-container) > picture > img,
.actions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.tRsecondaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 108px;
  height: 43px;
  flex-shrink: 0;
}
.tRprimaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 108px;
  height: 44px;
  flex-shrink: 0;
}
