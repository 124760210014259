.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 32px;
  max-width: 100%;
  justify-self: flex-start;
  background: none;
  min-width: 0;
}
.root:hover {
  background: none;
  border-radius: 32px;
}
.root:active {
  box-shadow: none;
  border-radius: 32px;
}
.popover2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover2__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_iCDIEzAoFS4g {
  outline: none;
  padding: 0px;
}
.mapView2 {
  background: #f5f5f5;
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 8px;
}
.mapView2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.mapView2 > :global(.__wab_flex-container) > *,
.mapView2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.mapView2 > :global(.__wab_flex-container) > picture > img,
.mapView2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.root:active .mapView2 {
  box-shadow: 0px 0px 0px 1px #c9c9c9;
}
.root:hover .mapView2 {
  background: linear-gradient(#e8e8e8, #e8e8e8), #f5f5f5;
}
.frame1000004258 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  min-width: 0;
}
.frame1000004258 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000004258 > :global(.__wab_flex-container) > *,
.frame1000004258 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004258 > :global(.__wab_flex-container) > picture > img,
.frame1000004258
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.svg___01EhR {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 20px;
}
.svg___01EhR > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.svg___01EhR > :global(.__wab_flex-container) > *,
.svg___01EhR > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.svg___01EhR > :global(.__wab_flex-container) > picture > img,
.svg___01EhR
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.root:hover .svg___01EhR {
  color: #333333;
}
.root:active .svg___01EhR {
  color: #333333;
}
.frame1000005097 {
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 240px;
  width: 100%;
  min-width: 0;
}
.frame1000005097 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.frame1000005097 > :global(.__wab_flex-container) > *,
.frame1000005097 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005097 > :global(.__wab_flex-container) > picture > img,
.frame1000005097
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.text__rqbKs {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  white-space: pre;
  padding-right: 0px;
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 1;
}
.root:hover .text__rqbKs {
  color: #333333;
}
.root:active .text__rqbKs {
  color: #333333;
}
.frame1000002153 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002153 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002153 > :global(.__wab_flex-container) > *,
.frame1000002153 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002153 > :global(.__wab_flex-container) > picture > img,
.frame1000002153
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000002149 {
  width: auto;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 1;
}
.frame1000002149 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 3px);
  width: calc(100% + 3px);
}
.frame1000002149 > :global(.__wab_flex-container) > *,
.frame1000002149 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002149 > :global(.__wab_flex-container) > picture > img,
.frame1000002149
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 3px;
}
.svg__kcAwX {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 8px;
  height: 8px;
  display: block;
  flex-shrink: 0;
}
.root:hover .svg__kcAwX {
  color: #333333;
}
.text {
  display: block;
  font-size: 8px;
  text-align: left;
  text-decoration-line: underline;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(102, 102, 102, 1);
  position: relative;
  white-space: pre;
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 1;
  max-width: 140px;
}
.root:hover .text {
  color: #333333;
}
.root:active .text {
  color: #333333;
}
.root .text:hover {
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_G97eo3i5N9YG {
  outline: none;
  padding: 0px;
}
.freeBox {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 20px;
  height: 20px;
  max-width: 100%;
  border-radius: 6px;
}
.root .freeBox:hover {
  background: #3333331a;
  border-radius: 4px;
}
.svg__dxRTn {
  object-fit: cover;
  max-width: 100%;
  color: #aaa;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.root .svg__dxRTn:hover {
  color: #333333;
}
.tooltipContent__tGx2:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.chevron {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
}
.chevron > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.chevron > :global(.__wab_flex-container) > *,
.chevron > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.chevron > :global(.__wab_flex-container) > picture > img,
.chevron
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.svg__fl3Zg {
  position: relative;
  object-fit: cover;
  color: #666;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.root:hover .svg__fl3Zg {
  color: #333333;
}
.root:active .svg__fl3Zg {
  color: #333333;
}
.tooltipContent__wFsqF:global(.__wab_instance) {
  max-width: 100%;
}
