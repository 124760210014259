.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.confirmationPopups {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px;
}
.confirmationPopups > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.confirmationPopups > :global(.__wab_flex-container) > *,
.confirmationPopups > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.confirmationPopups > :global(.__wab_flex-container) > picture > img,
.confirmationPopups
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.frame1000003005 {
  width: 496px;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000003005 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.frame1000003005 > :global(.__wab_flex-container) > *,
.frame1000003005 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000003005 > :global(.__wab_flex-container) > picture > img,
.frame1000003005
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.featuredIcon {
  background: rgba(255, 241, 194, 1);
  box-shadow: 0px 0px 0px 4px rgba(255, 250, 234, 1),
    inset 0px 0px 0px 4px rgba(255, 250, 234, 1);
  width: 48px;
  height: 48px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 28px;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: #ffb222;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 12px;
  left: 12px;
  overflow: hidden;
}
.textAndSupportingText {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
}
.textAndSupportingText > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.textAndSupportingText > :global(.__wab_flex-container) > *,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.textAndSupportingText > :global(.__wab_flex-container) > picture > img,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text {
  display: block;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 28px;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
}
.supportingText {
  display: block;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 1.54;
  color: rgba(102, 102, 102, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
}
.span__auYzs {
  font-size: 14px;
  font-weight: 600;
  color: #666666;
}
.actions {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.actions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.actions > :global(.__wab_flex-container) > *,
.actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actions > :global(.__wab_flex-container) > picture > img,
.actions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.tRsecondaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 128px;
  height: 44px;
  flex-shrink: 0;
}
.tRprimaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
  height: 44px;
}
