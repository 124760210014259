.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
._118 {
  background: rgba(255, 255, 255, 1);
  width: 496px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px 24px 22px;
}
._118 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
._118 > :global(.__wab_flex-container) > *,
._118 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
._118 > :global(.__wab_flex-container) > picture > img,
._118 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.textAndSupportingText {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.textAndSupportingText > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.textAndSupportingText > :global(.__wab_flex-container) > *,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.textAndSupportingText > :global(.__wab_flex-container) > picture > img,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.frame1000006794 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006794 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006794 > :global(.__wab_flex-container) > *,
.frame1000006794 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006794 > :global(.__wab_flex-container) > picture > img,
.frame1000006794
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  position: relative;
  flex-shrink: 0;
  border-radius: 18.666px;
}
.img > picture > img {
  object-fit: cover;
}
.text {
  display: block;
  flex-grow: 1;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 28px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.supportingText {
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
  display: none;
}
.embedHtml:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
}
.frame1000006923 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000006923 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000006923 > :global(.__wab_flex-container) > *,
.frame1000006923 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006923 > :global(.__wab_flex-container) > picture > img,
.frame1000006923
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000006929 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006929 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006929 > :global(.__wab_flex-container) > *,
.frame1000006929 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006929 > :global(.__wab_flex-container) > picture > img,
.frame1000006929
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.selectionComponent {
  background: rgba(248, 249, 250, 1);
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: 8px;
}
.selectionComponent > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.selectionComponent > :global(.__wab_flex-container) > *,
.selectionComponent > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.selectionComponent > :global(.__wab_flex-container) > picture > img,
.selectionComponent
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.root .selectionComponent:hover {
  background: #f5f5f5;
}
.root .selectionComponent:active {
  background: #f9fafb;
}
.coParcelRadioBtn__uOnkW:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.frame1000006930 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;
}
.frame1000006930 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000006930 > :global(.__wab_flex-container) > *,
.frame1000006930 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006930 > :global(.__wab_flex-container) > picture > img,
.frame1000006930
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.supportingText2 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 18px;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
}
.supportingText3 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
}
.component259 {
  background: rgba(248, 249, 250, 1);
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  cursor: pointer;
  min-height: 0;
  border-radius: 4px;
  padding: 8px;
}
.component259 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.component259 > :global(.__wab_flex-container) > *,
.component259 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.component259 > :global(.__wab_flex-container) > picture > img,
.component259
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.root .component259:hover {
  background: #f5f5f5;
}
.root .component259:active {
  background: #f9fafb;
}
.coParcelRadioBtn__sBtFy:global(.__wab_instance) {
  max-width: 100%;
}
.frame1000006931 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;
}
.frame1000006931 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame1000006931 > :global(.__wab_flex-container) > *,
.frame1000006931 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006931 > :global(.__wab_flex-container) > picture > img,
.frame1000006931
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.supportingText4 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 18px;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
}
.supportingText5 {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
}
.crossIcon {
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  flex-direction: column;
  left: auto;
  top: -15px;
  right: 10px;
  bottom: 298px;
  flex-shrink: 0;
  padding: 8px;
}
.crossIcon > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.crossIcon > :global(.__wab_flex-container) > *,
.crossIcon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.crossIcon > :global(.__wab_flex-container) > picture > img,
.crossIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.frame1000006935 {
  width: 312px;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000006935 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.frame1000006935 > :global(.__wab_flex-container) > *,
.frame1000006935 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006935 > :global(.__wab_flex-container) > picture > img,
.frame1000006935
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.buttonsMarch29 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(208, 212, 215, 1);
  width: 148px;
  height: auto;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  display: none;
  border-radius: 4px;
  padding: 12px 24px;
}
.buttonsMarch29 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch29 > :global(.__wab_flex-container) > *,
.buttonsMarch29 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch29 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch29
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText {
  display: block;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.buttonsMarch28 {
  background: rgba(255, 208, 42, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 208, 42, 1);
  width: auto;
  height: auto;
  position: relative;
  flex-direction: row;
  display: none;
  border-radius: 4px;
  padding: 12px 24px;
}
.buttonsMarch28 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.buttonsMarch28 > :global(.__wab_flex-container) > *,
.buttonsMarch28 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsMarch28 > :global(.__wab_flex-container) > picture > img,
.buttonsMarch28
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.buttonText2 {
  display: block;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.tRsecondaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 148px;
  height: 44px;
  flex-shrink: 0;
}
.tRprimaryButton:global(.__wab_instance) {
  max-width: 100%;
  height: 44px;
}
