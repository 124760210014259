.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.confirmationPopups {
  background: rgba(255, 255, 255, 1);
  width: 496px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px;
}
.confirmationPopups > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.confirmationPopups > :global(.__wab_flex-container) > *,
.confirmationPopups > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.confirmationPopups > :global(.__wab_flex-container) > picture > img,
.confirmationPopups
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.textAndSupportingText {
  width: 448px;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.textAndSupportingText > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.textAndSupportingText > :global(.__wab_flex-container) > *,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.textAndSupportingText > :global(.__wab_flex-container) > picture > img,
.textAndSupportingText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.frame1000006794 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006794 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006794 > :global(.__wab_flex-container) > *,
.frame1000006794 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006794 > :global(.__wab_flex-container) > picture > img,
.frame1000006794
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  position: relative;
  flex-shrink: 0;
  border-radius: 18.666px;
}
.img > picture > img {
  object-fit: cover;
}
.text {
  display: block;
  flex-grow: 1;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 28px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.crossIcon {
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  flex-direction: column;
  left: 416px;
  top: -14px;
  flex-shrink: 0;
  padding: 8px;
}
.crossIcon > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.crossIcon > :global(.__wab_flex-container) > *,
.crossIcon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.crossIcon > :global(.__wab_flex-container) > picture > img,
.crossIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.svg__m227J {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.embedHtml:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 1.54;
}
.createNewView {
  background: rgba(244, 244, 244, 1);
  height: 40px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 8px;
}
.createNewView > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.createNewView > :global(.__wab_flex-container) > *,
.createNewView > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.createNewView > :global(.__wab_flex-container) > picture > img,
.createNewView
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.root .createNewView:hover {
  background: #ebebeb;
}
.root .createNewView:active {
  background: #dbdbdb;
  box-shadow: inset 0px 0px 0px 1px #999999;
}
.svg__lmoA {
  object-fit: cover;
  max-width: 100%;
  color: #333;
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.title {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000002153 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002153 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002153 > :global(.__wab_flex-container) > *,
.frame1000002153 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002153 > :global(.__wab_flex-container) > picture > img,
.frame1000002153
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000002149 {
  width: auto;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002149 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.frame1000002149 > :global(.__wab_flex-container) > *,
.frame1000002149 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002149 > :global(.__wab_flex-container) > picture > img,
.frame1000002149
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.svg__cxhNa {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 8px;
  height: 8px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.text2 {
  display: block;
  font-size: 8px;
  text-align: center;
  text-decoration-line: underline;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.frame1000002154 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002154 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002154 > :global(.__wab_flex-container) > *,
.frame1000002154 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002154 > :global(.__wab_flex-container) > picture > img,
.frame1000002154
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000002150 {
  width: auto;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002150 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.frame1000002150 > :global(.__wab_flex-container) > *,
.frame1000002150 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002150 > :global(.__wab_flex-container) > picture > img,
.frame1000002150
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.svg__uPaOt {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 8px;
  height: 8px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.text3 {
  display: block;
  font-size: 8px;
  text-align: center;
  text-decoration-line: underline;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.actions {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: auto;
}
.actions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.actions > :global(.__wab_flex-container) > *,
.actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actions > :global(.__wab_flex-container) > picture > img,
.actions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.tRsecondaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 138px;
  flex-shrink: 0;
}
.tRprimaryButton:global(.__wab_instance) {
  max-width: 100%;
  width: 169px;
  flex-shrink: 0;
}
