.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
}
.frame1000006032 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.frame1000006032 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.frame1000006032 > :global(.__wab_flex-container) > *,
.frame1000006032 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006032 > :global(.__wab_flex-container) > picture > img,
.frame1000006032
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.frame1000006033 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  border-radius: 4px;
}
.frame1000006033 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.frame1000006033 > :global(.__wab_flex-container) > *,
.frame1000006033 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006033 > :global(.__wab_flex-container) > picture > img,
.frame1000006033
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.dropdown:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu)
  :global(.ant-dropdown-menu-item) {
  outline: none;
  padding: 0px;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu) {
  outline: none;
  padding: 0px;
}
.menuItem:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.moveLayer {
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  min-width: 0;
  padding: 8px;
}
.moveLayer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.moveLayer > :global(.__wab_flex-container) > *,
.moveLayer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.moveLayer > :global(.__wab_flex-container) > picture > img,
.moveLayer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.item {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.54;
  color: rgba(51, 51, 51, 1);
  position: relative;
  white-space: pre;
  max-width: 200px;
  flex-shrink: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.emTakeOffLayerChips:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.emTakeOffLayerChips2:global(.__wab_instance) {
  max-width: 100%;
}
.popover4:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover4__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_05zVLNZ7WQTh {
  outline: none;
  padding: 0px;
}
.frame1000006031 {
  height: 40px;
  display: flex;
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  flex-direction: row;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  border-radius: 4px;
  border: 0.5px solid #dddddd;
}
.frame1000006031 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000006031 > :global(.__wab_flex-container) > *,
.frame1000006031 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006031 > :global(.__wab_flex-container) > picture > img,
.frame1000006031
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.text {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.chevron {
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
}
.chevron > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.chevron > :global(.__wab_flex-container) > *,
.chevron > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.chevron > :global(.__wab_flex-container) > picture > img,
.chevron
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.svg__mUrZy {
  object-fit: cover;
  max-width: 100%;
  color: #666666;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.tooltipContent___2P9Ay:global(.__wab_instance) {
  max-width: 100%;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: auto;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_azyL_57-H7T- {
  outline: none;
  padding: 0px;
}
.freeBox__ilAa {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.root .freeBox__ilAa:hover {
  background: #f5f5f5;
  width: auto;
  border-radius: 2px;
}
.svg__aMxS1 {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  cursor: pointer;
  flex-shrink: 0;
}
.svg___099IA {
  object-fit: cover;
  max-width: 100%;
  color: #999;
  width: 16px;
  height: 16px;
  cursor: not-allowed;
}
.tooltipContent__vVt44:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.popover2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: auto;
}
.popover2__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_0TY-OsKa-LIx {
  outline: none;
  padding: 0px;
}
.freeBox__zV07C {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
}
.root .freeBox__zV07C:hover {
  background: #f5f5f5;
  border-radius: 2px;
}
.svg___3D5Nt {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  display: block;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 2px;
}
.svg__kTp8K {
  object-fit: cover;
  max-width: 100%;
  color: #999;
  width: 16px;
  height: 16px;
  cursor: not-allowed;
}
.tooltipContent__qrCwa:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.popover3:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  box-shadow: 0px 4px 16px 0px transparent;
}
.popover3__popover:global(.ant-popover) :global(.ant-popover-inner) {
  box-shadow: 0px 4px 16px 0px transparent;
  outline: none;
  padding: 0px;
}
.pcls_JbnjtenJz66P {
  box-shadow: 0px 4px 16px 0px transparent;
  outline: none;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 16px;
}
.img > picture > img {
  object-fit: cover;
}
.mrSubscriptionPopover:global(.__wab_instance) {
  max-width: 100%;
}
