.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  justify-self: flex-start;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.freeBox___8KfNj {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  max-width: 100%;
  background: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid #666666;
}
.freeBoxradioBtnClicked___8KfNjPsWtO {
  background: #ffd02b;
  border-style: solid;
  border-color: #ffd02b;
}
.freeBoxdisabledBtn___8KfNjxIPfh {
  background: #eaeaea;
  cursor: auto;
  justify-content: center;
  align-items: center;
  height: 16px;
  border-color: #999999;
}
.freeBox__l9Xz0 {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 8px;
  height: 8px;
  max-width: 100%;
  background: #333333;
  flex-shrink: 0;
  display: none;
  border-radius: 8px;
}
.freeBoxradioBtnClicked__l9Xz0PsWtO {
  display: flex;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.svgdisabledBtn {
  width: 12px;
  flex-shrink: 0;
}
.freeBox___553Di {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12px;
  max-width: 100%;
  min-width: 0;
}
.text {
  width: auto;
  height: auto;
  max-width: 100%;
  line-height: 18px;
  color: #666666;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}
