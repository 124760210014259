.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
}
.frame1000005238 {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
}
.frame1000002029 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 16px 32px 20px;
}
.frame1000002029 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.frame1000002029 > :global(.__wab_flex-container) > *,
.frame1000002029 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002029 > :global(.__wab_flex-container) > picture > img,
.frame1000002029
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.group1000002087 {
  width: 100%;
  height: 56px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  flex-shrink: 0;
}
.group1000002085 {
  width: 63px;
  height: 56px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.svg__mbDlx {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #eee;
  transform: translate(0px, -0.19400000000000261px);
  width: 63px;
  height: 39px;
  display: block;
  top: 17px;
  left: 0px;
}
.rectangle26942 {
  background: rgba(255, 208, 42, 1);
  transform: translate(0.3130000000000166px, -0.3949999999999996px);
  width: 41px;
  height: 49px;
  display: block;
  position: absolute;
  top: 8px;
  left: 9px;
  border-radius: 3.908px;
}
.rectangle26944 {
  background: rgba(214, 167, 0, 1);
  transform: scale(0.9994598541212149, 0.9992977665702922)
    rotate(-3.0971522847343227deg) skew(-0.17172851698009206deg, 0deg)
    translate(-0.22399999999998954px, -0.4450000000000003px);
  width: 33px;
  height: 42px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 13px;
  left: 12px;
  border-radius: 1.954px;
}
.rectangle26943 {
  background: rgba(255, 255, 255, 1);
  transform: translate(0.3610000000000184px, 0.370000000000001px);
  width: 33px;
  height: 40px;
  display: block;
  position: absolute;
  top: 10px;
  left: 13px;
  border-radius: 1.954px;
}
.rectangle26945 {
  background: rgba(215, 215, 215, 1);
  transform: translate(-0.4230000000000018px, -0.46999999999999886px);
  width: 24px;
  height: 7px;
  display: block;
  position: absolute;
  top: 6px;
  left: 18px;
  border-radius: 9.77px 9.77px 3.908px 3.908px;
}
.group1000002084 {
  transform: translate(-0.3170000000000073px, 0px);
  width: 6px;
  height: 6px;
  display: block;
  position: absolute;
  top: 0px;
  left: 27px;
}
.svg___5SSnf {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #d8d8d8;
  width: 6px;
  height: 6px;
  display: block;
  top: 0px;
  left: 0px;
}
.rectangle26947 {
  background: rgba(172, 172, 172, 1);
  transform: translate(0px, 0.49299999999999855px);
  width: 6px;
  height: 2px;
  display: block;
  position: absolute;
  top: 4px;
  left: 0px;
}
.svg___4Z6Zt {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #c5c5c5;
  width: 9px;
  height: 7px;
  display: block;
  top: 6px;
  left: 32px;
  transform: translate(0.3410000000000082px, -0.46399999999999864px);
}
.svg__ijF81 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #d9d9d9;
  transform: translate(-0.12199999999998568px, 0.18599999999999994px);
  width: 29px;
  height: 3px;
  display: block;
  top: 47px;
  left: 15px;
}
.svg__y9YrI {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ccc;
  transform: translate(0.3439999999999941px, -0.4200000000000017px);
  width: 21px;
  height: 15px;
  display: block;
  top: 19px;
  left: 33px;
  border-radius: 9.77px;
}
.svg__krC7 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #ccc;
  transform: translate(0.3810000000000002px, 0.23499999999999943px);
  width: 4px;
  height: 3px;
  display: block;
  top: 32px;
  left: 29px;
  border-radius: 9.77px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  transform: scale(0.9988413287404562, 0.9996883101134304)
    rotate(13.312780089515309deg) skew(0.5419967014944053deg, 0deg)
    translate(-0.3160000000000025px, -0.03200000000000003px);
  width: 9.57px;
  height: 10.75px;
  display: block !important;
  transform-origin: top left;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.frame1000001961 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding-top: 3px;
  width: 100%;
  min-width: 0;
}
.frame1000001961 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.frame1000001961 > :global(.__wab_flex-container) > *,
.frame1000001961 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001961 > :global(.__wab_flex-container) > picture > img,
.frame1000001961
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 2px;
}
.text___2ILf6 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0px;
  line-height: 1.54;
  position: relative;
  width: 100%;
  color: #666666;
  font-weight: 500;
  font-style: italic;
  min-width: 0;
}
.text__lvice {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  white-space: pre-wrap;
  text-align: center;
  color: #999999;
  font-weight: 300;
  line-height: 1.54;
  min-width: 0;
}
