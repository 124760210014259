@pagination-item-bg: @component-background;
@pagination-item-size: @height-base;
@pagination-item-size-sm: 24px;
@pagination-font-family: @font-family;
@pagination-font-weight-active: 400;
@pagination-item-bg-active: @component-background;
@pagination-item-link-bg: @component-background;
@pagination-item-disabled-color-active: @white;
@pagination-item-disabled-bg-active: darken(@disabled-bg, 10%);
@pagination-item-input-bg: @component-background;
@pagination-mini-options-size-changer-top: 0px;

.ant-table-pagination.ant-pagination {
  margin: 10px auto;
  height: 40px;
  padding: 0 15px;
}

.ant-pagination {
  border: 1px solid @grey;
  border-radius: 4px;
  width: min-content;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  justify-content: center;
}

.ant-pagination-item {
  border: none;
  height: 25px;
  width: 25px;
  min-width: 25px !important;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    line-height: 24;
    font-size: 16px;
    color: @black;
    font-weight: 400;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      color: @black;
      font-weight: 500;
    }
  }
}

.ant-pagination-item-active {
  background: @main-color;

  a {
    line-height: 24;
    font-size: 16px;
    color: @black !important;
    font-weight: 400;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      color: @black !important;
      font-weight: 500;
    }
  }
}

.ant-pagination-prev,
.ant-pagination-next {
  height: 25px;
  width: 25px;
  min-width: 25px !important;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  button.ant-pagination-item-link {
    height: 25px !important;
    width: 25px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: none;
  }
}

.ant-pagination-options {
  height: 25px !important;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;

  .ant-select,
  .ant-select-open,
  .ant-select-focused {
    border-radius: 4px;
    height: 25px !important;
    color: @black !important;
    border: none !important;
    background: @main-color;

    &:hover {
      border: none !important;
      color: @black !important;
    }

    .ant-select-selector {
      border-radius: 4px;
      height: 25px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none !important;
      color: @black !important;
      background: @main-color;

      input {
        border: none !important;
        color: @black !important;

        &:hover,
        &:focus,
        &:active,
        &:visited,
        &:link {
          border: none !important;
          color: @black !important;
        }
      }

      .ant-select-selection-item {
        color: @black !important;

        &:hover,
        &:focus,
        &:active,
        &:visited,
        &:link {
          border: none !important;

          color: @black !important;
        }
      }
    }
  }
}
