@import '../../../styles/base/colors.less';

.bulkErrorModal {
  .ant-modal-content {
    padding: 20px;
    border-radius: 6px;

    .ant-modal-body {
      padding: 0;
    }
  }
}

.bulk-error-list-wrapper {
  position: relative;

  .bulk-error-lists {
    .ant-table-header {
      border-top: 0.5px solid @table-border-color;
    }

    .ant-table-cell {
      border-right: 0.5px solid @table-border-color;
      padding: 0px;

      &:first-child {
        border-left: 0.5px solid @table-border-color;
      }
    }

    .ant-table-thead>tr>th {
      background-color: @background-color;
    }

    .ant-table-container {
      border-left: none;
    }
  }

}

.color-of-error-green {
  color: @progress-color  !important;
}

.color-of-error-red {
  color: @error-table-text  !important;
}

.error-name-processing-complete {
  margin-bottom: 9px;
}

.footer {
  .csv-btn-error {
    font-size: 14px;
    margin: 0;
    padding: 0 0 0 13px;
    color: #333333;
    font-weight: 500;
  }

  .csv-btn-update {
    font-size: 14px;
    padding-left: 52px;
    padding-right: 52px;
  }
}