// Helper css classes

// Display
.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

// Flex
.d-flex {
  display: flex !important;
}

.flex-1 {
  flex: 1;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-column {
  display: flex;
  flex-direction: column !important;
}

.flex-justify-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.flex-gap-30 {
  gap: rem(30);
}

// Position
.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

// Color
.bg-white {
  background-color: #ffffff;
}

.bg-gainsboro {
  background-color: #dfdfdf;
}

.bg-ivory {
  background-color: #fcfcfa;
}

.bg-patient-white {
  background-color: #ebdfdb;
}

.bg-corn-silk {
  background-color: #fffae8;
}

.bg-chablis {
  background-color: #fde8e0;
}

.bg-lightred {
  background-color: #ffeeee;
}

.bg-lightgreen {
  background-color: rgba(108, 187, 47, 10%);
}

.text-midgray {
  color: #666666;
}

.text-deepgray {
  color: #333333;
}

.text-black {
  color: #000000;
}

.text-azure-blue {
  color: #1a73e8;
}

.text-chablis {
  color: #fde8e0;
}

.text-warning {
  color: #dc2a2a;
}

// Text align
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

// Font
.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.text-9 {
  font-size: rem(9);
}

.text-10 {
  font-size: rem(10);
}

.text-10 {
  font-size: rem(10);
}

.text-11 {
  font-size: rem(11);
}

.text-12 {
  font-size: rem(12);
}

.text-14 {
  font-size: rem(14);
}

.text-16 {
  font-size: rem(16);
}

.text-18 {
  font-size: rem(18);
}

.text-24 {
  font-size: rem(24);
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.decoration-underline {
  text-decoration: underline;
}

.leading-12 {
  line-height: rem(12);
}

.leading-14 {
  line-height: rem(14);
}

.leading-15 {
  line-height: rem(15);
}

.leading-18 {
  line-height: rem(18);
}

.leading-21 {
  line-height: 21px;
}

.leading-16 {
  line-height: rem(16);
}

.leading-18 {
  line-height: rem(18);
}

.leading-20 {
  line-height: rem(20);
}

.leading-21 {
  line-height: rem(21);
}

.leading-25 {
  line-height: rem(25);
}

.leading-28 {
  line-height: rem(28);
}

.leading-38 {
  line-height: rem(38);
}

// Sizing
.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-60 {
  width: 60%;
}

.w-90 {
  width: 90%;
}

// Overflow
.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

// Border
.border-none {
  border: none !important;
}

.rounded-0 {
  border-radius: rem(0);
}

.rounded-3 {
  border-radius: rem(3);
}

.rounded-4 {
  border-radius: 4px;
}

.rounded-5 {
  border-radius: rem(5);
}

.rounded-10 {
  border-radius: rem(10);
}

.rounded-12 {
  border-radius: rem(12);
}

.rounded-20 {
  border-radius: rem(20);
}

// z-index
.z-2 {
  z-index: 2;
}

// Box-sizing
.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

// cursor
.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.text-underline {
  text-decoration: underline !important;
}

.sr-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

body.disabled {
  position: relative;

  &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000;
    opacity: 0.1;
    z-index: 999999;
  }
}
