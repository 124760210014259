.baseView {
  background: none;
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  justify-self: flex-start;
  min-width: 0;
  padding: 4px 8px 4px 12px;
}
.baseView > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.baseView > :global(.__wab_flex-container) > *,
.baseView > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.baseView > :global(.__wab_flex-container) > picture > img,
.baseView
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.baseViewisActive2 {
  background: #feedbb;
}
.baseViewrename {
  box-shadow: 0px 0px 0px 1px #ffd02b;
  border-radius: 4px;
}
.baseViewrename_isActive2 {
  background: #ffffff;
}
.baseView:hover {
  background: #e9e9e9;
}
.baseViewisActive2:hover {
  background: #feedbb;
}
.baseViewrename:hover {
  background: #ffffff;
}
.checkmarckIcon {
  width: 16px;
  height: 16px;
  position: relative;
  flex-shrink: 0;
  display: none;
}
.checkmarckIconisActive2 {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.svg__hfRC {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #333;
  transform: translate(-0.5px, 0px);
  width: 9px;
  height: 8px;
  display: block;
  top: 25%;
  left: 25%;
}
.svgisActive2__hfRCPknda {
  position: relative;
  left: auto;
  top: auto;
}
.svgrename__hfRCK6Wfp {
  display: block;
}
.frame1000004954 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-direction: row;
  flex-shrink: 1;
  width: 100%;
  min-width: 0;
}
.frame1000004954 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000004954 > :global(.__wab_flex-container) > *,
.frame1000004954 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004954 > :global(.__wab_flex-container) > picture > img,
.frame1000004954
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.input:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.inputrename:global(.__wab_instance) {
  display: flex;
}
.tooltipCore:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__d1Io8 {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__d1Io8 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.freeBox__d1Io8 > :global(.__wab_flex-container) > *,
.freeBox__d1Io8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__d1Io8 > :global(.__wab_flex-container) > picture > img,
.freeBox__d1Io8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.freeBoxrename__d1Io8K6Wfp {
  display: none;
}
.svg__hZOuk {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 12px;
  height: 12px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.svg__hZOuk > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 0.339px);
  height: calc(100% + 0.339px);
}
.svg__hZOuk > :global(.__wab_flex-container) > *,
.svg__hZOuk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.svg__hZOuk > :global(.__wab_flex-container) > picture > img,
.svg__hZOuk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0.339px;
}
.svgisActive2__hZOukPknda {
  color: #333333;
}
.svgrename__hZOukK6Wfp {
  display: none;
}
.text__nH0D {
  width: 100%;
  height: auto;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #666666;
  font-size: 12px;
  flex-shrink: 1;
  flex-grow: 0;
  max-width: auto;
  min-width: 0;
}
.textisActive2__nH0DPknda {
  color: #333333;
  font-size: 12px;
}
.textrename__nH0DK6Wfp {
  display: none;
}
.baseView:hover .text__nH0D {
  color: #333333;
}
.freeBox__qp9Dl {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: 24px;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  box-shadow: none;
  background: #333333;
  border-radius: 4px;
  padding: 4px 8px;
}
.baseView:hover .freeBox__qp9Dl {
  display: flex;
}
.text__neqw {
  color: #ffffff;
  font-size: 12px;
}
.frame1000001994 {
  background: rgba(244, 244, 244, 1);
  width: 45px;
  height: 16px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 2px 8px;
}
.frame1000001994 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame1000001994 > :global(.__wab_flex-container) > *,
.frame1000001994 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000001994 > :global(.__wab_flex-container) > picture > img,
.frame1000001994
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.frame1000001994rename {
  display: none;
}
.baseView:hover .frame1000001994 {
  display: flex;
}
.baseViewrename:hover .frame1000001994rename {
  display: none;
}
.text__eeR0D {
  display: block;
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_qMingx0Jetds {
  outline: none;
  padding: 0px;
}
.freeBox__p7URd {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 24px;
  height: 24px;
  max-width: 100%;
  padding: 0px;
}
.freeBoxisActive2__p7URdPknda {
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0px;
}
.freeBoxrename__p7URdK6Wfp {
  display: none;
}
.baseView:hover .freeBox__p7URd {
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0px;
}
.baseViewrename:hover .freeBoxrename__p7URdK6Wfp {
  display: none;
}
.baseViewisActive2 .freeBoxisActive2__p7URdPknda:hover {
  background: #3333331a;
}
.baseView:hover .freeBox__p7URd:hover {
  background: #3333331a;
}
.lock {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
}
.lock > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.lock > :global(.__wab_flex-container) > *,
.lock > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.lock > :global(.__wab_flex-container) > picture > img,
.lock > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.baseView .lock:hover {
  background: #0000001a;
}
.svg__bIx1I {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #666666;
  width: 9px;
  height: 9.999px;
  flex-shrink: 0;
  display: none;
}
.svgisActive2__bIx1IPknda {
  color: #333333;
}
.baseView:hover .svg__bIx1I {
  color: #333333;
}
.svg__yxPmx {
  object-fit: cover;
  max-width: 100%;
  color: #aaa;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.tooltipContent:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.freeBox__jcKWl {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  max-width: 100%;
  flex-shrink: 0;
}
.freeBoxisActive2__jcKWlPknda {
  padding-bottom: 0px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
}
.baseView:hover .freeBox__jcKWl {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
}
.baseViewisActive2:hover .freeBoxisActive2__jcKWlPknda {
  display: flex;
}
.baseViewrename:hover .freeBoxrename__jcKWlK6Wfp {
  display: none;
}
.baseViewisActive2 .freeBoxisActive2__jcKWlPknda:hover {
  background: #3333331a;
}
.baseView:hover .freeBox__jcKWl:hover {
  background: #3333331a;
}
.dropdown:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu) {
  width: 164px;
  height: 104px;
  background: #ffffff;
  outline: none;
  border-radius: 8px;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu)
  :global(.ant-dropdown-menu-item) {
  width: 164px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  color: #666666;
  background: #ffffff;
  outline: none;
}
.dropdownisActive2:global(.__wab_instance) {
  display: flex;
}
.dropdownrename:global(.__wab_instance) {
  display: none;
}
.baseView:hover .dropdown:global(.__wab_instance) {
  display: flex;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu) {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  outline: none;
  border-radius: 8px;
}
.dropdown__dropdownMenu:global(.ant-dropdown-menu)
  :global(.ant-dropdown-menu-item) {
  color: #666666;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  cursor: pointer;
  filter: blur(0px);
  backdrop-filter: blur(0px);
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  outline: none;
  -webkit-backdrop-filter: blur(0px);
}
.baseViewrename:hover .dropdownrename:global(.__wab_instance) {
  display: none;
}
.freeBox__mFtui {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.baseView:hover .freeBox__mFtui {
  padding: 0px;
}
.menuItem__ilj2V:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__bN2I4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.baseView:hover .text__bN2I4 {
  font-size: 12px;
  color: #666666;
  font-weight: 500;
}
.baseView:hover .text__bN2I4:hover {
  color: #333333;
}
.freeBox__lerj9 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.menuItem__xkzc5:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text___88KEc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.baseView:hover .text___88KEc {
  color: #666666;
  font-size: 12px;
  font-weight: 500;
}
.baseView:hover .text___88KEc:hover {
  color: #333333;
}
.freeBox___4FTsq {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.menuItem__r93Ia:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.text__zwCpS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.baseView:hover .text__zwCpS {
  color: #666666;
  font-weight: 500;
  font-size: 12px;
}
.baseView:hover .text__zwCpS:hover {
  color: #333333;
}
.svg___8Dnd0 {
  position: relative;
  object-fit: cover;
  color: #666666;
  width: 24px;
  height: 24px;
}
.svgisActive2___8Dnd0Pknda {
  color: #333333;
}
.baseView:hover .svg___8Dnd0 {
  color: #333333;
  width: 24px;
  height: 24px;
}
.sideEffect:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
