.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  flex-grow: 1;
  min-width: 0;
}
.rootcloseStylingPanel {
  height: auto;
}
.polygonLayerStyling {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 500px;
  min-width: 0;
  border-radius: 4px;
  border: 1px solid #dddddd;
}
.frame1000005039 {
  background: #ffffff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: auto;
  display: flex;
  position: relative;
  align-self: auto;
  overflow: hidden;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  pointer-events: auto;
  min-width: 0;
  padding: 12px 8px;
}
.frame1000005039 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000005039 > :global(.__wab_flex-container) > *,
.frame1000005039 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005039 > :global(.__wab_flex-container) > picture > img,
.frame1000005039
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000005039closeStylingPanel {
  height: auto;
}
.frame1000005039disable {
  background: #dddddd;
  cursor: not-allowed;
}
.emTakeOffLayerChips2__qItix:global(.__wab_instance) {
  max-width: 100%;
}
.text__j6ToL {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.textdisable__j6ToLswFiG {
  color: #999999;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  padding: 0px;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_GG7q8GXechn4 {
  outline: none;
  padding: 0px;
}
.freeBox__zbVly {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
}
.root .freeBox__zbVly:hover {
  background: #f5f5f5;
  border-radius: 2px;
}
.svg__jkL7Z {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 16px;
  height: 16px;
  display: block;
  flex-shrink: 0;
  border-radius: 2px;
}
.tooltipContent__fiOh:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.freeBox__tIwo4 {
  display: flex;
  position: relative;
  width: auto;
  height: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
}
.svg___9S9TA {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  position: relative;
  left: auto;
  top: auto;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: none;
}
.svgcloseStylingPanel___9S9TAm3DOa {
  display: block;
}
.svg___25NhR {
  object-fit: cover;
  max-width: 100%;
  color: #333333;
  width: 18px;
  height: 20px;
  flex-shrink: 0;
}
.svgcloseStylingPanel___25NhRm3DOa {
  display: none;
}
.polygonColors {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.polygonColorscloseStylingPanel {
  display: none;
}
.frame1000005043 {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  box-shadow: none;
  padding: 8px;
  border-top: 0.5px solid #dddddd;
  border-bottom: 0.5px solid #dddddd;
}
.frame1000005043 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000005043 > :global(.__wab_flex-container) > *,
.frame1000005043 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005043 > :global(.__wab_flex-container) > picture > img,
.frame1000005043
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000005043disable {
  display: none;
}
.text___22Moc {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
  height: 18px;
  flex-shrink: 0;
}
.frame1000005040 {
  height: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  min-height: 0;
}
.frame1000005040 > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  min-height: 0;
  margin-left: calc(0px - 3px);
  width: calc(100% + 3px);
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.frame1000005040 > :global(.__wab_flex-container) > *,
.frame1000005040 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005040 > :global(.__wab_flex-container) > picture > img,
.frame1000005040
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 3px;
  margin-top: 2px;
}
.freeBox__lV1Op {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  max-width: 100%;
  cursor: pointer;
  background: none;
  flex-shrink: 0;
  border-radius: 3px;
}
.root .freeBox__lV1Op:hover {
  box-shadow: inset 0px 0px 0px 0.75px #e0e0e0;
}
.root .freeBox__lV1Op:active {
  background: #ebebeb;
}
.tooltipCore:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.emTakeOffLayerChips2__vgt4U:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox__px7Sm {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  box-shadow: 0px 4px 16px 0px #00000033;
  background: white;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}
.tooltipContent___3Xy9Z:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.emTakeOffLayerChips:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.freeBox__ixi4H {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  border-radius: 3px;
}
.root .freeBox__ixi4H:active {
  background: #ebebeb;
}
.svg__qoN {
  object-fit: cover;
  max-width: 100%;
  color: #666666;
  width: 24px;
  height: 24px;
  display: block;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 3px;
}
.root .svg__qoN:hover {
  color: #333333;
}
.lottie:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: none;
}
.yellowLoader:global(.__wab_instance) {
  max-width: 100%;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.colorPicker {
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding: 8px;
}
.colorPicker > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.colorPicker > :global(.__wab_flex-container) > *,
.colorPicker > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.colorPicker > :global(.__wab_flex-container) > picture > img,
.colorPicker
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.colorPickercloseStylingPanel {
  display: none;
}
.frame1000005097 {
  width: 280px;
  height: auto;
  display: flex;
  flex-direction: row;
}
.frame1000005097 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.frame1000005097 > :global(.__wab_flex-container) > *,
.frame1000005097 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005097 > :global(.__wab_flex-container) > picture > img,
.frame1000005097
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.frame1000004333 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000004333 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.frame1000004333 > :global(.__wab_flex-container) > *,
.frame1000004333 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004333 > :global(.__wab_flex-container) > picture > img,
.frame1000004333
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.frame1000004331 {
  background: none;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px 12px;
}
.frame1000004331 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame1000004331 > :global(.__wab_flex-container) > *,
.frame1000004331 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004331 > :global(.__wab_flex-container) > picture > img,
.frame1000004331
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.text__uoGqY {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.5;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.embedHtml:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.symbol {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  flex-shrink: 0;
  padding: 11px 8px;
}
.symbol > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.symbol > :global(.__wab_flex-container) > *,
.symbol > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.symbol > :global(.__wab_flex-container) > picture > img,
.symbol
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.symbolcloseStylingPanel {
  display: none;
}
.symboldisable {
  display: none;
}
.text__scBsn {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.popover2:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover2__popover:global(.ant-popover) :global(.ant-popover-inner) {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  outline: none;
  padding: 0px;
}
.pcls_ru0TF0Hhd0U0 {
  box-shadow: 0px 4px 16px 0px transparent;
  outline: none;
  padding: 0px;
}
.frame1000005033 {
  background: rgba(244, 244, 244, 1);
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
}
.emTakeOffLayerChips2___0A2Gl:global(.__wab_instance) {
  max-width: 100%;
}
.mRcustomSymbolsPalette__py1Fo:global(.__wab_instance) {
  max-width: 100%;
}
.arrowSymbol {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 11px 8px;
  border-bottom: 0.5px solid #dddddd;
}
.arrowSymbol > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.arrowSymbol > :global(.__wab_flex-container) > *,
.arrowSymbol > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.arrowSymbol > :global(.__wab_flex-container) > picture > img,
.arrowSymbol
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.arrowSymbolcloseStylingPanel {
  display: none;
}
.arrowSymboldisable {
  display: none;
}
.text__zu0Jr {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.popover3:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.frame1000005034 {
  background: rgba(244, 244, 244, 1);
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.emTakeOffLayerChips2__li1JJ:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.emCustomSymbol:global(.__wab_instance) {
  max-width: 100%;
}
.mRcustomSymbolsPalette___9Hsy:global(.__wab_instance) {
  max-width: 100%;
}
.arrowOpacity {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 11px 8px;
  border-bottom: 0.5px solid #dddddd;
}
.arrowOpacity > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.arrowOpacity > :global(.__wab_flex-container) > *,
.arrowOpacity > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.arrowOpacity > :global(.__wab_flex-container) > picture > img,
.arrowOpacity
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.arrowOpacitycloseStylingPanel {
  display: none;
}
.arrowOpacitydisable {
  display: none;
}
.text__hOan6 {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000002508 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002508 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002508 > :global(.__wab_flex-container) > *,
.frame1000002508 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002508 > :global(.__wab_flex-container) > picture > img,
.frame1000002508
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.popover12:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.pcls_Bs-mOoDHcceM {
  outline: none;
  padding: 0px;
}
.popover12__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.frame1000002509 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 33.333px;
  padding: 3.333px;
}
.frame1000002509 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.frame1000002509 > :global(.__wab_flex-container) > *,
.frame1000002509 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002509 > :global(.__wab_flex-container) > picture > img,
.frame1000002509
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .frame1000002509:hover {
  background: #dddddd;
}
.root .frame1000002509:active {
  background: #bbbbbb;
}
.svg___87Vz4 {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.tooltipContent__blOwi:global(.__wab_instance) {
  max-width: 100%;
}
._803 {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 36px;
  flex-shrink: 0;
}
.popover13:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.pcls_LLOzmJ74pyg8 {
  outline: none;
  padding: 0px;
}
.popover13__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.plusIcon5 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 33.333px;
  padding: 3.333px;
}
.plusIcon5 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.plusIcon5 > :global(.__wab_flex-container) > *,
.plusIcon5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.plusIcon5 > :global(.__wab_flex-container) > picture > img,
.plusIcon5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .plusIcon5:hover {
  background: #dddddd;
}
.root .plusIcon5:active {
  background: #bbbbbb;
}
.svg__h95J {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.tooltipContent__g5Vnp:global(.__wab_instance) {
  max-width: 100%;
}
.arrowSize {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  padding: 11px 8px;
  border-bottom: 0.5px solid #dddddd;
}
.arrowSize > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.arrowSize > :global(.__wab_flex-container) > *,
.arrowSize > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.arrowSize > :global(.__wab_flex-container) > picture > img,
.arrowSize
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.arrowSizecloseStylingPanel {
  display: none;
}
.arrowSizedisable {
  display: none;
}
.text__ubgv {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000002506 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002506 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002506 > :global(.__wab_flex-container) > *,
.frame1000002506 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002506 > :global(.__wab_flex-container) > picture > img,
.frame1000002506
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.popover14:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover14__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_Hi-htNS5hYFD {
  outline: none;
  padding: 0px;
}
.frame1000002507 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 33.333px;
  padding: 3.333px;
}
.frame1000002507 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.frame1000002507 > :global(.__wab_flex-container) > *,
.frame1000002507 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002507 > :global(.__wab_flex-container) > picture > img,
.frame1000002507
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .frame1000002507:hover {
  background: #dddddd;
}
.root .frame1000002507:active {
  background: #bbbbbb;
}
.svg___0BteU {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.tooltipContent__y0Kkd:global(.__wab_instance) {
  max-width: 100%;
}
._802 {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 36px;
  flex-shrink: 0;
}
.popover15:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover15__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_Y0NmkW8YEICI {
  outline: none;
  padding: 0px;
}
.plusIcon4 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 33.333px;
  padding: 3.333px;
}
.plusIcon4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.plusIcon4 > :global(.__wab_flex-container) > *,
.plusIcon4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.plusIcon4 > :global(.__wab_flex-container) > picture > img,
.plusIcon4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .plusIcon4:hover {
  background: #dddddd;
}
.root .plusIcon4:active {
  background: #bbbbbb;
}
.svg___2HiOc {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.tooltipContent__fTdJj:global(.__wab_instance) {
  max-width: 100%;
}
.opacity2 {
  background: rgba(255, 255, 255, 1);
  height: 36px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
  padding: 10px 8px;
  border-top: 0.5px solid #dddddd;
  border-bottom: 0.5px solid #dddddd;
}
.opacity2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.opacity2 > :global(.__wab_flex-container) > *,
.opacity2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.opacity2 > :global(.__wab_flex-container) > picture > img,
.opacity2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.opacity2closeStylingPanel {
  display: none;
}
.opacity2disable {
  display: none;
}
.text__lkd8A {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000002500 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002500 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002500 > :global(.__wab_flex-container) > *,
.frame1000002500 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002500 > :global(.__wab_flex-container) > picture > img,
.frame1000002500
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.frame1000002500closeStylingPanel {
  display: none;
}
.popover4:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover4__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_fuxuMIoFUrwZ {
  outline: none;
  padding: 0px;
}
.frame1000002501 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: auto;
  border-radius: 33.333px;
  padding: 3.333px;
}
.frame1000002501 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.frame1000002501 > :global(.__wab_flex-container) > *,
.frame1000002501 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002501 > :global(.__wab_flex-container) > picture > img,
.frame1000002501
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .frame1000002501:hover {
  background: #dddddd;
}
.root .frame1000002501:active {
  background: #b8b8b8;
}
.svg__muBKf {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.frame1000002512 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: auto;
  border-radius: 33.333px;
  padding: 3.333px;
}
.frame1000002512 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.frame1000002512 > :global(.__wab_flex-container) > *,
.frame1000002512 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002512 > :global(.__wab_flex-container) > picture > img,
.frame1000002512
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .frame1000002512:hover {
  background: #dddddd;
}
.root .frame1000002512:active {
  background: #b8b8b8;
}
.svg__nnlT2 {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.tooltipContent__hEf9:global(.__wab_instance) {
  max-width: 100%;
}
.text__mdNTz {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 36px;
  flex-shrink: 0;
}
.popover5:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover5__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_igTWQd12TbSJ {
  outline: none;
  padding: 0px;
}
.plusIcon {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 33.333px;
  padding: 3.333px;
}
.plusIcon > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.plusIcon > :global(.__wab_flex-container) > *,
.plusIcon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.plusIcon > :global(.__wab_flex-container) > picture > img,
.plusIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .plusIcon:hover {
  background: #dddddd;
}
.root .plusIcon:active {
  background: #bbbbbb;
}
.svg__rkc9 {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.tooltipContent__eubW3:global(.__wab_instance) {
  max-width: 100%;
}
.size {
  background: rgba(255, 255, 255, 1);
  height: 36px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
  padding: 10px 8px;
}
.size > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.size > :global(.__wab_flex-container) > *,
.size > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.size > :global(.__wab_flex-container) > picture > img,
.size > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.sizecloseStylingPanel {
  display: none;
}
.sizedisable {
  display: none;
}
.text__nCiU {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000002504 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002504 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002504 > :global(.__wab_flex-container) > *,
.frame1000002504 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002504 > :global(.__wab_flex-container) > picture > img,
.frame1000002504
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.popover6:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover6__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_5vSOq0DbIw4W {
  outline: none;
  padding: 0px;
}
.frame1000002505 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: auto;
  border-radius: 33.333px;
  padding: 3.333px;
}
.frame1000002505 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.frame1000002505 > :global(.__wab_flex-container) > *,
.frame1000002505 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002505 > :global(.__wab_flex-container) > picture > img,
.frame1000002505
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .frame1000002505:hover {
  background: #dddddd;
}
.root .frame1000002505:active {
  background: #b8b8b8;
}
.svg__sYLjG {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.tooltipContent__egvhl:global(.__wab_instance) {
  max-width: 100%;
}
.text__jkj7B {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 36px;
  flex-shrink: 0;
}
.popover7:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover7__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_eQi4Sto-DeVb {
  outline: none;
  padding: 0px;
}
.plusIcon3 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 33.333px;
  padding: 3.333px;
}
.plusIcon3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.plusIcon3 > :global(.__wab_flex-container) > *,
.plusIcon3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.plusIcon3 > :global(.__wab_flex-container) > picture > img,
.plusIcon3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .plusIcon3:hover {
  background: #dddddd;
}
.root .plusIcon3:active {
  background: #bbbbbb;
}
.svg__io8Cd {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.tooltipContent__qH1B:global(.__wab_instance) {
  max-width: 100%;
}
.size2 {
  background: rgba(255, 255, 255, 1);
  height: 36px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
  padding: 10px 8px;
}
.size2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.size2 > :global(.__wab_flex-container) > *,
.size2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.size2 > :global(.__wab_flex-container) > picture > img,
.size2 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 4px;
}
.size2closeStylingPanel {
  display: none;
}
.size2disable {
  display: none;
}
.text__vlKq {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000002510 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002510 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002510 > :global(.__wab_flex-container) > *,
.frame1000002510 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002510 > :global(.__wab_flex-container) > picture > img,
.frame1000002510
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.popover10:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover10__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_YKG60zChWncZ {
  outline: none;
  padding: 0px;
}
.frame1000002511 {
  background: #f4f4f4;
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: auto;
  border-radius: 33.333px;
  padding: 3.333px;
}
.frame1000002511 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.frame1000002511 > :global(.__wab_flex-container) > *,
.frame1000002511 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002511 > :global(.__wab_flex-container) > picture > img,
.frame1000002511
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .frame1000002511:hover {
  background: #dddddd;
}
.root .frame1000002511:active {
  background: #b8b8b8;
}
.svg__hb9H8 {
  object-fit: cover;
  max-width: 100%;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  color: #666666;
  flex-shrink: 0;
}
.tooltipContent__pf9Bn:global(.__wab_instance) {
  max-width: 100%;
}
.text___4ILbj {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 36px;
  flex-shrink: 0;
}
.popover11:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover11__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_zGaA1t3LF98n {
  outline: none;
  padding: 0px;
}
.plusIcon6 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 33.333px;
  padding: 3.333px;
}
.plusIcon6 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.plusIcon6 > :global(.__wab_flex-container) > *,
.plusIcon6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.plusIcon6 > :global(.__wab_flex-container) > picture > img,
.plusIcon6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .plusIcon6:hover {
  background: #dddddd;
}
.root .plusIcon6:active {
  background: #bbbbbb;
}
.svg__hPkAg {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.tooltipContent___9EInw:global(.__wab_instance) {
  max-width: 100%;
}
.strokeSize2 {
  background: rgba(255, 255, 255, 1);
  height: 36px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
  padding: 10px 8px;
}
.strokeSize2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.strokeSize2 > :global(.__wab_flex-container) > *,
.strokeSize2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.strokeSize2 > :global(.__wab_flex-container) > picture > img,
.strokeSize2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.strokeSize2closeStylingPanel {
  display: none;
}
.strokeSize2disable {
  display: none;
}
.opacity {
  display: block;
  flex-grow: 1;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000002502 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.frame1000002502 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000002502 > :global(.__wab_flex-container) > *,
.frame1000002502 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002502 > :global(.__wab_flex-container) > picture > img,
.frame1000002502
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.popover8:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover8__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_u26S4l0S3C6r {
  outline: none;
  padding: 0px;
}
.frame1000002503 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 33.333px;
  padding: 3.333px;
}
.frame1000002503 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.frame1000002503 > :global(.__wab_flex-container) > *,
.frame1000002503 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000002503 > :global(.__wab_flex-container) > picture > img,
.frame1000002503
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .frame1000002503:hover {
  background: #dddddd;
}
.root .frame1000002503:active {
  background: #bbbbbb;
}
.svg__z9On {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.tooltipContent__siGht:global(.__wab_instance) {
  max-width: 100%;
}
._80 {
  display: block;
  font-size: 14px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(102, 102, 102, 1);
  position: relative;
  width: 36px;
  flex-shrink: 0;
}
.popover9:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover9__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.pcls_1jk3KsswMPO3 {
  outline: none;
  padding: 0px;
}
.plusIcon2 {
  background: rgba(244, 244, 244, 1);
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 33.333px;
  padding: 3.333px;
}
.plusIcon2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 6.666px);
  height: calc(100% + 6.666px);
}
.plusIcon2 > :global(.__wab_flex-container) > *,
.plusIcon2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.plusIcon2 > :global(.__wab_flex-container) > picture > img,
.plusIcon2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 6.666px;
}
.root .plusIcon2:hover {
  background: #dddddd;
}
.root .plusIcon2:active {
  background: #bbbbbb;
}
.svg__blT4J {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 13.888px;
  height: 13.333px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.tooltipContent__yTbA:global(.__wab_instance) {
  max-width: 100%;
}
.toggle {
  background: rgba(255, 255, 255, 1);
  height: 36px;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
  display: none;
  padding: 6px 8px;
}
.toggle > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.toggle > :global(.__wab_flex-container) > *,
.toggle > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.toggle > :global(.__wab_flex-container) > picture > img,
.toggle
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.togglecloseStylingPanel {
  display: none;
}
.frame1000005071 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.frame1000005071 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000005071 > :global(.__wab_flex-container) > *,
.frame1000005071 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005071 > :global(.__wab_flex-container) > picture > img,
.frame1000005071
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.text__l96Mq {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.infoTooltip {
  background: rgba(255, 255, 255, 1);
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 1.333px;
}
.svg___9N43P {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 12px;
  height: 11.999px;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
}
.switchComponent__bRySp:global(.__wab_instance) {
  position: relative;
}
.vertices {
  background: rgba(255, 255, 255, 1);
  height: 36px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
  padding: 6px 8px;
  border-top: 0.5px solid #dddddd;
}
.vertices > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.vertices > :global(.__wab_flex-container) > *,
.vertices > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.vertices > :global(.__wab_flex-container) > picture > img,
.vertices
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.verticescloseStylingPanel {
  display: none;
}
.verticesdisable {
  display: none;
}
.frame1000005072 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.frame1000005072 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.frame1000005072 > :global(.__wab_flex-container) > *,
.frame1000005072 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000005072 > :global(.__wab_flex-container) > picture > img,
.frame1000005072
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 4px;
}
.labels {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.infoTooltip2 {
  background: rgba(255, 255, 255, 1);
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 1.333px;
}
.svg__fBok {
  object-fit: cover;
  max-width: 100%;
  color: #666;
  width: 12px;
  height: 11.999px;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
}
.toggle4 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.toggle4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 7.5px);
  height: calc(100% + 7.5px);
}
.toggle4 > :global(.__wab_flex-container) > *,
.toggle4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.toggle4 > :global(.__wab_flex-container) > picture > img,
.toggle4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 7.5px;
}
.switchComponent__j2Oxg:global(.__wab_instance) {
  max-width: 100%;
}
.text__scH4E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
}
.sideEffect:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
