.toggle2 {
  width: auto;
  height: auto;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}
.toggle2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 7.5px);
  height: calc(100% + 7.5px);
}
.toggle2 > :global(.__wab_flex-container) > *,
.toggle2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.toggle2 > :global(.__wab_flex-container) > picture > img,
.toggle2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 7.5px;
}
._switch {
  width: 24px;
  height: 15px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.group1000001991 {
  width: 24px;
  height: 15px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}
.base {
  width: 24px;
  height: 15px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}
.rectangle26811 {
  background: rgba(209, 209, 210, 1);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  cursor: pointer;
  border-radius: 15px;
}
.rectangle26811openSwitch {
  background: #ffd02b;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: translate(-0.5px, -0.5px);
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  top: 3px;
  left: 1px;
  cursor: pointer;
}
.svgopenSwitch {
  left: 11px;
  top: 3px;
}
