.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  justify-self: flex-start;
  max-height: 220px;
  background: #36389e;
  min-width: 0;
  border-radius: 4px;
}
.freeBox__v7Zdj {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: flex-start;
  background: #ffffff;
  min-width: 0;
}
.freeBox___3Lv2F {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: 0px;
  top: 0px;
  background: none;
  z-index: 100;
  flex-shrink: 0;
  flex-grow: 1;
  max-height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.header {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 36px;
  max-width: 100%;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
}
.header > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.header > :global(.__wab_flex-container) > *,
.header > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.header > :global(.__wab_flex-container) > picture > img,
.header
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.freeBox__pvZzR {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: calc(0px + 2px) !important;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  min-width: 0;
}
.freeBox__pvZzR > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.freeBox__pvZzR > :global(.__wab_flex-container) > *,
.freeBox__pvZzR > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pvZzR > :global(.__wab_flex-container) > picture > img,
.freeBox__pvZzR
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.emServiceRow:global(.__wab_instance) {
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.child {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-bottom: 5px;
  min-width: 0;
  min-height: 0;
}
.emServiceItemRow2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
  display: none;
}
.emServiceItemRow32:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
