.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}
.frame1000006574 {
  background: rgba(255, 255, 255, 1);
  filter: none;
  width: 336px;
  height: 136px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 16px 12px;
}
.frame1000006574 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006574 > :global(.__wab_flex-container) > *,
.frame1000006574 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006574 > :global(.__wab_flex-container) > picture > img,
.frame1000006574
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000006575 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
}
.frame1000006575 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000006575 > :global(.__wab_flex-container) > *,
.frame1000006575 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006575 > :global(.__wab_flex-container) > picture > img,
.frame1000006575
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.frame1000006578 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.frame1000006578 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000006578 > :global(.__wab_flex-container) > *,
.frame1000006578 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006578 > :global(.__wab_flex-container) > picture > img,
.frame1000006578
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.frame1000006577 {
  background: linear-gradient(
        227.29deg,
        rgba(233, 174, 199, 1) 11.58%,
        rgba(100, 77, 162, 1) 127.46%
      )
      0% 0% / 100% 100% no-repeat,
    rgba(255, 255, 255, 1);
  width: 28px;
  height: 28px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 56px;
}
.svg {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 18px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 5px;
  left: 5px;
  flex-direction: column;
  padding: 3.15px 0.899px;
}
.svg > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 9px);
  height: calc(100% + 9px);
}
.svg > :global(.__wab_flex-container) > *,
.svg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.svg > :global(.__wab_flex-container) > picture > img,
.svg > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 9px;
}
.text__czHeP {
  display: block;
  flex-grow: 1;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.frame1000006579 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding-left: 36px;
}
.frame1000006579 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.frame1000006579 > :global(.__wab_flex-container) > *,
.frame1000006579 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000006579 > :global(.__wab_flex-container) > picture > img,
.frame1000006579
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.text__i4Sff {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 21px;
  color: rgba(51, 51, 51, 1);
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
}
.text__aIfkA {
  display: block;
  font-size: 12px;
  text-decoration-line: underline;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 16px;
  color: rgba(15, 84, 234, 1);
  position: relative;
  width: auto;
  cursor: pointer;
}
