.map-nav-center {
  display: flex;
  align-items: center;

  .nav-center-left-arrow {
    position: absolute;
    left: -67px;
  }

  .nav-center-right-arrow {
    position: absolute;
    right: -30px;
  }

  .nav-center-property-info {
    display: flex;
    position: relative;
    right: 0;
    max-width: 320px;

    .overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
