.time-container {
  .top-section {
    display: flex;
  }
  div {
    display: flex;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
  }
}
