#share-navbar {
  width: 100vw;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding: 0 12px;
  background: #ffffff;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  position: fixed;
  z-index: 100;

  .share-navbar-left {
    display: flex;
    align-items: center;
    width: 30%;

    .navbar-logo-wrapper {
      float: left;
      height: 25px;
      padding-left: 3px;
      padding-right: 3px;
      display: flex;

      .navbar-logo {
        height: 100%;
      }
    }
  }

  .share-navbar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 25px;
  }
}

.share-container {
  position: fixed;
  bottom: 0;
  top: 50px;

  .tree-div {
    max-height: calc(100vh - 100px) !important;
    overflow: hidden auto;
  }
}

.main-panel {
  height: 91vh;

  .main-panel-info {
    padding-top: 10px;
  }
}

#map-popover {
  background-color: #ffffff;
  background: 'white';
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  display: none;
  font-size: 15px;
  position: absolute;
  z-index: 5;

  p {
    margin-bottom: 0;
  }

  .map-hover-parent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    height: 70px;
    padding: 0.75rem;

    .map-hover-child__left {
      align-items: center;
      display: flex;
      margin-bottom: 0.25rem;

      #name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      #separator {
        padding: 0 0.3125rem;
        color: #666666;
      }

      #featureId {
        color: #666666;
      }
    }

    .map-hover-child__right {
      align-items: center;
      display: flex;
      font-weight: 400;

      #featureIcon {
        margin-right: 0.25rem;
      }

      #hoverArea {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      #unit {
        padding-left: 0.3125rem;
      }
    }
  }
}

.secondary-icons {
  margin-left: 4px;
  cursor: pointer;
}

#measure-div {
  z-index: 100;
  background: white;
  position: absolute;
  right: 16px;
  top: 154px;
  width: 250px;
  min-height: 180px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

sup {
  font-size: smaller;
}

.order-text {
  padding: 3px 10px;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.small-logo {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#track_map.measuretool #ol-map--project {
  cursor: url('../../../assets/cursors/measuretool.svg') 0 35, auto;
}

.scene-footer-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 3;

  .scene-footer {
    border-radius: 4px 0px 0px 0px;
    float: right;
    background-color: white;
    padding-left: 3px;
    display: flex;
    font-size: 10px;
    color: #000000;
    padding-right: 2px;
  }
}

.ant-btn-register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
}

.co-widget-bar-integration {
  top: 50px;
}