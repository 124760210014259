@import '../../../../../styles/base/colors.less';

.bulkSuccessModal {
  .ant-modal-content {
    padding: 20px 20px 0px 20px;
    border-radius: 6px;

    .ant-modal-body {
      padding: 0;

      .bulkSuccessModal__wrapper {
        position: relative;
        .bulkSuccessModal__lists {
          .ant-table-header {
            border-top: 0.5px solid #c2c9d1;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-color: rgba(0, 0, 0, 0.1);

            thead {
              height: 48px;

              th:first-child {
                border-top-left-radius: 5px;
              }
              th:last-child {
                border-top-right-radius: 5px;
              }
            }
          }

          .ant-table-body {
            padding-bottom: 10px;
          }
          .ant-table-cell {
            border-right: 0.5px solid #c2c9d1;
            border-color: rgba(0, 0, 0, 0.1);

            padding: 0px;

            &:first-child {
              border-left: 0.5px solid #c2c9d1;
              border-color: rgba(0, 0, 0, 0.1);
            }
          }

          .ant-table-thead > tr > th {
            background-color: @background-color;
          }
          .ant-table-container {
            border-left: none;
          }
        }

        .success__color {
          color: @progress-color !important;
        }
      }
    }
  }
}
