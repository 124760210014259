@import '/src/styles/base/variables/index';

.badge--popover {
  .ant-popover-content {
    max-width: 286px;

    .ant-popover-inner {
      border-radius: @border-radius-base;
    }

    .ant-popover-title {
      border-bottom: 0;
      padding: 12px 12px 6px 12px;
    }

    .ant-popover-inner-content {
      padding: 0 12px 12px 12px !important;
    }
  }
}
