.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 248px;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  height: 100%;
  min-height: 0;
}
.mapViews {
  background: rgba(255, 255, 255, 1);
  filter: drop-shadow(4px 0px 2px rgba(0, 0, 0, 0.159));
  width: 248px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-grow: 0;
  align-self: stretch;
}
.mapViewsisPinned {
  filter: none;
}
.frame1000004938 {
  width: 100%;
  height: 44px;
  display: flex;
  position: relative;
  flex-direction: row;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px 6px 8px 12px;
}
.frame1000004938 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 5px);
  width: calc(100% + 5px);
}
.frame1000004938 > :global(.__wab_flex-container) > *,
.frame1000004938 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004938 > :global(.__wab_flex-container) > picture > img,
.frame1000004938
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5px;
}
.freeBox__fwqyl {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 124px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.text__z8Pg5 {
  display: block;
  flex-grow: 0;
  letter-spacing: 0em;
  line-height: 16px;
  width: 100%;
  flex-shrink: 1;
  font-weight: 400;
  font-size: 14px;
  min-width: 0;
}
.span {
  font-size: 10px;
  font-weight: 400;
  color: #666666;
}
.frame1000004942 {
  width: 104px;
  height: 28px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
}
.frame1000004942 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.frame1000004942 > :global(.__wab_flex-container) > *,
.frame1000004942 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004942 > :global(.__wab_flex-container) > picture > img,
.frame1000004942
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 6px;
}
.popover:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.popover__popover:global(.ant-popover) :global(.ant-popover-inner) {
  outline: none;
  padding: 0px;
}
.frame1000004943 {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(221, 221, 221, 1);
  width: auto;
  height: 28px;
  display: flex;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  border-radius: 4px;
  padding: 7px 12px 7px 8px;
}
.frame1000004943 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.frame1000004943 > :global(.__wab_flex-container) > *,
.frame1000004943 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000004943 > :global(.__wab_flex-container) > picture > img,
.frame1000004943
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.root .frame1000004943:hover {
  background: #e9e9e9;
}
.add {
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #666;
  transform: translate(0.3330000000000002px, 0.3330000000000002px);
  width: 10px;
  height: 10px;
  display: block;
  top: 18.75%;
  left: 18.75%;
}
.text__zoc1V {
  display: block;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 100%;
  color: rgba(102, 102, 102, 1);
  position: relative;
}
.tooltipContent:global(.__wab_instance) {
  max-width: 100%;
}
.emPinButton:global(.__wab_instance) {
  position: relative;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}
.emPinButtonisPinned:global(.__wab_instance) {
  flex-shrink: 0;
}
.freeBox__yO6Au {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  align-self: stretch;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
}
.freeBox__aFwZo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: stretch;
  min-width: 0;
  padding: 0px;
}
.emRowView2:global(.__wab_instance) {
  width: 100%;
  align-self: stretch;
  min-width: 0;
  flex-shrink: 0;
}
.emEstimationRow3:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 0;
}
.emEstimationRow3isPinned:global(.__wab_instance) {
  flex-shrink: 0;
}
