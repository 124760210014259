.separation-line {
  height: 0.2px;
  margin: 1em 0;
  background: rgb(228, 227, 227);
  padding: 0;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.f-column {
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.italic {
  font-style: italic;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.w-100p {
  width: 100%;
}

.text-capatalize{
    text-transform: capitalize;
}

.z-index-10 {
  z-index: 10;
}

.overflow-hidden{
  overflow: hidden;
}

.for-loop(@index) when (@index >= 0) {
  .gap-@{index} {
    gap: @index + 0px;
  }
  .h-@{index}{
    height: @index + 0px;
  }
  .w-@{index}{
    width: @index + 0px;
  }
  .p-@{index} {
    padding: @index + 0px;
  }
  .pt-@{index} {
    padding-top: @index + 0px;
  }
  .pb-@{index} {
    padding-bottom: @index + 0px;
  }
  .pr-@{index} {
    padding-right: @index + 0px;
  }
  .pl-@{index} {
    padding-left: @index + 0px;
  }
  .mto-@{index} {
    margin-top: @index + 0px;
  }
  .mbo-@{index} {
    margin-bottom: @index + 0px !important;
  }
  .mri-@{index} {
    margin-right: @index + 0px;
  }
  .mle-@{index} {
    margin-left: @index + 0px !important;
  }
  .top-@{index} {
    top: @index + 0px;
  }
  .right-@{index} {
    right: @index + 0px;
  }
  .left-@{index} {
    left: @index + 0px;
  }
  .bottom-@{index} {
    bottom: @index + 0px;
  }

  .for-loop(@index - 1); /* the next iteration's call - final-expression*/
}
.for-loop(200);
